import isPast from 'date-fns/isPast';
import subMonths from 'date-fns/subMonths';
import { useIntl } from 'react-intl';
import { DateClickHandler } from '@hqo/react-components-library/dist/molecules/calendar-view/calendar-view.interface';
import { useCalendarProps, ChangeMonthHandler } from './hooks';
import { StyledCalendarView } from './styles';

export type MultiDayPickerChange = (dates: Date[]) => void;

export interface CalendarViewProps {
  value: Date[];
  onSave: VoidFunction;
  onClear: VoidFunction;
  currentMonth: Date;
  onMonthChange: ChangeMonthHandler;
  locale: string;
  onDateClick: DateClickHandler;
}

const getClearBtn = (title: string, onClear: VoidFunction) => ({
  title,
  variant: 'secondary',
  handler: onClear,
  dataTestId: 'calendar-clear',
});

const getSaveBtn = (title: string, onSave: VoidFunction) => ({
  title,
  variant: 'primary',
  handler: onSave,
  dataTestId: 'calendar-save',
});

export const CalendarViewSecondMonth = ({
  value,
  onSave,
  onClear,
  currentMonth,
  onMonthChange,
  locale,
  onDateClick,
}: CalendarViewProps) => {
  const { formatMessage } = useIntl();
  const calendarProps = useCalendarProps(value, currentMonth, locale);
  return (
    <StyledCalendarView
      data={calendarProps.data}
      showLeftArrow={!isPast(subMonths(currentMonth, 2))}
      onLeftArrowClick={onMonthChange(-1)}
      onRightArrowClick={onMonthChange(1)}
      title={calendarProps.title}
      onDateClick={onDateClick}
      size="sm"
      isFlexStyle
      locale={locale}
      buttons={[
        getClearBtn(formatMessage({ id: 'common.form.clear' }), onClear),
        getSaveBtn(formatMessage({ id: 'common.form.save' }), onSave),
      ]}
    />
  );
};

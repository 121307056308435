import { ActionType, createReducer } from 'typesafe-actions';
import {
  createWatchlistRecord,
  deleteWatchlistRecord,
  getWatchlistMatches,
  getWatchlistMatchesCompanies,
  getWatchlistMatchesHosts,
  getWatchlistRecords,
  incrementRequestWatchlistRecordsCounter,
  removeWatchlistMatch,
  resolveWatchlistMatch,
  setPendingWatchlistMatchesCount,
  setPeopleForPendingWatchlistModal,
  setResolveWatchlistModal,
  setWatchlistMatch,
} from './actions';
import { BuildingWatchlistState, WatchlistMatch, WatchlistState } from './types';

export const initialState: WatchlistState = {};
export const initialBuildingWatchlistState: BuildingWatchlistState = {
  createWatchlistRecord: {
    loading: false,
    error: null,
  },
  watchlistRecord: {},
  pendingWatchlistModalPeople: [],
  pendingWatchlistMatchesCount: 0,
  getWatchlistRecords: {
    count: 0,
    loading: false,
    error: null,
  },
  watchlistRecords: {
    data: null,
    count: 0,
    offset: 0,
    total: 0,
  },
  getWatchlistMatches: {
    loading: false,
    error: null,
  },
  watchlistMatches: {
    data: null,
    count: 0,
    offset: 0,
    total: 0,
  },
  watchlistMatchesFilters: {
    buildingUuid: null,
    companyUuid: null,
    hostUuid: null,
    limit: 0,
    offset: 0,
    order: null,
    orderBy: 'createdAt',
    query: null,
    status: null,
  },
  liveMatches: {},
  deleteWatchlistRecord: {
    loading: false,
    error: null,
  },
  resolveWatchlistMatchForm: null,
  resolveWatchlistMatch: {
    loading: false,
    error: null,
  },
  getWatchlistMatchesCompanies: {
    loading: false,
    error: null,
  },
  watchlistMatchesCompanies: [],
  getWatchlistMatchesHosts: {
    loading: false,
    error: null,
  },
  watchlistMatchesHosts: [],
};

const handleCreateWatchlistRecordRequest = (
  state: WatchlistState,
  { payload }: ActionType<typeof createWatchlistRecord.request>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    createWatchlistRecord: {
      loading: true,
      error: null,
    },
  },
});

const handleCreateWatchlistRecordSuccess = (
  state: WatchlistState,
  { payload }: ActionType<typeof createWatchlistRecord.success>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    createWatchlistRecord: {
      loading: false,
      error: null,
    },
    watchlistRecord: {
      ...state[payload.params.buildingUuid]?.watchlistRecord,
      [payload.response.data.uuid]: {
        loading: false,
        error: null,
        record: payload.response.data,
      },
    },
  },
});

const handleCreateWatchlistRecordFailure = (
  state: WatchlistState,
  { payload }: ActionType<typeof createWatchlistRecord.failure>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    createWatchlistRecord: {
      loading: false,
      error: payload.response,
    },
  },
});

const handleSetPeopleForPendingWatchlistModal = (
  state: WatchlistState,
  { payload }: ActionType<typeof setPeopleForPendingWatchlistModal>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    pendingWatchlistModalPeople: payload.pendingWatchlistModalPeople,
  },
});

const handleSetResolveWatchlistModal = (
  state: WatchlistState,
  { payload }: ActionType<typeof setResolveWatchlistModal>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    resolveWatchlistMatchForm: payload.resolveWatchlistMatchForm,
  },
});

const handleResolveWatchlistMatchRequest = (
  state: WatchlistState,
  { payload }: ActionType<typeof resolveWatchlistMatch.request>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    resolveWatchlistMatch: {
      loading: true,
      error: null,
    },
  },
});

const handleResolveWatchlistMatchSuccess = (
  state: WatchlistState,
  { payload }: ActionType<typeof resolveWatchlistMatch.success>,
): WatchlistState => {
  const { buildingUuid } = payload.params;
  const matchUuid = payload.response.uuid;
  return ({
    ...state,
    [buildingUuid]: {
      ...state[buildingUuid],
      watchlistMatches: {
        ...state[buildingUuid].watchlistMatches,
        data: state[buildingUuid].watchlistMatches.data?.map(
          (match) => (match.uuid !== matchUuid ? match : payload.response),
        ) ?? null,
      },
      resolveWatchlistMatchForm: null,
      resolveWatchlistMatch: {
        loading: false,
        error: null,
      },
    },
  });
};

const handleResolveWatchlistMatchFailure = (
  state: WatchlistState,
  { payload }: ActionType<typeof resolveWatchlistMatch.failure>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    resolveWatchlistMatch: {
      loading: false,
      error: payload.error,
    },
  },
});

const handleSetPendingWatchlistMatchesCount = (
  state: WatchlistState,
  { payload }: ActionType<typeof setPendingWatchlistMatchesCount>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    pendingWatchlistMatchesCount: payload.count,
  },
});

const handleGetWatchlistRecordsRequest = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistRecords.request>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    getWatchlistRecords: {
      ...state[payload.buildingUuid]?.getWatchlistRecords,
      loading: true,
      error: null,
    },
  },
});

const handleIncrementWatchlistRecordsRequestCounter = (
  state: WatchlistState,
  { payload }: ActionType<typeof incrementRequestWatchlistRecordsCounter>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    getWatchlistRecords: {
      ...state[payload.buildingUuid]?.getWatchlistRecords,
      count: (state[payload.buildingUuid]?.getWatchlistRecords?.count ?? 0) + 1,
    },
  },
});

const handleGetWatchlistRecordsSuccess = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistRecords.success>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    getWatchlistRecords: {
      ...state[payload.params.buildingUuid]?.getWatchlistRecords,
      loading: false,
      error: null,
    },
    watchlistRecords: payload.response,
  },
});

const handleGetWatchlistRecordsFailure = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistRecords.failure>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    getWatchlistRecords: {
      ...state[payload.params.buildingUuid]?.getWatchlistRecords,
      loading: false,
      error: payload.response,
    },
  },
});

const handleGetWatchlistMatchesRequest = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistMatches.request>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid as string]: {
    ...state[payload.buildingUuid as string],
    getWatchlistMatches: {
      loading: true,
      error: null,
    },
  },
});

const handleGetWatchlistMatchesSuccess = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistMatches.success>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid as string]: {
    ...state[payload.params.buildingUuid as string],
    getWatchlistMatches: {
      loading: false,
      error: null,
    },
    watchlistMatches: payload.response,
    watchlistMatchesFilters: payload.params,
  },
});

const handleGetWatchlistMatchesFailure = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistMatches.failure>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid as string]: {
    ...state[payload.params.buildingUuid as string],
    getWatchlistMatches: {
      loading: false,
      error: payload.response,
    },
  },
});

const handleSetWatchlistMatch = (
  state: WatchlistState,
  { payload }: ActionType<typeof setWatchlistMatch>,
): WatchlistState => {
  const buildingUuid = payload.building.uuid;
  const watchlistMatches = state[buildingUuid]?.watchlistMatches;
  const data = watchlistMatches?.data || [];
  const matchIdx = data.findIndex((match) => match.uuid === payload.uuid);
  const hasMatch = matchIdx !== -1;

  return ({
    ...state,
    [buildingUuid]: {
      ...state[buildingUuid],
      ...(hasMatch && {
        watchlistMatches: {
          ...watchlistMatches,
          data: data.map((item, index) => (index === matchIdx ? payload : item)),
        },
      }),
      ...(!hasMatch && {
        liveMatches: {
          ...state[buildingUuid]?.liveMatches,
          [payload.uuid]: payload,
        },
      }),
    },
  });
};

const handleWatchlistSort = (watchlistMatchesData: WatchlistMatch[], order: 'ASC' | 'DESC') =>
  watchlistMatchesData.sort((a, b) => {
    if (order === 'ASC') {
      return new Date(a.visit.arrivalTime).getTime() - new Date(b.visit.arrivalTime).getTime();
    }
    return new Date(b.visit.arrivalTime).getTime() - new Date(a.visit.arrivalTime).getTime();
  });

const formatWatchlistMatches = (state: WatchlistState, match: WatchlistMatch) => {
  const watchlistMatches = { ...state[match.building.uuid].watchlistMatches };
  if (!watchlistMatches) {
    return initialBuildingWatchlistState.watchlistMatches;
  }
  const { limit, order } = state[match.building.uuid].watchlistMatchesFilters;
  let watchlistMatchesData = watchlistMatches.data || [];
  watchlistMatchesData = [match, ...watchlistMatchesData];

  if (order) {
    watchlistMatchesData = handleWatchlistSort(watchlistMatchesData, order);
  }
  if (limit) {
    watchlistMatchesData = watchlistMatchesData.slice(0, limit);
  }

  return {
    ...watchlistMatches,
    data: watchlistMatchesData,
  };
};

const handleRemoveWatchlistMatch = (
  state: WatchlistState,
  { payload }: ActionType<typeof removeWatchlistMatch>,
): WatchlistState => {
  const { buildingUuid, matchUuid } = payload;
  const match = state[buildingUuid]?.liveMatches?.[matchUuid];

  if (!match) {
    return state;
  }

  return {
    ...state,
    [buildingUuid]: {
      ...state[buildingUuid],
      liveMatches: Object.keys(state[buildingUuid]?.liveMatches || {})
        .filter((uuid) => uuid !== matchUuid)
        .reduce((acc, uuid) => ({ ...acc, [uuid]: state[buildingUuid]?.liveMatches[uuid] }), {}),
      watchlistMatches: formatWatchlistMatches(state, match),
    },
  };
};

const handleDeleteWatchlistRecordRequest = (
  state: WatchlistState,
  { payload }: ActionType<typeof deleteWatchlistRecord.request>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    deleteWatchlistRecord: {
      loading: true,
      error: null,
    },
  },
});

const handleDeleteWatchlistRecordSuccess = (
  state: WatchlistState,
  { payload }: ActionType<typeof deleteWatchlistRecord.success>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    deleteWatchlistRecord: {
      loading: false,
      error: null,
    },
  },
});

export const handleDeleteWatchlistRecordFailure = (
  state: WatchlistState,
  { payload }: ActionType<typeof deleteWatchlistRecord.failure>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    deleteWatchlistRecord: {
      loading: false,
      error: payload.response,
    },
  },
});

const handleGetWatchlistMatchesCompaniesRequest = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistMatchesCompanies.request>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    getWatchlistMatchesCompanies: {
      loading: true,
      error: null,
    },
  },
});

const handleGetWatchlistMatchesCompaniesSuccess = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistMatchesCompanies.success>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    getWatchlistMatchesCompanies: {
      loading: false,
      error: null,
    },
    watchlistMatchesCompanies: payload.response,
  },
});

const handleGetWatchlistMatchesCompaniesFailure = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistMatchesCompanies.failure>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    getWatchlistMatchesCompanies: {
      loading: false,
      error: payload.response,
    },
  },
});

const handleGetWatchlistMatchesHostsRequest = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistMatchesHosts.request>,
): WatchlistState => ({
  ...state,
  [payload.buildingUuid]: {
    ...state[payload.buildingUuid],
    getWatchlistMatchesHosts: {
      loading: true,
      error: null,
    },
  },
});

const handleGetWatchlistMatchesHostsSuccess = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistMatchesHosts.success>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    getWatchlistMatchesHosts: {
      loading: false,
      error: null,
    },
    watchlistMatchesHosts: payload.response,
  },
});

const handleGetWatchlistMatchesHostsFailure = (
  state: WatchlistState,
  { payload }: ActionType<typeof getWatchlistMatchesHosts.failure>,
): WatchlistState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    getWatchlistMatchesHosts: {
      loading: false,
      error: payload.response,
    },
  },
});

export const watchlistReducer = createReducer(initialState)
  .handleAction(createWatchlistRecord.request, handleCreateWatchlistRecordRequest)
  .handleAction(createWatchlistRecord.success, handleCreateWatchlistRecordSuccess)
  .handleAction(createWatchlistRecord.failure, handleCreateWatchlistRecordFailure)

  .handleAction(setPeopleForPendingWatchlistModal, handleSetPeopleForPendingWatchlistModal)

  .handleAction(setResolveWatchlistModal, handleSetResolveWatchlistModal)

  .handleAction(setPendingWatchlistMatchesCount, handleSetPendingWatchlistMatchesCount)

  .handleAction(getWatchlistRecords.request, handleGetWatchlistRecordsRequest)
  .handleAction(getWatchlistRecords.success, handleGetWatchlistRecordsSuccess)
  .handleAction(getWatchlistRecords.failure, handleGetWatchlistRecordsFailure)

  .handleAction(resolveWatchlistMatch.request, handleResolveWatchlistMatchRequest)
  .handleAction(resolveWatchlistMatch.success, handleResolveWatchlistMatchSuccess)
  .handleAction(resolveWatchlistMatch.failure, handleResolveWatchlistMatchFailure)

  .handleAction(getWatchlistMatches.request, handleGetWatchlistMatchesRequest)
  .handleAction(getWatchlistMatches.success, handleGetWatchlistMatchesSuccess)
  .handleAction(getWatchlistMatches.failure, handleGetWatchlistMatchesFailure)

  .handleAction(setWatchlistMatch, handleSetWatchlistMatch)

  .handleAction(removeWatchlistMatch, handleRemoveWatchlistMatch)

  .handleAction(incrementRequestWatchlistRecordsCounter, handleIncrementWatchlistRecordsRequestCounter)

  .handleAction(deleteWatchlistRecord.request, handleDeleteWatchlistRecordRequest)
  .handleAction(deleteWatchlistRecord.success, handleDeleteWatchlistRecordSuccess)
  .handleAction(deleteWatchlistRecord.failure, handleDeleteWatchlistRecordFailure)

  .handleAction(getWatchlistMatchesCompanies.request, handleGetWatchlistMatchesCompaniesRequest)
  .handleAction(getWatchlistMatchesCompanies.success, handleGetWatchlistMatchesCompaniesSuccess)
  .handleAction(getWatchlistMatchesCompanies.failure, handleGetWatchlistMatchesCompaniesFailure)

  .handleAction(getWatchlistMatchesHosts.request, handleGetWatchlistMatchesHostsRequest)
  .handleAction(getWatchlistMatchesHosts.success, handleGetWatchlistMatchesHostsSuccess)
  .handleAction(getWatchlistMatchesHosts.failure, handleGetWatchlistMatchesHostsFailure);

export interface FetchDaypassUsersPayload {
  buildingUuid: string;
  companyUuid?: string | null;
  includeAll?: boolean;
  text?: string;
  limit: number;
  offset: number;
}

export type FetchDaypassUsersPayloadDto = Omit<FetchDaypassUsersPayload, 'includeAll'>;

export interface FetchDaypassUsersSuccessDto {
  params: FetchDaypassUsersPayloadDto;
  response: DaypassUsersRO;
}

export interface FetchDaypassUsersFailureDto {
  params: FetchDaypassUsersPayloadDto;
  response: Error;
}

export interface DaypassUsersRO {
  limit: number;
  offset: number;
  total: number;
  data: DaypassUsersROData;
}

export interface DaypassUsersROData {
  asOf: string;
  users: DaypassUser[];
}

export interface DaypassUser {
  uuid: string;
  userUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  daypassStatus: DaypassStatus;
  building: DaypassUserBuilding;
  company: DaypassUserCompany;
  enabled: boolean;
}

export interface DaypassUserBuilding {
  uuid: string;
  name: string;
}

export interface DaypassUserCompany {
  uuid: string;
  name: string;
}

export enum DaypassStatus {
  AWAITING_APPROVAL = 'awaiting_approval',
  APPROVED = 'approved',
  DENIED = 'denied',
  PASS_AVAILABLE = 'pass_available',
  PASS_PRINTED = 'pass_printed',
  AWAITING_PASS = 'awaiting_pass',
}

export enum Selector {
  SELECT = 'select',
  ALL = 'all',
}

export interface CompanyDaypassUser {
  uuid: string;
  enabled: boolean;
}

export interface SetCompanyDaypassUsersAllDto {
  selector: Selector.ALL;
  enabled: boolean;
}

export interface SetCompanyDaypassUsersSelectDto {
  selector: Selector.SELECT;
  users: CompanyDaypassUser[];
}

export type SetCompanyDaypassUsersDto = SetCompanyDaypassUsersAllDto | SetCompanyDaypassUsersSelectDto;

export type SetCompanyDaypassUsersRequestDto = {
  buildingUuid: string;
  companyUuid: string;
  onSuccess?: VoidFunction;
} & SetCompanyDaypassUsersDto;

export interface SetCompanyDaypassUsersSuccessDto {
  params: SetCompanyDaypassUsersRequestDto;
}

export interface SetCompanyDaypassUsersFailureDto {
  params: SetCompanyDaypassUsersRequestDto;
  error: Error;
}

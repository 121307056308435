import { FC, Suspense, lazy } from 'react';
import { Loader } from 'app/components/loader';
import { useDayPassSettings } from 'app/components/settings/day-pass-settings/hooks/use-day-pass-settings.hook';
import { Outlet } from 'react-router';

const DayPassSettingsForm = lazy(
  () => import('app/components/settings/day-pass-settings/day-pass-settings-form/day-pass-settings-form'),
);

export const DayPassSettingsPage: FC = () => {
  const [daypassMode, loading, error] = useDayPassSettings();

  if (loading) {
    return <Loader testId="visitor-management-day-pass-settings-loader" />;
  }

  if (error) {
    return null;
  }

  return (
    <div data-testid="visitor-management-day-pass-settings-container">
      <Suspense fallback={<Loader testId="visitor-management-day-pass-settings-loader" />}>
        <DayPassSettingsForm daypassMode={daypassMode} />
      </Suspense>
      <Outlet />
    </div>
  );
};

export type UserPermissionsState = Record<string, BuildingUserPermissionsState>;

export interface BuildingUserPermissionsState {
  userPermissions: UserPermissions | null;
  loading: boolean;
  error: Error | null;
}

export interface FetchUserPermissionsPayloadDto {
  buildingUuid: string;
  permissions: string[];
}

export interface FetchUserPermissionsSuccessDto {
  params: FetchUserPermissionsPayloadDto;
  response: UserPermissions;
}

export interface FetchUserPermissionsFailureDto {
  params: FetchUserPermissionsPayloadDto;
  response: Error;
}

export type UserPermissions = Record<Permission, boolean>;

export enum Permission {
  BULK_CREATE = 'visitor-management-bulk-create',
  VIEW_TENANT_VISITORS = 'visitor-management-view-tenant-visitors',
  VIEW_CURRENT_USER_VISITORS = 'visitor-management-view-current-user-visitors',
  ADD_ANOTHER_VISIT_FOR_VISITOR = 'visitor-management-add-another-visit-for-visitor',
  CHANGE_VISITOR_HOST = 'visitor-management-change-visit-host',
  VIEW_BUILDING_VISITORS = 'visitor-management-view-building-visitors',
  ACTIVATE_VISITOR_PASS = 'visitor-management-activate-visitor-pass',
  PRINT_VISITOR_PASS = 'visitor-management-print-visitor-pass',
  NOTIFY_HOST = 'visitor-management-notify-host',
  CUSTOMIZE_VISITOR_EMAIL_SETTINGS = 'visitor-management-customize-visitor-email-settings',
  CUSTOMIZE_VISITOR_FIELDS_SETTINGS = 'visitor-management-customize-visitor-fields-settings',
  CUSTOMIZE_GENERAL_SETTINGS = 'visitor-management-customize-general-settings',
  CUSTOMIZE_ACCESS_GROUPS_SETTINGS = 'visitor-management-customize-access-groups-settings',
  REQUEST_DAYPASS = 'request-daypass',
  MANAGE_COMPANY_DAYPASS_REQUESTS = 'manage-company-daypass-requests',
  CONFIGURE_VISITOR_REGISTRATION_DAY_PASS = 'configure-visitor-registration-day-pass',
  CONFIGURE_VISITOR_REGISTRATION_WATCHLIST = 'configure-visitor-registration-watchlist',
  CONFIGURE_VISITOR_REGISTRATION_GROUPS = 'configure-visitor-registration-groups',
  MANAGE_COMPANY_DAYPASS_USERS = 'manage-company-daypass-users',
  READ_WATCHLIST = 'read-watchlist',
  VISITOR_MANAGEMENT_CREATE_VIP_VISIT = 'visitor-management-create-vip-visit',
  VISITOR_MANAGEMENT_EXPORT_AUDIT_REPORT = 'visitor-management-export-audit-report',
  CANCEL_VISITS = 'visitor-management-cancel-visits',
  CHECK_IN_VISIT = 'visitor-management-check-in-visit',
  CHECK_OUT_VISIT = 'visitor-management-check-out-visit',
  READ_WATCHLIST_MATCHES = 'read-watchlist-matches',
  CREATE_WATCHLIST = 'create-watchlist',
  SCAN_VISITOR_PASS = 'visitor-management-scan-visitor-pass',
  CREATE_DEFAULT_APP = 'visitor-management-create-default-app',
  VISITOR_MANAGEMENT_VIEW_GROUPS = 'visitor-management-view-groups',
}

import { ActionType, createReducer } from 'typesafe-actions';
import { TenantCompaniesState, BuildingTenantCompaniesState } from './types';
import { fetchTenantCompanies } from 'app/store/tenant-companies/actions';

export const initialState: TenantCompaniesState = {};
export const initialBuildingState: BuildingTenantCompaniesState = {
  tenantCompanies: null,
  loading: false,
  error: null,
};

const handleFetchTenantCompaniesRequest = (
  state: TenantCompaniesState,
  action: ActionType<typeof fetchTenantCompanies.request>,
): TenantCompaniesState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingState,
    ...state[action.payload.buildingUuid],
    loading: true,
    error: null,
  },
});

const handleFetchTenantCompaniesSuccess = (
  state: TenantCompaniesState,
  action: ActionType<typeof fetchTenantCompanies.success>,
): TenantCompaniesState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    tenantCompanies: action.payload.response.data.companies,
    loading: false,
    error: null,
  },
});

const handleFetchTenantCompaniesFailure = (
  state: TenantCompaniesState,
  action: ActionType<typeof fetchTenantCompanies.failure>,
): TenantCompaniesState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingState,
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

export const tenantCompaniesReducer = createReducer(initialState)
  .handleAction(fetchTenantCompanies.request, handleFetchTenantCompaniesRequest)
  .handleAction(fetchTenantCompanies.success, handleFetchTenantCompaniesSuccess)
  .handleAction(fetchTenantCompanies.failure, handleFetchTenantCompaniesFailure);

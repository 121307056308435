import { Loader as LoaderComponent } from '@hqo/react-components-library/dist/atoms/loader';

import { FC } from 'react';
import { LoaderWrapper } from './loader.styles';

interface LoaderProps {
  testId: string;
}

export const Loader: FC<LoaderProps> = ({ testId }) => (
  <LoaderWrapper>
    <LoaderComponent testId={testId} />
  </LoaderWrapper>
);

import { Loader } from 'app/components/loader';
import { useLoadVisit } from 'app/components/visits/hooks/use-load-visit.hook';
import { VisitUpdateForm } from 'app/components/visits/visit-update-form';
import { updateVisitLoadingSelector } from 'app/store/visits/selectors';
import { FC, lazy, Suspense } from 'react';
import { useSelector } from 'react-redux';

const VisitModal = lazy(() => import('app/components/visits/visit-modal/visit-modal.container'));

export const UpdateVisitPage: FC = () => {
  const isVisitLoaded = useLoadVisit();
  const isVisitUpdating = useSelector(updateVisitLoadingSelector);

  return (
    <Suspense fallback={<Loader testId="update-visit-form-loader" />}>
      <VisitModal
        FormComponent={VisitUpdateForm}
        isLoading={!isVisitLoaded || isVisitUpdating}
        data-testid="update-visit-page"
      />
    </Suspense>
  );
};

import { useDispatch, useSelector } from 'react-redux';
import { useServerSentEvents } from '../use-server-sent-events';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { useCallback, useMemo } from 'react';
import { apiUrlSelector } from 'app/store/config/selectors';
import { generatePath } from 'react-router';
import { DaypassUserRequest } from 'app/store/daypass-requests/types';
import { PayloadActionCreator } from 'typesafe-actions';

const DAYPASS_REQUESTS_EVENTS_PATH = '/visitor-registration/v1/locations/:buildingUuid/daypass-requests/events';

export const useDaypassRequestEventsListener = (action: PayloadActionCreator<string, DaypassUserRequest>) => {
  const buildingUuid = useSelector(buildingUuidSelector);
  const apiUrl = useSelector(apiUrlSelector);
  const url = useMemo(
    () => `${apiUrl}${generatePath(DAYPASS_REQUESTS_EVENTS_PATH, { buildingUuid })}`,
    [apiUrl, buildingUuid],
  );
  const dispatch = useDispatch();

  const handleEvent = useCallback(
    (data: string) => {
      try {
        const event: DaypassUserRequest = JSON.parse(data);

        if (!event.request || !event.person) {
          return;
        }

        dispatch(action(event));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Failed to handle event', error);
      }
    },
    [dispatch, action],
  );

  useServerSentEvents(url, handleEvent);
};

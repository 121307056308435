import styled from 'styled-components';
import { Field } from '@hqo/react-components-library/dist/atoms/field';
import { Paper } from '@hqo/react-components-library/dist/atoms/paper';
import { Popover } from '@hqo/react-components-library/dist/molecules/popover';
import { CalendarView } from '@hqo/react-components-library/dist/molecules/calendar-view';

export const CalendarFieldStyled = styled(Field)`
  &:hover {
    border-color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  }
`;

export const StyledPaper = styled(Paper)`
  padding: 12px 8px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
`;

export const StyledPopover = styled(Popover)`
  transform: translateX(-20%);
  z-index: 1;
`;

export const StyledCalendarView = styled(CalendarView)`
  margin-bottom: 30px;
  width: 100%;
  .calendar-header {
    padding-left: 5px;
  }
  .day-cell-unavailable {
    color: ${({ theme }) => theme.colors.palette.systemGreyMedium};
  }
  .day-cell-adjacentMonth {
    visibility: hidden;
  }
  .day-cell,
  .days-view {
    border: 0 none;
  }
  .day-cell {
    border-radius: 50%;
    width: 36px;
    height: 36px;
    margin: 4px;
  }
  .button-view {
    gap: 8px;
    justify-content: flex-end;
  }
`;

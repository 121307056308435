import { VisitRO } from 'app/store/visits/types';

export type UiMetadataState = Record<string, BuildingUiMetadataState>;

export interface BuildingUiMetadataState {
  uiMetadata: UiMetadata | null;
  loading: boolean;
  error: Error | null;
}

export interface FetchUiMetadataPayloadDto {
  buildingUuid: string;
}

export interface FetchUiMetadataSuccessDto {
  params: FetchUiMetadataPayloadDto;
  response: UiMetadata;
}

export interface FetchUiMetadataFailureDto {
  params: FetchUiMetadataPayloadDto;
  response: Error;
}

export interface UiMetadata {
  enabled?: boolean;
  integration_name?: string;
  app_name?: string;
  app_key?: string;
  app_uuid?: string;

  ui_metadata: VisitorRegistrationUiMetadata;
}

export enum FeatureEnablementFlagEnum {
  DISABLED = 'DISABLED',
  ENABLED_OPTIONAL = 'ENABLED_OPTIONAL',
  ENABLED_REQUIRED = 'ENABLED_REQUIRED',
}

export enum ContactInformationDisplayFlagEnum {
  EMAIL = 'email',
  PHONE = 'phone',
  EMAIL_PHONE = 'email_phone',
  NONE = 'none',
}

export enum ContactInformationRequirementFlagEnum {
  EMAIL = 'email',
  PHONE = 'phone',
  EMAIL_AND_PHONE = 'email_and_phone',
  EMAIL_OR_PHONE = 'email_or_phone',
  NONE = 'none',
}

export interface VisitorRegistrationUiMetadata extends VisitorRegistrationFlagProperties {
  alt_hosts_enabled?: boolean;
  group_visits?: boolean;
  group_visits_with_contact_waiver?: boolean;
  recurrence?: boolean;
  vip?: boolean;
  escort?: boolean;
  supports_visit_filtering?: boolean;
  visit_editing_enabled?: boolean;
  editable_fields?: Array<keyof VisitRO>;
  create_visits_enabled?: boolean;
  view_visits_enabled?: boolean;
  cancel_visits_enabled?: boolean;
  is_premier_enabled?: boolean;
  email_recipients_enabled?: boolean;
  visit_end_date_input_enabled?: boolean;
  visitors_typeahead_supported?: boolean;
}

export interface VisitorRegistrationFlagProperties {
  contact_information_display?: ContactInformationDisplayFlagEnum;
  contact_information_requirement?: ContactInformationRequirementFlagEnum;
  email_or_phone_case?: boolean;
  floor?: FeatureEnablementFlagEnum;
  suite?: FeatureEnablementFlagEnum;
  visitor_arrival_notification_preference?: FeatureEnablementFlagEnum;
  email?: FeatureEnablementFlagEnum;
  sms?: FeatureEnablementFlagEnum;
  push?: FeatureEnablementFlagEnum;
  visitor_company?: FeatureEnablementFlagEnum;
  visitor_phone?: FeatureEnablementFlagEnum;
  visitor_email?: FeatureEnablementFlagEnum;
  visit_start_time?: FeatureEnablementFlagEnum;
  visit_end_time?: FeatureEnablementFlagEnum;
  recur_end_date?: FeatureEnablementFlagEnum;
  visit_type?: FeatureEnablementFlagEnum;
  visit_reason?: FeatureEnablementFlagEnum;
  checkin_instructions?: FeatureEnablementFlagEnum;
  reason_for_visit?: FeatureEnablementFlagEnum;
}

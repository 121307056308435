import { Loader } from 'app/components/loader';
import { TabPageLayout } from 'app/components/visitor-management-tabs/tab-page-layout';
import { FC, lazy, Suspense } from 'react';
import { Outlet } from 'react-router';

const VisitorGroups = lazy(() => import('app/components/visitor-groups/visitor-groups.container'));

export const VisitorGroupsPage: FC = () => (
  <TabPageLayout data-testid="visitor-groups-page">
    <Suspense fallback={<Loader testId="visitor-groups-loader" />}>
      <VisitorGroups />
    </Suspense>
    <Outlet />
  </TabPageLayout>
);

import { FC, useCallback, useMemo } from 'react';
import { useField } from 'formik';
import { DateSelectionProps, DateSelectionType } from '@hqo/react-components-library/dist/molecules/date-selection';
import { DateSelection } from 'app/components/date-filter/date-filter.styles';
import { useFieldTranslations } from 'app/shared/hooks/use-field-translations';
import { FieldWrapper } from 'app/components/fields/field-wrapper';
import { futureDateMatcher } from 'app/utils';

interface DateInputProps {
  fieldName: string;
  translationKey: string;
  dataCy?: string;
  dataTestId?: string;
  width?: string;
  disableFutureDates?: boolean;
}

export const DateInput: FC<DateInputProps> = ({
  fieldName,
  translationKey,
  dataCy,
  dataTestId,
  width = 'auto',
  disableFutureDates,
}) => {
  const [field, { error, touched }, { setValue }] = useField(fieldName);
  const { label, placeholder } = useFieldTranslations(translationKey);
  const isErrored = touched && !!error;

  const inputProps = useMemo(() => ({
    label,
    placeholder,
    name: fieldName,
    dataCy,
    dataTestId,
    isErrored,
    variant: isErrored ? 'error' : 'master',
  }), [label, placeholder, fieldName, dataCy, dataTestId, isErrored]);

  const handleOnSave = useCallback<Exclude<DateSelectionProps['onSave'], undefined>>((value) => {
    setValue(value);
  }, []);

  return (
    <FieldWrapper touched={touched} error={error}>
      <DateSelection
        width={width}
        value={field.value}
        onSave={handleOnSave}
        type={DateSelectionType.SINGLE}
        inputProps={inputProps}
        disabledDays={disableFutureDates && futureDateMatcher}
      />
    </FieldWrapper>
  );
};

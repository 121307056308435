import { FC, useCallback } from 'react';
import { FieldWrapper } from '../field-wrapper';
import { CheckboxHqo, CheckboxProps } from '@hqo/react-components-library/dist/molecules/checkbox-hqo';
import { useField } from 'formik';
import { useFieldTranslations } from 'app/shared/hooks/use-field-translations';

interface CheckboxInputProps extends CheckboxProps {
  fieldName: string;
  translationKey: string;
  showPlaceholder?: boolean;
}

export const CheckboxInput: FC<CheckboxInputProps> = ({
  fieldName,
  translationKey,
  showPlaceholder,
  ...otherProps
}) => {
  const [{ value }, { error, touched }, { setValue, setTouched }] = useField(fieldName);
  const { label, placeholder } = useFieldTranslations(translationKey);
  const handleChange = useCallback(() => {
    setValue(!value);
    setTouched(true);
  },
  [value, setValue, setTouched]);

  return (
    <FieldWrapper flexGrow={0} touched={touched} error={error}>
      <CheckboxHqo
        subtitle={showPlaceholder ? placeholder : undefined}
        checked={value}
        data-testid={translationKey}
        label={label}
        onChange={handleChange}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
    </FieldWrapper>
  );
};

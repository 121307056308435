import { BuildingState } from 'app/store/building/types';
import { InitialState, InitialStateOptions } from 'app/store/initial-state/types';
import { useMemo } from 'react';

const getCdnUrl = (cdnUrl?: string) =>
  cdnUrl || (process.env.CDN_URL ? process.env.CDN_URL : 'https://dmevq6vd3sz66.cloudfront.net/');

export const useSanitizedBuilding = (building: InitialStateOptions['building']): BuildingState =>
  useMemo(
    () => ({
      uuid: building.uuid,
      name: building.name,
      timezone: building.timezone,
    }),
    [building.uuid, building.name, building.timezone],
  );

export const useSanitizedUser = (user: InitialStateOptions['user']) =>
  useMemo(
    () => ({
      uuid: user.uuid,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      company: user.company,
    }),
    [user.uuid, user.email, user.first_name, user.last_name, user.company],
  );

export const useInitialState = ({
  apiUrl,
  publicUrl,
  getAuthToken,
  locale,
  user,
  building,
  rootPath,
  featureFlags,
  cdnUrl,
}: InitialStateOptions) => {
  const sanitizedBuilding = useSanitizedBuilding(building);
  const sanitizedUser = useSanitizedUser(user);

  return useMemo(
    (): InitialState => ({
      config: {
        apiUrl: new URL(apiUrl).origin,
        publicUrl: publicUrl ? new URL(publicUrl).origin : process.env.PUBLIC_URL,
        cdnUrl: getCdnUrl(cdnUrl),
        getAuthToken,
        locale,
        rootPath: rootPath ?? '',
        enableVisitsRegistration: featureFlags?.enableVisitsRegistration,
        showVisRegSettingsPage: featureFlags?.showVisRegSettingsPage,
        showVisitorsTypeAhead: featureFlags?.showVisitorsTypeAhead,
        showAccessGroupsSettings: featureFlags?.showAccessGroupsSettings,
        showVisitorPasses: featureFlags?.showVisitorPasses,
        showVisitorsTab: featureFlags?.showVisitorsTab,
        showAdminNav51: featureFlags?.showAdminNav51,
        showVisitorRegistrationDaypassTab: featureFlags?.showVisitorRegistrationDaypassTab,
        showWatchlistFeature: featureFlags?.showWatchlistFeature,
        showVisitorRegistrationGroupsTab: featureFlags?.showVisitorRegistrationGroupsTab,
      },
      user: sanitizedUser,
      building: sanitizedBuilding,
    }),
    [
      apiUrl,
      getAuthToken,
      locale,
      sanitizedUser,
      sanitizedBuilding,
      rootPath,
      publicUrl,
      cdnUrl,
      featureFlags?.enableVisitsRegistration,
      featureFlags?.showVisRegSettingsPage,
      featureFlags?.showVisitorsTypeAhead,
      featureFlags?.showAccessGroupsSettings,
      featureFlags?.showVisitorPasses,
      featureFlags?.showVisitorsTab,
      featureFlags?.showAdminNav51,
      featureFlags?.showVisitorRegistrationDaypassTab,
    ],
  );
};

import { Modal } from 'app/components/modal';
import { FC } from 'react';
import { Outlet } from 'react-router';
import { useResolvedPath } from 'react-router-dom';

export const DetailsPage: FC = () => {
  const resolvedPath = useResolvedPath('..');
  return (
    <Modal basePath={resolvedPath.pathname} dataTestId="details-modal">
      <Outlet />
    </Modal>
  );
};

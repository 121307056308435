import { styled } from 'styled-components';
import { Modal as HqOModal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { ZIndex } from 'app/shared/consts/z-index';

export const CommonModalTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
`;

export const CommonModalButtonContainer = styled.div`
  display: flex;
  gap: 8px;
  justify-content: flex-end;
`;

export const StyledModal = styled(HqOModal)<{ width?: number }>`
  z-index: ${ZIndex.OVERLAY};
  & > .modal-paper {
    width: ${({ width }) => `${width ?? 490}px`};
    background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
    padding: 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: visible;
    color: ${({ theme }) => theme.colors.fontColor}
  }
`;

import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PageTabsProps, TabConfig } from './types';

const getCurrentActiveTab = (tabs: TabConfig[], currentPath: string, prefix: string): number => {
  const index = tabs.findIndex((tab) => {
    const lookupPath = [prefix, tab.route].join('/');
    return currentPath.includes(lookupPath);
  });

  return index >= 0 ? index : 0;
};

export const useCommonTabs = (pathPrefix: string, tabs: TabConfig[]): PageTabsProps => {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<number>(() => getCurrentActiveTab(tabs, location.pathname, pathPrefix));
  const navigate = useNavigate();

  const onTabChange = useCallback((index: number) => {
    setActiveTab(index);
    const targetTab = tabs[index];
    if (targetTab) {
      navigate([pathPrefix, targetTab.route].join('/'));
    }
  }, [pathPrefix, tabs, navigate]);

  return useMemo(() => ({
    tabs,
    activeTab,
    onTabChange,
  }), [tabs, onTabChange, activeTab]);
};

import { useCallback, useMemo, useState } from 'react';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';

export type ChangeMonthHandler = (amount: number) => VoidFunction;

export const useCurrentMonth = (): [Date, Date, ChangeMonthHandler] => {
  const [currentMonth, setCurrentMonth] = useState<Date>(endOfMonth(new Date()));
  const changeCurrentMonth = useCallback(
    (amount: number) => () => setCurrentMonth(addMonths(currentMonth, amount)),
    [currentMonth, setCurrentMonth],
  );
  const nextMonth = useMemo(() => addMonths(currentMonth, 1), [currentMonth]);
  return [currentMonth, nextMonth, changeCurrentMonth];
};

import { initialBuildingState } from 'app/store/hosts/reducer';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { RootState } from 'app/store/reducer';
import { createSelector } from 'reselect';

const hostsByBuildingState = (state: RootState) => state.hosts;

const hostsState = createSelector(
  [buildingUuidSelector, hostsByBuildingState],
  (buildingUuid, hostsByBuilding) => hostsByBuilding?.[buildingUuid] ?? null,
);

export const hostsSelector = createSelector(
  [hostsState],
  (hostsData) => hostsData?.hosts ?? initialBuildingState.hosts,
);

export const hostsLoadingSelector = createSelector(
  [hostsState],
  (hostsData) => hostsData?.loading ?? initialBuildingState.loading,
);

export const hostsErrorSelector = createSelector(
  [hostsState],
  (hostsData) => hostsData?.error ?? initialBuildingState.error,
);

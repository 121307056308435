import styled from 'styled-components';

interface FieldWrapperStyledProps {
  flexGrow?: number | string;
}

export const FieldWrapperStyled = styled.div<FieldWrapperStyledProps>`
  display: flex;
  flex-direction: column;
  flex-grow: ${({ flexGrow }) => flexGrow ?? 1};

  margin-bottom: 20px;
`;

export const ErrorMessage = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.admin.pink};
  margin-top: 8px;
`;

/* eslint-disable @typescript-eslint/no-use-before-define */
import { translationKey } from 'app/utils/translation-key';

const getResultKey = (key: string, parent?: string) => (parent ? translationKey(parent, key) : key);

function getValues(value: unknown, resultKey: string): Record<string, unknown> | null | undefined {
  if (typeof value === 'object') {
    return flatten(value as Record<string, unknown> | null, resultKey);
  }

  return {
    [resultKey]: value,
  };
}

export function flatten(
  obj?: Record<string, unknown> | null,
  parent?: string,
): Record<string, unknown> | null | undefined {
  if (!obj) {
    return obj;
  }

  return Object.entries(obj).reduce((result, [key, value]) => {
    const resultKey = getResultKey(key, parent);

    return {
      ...result,
      ...getValues(value, resultKey),
    };
  }, {});
}

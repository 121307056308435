import { useMemo } from 'react';
import { formatDateForUI } from 'app/utils';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { localeSelector } from 'app/store/config/selectors';

export const useDateDisplayValue = (values: Date[]): string | undefined => {
  const { formatMessage } = useIntl();
  const locale = useSelector(localeSelector);
  return useMemo(() => {
    if (!values || !values.length) {
      return undefined;
    }
    return values.length === 1
      ? formatDateForUI(values[0], locale)
      : formatMessage({ id: 'fields.visitDateTimes.multipleDays' });
  }, [locale, formatMessage, values]);
};

import { daypassRequestUpdatedForCurrentCompany, fetchDaypassRequests, setDaypassRequestApproval } from './actions';
import { BuildingDaypassRequestsState, DaypassRequestsState } from './types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: DaypassRequestsState = {};
export const initialBuildingDaypassUsersState: BuildingDaypassRequestsState = {
  daypassRequests: [],
  total: 0,
  error: null,
  loading: false,
};

const getUpdatedBuildingDaypassUsersState = (
  state: DaypassRequestsState,
  buildingUuid: string,
  updates: Partial<BuildingDaypassRequestsState>,
): DaypassRequestsState => ({
  ...state,
  [buildingUuid]: {
    ...initialBuildingDaypassUsersState,
    ...state[buildingUuid],
    ...updates,
  },
});

const handleFetchDaypassRequestsRequest = (
  state: DaypassRequestsState,
  action: ActionType<typeof fetchDaypassRequests.request>,
): DaypassRequestsState => {
  const offset = action.payload.offset || 0;
  const oldState = state[action.payload.buildingUuid] || initialBuildingDaypassUsersState;

  return getUpdatedBuildingDaypassUsersState(state, action.payload.buildingUuid, {
    daypassRequests: oldState.daypassRequests.slice(0, offset),
    loading: true,
    error: null,
  });
};

const handleFetchDaypassRequestsSuccess = (
  state: DaypassRequestsState,
  action: ActionType<typeof fetchDaypassRequests.success>,
): DaypassRequestsState => {
  const offset = action.payload.params.offset || 0;
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingDaypassUsersState;

  return getUpdatedBuildingDaypassUsersState(state, action.payload.params.buildingUuid, {
    daypassRequests: [...oldState.daypassRequests.slice(0, offset), ...action.payload.response.data.requests],
    total: action.payload.response.total,
    loading: false,
    error: null,
  });
};

const handleSetDaypassRequestApprovalRequest = (
  state: DaypassRequestsState,
  action: ActionType<typeof setDaypassRequestApproval.request>,
): DaypassRequestsState => {
  const oldState = state[action.payload.buildingUuid] || initialBuildingDaypassUsersState;

  return getUpdatedBuildingDaypassUsersState(state, action.payload.buildingUuid, {
    daypassRequests: oldState.daypassRequests,
    loading: true,
    error: null,
  });
};

const handleSetDaypassRequestApprovalSuccess = (
  state: DaypassRequestsState,
  action: ActionType<typeof setDaypassRequestApproval.success>,
): DaypassRequestsState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingDaypassUsersState;
  const updatedDaypassRequests = oldState.daypassRequests.map((daypassRequest) => {
    if (daypassRequest.request.uuid === action.payload.params.requestUuid) {
      return {
        ...daypassRequest,
        request: {
          ...daypassRequest.request,
          status: action.payload.params.status,
          approver: action.payload.approver,
          updatedAt: new Date().toISOString(),
        },
      };
    }

    return daypassRequest;
  });

  return getUpdatedBuildingDaypassUsersState(state, action.payload.params.buildingUuid, {
    ...oldState,
    daypassRequests: updatedDaypassRequests,
    loading: false,
    error: null,
  });
};

const handleFailure = (
  state: DaypassRequestsState,
  action: ActionType<typeof setDaypassRequestApproval.failure | typeof fetchDaypassRequests.failure>,
): DaypassRequestsState =>
  getUpdatedBuildingDaypassUsersState(state, action.payload.params.buildingUuid, {
    loading: false,
    error: action.payload.response,
  });

const handleDaypassRequestUpdated = (
  state: DaypassRequestsState,
  action: ActionType<typeof daypassRequestUpdatedForCurrentCompany>,
): DaypassRequestsState => {
  const { buildingUuid } = action.payload.request;
  const buildingState = state[buildingUuid] || initialBuildingDaypassUsersState;
  const existingRequestIndex = buildingState.daypassRequests.findIndex(
    (daypassRequest) => daypassRequest.request.uuid === action.payload.request.uuid,
  );

  if (existingRequestIndex > -1) {
    const updatedDaypassRequests = [...buildingState.daypassRequests];
    updatedDaypassRequests[existingRequestIndex] = action.payload;

    return {
      ...state,
      [buildingUuid]: {
        ...buildingState,
        daypassRequests: updatedDaypassRequests,
      },
    };
  }

  return {
    ...state,
    [buildingUuid]: {
      ...buildingState,
      daypassRequests: [action.payload, ...buildingState.daypassRequests],
      total: buildingState.total + 1,
    },
  };
};

export const daypassRequestsReducer = createReducer(initialState)
  .handleAction(fetchDaypassRequests.request, handleFetchDaypassRequestsRequest)
  .handleAction(fetchDaypassRequests.success, handleFetchDaypassRequestsSuccess)
  .handleAction(fetchDaypassRequests.failure, handleFailure)

  .handleAction(setDaypassRequestApproval.request, handleSetDaypassRequestApprovalRequest)
  .handleAction(setDaypassRequestApproval.success, handleSetDaypassRequestApprovalSuccess)
  .handleAction(setDaypassRequestApproval.failure, handleFailure)

  .handleAction(daypassRequestUpdatedForCurrentCompany, handleDaypassRequestUpdated);

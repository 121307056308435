import { Epic } from 'redux-observable';
import { toast } from 'react-toastify';
import { isActionOf } from 'typesafe-actions';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { fetchVisitorGroup, fetchVisitorGroups } from './actions';

export const fetchVisitorGroupsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitorGroups.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .getVisitorGroups(action.payload)
        .pipe(
          map((response) => fetchVisitorGroups.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorGroups.fetch.error' }));

            return of(fetchVisitorGroups.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const fetchVisitorGroupEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitorGroup.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .getVisitorGroup(action.payload)
        .pipe(
          map((response) => fetchVisitorGroup.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorGroup.fetch.error' }));

            return of(fetchVisitorGroup.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

import { createAsyncAction } from 'typesafe-actions';
import { FetchFilterOptions, FetchFilterOptionsError, FetchFilterOptionsSuccess, FetchGroupFilterOptionsSuccess } from 'app/store/visits-filters/types';

export const fetchVisitsHostFilterOptions = createAsyncAction(
  'FETCH_VISITS_FILTER_OPTIONS_HOST/REQUEST',
  'FETCH_VISITS_FILTER_OPTIONS_HOST/SUCCESS',
  'FETCH_VISITS_FILTER_OPTIONS_HOST/FAILURE',
)<
  FetchFilterOptions,
  FetchFilterOptionsSuccess,
  FetchFilterOptionsError
>();

export const fetchVisitsCompanyFilterOptions = createAsyncAction(
  'FETCH_VISITS_FILTER_OPTIONS_COMPANY/REQUEST',
  'FETCH_VISITS_FILTER_OPTIONS_COMPANY/SUCCESS',
  'FETCH_VISITS_FILTER_OPTIONS_COMPANY/FAILURE',
)<
  FetchFilterOptions,
  FetchFilterOptionsSuccess,
  FetchFilterOptionsError
>();

export const fetchVisitsGroupFilterOptions = createAsyncAction(
  'FETCH_VISITS_FILTER_OPTIONS_GROUP/REQUEST',
  'FETCH_VISITS_FILTER_OPTIONS_GROUP/SUCCESS',
  'FETCH_VISITS_FILTER_OPTIONS_GROUP/FAILURE',
)<
  FetchFilterOptions,
  FetchGroupFilterOptionsSuccess,
  FetchFilterOptionsError
>();

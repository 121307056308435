import { FC } from 'react';
import { useFieldTranslations } from 'app/shared/hooks/use-field-translations';
import { TimeSelectBase } from './time-select-base';
import { useUIMetadataFieldRequirements } from 'app/shared/hooks/use-ui-metadata-field-requirements/use-ui-metadata-field-requirements.hook';
import { VisitorRegistrationFlagProperties } from 'app/store/ui-metadata/types';
import { Time } from 'app/shared/types/time';

interface VisitTimeSelectProps {
  fieldTranslationKey: string;
  fieldName: string;
  uiMetadataFlag: keyof VisitorRegistrationFlagProperties;
  min?: Time;
  max?: Time;
  is12h: boolean;
}

export const TimeSelect: FC<VisitTimeSelectProps> = ({
  fieldTranslationKey,
  fieldName,
  uiMetadataFlag,
  min,
  max,
  is12h,
}) => {
  const { label, placeholder } = useFieldTranslations(fieldTranslationKey);
  const { hidden, required } = useUIMetadataFieldRequirements(uiMetadataFlag);

  if (hidden) {
    return null;
  }

  return (
    <TimeSelectBase
      required={required}
      label={label}
      placeholder={placeholder}
      fieldName={fieldName}
      min={min}
      max={max}
      is12h={is12h}
    />
  );
};

import { Navigate } from 'react-router';
import { useSettingsTabs } from '../settings/settings-tabs';
import { useSelector } from 'react-redux';
import { rootPathSelector } from 'app/store/config/selectors';

export const DefaultSettingsRouteRedirect = () => {
  const { tabs } = useSettingsTabs();
  const firstTabPath = tabs[0]?.route;
  const rootPath = useSelector(rootPathSelector) ?? '';
  const redirectPath = firstTabPath ? `${rootPath}/${firstTabPath}` : rootPath;

  return <Navigate to={redirectPath} replace />;
};

import { useFormikContext } from 'formik';
import { Button } from '@hqo/react-components-library/dist/atoms/button/button';
import { CommonModal } from 'app/components/common-modal';
import { ExportAuditReportForm } from 'app/components/audit-export/audit-export-modal.types';
import { DateInput } from 'app/components/fields/date-input';
import { RadioForm } from 'app/components/fields/radio-form';
import { AuditExportType } from 'app/store/visits/types';
import { DateRangeContainer } from './audit-export-modal.styles';

const radioButtons = [{ value: AuditExportType.CSV, label: 'CSV' }, { value: AuditExportType.PDF, label: 'PDF' }];

interface AuditExportModalViewProps {
  opened: boolean;
  onClose: VoidFunction;
  title: string;
  cancelButtonLabel: string;
  exportButtonLabel: string;
}

export const AuditExportModalView = ({ title, exportButtonLabel, cancelButtonLabel, opened, onClose }:AuditExportModalViewProps) => {
  const { submitForm } = useFormikContext<ExportAuditReportForm>();

  return (
    <CommonModal
      opened={opened}
      onClose={onClose}
      title={title}
      cancelButtonText={cancelButtonLabel}
      cta={<Button variant="Primary" text={exportButtonLabel} onClick={submitForm} />}
    >
      <div>
        <RadioForm fieldName="type" translationKey="export.startDate" options={radioButtons} />
        <DateRangeContainer>
          <DateInput fieldName="startDate" translationKey="export.startDate" disableFutureDates />
          <DateInput fieldName="endDate" translationKey="export.endDate" disableFutureDates />
        </DateRangeContainer>
      </div>
    </CommonModal>
  );
};

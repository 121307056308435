import { FC } from 'react';
import { useIntl } from 'react-intl';
import { DownloadTemplateButton } from '../download-visitor-template-button';
import { UploadVisitors } from '../upload-visitors';
import { SelectVisitorGroup } from '../select-visitor-group';
import { HeaderButtonsGroup, HeaderContainer, Title } from './header.styles';

interface HeaderProps {
  canAddVisitor: boolean;
  canSaveAsGroup?: boolean;
}

export const Header: FC<HeaderProps> = ({ canAddVisitor, canSaveAsGroup }) => {
  const { formatMessage } = useIntl();

  return (
    <HeaderContainer>
      <Title>{formatMessage({ id: 'visitForm.visitors.title' })}</Title>
      {canAddVisitor && (
        <HeaderButtonsGroup>
          <DownloadTemplateButton />
          <UploadVisitors />
          {canSaveAsGroup && <SelectVisitorGroup />}
        </HeaderButtonsGroup>
      )}
    </HeaderContainer>
  );
};

import {
  DaypassUserRequest,
  FetchDaypassRequestsFailureDto,
  FetchDaypassRequestsPayloadDto,
  FetchDaypassRequestsSuccessDto,
  SetDaypassRequestApprovalFailureDto,
  SetDaypassRequestApprovalPayloadDto,
  SetDaypassRequestApprovalSuccessDto,
} from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const fetchDaypassRequests = createAsyncAction(
  'DAYPASS_REQUESTS/FETCH_REQUEST',
  'DAYPASS_REQUESTS/FETCH_SUCCESS',
  'DAYPASS_REQUESTS/FETCH_FAILURE',
)<FetchDaypassRequestsPayloadDto, FetchDaypassRequestsSuccessDto, FetchDaypassRequestsFailureDto>();

export const setDaypassRequestApproval = createAsyncAction(
  'DAYPASS_REQUESTS/SET_APPROVAL_REQUEST',
  'DAYPASS_REQUESTS/SET_APPROVAL_SUCCESS',
  'DAYPASS_REQUESTS/SET_APPROVAL_FAILURE',
)<SetDaypassRequestApprovalPayloadDto, SetDaypassRequestApprovalSuccessDto, SetDaypassRequestApprovalFailureDto>();

export const daypassRequestUpdated = createAction('DAYPASS_REQUESTS/UPDATED')<DaypassUserRequest>();

export const daypassRequestUpdatedForCurrentCompany = createAction(
  'DAYPASS_REQUESTS/UPDATED_FOR_CURRENT_COMPANY',
)<DaypassUserRequest>();

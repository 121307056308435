import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 16px;

  & > div:first-child {
    margin-top: 14px;
  }

  & input[type='text'] {
    background-color: ${({ theme }) => theme.colors.universal.white};
    width: 245px;
  }
`;

import { createSelector } from 'reselect';
import { RootState } from 'app/store/reducer';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { BuildingDaypassRequestsState, DaypassUserRequest } from './types';

const daypassRequestsByBuildingState = (state: RootState) => state.daypassRequests;

export const daypassRequestsState = createSelector(
  [buildingUuidSelector, daypassRequestsByBuildingState],
  (buildingUuid, daypassRequestsByBuilding) => daypassRequestsByBuilding?.[buildingUuid] ?? null,
);
export const daypassRequestsSelector = createSelector(
  daypassRequestsState,
  (state: BuildingDaypassRequestsState): DaypassUserRequest[] | null => state?.daypassRequests ?? null,
);
export const daypassRequestsTotalSelector = createSelector(
  daypassRequestsState,
  (state: BuildingDaypassRequestsState): number => state?.total ?? 0,
);
export const daypassRequestsLoadingState = createSelector(
  daypassRequestsState,
  (state: BuildingDaypassRequestsState): boolean => state?.loading ?? false,
);
export const daypassRequestsErrorState = createSelector(
  daypassRequestsState,
  (state: BuildingDaypassRequestsState): Error | null => state?.error ?? null,
);

import styled from 'styled-components';
import { Panel } from 'app/components/panel';

export const Container = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  display: flex;
  flex-direction: column;
  align-items: end;
`;

export const Icon = styled.span`
  cursor: pointer;
`;

export const StyledPanel = styled(Panel)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  width: auto;
  height: auto;
  gap: 16px;
  padding: 16px;
  position: relative;
  z-index: 1;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const NoVisibilityIcon = styled.span`
  color: ${({ theme }) => theme.colors.palette.systemPink};
  font-size: 16px;
`;

import { useMemo } from 'react';
import { DropdownOption } from '@hqo/react-components-library/dist/molecules/search-input-v2/components/dropdown-item';
import { Host } from 'app/store/hosts/types';

export const useSearchHostDropdownOptions = (
  hostOptions: Host[],
  selectedHostUuid?: string,
  selectedHostName?: string,
): DropdownOption[] =>
  useMemo(() => {
    const dropdownOptions = hostOptions.map(
      ({ uuid, first_name: firstName, last_name: lastName }): DropdownOption => ({
        value: uuid,
        label: `${firstName} ${lastName}`,
        props: { className: 'search-host-dropdown-item' },
      }),
    );

    if (!dropdownOptions.find(({ value }) => value === selectedHostUuid) && selectedHostName) {
      dropdownOptions.unshift({
        value: selectedHostUuid ?? '',
        label: selectedHostName,
        props: { className: 'search-host-dropdown-item' },
      });
    }

    // TODO: remove after search field in dropdown is fixed
    return dropdownOptions.length === 0
      ? ([{ value: '', props: { className: 'no-results' } }] as DropdownOption[])
      : dropdownOptions;
  }, [hostOptions]);

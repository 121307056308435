import { DEFAULT_LOCALE, useLocaleData } from 'app/components/intl-provider/intl-provider.hooks';
import { FC, ReactNode } from 'react';

import { IntlProvider as ReactIntlProvider } from 'react-intl';

interface IntlProviderProps {
  children: ReactNode;
  configLocale?: string;
}

export const IntlProvider: FC<IntlProviderProps> = ({
  children,
  configLocale = DEFAULT_LOCALE,
}: IntlProviderProps): JSX.Element => {
  const { locale, messages } = useLocaleData(configLocale.toLowerCase());

  return (
    <ReactIntlProvider messages={messages} locale={locale} defaultLocale={DEFAULT_LOCALE}>
      {children}
    </ReactIntlProvider>
  );
};

import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  CreateWatchlistRecordParams,
  CreateWatchlistRecordSuccessDto,
  CreateWatchlistRecordErrorDto,
  GetWatchlistRecordsParams,
  GetWatchlistRecordsSuccessDto,
  GetWatchlistRecordsErrorDto,
  PendingWatchlistMatchesCount,
  PeopleForPendingWatchlistModal,
  GetWatchlistMatchesParams,
  GetWatchlistMatchesErrorDto,
  GetWatchlistMatchesSuccessDto,
  WatchlistMatch,
  RemoveWatchlistMatchParams,
  DeleteWatchlistRecordParams,
  DeleteWatchlistRecordSuccessDto,
  DeleteWatchlistRecordErrorDto,
  GetWatchlistMatchesCompaniesErrorDto,
  GetWatchlistMatchesCompaniesParams,
  GetWatchlistMatchesCompaniesSuccessDto,
  GetWatchlistMatchesHostsErrorDto,
  GetWatchlistMatchesHostsParams,
  GetWatchlistMatchesHostsSuccessDto,
  ResolveWatchlistMatchModal,
  ResolveWatchlistMatchRequest,
  ResolveWatchlistMatchSuccess,
  ResolveWatchlistMatchError,
  BulkUploadWatchlistRecords,
  IncrementRequestWatchlistRecordsCounterParams,
} from './types';

export const createWatchlistRecord = createAsyncAction(
  'WATCHLIST/CREATE_RECORD_REQUEST',
  'WATCHLIST/CREATE_RECORD_SUCCESS',
  'WATCHLIST/CREATE_RECORD_FAILURE',
)<CreateWatchlistRecordParams, CreateWatchlistRecordSuccessDto, CreateWatchlistRecordErrorDto>();

export const resolveWatchlistMatch = createAsyncAction(
  'WATCHLIST/RESOLVE_WATCHLIST_MATCH_REQUEST',
  'WATCHLIST/RESOLVE_WATCHLIST_MATCH_SUCCESS',
  'WATCHLIST/RESOLVE_WATCHLIST_MATCH_FAILURE',
)<ResolveWatchlistMatchRequest, ResolveWatchlistMatchSuccess, ResolveWatchlistMatchError>();

export const setPeopleForPendingWatchlistModal = createAction(
  'WATCHLIST/SET_PEOPLE_FOR_PENDING_WATCHLIST_MODAL',
)<PeopleForPendingWatchlistModal>();

export const setResolveWatchlistModal = createAction(
  'WATCHLIST/SET_RESOLVE_WATCHLIST_MODAL',
)<ResolveWatchlistMatchModal>();

export const setPendingWatchlistMatchesCount = createAction(
  'WATCHLIST/SET_PENDING_WATCHLIST_MATCHES_COUNT',
)<PendingWatchlistMatchesCount>();

export const bulkCreateWatchlistRecords = createAction(
  'WATCHLIST/BULK_UPLOAD_WATCHLIST_RECORDS',
)<BulkUploadWatchlistRecords>();

export const incrementRequestWatchlistRecordsCounter = createAction(
  'WATCHLIST/INCREMENT_REQUEST_WATCHLIST_RECORDS_COUNTER',
)<IncrementRequestWatchlistRecordsCounterParams>();

export const getWatchlistRecords = createAsyncAction(
  'WATCHLIST/GET_RECORDS_REQUEST',
  'WATCHLIST/GET_RECORDS_SUCCESS',
  'WATCHLIST/GET_RECORDS_FAILURE',
)<GetWatchlistRecordsParams, GetWatchlistRecordsSuccessDto, GetWatchlistRecordsErrorDto>();

export const getWatchlistMatches = createAsyncAction(
  'WATCHLIST/GET_MATCHES_REQUEST',
  'WATCHLIST/GET_MATCHES_SUCCESS',
  'WATCHLIST/GET_MATCHES_FAILURE',
)<GetWatchlistMatchesParams, GetWatchlistMatchesSuccessDto, GetWatchlistMatchesErrorDto>();

export const setWatchlistMatch = createAction(
  'WATCHLIST/SET_MATCH',
)<WatchlistMatch>();

export const removeWatchlistMatch = createAction(
  'WATCHLIST/REMOVE_MATCH',
)<RemoveWatchlistMatchParams>();

export const deleteWatchlistRecord = createAsyncAction(
  'WATCHLIST/DELETE_RECORD_REQUEST',
  'WATCHLIST/DELETE_RECORD_SUCCESS',
  'WATCHLIST/DELETE_RECORD_FAILURE',
)<DeleteWatchlistRecordParams, DeleteWatchlistRecordSuccessDto, DeleteWatchlistRecordErrorDto>();

export const getWatchlistMatchesCompanies = createAsyncAction(
  'WATCHLIST/GET_MATCHES_COMPANIES_REQUEST',
  'WATCHLIST/GET_MATCHES_COMPANIES_SUCCESS',
  'WATCHLIST/GET_MATCHES_COMPANIES_FAILURE',
)<GetWatchlistMatchesCompaniesParams, GetWatchlistMatchesCompaniesSuccessDto, GetWatchlistMatchesCompaniesErrorDto>();

export const getWatchlistMatchesHosts = createAsyncAction(
  'WATCHLIST/GET_MATCHES_HOSTS_REQUEST',
  'WATCHLIST/GET_MATCHES_HOSTS_SUCCESS',
  'WATCHLIST/GET_MATCHES_HOSTS_FAILURE',
)<GetWatchlistMatchesHostsParams, GetWatchlistMatchesHostsSuccessDto, GetWatchlistMatchesHostsErrorDto>();

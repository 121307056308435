import { parseTime } from 'app/shared/utils/date';
import { Time } from 'app/shared/types/time';
import isToday from 'date-fns/isToday';
import { useSelector } from 'react-redux';
import { timezoneSelector } from 'app/store/building/selectors';
import { localeSelector } from 'app/store/config/selectors';
import { useMemo } from 'react';
import { toNearestMinutes } from 'app/shared/utils/to-nearest-minutes';

const startOfDay: Time = '00:00';
const finalStartTime: Time = '23:30';
const finalStopTime: Time = '23:45';
const timeIntervalInSeconds = 15 * 60;

export const formatTimeValue = (dateTime: Date, timezone?: string): Time =>
  dateTime.toLocaleTimeString(undefined, {
    timeZone: timezone,
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  }) as Time;

const getStartTime = (startDate: Date | null, timezone: string): Time => {
  if (startDate && isToday(startDate)) {
    const currentDateTime = toNearestMinutes(new Date(), 15);

    return formatTimeValue(currentDateTime, timezone);
  }

  return startOfDay;
};

const getTimeBounds = (startDate: Date | null, timezone: string, startTime?: Time | null | undefined): [Time, Time] => {
  if (startTime) {
    const [hours, minutes] = parseTime(startTime);
    const startDateTime = new Date();
    startDateTime.setHours(hours, minutes, 0);
    startDateTime.setSeconds(startDateTime.getSeconds() + timeIntervalInSeconds);

    return [formatTimeValue(startDateTime), finalStopTime];
  }

  return [getStartTime(startDate, timezone), finalStartTime];
};

export const useIs12hFormat = (): boolean => {
  const locale = useSelector(localeSelector);

  return useMemo(() => !!new Date().toLocaleTimeString(locale, { timeStyle: 'short' }).match(/(am|pm)/i), [locale]);
};

interface VisitTimeOptions {
  minStartTime: Time;
  maxStartTime: Time;
  minEndTime: Time;
  maxEndTime: Time;
}

export const useVisitTimeOptions = (visitDate: Date | null, startTime: Time | null): VisitTimeOptions => {
  const timezone = useSelector(timezoneSelector);

  return useMemo(() => {
    const [minStartTime, maxStartTime] = getTimeBounds(visitDate, timezone, null);
    const [minEndTime, maxEndTime] = getTimeBounds(visitDate, timezone, startTime);

    return {
      minStartTime,
      maxStartTime,
      minEndTime,
      maxEndTime,
    };
  }, [timezone, visitDate, startTime]);
};

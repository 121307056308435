import { createAsyncAction } from 'typesafe-actions';
import { FetchBuildingSettingsParams, FetchBuildingSettingsSuccessDto, FetchBuildingSettingsErrorDto, PatchBuildingSettingsParams } from './types';

export const fetchBuildingSettings = createAsyncAction(
  'BUILDING_SETTINGS/FETCH_REQUEST',
  'BUILDING_SETTINGS/FETCH_SUCCESS',
  'BUILDING_SETTINGS/FETCH_FAILURE',
)<FetchBuildingSettingsParams, FetchBuildingSettingsSuccessDto, FetchBuildingSettingsErrorDto>();

export const patchBuildingSettings = createAsyncAction(
  'BUILDING_SETTINGS/PATCH_REQUEST',
  'BUILDING_SETTINGS/PATCH_SUCCESS',
  'BUILDING_SETTINGS/PATCH_FAILURE',
)<PatchBuildingSettingsParams, FetchBuildingSettingsSuccessDto, FetchBuildingSettingsErrorDto>();

import { Loader } from 'app/components/loader';
import { Modal } from 'app/components/modal';
import { FC, lazy, Suspense } from 'react';
import { useResolvedPath } from 'react-router';

const ManageDayPasses = lazy(() => import('app/components/manage-day-passes/manage-day-passes.container'));

export const ManageDayPassesPage: FC = () => {
  const resolvedPath = useResolvedPath('..');

  return (
    <Modal basePath={resolvedPath.pathname} dataTestId="manage-day-passes-page">
      <Suspense fallback={<Loader testId="manage-day-passes-loader" />}>
        <ManageDayPasses />
      </Suspense>
    </Modal>
  );
};

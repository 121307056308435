import { FetchVisitors } from './use-fetch-visitors.hook';
import { useCallback } from 'react';

export const useLoadDataOnOpen = (
  visitorOptionsLength: number,
  searchVisitor: FetchVisitors,
  fieldValue: string,
  handleClick: VoidFunction,
) => {
  const handleLoadDataOnOpen = useCallback(() => {
    if (!visitorOptionsLength && fieldValue) {
      searchVisitor(fieldValue);
    }

    handleClick();
  }, [visitorOptionsLength, searchVisitor, fieldValue, handleClick]);

  return handleLoadDataOnOpen;
};

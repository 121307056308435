import { FC } from 'react';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { useIntl } from 'react-intl';

interface UploadVisitorsButtonProps {
  onClick: () => void
}

export const UploadButton: FC<UploadVisitorsButtonProps> = ({ onClick }) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      variant="Outline"
      onClick={onClick}
      text={formatMessage({ id: 'visitForm.visitors.uploadVisitors' })}
      data-testid="upload-visitors-button"
    />
  );
};

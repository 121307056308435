import styled from 'styled-components';
import { Modal as HqOModal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { ZIndex } from 'app/shared/consts/z-index';
import { hexToRgba } from 'app/utils/hex-to-rgba';

export const VisitPendingModal = styled(HqOModal)`
  z-index: ${ZIndex.OVERLAY};
  background-color: ${({ theme }) => hexToRgba(theme.colors.palette.halfMoonBlue, 0.4)};

  & > .modal-paper {
    width: 490px;

    background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
    padding: 32px;
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    gap: 24px;
  }
`;

export const VisitPendingTitle = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  margin: 0;
`;

export const VisitPendingContents = styled.div`
  background-color: ${({ theme }) => theme.colors.palette.systemLightGold};
  border-radius: 4px;
  padding: 12px 24px 24px 8px;

  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Row = styled.div`
  display: flex;
  gap: 16px;
`;

export const VisitPendingIconContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.palette.systemYellow};
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 24px;
  height: 24px;
`;

export const VisitPendingIcon = styled.span`
  font-size: 20px;
  color: ${({ theme }) => theme.colors.palette.systemGold};
`;

export const VisitPendingText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.palette.systemGoldDark};
  margin: 0;
`;

export const VisitPendingUserList = styled.ul`
  padding: 0px 54px;
  margin: 0px;
`;

export const VisitPendingUser = styled.li`
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.palette.systemGoldDark};
`;

export const VisitPendingBottomText = styled(VisitPendingText)`
  padding: 0 40px;
`;

export const VisitPendingButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

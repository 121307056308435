import { Modal as HqOModal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { ZIndex } from 'app/shared/consts/z-index';
import styled from 'styled-components';

const MODAL_HEADER_HEIGHT = 108;
const MODAL_FOOTER_HEIGHT = 64;

export const Modal = styled(HqOModal)`
  z-index: ${ZIndex.MODAL};
  padding: 80px 0 0 0;

  & > .modal-paper {
    width: 100%;
    height: 100%;

    background-color: ${({ theme }) => theme.colors.palette.systemGreyLight};
    padding: 0;
    display: flex;
    flex-direction: column;
  }
`;

export const VisitModalHeader = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
  border-bottom: 1px solid ${({ theme }) => theme.colors.palette.systemGreyMedium};
`;

export const VisitModalTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.join()};
  margin: 0;
  color: ${({ theme }) => theme.colors.palette.systemBlack};
`;

export const VisitModalSubtitle = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.join()};
  margin: 0;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const VisitModalBody = styled.div`
  padding: 24px;
  flex-grow: 1;
  height: calc(100% - ${MODAL_HEADER_HEIGHT + MODAL_FOOTER_HEIGHT}px);
  overflow-y: scroll;
`;

export const VisitModalFooter = styled.div`
  padding: 16px 24px;
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  border-top: 1px solid ${({ theme }) => theme.colors.palette.systemGreyMedium};
`;

import { useEffect } from 'react';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state/use-boolean-state.hook';
import { OPEN_EXPORT_MODAL_EVENT } from 'app/components/audit-export/audit-export-modal.consts';
import { useExportFormValidationSchema, initialValues } from './hooks/use-export-from-validation-schema.hook';
import { AuditExportModalView } from './audit-export-modal-view';
import { ExportAuditReportForm } from './audit-export-modal.types';
import { useSubmitAuditExportRequest } from './hooks/use-submit-audit-export-request.hook';

export const AuditExportModal = () => {
  const { formatMessage } = useIntl();
  const { value: opened, setFalse, setTrue } = useBooleanState(false);
  const validationSchema = useExportFormValidationSchema();
  const { handleSubmit } = useSubmitAuditExportRequest(setFalse);

  useEffect(() => {
    window.addEventListener(OPEN_EXPORT_MODAL_EVENT, setTrue);

    return () => {
      window.removeEventListener(OPEN_EXPORT_MODAL_EVENT, setTrue);
    };
  }, [setTrue]);

  return (
    <Formik<ExportAuditReportForm> initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <AuditExportModalView
        opened={opened}
        onClose={setFalse}
        title={formatMessage({ id: 'modals.auditExportModal.title' })}
        exportButtonLabel={formatMessage({ id: 'modals.auditExportModal.submitButton' })}
        cancelButtonLabel={formatMessage({ id: 'common.form.cancel' })}
      />
    </Formik>
  );
};

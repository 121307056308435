import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { buildingUuidSelector } from 'app/store/building/selectors';

export const useFilterResetOnContextSwitch = (filterParams: string[]) => {
  const [_, setSearchParams] = useSearchParams();
  const currentBuildingUuid = useSelector(buildingUuidSelector);
  const prevBuildingUuid = useRef<string>(currentBuildingUuid);
  useEffect(() => {
    if (prevBuildingUuid.current !== currentBuildingUuid) {
      setSearchParams((params) => {
        filterParams.forEach((key) => {
          params.delete(key);
        });
        return params;
      });
      prevBuildingUuid.current = currentBuildingUuid;
    }
  }, [currentBuildingUuid, setSearchParams]);
};

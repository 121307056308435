import { RootState } from 'app/store';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

interface RouteGuardProps {
  selector: (state: RootState) => boolean;
}

export const RouteGuard: FC<RouteGuardProps> = ({ selector: isAvailableSelector }) => {
  const isAvailable = useSelector(isAvailableSelector);

  return isAvailable ? <Outlet /> : <Navigate to=".." replace />;
};

import { AnySchema, array, boolean, date, number, object, Schema, string, TestContext } from 'yup';
import { timeToDate } from 'app/shared/utils/date';
import { useSelector } from 'react-redux';
import { timezoneSelector } from 'app/store/building/selectors';
import { useMemo } from 'react';
import isBefore from 'date-fns/isBefore';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';
import isFuture from 'date-fns/isFuture';
import startOfDay from 'date-fns/startOfDay';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { Time } from 'app/shared/types/time';
import { useLocationInfo } from 'app/shared/hooks/use-location-info';
import { FormValues, VisibilityEnum } from '../visit-form.interfaces';
import { DeepPartial } from 'app/shared/types';
import { FeatureEnablementFlagEnum, UiMetadata } from 'app/store/ui-metadata/types';
import { useUiMetadata } from 'app/store/ui-metadata/hooks';
import { utcToZonedTime } from 'date-fns-tz';

const wrapWithUiMetadataValidation = (
  validationSchema: AnySchema<string | null | undefined>,
  errorKey?: string,
  uiMetadata?: FeatureEnablementFlagEnum,
) => {
  if (uiMetadata === FeatureEnablementFlagEnum.ENABLED_REQUIRED) {
    return validationSchema.required(errorKey);
  }

  return validationSchema;
};

const testTimeStartDate = (value: Time | null | undefined, { parent }: TestContext): boolean => {
  if (!value || isFuture(parent.startDate)) {
    return true;
  }

  const timeAtBuilding = new Date(timeToDate(value));
  const startOfDayAtBuilding = startOfDay(new Date());

  return !isBefore(timeAtBuilding, startOfDayAtBuilding);
};

const testEndDate = (value: Date | null | undefined, { parent }: TestContext): boolean => {
  const latestVisitDate = parent.visitDateTimes?.[parent.visitDateTimes.length - 1]?.startDate;
  if (!(value && latestVisitDate)) {
    return true;
  }

  return isAfter(value, latestVisitDate);
};

const testStartDate = (value: Time | null | undefined, { parent }: TestContext): boolean => {
  if (!(value && parent.endTime)) {
    return true;
  }

  return !isAfter(timeToDate(value), timeToDate(parent.endTime));
};

const testDateIsDefined = (timezone: string) =>
  (value: Date | null | undefined): boolean => {
    const startOfDayDate = startOfDay(utcToZonedTime(new Date(), timezone));

    return !!value && (isAfter(value, startOfDayDate) || isEqual(value, startOfDayDate));
  };

const testEndTime = (value: Time | null | undefined, { parent }: TestContext): boolean => {
  if (!(value && parent.startTime)) {
    return true;
  }

  return isAfter(timeToDate(value), timeToDate(parent.startTime));
};

const addRequiredCheck = (schema: Schema, isRequired: boolean, messageKey: string) => {
  if (!isRequired) {
    return schema;
  }

  return schema.required(messageKey);
};

function testVisitorEmailOrPhoneIsRequired(emailOrPhoneCase?: boolean) {
  return (_: string | null | undefined, context: TestContext): boolean => {
    if (emailOrPhoneCase) {
      const { phone, email } = context.parent;
      return !!phone || !!email;
    }
    return true;
  };
}

function testVisitorEmailIsRequired(visitorEmail?: FeatureEnablementFlagEnum) {
  return (value: string | null | undefined): boolean => {
    if (visitorEmail === FeatureEnablementFlagEnum.ENABLED_REQUIRED) {
      return !!value;
    }
    return true;
  };
}

function testVisitorPhoneIsRequired(visitorPhone?: FeatureEnablementFlagEnum) {
  return (value: string | null | undefined): boolean => {
    if (visitorPhone === FeatureEnablementFlagEnum.ENABLED_REQUIRED) {
      return !!value;
    }
    return true;
  };
}

const getValidationSchema = (
  timezone: string,
  isFloorRequired: boolean,
  uiMetadata: UiMetadata | null,
): Schema<DeepPartial<FormValues>> => {
  const visitStartTime = uiMetadata?.ui_metadata.visit_start_time;
  const visitEndTime = uiMetadata?.ui_metadata.visit_end_time;
  const visitorPhone = uiMetadata?.ui_metadata.visitor_phone;
  const visitorEmail = uiMetadata?.ui_metadata.visitor_email;
  const emailOrPhoneCase = uiMetadata?.ui_metadata.email_or_phone_case;

  return object().shape({
    host: string().required('errors.hostIsRequired'),
    floor: addRequiredCheck(string(), isFloorRequired, 'errors.floorIsRequired'),
    suite: addRequiredCheck(string(), isFloorRequired, 'errors.suiteIsRequired'),
    visitTypeId: number().required(),
    description: string().max(100).nullable(),
    saveAsGroup: boolean().required(),
    groupName: string().when('saveAsGroup', {
      is: true,
      then: (schema) => schema
        .max(30, 'errors.groupNameMaxLength')
        .required('errors.groupNameIsRequired'),
      otherwise: (schema) => schema.notRequired(),
    }),
    visitDateTimes: array().of(
      object().shape({
        startDate: date().nullable().test('date-is-defined', 'errors.visitDateIsRequired', testDateIsDefined(timezone)),
        startTime: wrapWithUiMetadataValidation(
          string<Time>()
            .nullable()
            .test('is-in-future', 'errors.startTimeShouldBeInFuture', testTimeStartDate)
            .test('is-before', 'errors.startTimeShouldBeBeforeEnd', testStartDate),
          'errors.startTimeIsRequired',
          visitStartTime,
        ),
        endTime: wrapWithUiMetadataValidation(
          string<Time>().nullable().test('is-after', 'errors.endTimeShouldBeAfterStart', testEndTime),
          'errors.endTimeIsRequired',
          visitEndTime,
        ),
      }),
    ),
    endDate: date().test('end-date-in-future', 'errors.visitDateShouldBeInFuture', testEndDate).nullable(),
    visitors: array()
      .of(
        object().shape({
          firstName: string().required('errors.pleaseEnterAValidFirstName'),
          lastName: string().required('errors.pleaseEnterAValidLastName'),
          email: string()
            .email('errors.pleaseEnterAValidEmail')
            .test('is-required', 'errors.emailOrPhoneRequired', testVisitorEmailOrPhoneIsRequired(emailOrPhoneCase))
            .test('is-required', 'errors.emailRequired', testVisitorEmailIsRequired(visitorEmail)),
          phone: string()
            .test(
              'is-valid-phone',
              'errors.pleaseEnterAValidPhoneNumber',
              (value?: string) => !value || isPossiblePhoneNumber(value),
            )
            .test('is-required', 'errors.emailOrPhoneRequired', testVisitorEmailOrPhoneIsRequired(emailOrPhoneCase))
            .test('is-required', 'errors.phoneRequired', testVisitorPhoneIsRequired(visitorPhone)),
          visibility: string<VisibilityEnum>().required(),
        }),
      )
      .required(),
  });
};

export const useValidationSchema = () => {
  const timezone = useSelector(timezoneSelector);
  const locationInfo = useLocationInfo();
  const isFloorRequired = useMemo(() => !!locationInfo?.floors?.length, [locationInfo]);
  const metadata = useUiMetadata();

  return useMemo(
    () => getValidationSchema(timezone, isFloorRequired, metadata),
    [timezone, isFloorRequired, metadata],
  );
};

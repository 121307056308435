import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { FormValues } from 'app/components/visits/visit-form/visit-form.interfaces';
import { Host } from 'app/store/hosts/types';
import { DropdownOption } from '@hqo/react-components-library/dist/molecules/dropdown/types';

export const useSearchDropdown = (hostOptions: Host[]) => {
  const { setValues } = useFormikContext<FormValues>();

  const handleSelectOption = useCallback(
    ({ value }: DropdownOption) => {
      const selectedHost = hostOptions.find(({ uuid }) => uuid === value);

      if (!selectedHost) {
        return;
      }

      setValues((previousValues) => ({
        ...previousValues,
        host: `${selectedHost.first_name} ${selectedHost.last_name}`,
        hostUuid: selectedHost.uuid,
      }));
    },
    [setValues, hostOptions],
  );

  return {
    handleSelectOption,
  };
};

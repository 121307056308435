import rootReducer, { RootState } from 'app/store/reducer';
import { useEffect, useMemo, useRef } from 'react';
import { legacy_createStore as createStore, applyMiddleware } from 'redux';
import { StateType } from 'typesafe-actions';

import { RootDependencies } from 'app/store/dependencies';

import { composeEnhancers } from 'app/store/utils';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './epics';
import { RootAction } from 'app/store/actions';
import { apiClient } from 'app/store/api-client';
import { useIntl } from 'react-intl';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { reInitializeState } from 'app/store/initial-state/actions';
import { InitialStateOptions } from 'app/store/initial-state/types';
import { useInitialState } from 'app/store/initial-state/use-initial-state.hook';

export const useStore = (options: InitialStateOptions) => {
  const initialState = useInitialState(options);
  const intl = useIntl();
  const navigateRef = useRef<NavigateFunction>();
  navigateRef.current = useNavigate();

  const reduxStore = useMemo(() => {
    const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, RootDependencies>({
      dependencies: {
        apiClient,
        intl,
        navigate: (...args) => navigateRef.current?.(...(args as Parameters<NavigateFunction>)),
      },
    });
    const enhancer = composeEnhancers(applyMiddleware(epicMiddleware));
    const store = createStore(rootReducer, initialState, enhancer);

    epicMiddleware.run(rootEpic);

    return store;
  }, [intl, navigateRef]);

  useEffect(() => {
    reduxStore.dispatch(reInitializeState(initialState));
  }, [initialState]);

  return reduxStore;
};
export type Store = StateType<ReturnType<typeof useStore>>;

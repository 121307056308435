import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { localeSelector } from 'app/store/config/selectors';
import { useHandleOnDateClick, useCurrentMonth } from './hooks';
import { CalendarViewFirstMonth } from './calendar-view-first-month';
import { CalendarViewSecondMonth } from './calendar-view-second-month';
import { FormFeatureFlags } from 'app/components/visits/visit-form/visit-form.interfaces';

export type MultiDayPickerChange = (dates: Date[]) => void;

export interface CalendarViewProps extends Pick<FormFeatureFlags, 'canSelectMultipleDates'> {
  value: Date[];
  shouldReplaceDate?: boolean;
  onChange: MultiDayPickerChange;
  onClear: VoidFunction;
}

export const CalendarView = ({
  value,
  onChange,
  onClear,
  canSelectMultipleDates,
  shouldReplaceDate,
}: CalendarViewProps) => {
  const locale = useSelector(localeSelector);
  const [dates, onDateClick] = useHandleOnDateClick(value, canSelectMultipleDates, shouldReplaceDate);
  const [currentMonth, nextMonth, changeCurrentMonth] = useCurrentMonth();
  const onSaveHandler = useCallback(() => onChange(dates), [dates, onChange]);
  return (
    <>
      <CalendarViewFirstMonth currentMonth={currentMonth} value={dates} locale={locale} onDateClick={onDateClick} />
      <CalendarViewSecondMonth
        value={dates}
        locale={locale}
        onDateClick={onDateClick}
        currentMonth={nextMonth}
        onClear={onClear}
        onSave={onSaveHandler}
        onMonthChange={changeCurrentMonth}
      />
    </>
  );
};

import { ChangeHandler } from 'app/shared/types/change-handler';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParam = (
  param: string,
  defaultValue: string = '',
): [string, ChangeHandler<string | undefined>] => {
  const [searchParams, setSearchParams] = useSearchParams();

  const value = searchParams.get(param) ?? defaultValue;

  const setSearchParam = useCallback(
    (newValue?: string) =>
      setSearchParams((previousParams: URLSearchParams) => {
        const newParams = new URLSearchParams(previousParams);
        if (newValue) {
          newParams.set(param, String(newValue));
        } else {
          newParams.delete(param);
        }

        return newParams;
      }),
    [setSearchParams],
  );

  return [value, setSearchParam];
};

import { Loader } from 'app/components/loader';
import { Modal } from 'app/components/modal';
import { FC, lazy, Suspense } from 'react';
import { useResolvedPath } from 'react-router';

const ManageWatchlist = lazy(() => import('app/components/manage-watchlist/manage-watchlist.container'));

export const ManageWatchlistPage: FC = () => {
  const resolvedPath = useResolvedPath('..');

  return (
    <Modal basePath={resolvedPath.pathname} dataTestId="manage-watchlist-page">
      <Suspense fallback={<Loader testId="manage-watchlist-loader" />}>
        <ManageWatchlist />
      </Suspense>
    </Modal>
  );
};

import { IsoDateString } from 'app/shared/types/iso-date-string';
import { getDateString } from 'app/shared/utils/get-date-string';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export const VISITS_DATE_FROM_FILTER_QUERY_PARAM = 'visitDateFrom';
export const VISITS_DATE_TO_FILTER_QUERY_PARAM = 'visitDateTo';

export const useVisitsDateFilter = () => {
  const now = getDateString(new Date());
  const [searchParams, setSearchParams] = useSearchParams();

  const fromDate = (searchParams.get(VISITS_DATE_FROM_FILTER_QUERY_PARAM) as IsoDateString) ?? now;
  const toDate = (searchParams.get(VISITS_DATE_TO_FILTER_QUERY_PARAM) as IsoDateString) ?? now;

  const setDateRange = useCallback(
    (newFromDate?: IsoDateString, newToDate?: IsoDateString) =>
      setSearchParams((previousParams: URLSearchParams) => {
        const newParams = new URLSearchParams(previousParams);

        if (newFromDate) {
          newParams.set(VISITS_DATE_FROM_FILTER_QUERY_PARAM, String(newFromDate));
        } else {
          newParams.delete(VISITS_DATE_FROM_FILTER_QUERY_PARAM);
        }

        if (newToDate) {
          newParams.set(VISITS_DATE_TO_FILTER_QUERY_PARAM, String(newToDate));
        } else {
          newParams.delete(VISITS_DATE_TO_FILTER_QUERY_PARAM);
        }

        return newParams;
      }),
    [setSearchParams],
  );

  return [fromDate, toDate, setDateRange] as const;
};

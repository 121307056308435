import { useSelector } from 'react-redux';

import {
  daypassCompaniesModeStateSelector,
  daypassCurrentCompanyModeSelector,
} from 'app/store/daypass-modes/selectors';
import { initialBuildingDaypassCompaniesModeState } from 'app/store/daypass-modes/reducer';
import { DaypassCompanyMode } from 'app/store/daypass-modes/types';

export const useDayPassSettings = (): [DaypassCompanyMode, boolean, Error | null] => {
  const { error, loading } = useSelector(daypassCompaniesModeStateSelector) || initialBuildingDaypassCompaniesModeState;
  const daypassMode = useSelector(daypassCurrentCompanyModeSelector);

  return [daypassMode, loading, error];
};

import { FC } from 'react';
import { FieldWrapper } from '../field-wrapper';
import { FormFieldType, InputProps } from '@hqo/react-components-library/dist/atoms/form-field';
import { StyledInput } from './form-input.styles';
import { useField } from 'formik';
import { useFieldTranslations } from 'app/shared/hooks/use-field-translations';

interface FormTextAreaProps extends InputProps {
  fieldName: string;
  translationKey: string;
}

export const FormTextArea: FC<FormTextAreaProps> = ({
  fieldName,
  translationKey,
  ...otherProps
}) => {
  const [field, { error, touched }] = useField(fieldName);
  const { label, placeholder } = useFieldTranslations(translationKey);
  const showError = touched && !!error;
  return (
    <FieldWrapper touched={touched} error={error}>
      <StyledInput
        label={label}
        placeholder={placeholder}
        name={fieldName}
        variant={showError ? 'error' : 'master'}
        isErrored={showError}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        dataCy={translationKey}
        fieldType={FormFieldType.TEXTAREA}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...otherProps}
      />
    </FieldWrapper>
  );
};

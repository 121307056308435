import { Time } from '../../types/time';

const splitSymbol = ':';
const decimalRadix = 10;

export const parseTime = (timeString: Time): [number, number] => {
  const [hoursString, minutesString] = timeString.split(splitSymbol);

  return [parseInt(hoursString, decimalRadix), parseInt(minutesString, decimalRadix)];
};

export const timeToDate = (time: Time): number => {
  const [hour, minute] = parseTime(time);

  return new Date().setHours(hour, minute);
};

import { useField } from 'formik';
import { FC } from 'react';

import { FormPhoneInput } from '@hqo/react-components-library/dist/molecules/international-phone-form';
import { useFieldTranslations } from 'app/shared/hooks/use-field-translations';
import { useCountry } from './international-phone-input.hooks';
import { FormPhoneInputWrapper } from './international-phone-input.styles';

interface InternationalPhoneInputProps {
  fieldName: string;
  translationKey: string;
  required?: boolean;
  disabled?: boolean;
}

export const InternationalPhoneInput: FC<InternationalPhoneInputProps> = ({
  fieldName,
  translationKey,
  required = false,
  disabled = false,
}) => {
  const { label, placeholder } = useFieldTranslations(translationKey);
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField(fieldName);
  const country = useCountry();
  const isErrored = touched && !!error;

  return (
    <FormPhoneInputWrapper touched={touched} error={error}>
      <FormPhoneInput
        fieldName={fieldName}
        placeholder={placeholder}
        label={label}
        onBlur={onBlur}
        value={value}
        setValue={setValue}
        disabled={disabled}
        country={country}
        dataCy="phone-input"
        required={required}
        isErrored={isErrored}
      />
    </FormPhoneInputWrapper>
  );
};

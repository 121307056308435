import { fetchDaypassCompaniesMode, saveDaypassMode } from './actions';
import { BuildingDaypassCompaniesModeState, DaypassCompaniesModeState, SaveDaypassModeSuccessDto } from './types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: DaypassCompaniesModeState = {};
export const initialBuildingDaypassCompaniesModeState: BuildingDaypassCompaniesModeState = {
  daypassModes: null,
  error: null,
  loading: false,
};

const handleFetchDaypassCompaniesModeRequest = (
  state: DaypassCompaniesModeState,
  action: ActionType<typeof fetchDaypassCompaniesMode.request>,
): DaypassCompaniesModeState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingDaypassCompaniesModeState,
    ...state[action.payload.buildingUuid],
    loading: true,
    error: null,
  },
});

const handleFetchDaypassCompaniesModeSuccess = (
  state: DaypassCompaniesModeState,
  action: ActionType<typeof fetchDaypassCompaniesMode.success>,
): DaypassCompaniesModeState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    daypassModes: action.payload.response.data,
    loading: false,
    error: null,
  },
});

const handleFetchDaypassCompaniesModeFailure = (
  state: DaypassCompaniesModeState,
  action: ActionType<typeof fetchDaypassCompaniesMode.failure>,
): DaypassCompaniesModeState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingDaypassCompaniesModeState,
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

const handleSaveDaypassCompaniesModeRequest = (
  state: DaypassCompaniesModeState,
  action: ActionType<typeof saveDaypassMode.request>,
): DaypassCompaniesModeState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingDaypassCompaniesModeState,
    ...state[action.payload.buildingUuid],
    loading: true,
    error: null,
  },
});

const updateDaypassModesForSave = (
  daypassModes: BuildingDaypassCompaniesModeState['daypassModes'],
  params: SaveDaypassModeSuccessDto['params'],
): BuildingDaypassCompaniesModeState['daypassModes'] => {
  if (!daypassModes) {
    return null;
  }

  if (daypassModes.findIndex((daypassMode) => daypassMode.companyUuid === params.companyUuid) === -1) {
    return [
      ...daypassModes,
      {
        companyUuid: params.companyUuid,
        daypassMode: params.daypassMode,
        approvalEnabled: params.approvalEnabled,
      },
    ];
  }

  return daypassModes.map((daypassMode) => {
    if (daypassMode.companyUuid === params.companyUuid) {
      return {
        ...daypassMode,
        daypassMode: params.daypassMode,
        approvalEnabled: params.approvalEnabled,
      };
    }

    return daypassMode;
  });
};

const handleSaveDaypassCompaniesModeSuccess = (
  state: DaypassCompaniesModeState,
  action: ActionType<typeof saveDaypassMode.success>,
): DaypassCompaniesModeState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    daypassModes: updateDaypassModesForSave(state[action.payload.params.buildingUuid]?.daypassModes, action.payload.params),
    loading: false,
    error: null,
  },
});

const handleSaveDaypassCompaniesModeFailure = (
  state: DaypassCompaniesModeState,
  action: ActionType<typeof saveDaypassMode.failure>,
): DaypassCompaniesModeState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingDaypassCompaniesModeState,
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

export const daypassCompaniesModeReducer = createReducer(initialState)
  .handleAction(fetchDaypassCompaniesMode.request, handleFetchDaypassCompaniesModeRequest)
  .handleAction(fetchDaypassCompaniesMode.success, handleFetchDaypassCompaniesModeSuccess)
  .handleAction(fetchDaypassCompaniesMode.failure, handleFetchDaypassCompaniesModeFailure)
  .handleAction(saveDaypassMode.request, handleSaveDaypassCompaniesModeRequest)
  .handleAction(saveDaypassMode.success, handleSaveDaypassCompaniesModeSuccess)
  .handleAction(saveDaypassMode.failure, handleSaveDaypassCompaniesModeFailure);

import { FC, lazy, Suspense } from 'react';
import { Outlet } from 'react-router';
import { useParams } from 'react-router-dom';
import { ENTITY_UUID_PARAMETER } from 'app/shared/consts';
import { Loader } from 'app/components/loader';

const VisitorDetails = lazy(() => import('app/components/details/visitor/visitor-details'));

export const VisitorDetailsPage: FC = () => {
  const visitorUuid = useParams()?.[ENTITY_UUID_PARAMETER] ?? '';

  return (
    <div data-testid="visitor-details-page">
      <Suspense fallback={<Loader testId="visitor-details-loader" />}>
        <VisitorDetails uuid={visitorUuid} />
      </Suspense>
      <Outlet />
    </div>
  );
};

import { buildingUuidSelector } from 'app/store/building/selectors';
import { RootState } from 'app/store/reducer';
import { initialBuildingVisitsState } from 'app/store/visits/reducer';
import { createSelector } from 'reselect';
import { isDefined } from '../../shared/utils/is-defined';
import { VisitDOWithLoadingInfo } from './types';

const visitsByBuildingState = (state: RootState) => state.visits;

const visitsState = createSelector(
  [buildingUuidSelector, visitsByBuildingState],
  (buildingUuid, visitsByBuilding) => visitsByBuilding?.[buildingUuid] ?? initialBuildingVisitsState,
);

export const visitUuidsSelector = createSelector([visitsState], (visitsData) => visitsData?.visits);

export const visitsMapUuidsSelector = createSelector([visitsState], (visitsData) =>
  Object.keys(visitsData?.visitsMap ?? {}).sort(),
);

export const visitsSelector = createSelector(
  [visitsState],
  (visitsData): VisitDOWithLoadingInfo[] =>
    visitsData?.visits
      ?.map((visitUuid: string) => {
        const visitState = visitsData?.visitsMap?.[visitUuid];

        if (!visitState?.visit) {
          return null;
        }

        return {
          ...visitState.visit,
          loading: visitState.loading,
        };
      })
      ?.filter(isDefined) ?? [],
);

export const visitStateSelector = createSelector(
  [visitsState, (_state: RootState, visitUuid: string) => visitUuid],
  (visitsData, visitUuid) => visitsData?.visitsMap?.[visitUuid] ?? null,
);

export const visitLoadingSelector = createSelector([visitStateSelector], (visitState) => visitState?.loading ?? false);

export const visitSelector = createSelector([visitStateSelector], (visitState) => visitState?.visit ?? null);

export const visitErrorSelector = createSelector([visitStateSelector], (visitState) => visitState?.error ?? null);

export const openedVisitSelector = (visitUuid: string) =>
  createSelector([visitsState], (visitsData) => {
    const visit = visitsData?.openedVisit;

    return visit?.uuid === visitUuid ? visit : null;
  });

export const openedVisitLoadingSelector = createSelector(
  [visitsState],
  (visitsData) => visitsData?.getVisitRequest.loading,
);

export const visitsTotalSelector = createSelector(
  [visitsState],
  (visitsData) => visitsData?.total ?? initialBuildingVisitsState.total,
);

export const visitsLoadingSelector = createSelector(
  [visitsState],
  (visitsData) => visitsData?.loading ?? initialBuildingVisitsState.loading,
);

export const createVisitsLoadingSelector = createSelector(
  [visitsState],
  (visitsData) => visitsData?.createVisitRequest.loading ?? initialBuildingVisitsState.createVisitRequest.loading,
);

export const updateVisitLoadingSelector = createSelector(
  [visitsState],
  (visitsData) => visitsData?.updateVisitRequest.loading ?? initialBuildingVisitsState.updateVisitRequest.loading,
);

export const searchVisitLoadingSelector = createSelector(
  [visitsState],
  (visitsData) =>
    visitsData?.searchByCredentialRequestLoading ?? initialBuildingVisitsState.searchByCredentialRequestLoading,
);

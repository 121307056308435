import { createSelector } from 'reselect';
import { RootState } from 'app/store/reducer';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { BuildingDaypassCompaniesModeState, DaypassMode } from './types';
import { userCompanyUuidSelector } from '../user/selectors';

const DEFAULT_COMPANY_DAYPASS_MODE_SETTINGS = {
  daypassMode: DaypassMode.disabled,
  approvalEnabled: false,
};

const daypassCompaniesByBuildingState = (state: RootState) => state.daypassModes;

export const daypassCompaniesModeStateSelector = createSelector(
  [buildingUuidSelector, daypassCompaniesByBuildingState],
  (buildingUuid, daypassCompaniesByBuilding): BuildingDaypassCompaniesModeState | null =>
    daypassCompaniesByBuilding?.[buildingUuid] ?? null,
);

export const daypassCompaniesModeSelector = createSelector(
  daypassCompaniesModeStateSelector,
  (daypassCompaniesData) => daypassCompaniesData?.daypassModes ?? [],
);

export const daypassCurrentCompanyModeSelector = createSelector(
  [daypassCompaniesModeSelector, userCompanyUuidSelector],
  (daypassCompaniesMode, userCompanyUuid) =>
    daypassCompaniesMode.find(({ companyUuid }) => companyUuid === userCompanyUuid) ?? {
      companyUuid: userCompanyUuid,
      ...DEFAULT_COMPANY_DAYPASS_MODE_SETTINGS,
    },
);

export const hasDaypassEnabledForCompanySelector = createSelector(
  daypassCurrentCompanyModeSelector,
  (daypassCompaniesMode) =>
    !!daypassCompaniesMode?.daypassMode && daypassCompaniesMode.daypassMode !== DaypassMode.disabled,
);

export const hasDaypassApprovalEnabledSelector = createSelector(
  [hasDaypassEnabledForCompanySelector, daypassCurrentCompanyModeSelector],
  (isEnabledForCompany, daypassCompaniesMode) => isEnabledForCompany && !!daypassCompaniesMode?.approvalEnabled,
);

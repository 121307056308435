import { RootState } from 'app/store/reducer';
import { createSelector } from 'reselect';

const userState = (state: RootState) => state.user;

export const userSelector = createSelector(
  [userState],
  (user) => user,
);

export const userCompanyUuidSelector = createSelector(
  [userState],
  (user) => user?.company?.uuid ?? null,
);

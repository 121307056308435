import {
  FetchCompaniesErrorDto,
  FetchCompaniesParams,
  FetchCompaniesSuccessDto,
} from 'app/store/tenant-companies/types';
import { createAsyncAction } from 'typesafe-actions';

export const fetchTenantCompanies = createAsyncAction(
  'TENANT_COMPANIES/FETCH_REQUEST',
  'TENANT_COMPANIES/FETCH_SUCCESS',
  'TENANT_COMPANIES/FETCH_FAILURE',
)<FetchCompaniesParams, FetchCompaniesSuccessDto, FetchCompaniesErrorDto>();

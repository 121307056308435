import { useDebounceFetchVisitors } from './use-debounce-fetch-visitors.hook';
import { useCallback } from 'react';

export const useSearchVisitor = () => {
  const { visitors, isLoading, fetchVisitors, setVisitors } = useDebounceFetchVisitors();

  const clearSearch = useCallback(() => { setVisitors([]); }, [setVisitors]);

  return { visitorOptions: visitors, searchVisitor: fetchVisitors, isLoading, clearSearch };
};

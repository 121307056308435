import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Formik } from 'formik';

import { useFormSubmitHandler, useInitialValues } from 'app/components/visits/visit-form/hooks/creation';
import { VisitModalView } from 'app/components/visits/visit-modal/visit-modal';
import { hasVisitorGroupsEnabledSelector } from 'app/store/building-settings/selectors';
import { useValidationSchema } from './visit-form/hooks';
import { FormValues, VisitFormProps } from './visit-form/visit-form.interfaces';

export const VisitCreationForm: FC<VisitFormProps> = ({ onClose }) => {
  const { handleSubmit } = useFormSubmitHandler();
  const validationSchema = useValidationSchema();
  const initialValues = useInitialValues();
  const canSaveAsGroup = useSelector(hasVisitorGroupsEnabledSelector);

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <VisitModalView
        onClose={onClose}
        translationPrefix="modals.newVisit"
        canAddVisitor
        canEditVisitTypes
        canEditVisitorDetails
        canSelectMultipleDates
        canEditHost
        canSaveAsGroup={canSaveAsGroup}
        shouldReplaceInitialDate
        canChangeVisitVisibility
      />
    </Formik>
  );
};

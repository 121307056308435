import { useCallback, useRef } from 'react';
import { useOnClickOutside } from '@hqo/react-components-library/dist/hooks/use-on-click-outside';
import { useField } from 'formik';
import { VisibilityEnum } from 'app/components/visits/visit-form/visit-form.interfaces';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';

interface Props {
  fieldNamePrefix: string;
}

export interface UseMoreOptionsResponse {
  onChange: VoidFunction;
  ref: React.RefObject<HTMLDivElement>;
  toggleVisibility: VoidFunction;
  visible: boolean;
  value: string;
}

export const useMoreOptions: ({ fieldNamePrefix }: Props) => UseMoreOptionsResponse = ({ fieldNamePrefix }: Props): UseMoreOptionsResponse => {
  const { value: visible, setFalse, toggle } = useBooleanState(false);

  const ref = useRef<HTMLDivElement>(null);

  const toggleVisibility: VoidFunction = useCallback((): void => {
    toggle();
  }, []);

  const [{ value }, , { setValue }] = useField(`${fieldNamePrefix}.visibility`);

  const onChange = useCallback((): void => {
    setValue(value === VisibilityEnum.HOST_AND_CREATOR ? VisibilityEnum.PUBLIC : VisibilityEnum.HOST_AND_CREATOR);
  }, [setValue, value]);

  useOnClickOutside(() => setFalse(), [ref]);

  return { onChange, ref, toggleVisibility, visible, value };
};

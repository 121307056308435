import { StateType } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { configReducer } from 'app/store/config/reducer';
import { userReducer } from 'app/store/user/reducer';
import { buildingReducer } from 'app/store/building/reducer';
import { visitsReducer } from 'app/store/visits/reducer';
import { visitsMetricsReducer } from 'app/store/visits-metrics/reducer';
import { settingsReducer } from 'app/store/settings/reducer';
import { locationInfoReducer } from 'app/store/location-info/reducer';
import { visitsFiltersReducer } from 'app/store/visits-filters/reducer';
import { accessGroupsReducer } from 'app/store/access-groups/reducer';
import { tenantCompaniesReducer } from 'app/store/tenant-companies/reducer';
import { visitorPassesReducer } from 'app/store/visitor-passes/reducer';
import { hostsReducer } from 'app/store/hosts/reducer';
import { visitorsReducer } from 'app/store/visitors/reducer';
import { visitorReducer } from 'app/store/visitor/reducer';
import { visitorVisitsReducer } from 'app/store/visitor-visits/reducer';
import { uiMetadataReducer } from 'app/store/ui-metadata/reducer';
import { userPermissionsReducer } from 'app/store/user-permissions/reducer';
import { hostDetailsReducer } from 'app/store/host-details/reducer';
import { hostVisitsReducer } from 'app/store/host-visits/reducer';
import { daypassUsersReducer } from 'app/store/daypass-users/reducer';
import { daypassRequestsReducer } from 'app/store/daypass-requests/reducer';
import { daypassCompaniesReducer } from './daypass-companies/reducer';
import { daypassCompaniesModeReducer } from './daypass-modes/reducer';
import { buildingSettingsReducer } from 'app/store/building-settings/reducer';
import { watchlistReducer } from 'app/store/watchlist/reducer';
import { visitorGroupsReducer } from 'app/store/visitor-groups/reducer';

const rootReducer = combineReducers({
  config: configReducer,
  user: userReducer,
  building: buildingReducer,
  visits: visitsReducer,
  visitsMetrics: visitsMetricsReducer,
  visitsFilters: visitsFiltersReducer,
  settings: settingsReducer,
  locationInfo: locationInfoReducer,
  accessGroups: accessGroupsReducer,
  tenantCompanies: tenantCompaniesReducer,
  visitorPasses: visitorPassesReducer,
  hosts: hostsReducer,
  visitors: visitorsReducer,
  visitorVisits: visitorVisitsReducer,
  visitor: visitorReducer,
  uiMetadata: uiMetadataReducer,
  userPermissions: userPermissionsReducer,
  hostDetails: hostDetailsReducer,
  hostVisits: hostVisitsReducer,
  daypassUsers: daypassUsersReducer,
  daypassRequests: daypassRequestsReducer,
  daypassCompanies: daypassCompaniesReducer,
  daypassModes: daypassCompaniesModeReducer,
  buildingSettings: buildingSettingsReducer,
  watchlist: watchlistReducer,
  visitorGroups: visitorGroupsReducer,
});

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;

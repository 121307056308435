import { ChangeEvent, useCallback } from 'react';
import Papa, { ParseResult } from 'papaparse';
import { Visitor } from 'app/components/visits/visit-form/visit-form.interfaces';

export const useFileUpload = (onComplete: (results: ParseResult<Visitor>) => Promise<void>) =>
  useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        Papa.parse<Visitor>(file, {
          header: true,
          dynamicTyping: false,
          skipEmptyLines: true,
          delimiter: ',',
          complete: onComplete,
        });
      }
    },
    [onComplete],
  );

import { DateSelectionType } from '@hqo/react-components-library/dist/molecules/date-selection';
import { FC } from 'react';
import { useDateFilter } from 'app/components/date-filter/use-date-filter.hook';
import { ArrowButton, DateFilterWrapper, DateSelection } from 'app/components/date-filter/date-filter.styles';
import { IsoDateString } from 'app/shared/types/iso-date-string';
import { useIntl } from 'react-intl';

interface DateFilterProps {
  from: IsoDateString;
  to: IsoDateString;
  onChange: (from?: IsoDateString, to?: IsoDateString) => void;
}

const dateInputProps = {
  dataTestId: 'date-filter-input',
};

export const DateFilter: FC<DateFilterProps> = ({ from, to, onChange: handleChange }) => {
  const { formatMessage } = useIntl();
  const { value, handleSave, handleClear, handlePreviousClick, handleNextClick, isSingleSelect, toggleRangeSelect } = useDateFilter(from, to, handleChange);

  return (
    <DateFilterWrapper>
      <DateSelection
        value={value}
        onSave={handleSave}
        onClear={handleClear}
        type={isSingleSelect ? DateSelectionType.SINGLE : DateSelectionType.RANGE}
        inputProps={dateInputProps}
        onToggleRange={toggleRangeSelect}
        showRangeToggle
        rangeToggleText={formatMessage({ id: 'filters.dateFilter.rangeToggle' })}
      />
      {isSingleSelect && (
        <>
          <ArrowButton
            onClick={handlePreviousClick}
            variant="Plain"
            Icon={<span className="material-icons">chevron_left</span>}
            aria-label="Previous date"
          />
          <ArrowButton
            onClick={handleNextClick}
            variant="Plain"
            Icon={<span className="material-icons">chevron_right</span>}
            aria-label="Next date"
          />
        </>
      )}
    </DateFilterWrapper>
  );
};

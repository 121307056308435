import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueryParam } from 'app/shared/hooks/use-query-param';
import { filtersGroupOptionsSelector } from 'app/store/visits-filters/selectors';
import { VisitFilterParams } from 'app/shared/consts/visit-filter-params';
import type { FilterOption } from 'app/shared/types/filter-option';

export interface GroupFiltersQueryDto {
  groupUuid?: string;
}

export interface GroupFilters {
  filters: GroupFiltersQueryDto;
  setGroupUuid: (value?: string) => void;
}

const getValueOrUndefined = (options: FilterOption[], value: string) => {
  const hasValue = options.some(({ value: uuid }) => uuid === value);
  return hasValue ? value : undefined;
};

export const useVisitorGroupFilters = (): GroupFilters => {
  const [groupUuid, setGroupUuid] = useQueryParam(VisitFilterParams.GROUP_UUID);
  const groupOptions = useSelector(filtersGroupOptionsSelector);

  const filters: GroupFiltersQueryDto = useMemo(
    () => ({
      groupUuid: getValueOrUndefined(groupOptions, groupUuid),
    }),
    [groupUuid, groupOptions],
  );

  return useMemo(
    () => ({
      filters,
      setGroupUuid,
    }),
    [filters, setGroupUuid],
  );
};

import { useCalendarProps } from './hooks';
import { StyledCalendarView } from './styles';
import { DateClickHandler } from '@hqo/react-components-library/dist/molecules/calendar-view/calendar-view.interface';

export interface CalendarViewProps {
  value: Date[];
  currentMonth: Date;
  onDateClick: DateClickHandler;
  locale: string;
}

export const CalendarViewFirstMonth = ({ value, currentMonth, locale, onDateClick }: CalendarViewProps) => {
  const calendarProps = useCalendarProps(value, currentMonth, locale);
  return (
    <StyledCalendarView
      data={calendarProps.data}
      showLeftArrow={false}
      showRightArrow={false}
      title={calendarProps.title}
      onDateClick={onDateClick}
      size="sm"
      isFlexStyle
      locale={locale}
    />
  );
};

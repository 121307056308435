import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { useRootPageTabs } from 'app/components/visitor-management-tabs/root-page-tabs';
import { PageHeader } from 'app/pages/root/root.styles';
import { rootPathSelector } from 'app/store/config/selectors';
import { useFetchUiMetadata } from 'app/store/ui-metadata/hooks';
import { SETTINGS_PATH } from 'app/shared/consts';
import { useCreateVisitButton } from 'app/components/visits/create-visit-button';
import { VisitorPassUpdates } from 'app/components/visitor-pass-updates';
import { hasSomeManageSettingsPermissions } from 'app/store/user-permissions/selectors';
import { useSecondaryCta } from 'app/components/secondary-cta';
import { VisitPendingModal } from 'app/components/visits/visits-table/modals/visit-pending-modal';
import { WatchlistUpdates } from 'app/components/watchlist/watchlist-updates';

export const VisitsRootPage = (): JSX.Element => {
  const ctaProps = useCreateVisitButton();
  const rootPath = useSelector(rootPathSelector);
  const navigate = useNavigate();
  const intl = useIntl();
  const showVisRegSettingsPage = useSelector(hasSomeManageSettingsPermissions);
  useFetchUiMetadata();
  const { tabs, activeTab, onTabChange } = useRootPageTabs(rootPath);
  const secondaryCta = useSecondaryCta(tabs[activeTab]?.secondaryCtaType);

  const onSettingsClick = useCallback(() => {
    navigate(`${rootPath}/${SETTINGS_PATH}`);
  }, [navigate]);

  return (
    <>
      <PageHeader
        data-cy="visits-root-page"
        title={intl.formatMessage({ id: 'title' })}
        onSettingsIconClick={onSettingsClick}
        isSettingsIconVisible={showVisRegSettingsPage}
        ctaButtonText={ctaProps.ctaButtonText}
        onCtaButtonClick={ctaProps.onCtaButtonClick}
        ctaButtonProps={ctaProps.ctaButtonProps}
        activeTab={activeTab}
        tabs={tabs}
        onTabChange={onTabChange}
        secondaryButtonText={secondaryCta?.secondaryButtonText}
        secondaryButtonProps={secondaryCta?.secondaryButtonProps}
        onSecondaryButtonClick={secondaryCta?.onSecondaryButtonClick}
        tabSize="auto"
      />
      <Outlet />
      <VisitorPassUpdates />
      <VisitPendingModal />
      <WatchlistUpdates />
    </>
  );
};

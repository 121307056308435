export enum DaypassMode {
  disabled = 'disabled',
  enabled = 'enabled',
  enabledAll = 'enabledAll',
}

export interface DaypassCompanyMode {
  companyUuid: string;
  daypassMode: DaypassMode;
  approvalEnabled: boolean;
}

export interface BuildingDaypassCompaniesModeState {
  daypassModes: DaypassCompanyMode[] | null;
  loading: boolean;
  error: Error | null;
}

export type DaypassCompaniesModeState = Record<string, BuildingDaypassCompaniesModeState>;

export interface DaypassCompaniesModeRO {
  data: DaypassCompanyMode[];
}

export interface FetchDaypassCompaniesModePayloadDto {
  buildingUuid: string;
}

export interface FetchDaypassCompaniesModeSuccessDto {
  params: FetchDaypassCompaniesModePayloadDto;
  response: DaypassCompaniesModeRO;
}

export interface FetchDaypassCompaniesModeFailureDto {
  params: FetchDaypassCompaniesModePayloadDto;
  response: Error;
}

export interface SaveDaypassModePayloadDto {
  approvalEnabled: boolean;
  buildingUuid: string;
  companyUuid: string;
  daypassMode: DaypassMode;
}

export interface SaveDaypassModeSuccessDto {
  params: SaveDaypassModePayloadDto;
}

export interface SaveDaypassModeFailureDto {
  params: SaveDaypassModePayloadDto;
  response: Error;
}

export interface DaypassSettingsValues {
  approvalEnabled: boolean;
  daypassMode: DaypassMode;
}

export enum DayPassRadioGroupFieldsNames {
  daypassMode = 'daypassMode',
  approvalEnabled = 'approvalEnabled',
}

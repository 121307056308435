import { buildingUuidSelector } from 'app/store/building/selectors';
import { RootState } from 'app/store/reducer';
import { createSelector } from 'reselect';

const visitsMetricsByBuildingState = (state: RootState) => state.visitsMetrics;

const visitsMetricsState = createSelector(
  [buildingUuidSelector, visitsMetricsByBuildingState],
  (buildingUuid, visitsMetricsByBuilding) => visitsMetricsByBuilding?.[buildingUuid] ?? null,
);

export const visitsMetricsSelector = createSelector(
  [visitsMetricsState],
  (visitsMetricsData) => visitsMetricsData?.visitsMetrics ?? null,
);

import { Panel } from 'app/components/panel';
import { styled } from 'styled-components';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

import backgroundImage from './background.png';

export const SplashScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const SplashScreenPanel = styled(Panel)`
  max-width: 764px;
  max-height: 400px;
  padding: 72px;

  background-image: url(${backgroundImage});
  background-position-x: right;
  background-position-y: bottom;
  background-size: contain;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
`;

export const SplashScreenPanelTitle = styled.h1`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: 2.25rem;
  font-weight: 500;
  margin: 0;
  color: ${({ theme }) => theme.colors.palette.systemBlack};
`;

export const SplashScreenMessage = styled.h3`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: 1.25rem;
  font-weight: 500;
  max-width: 456px;
  margin: 0;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const SplashScreenButton = styled(Button)`
  width: auto;
  height: auto;
  margin-top: auto;
`;

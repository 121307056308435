import { ActionType, createReducer } from 'typesafe-actions';
import {
  cancelVisit,
  checkInVisit,
  checkOutVisit,
  createVisits,
  fetchVisit,
  fetchVisits,
  searchVisitByCredential,
  updateVisit,
  visitUpdatedEventForCurrentView,
} from './actions';
import { VisitsState, BuildingVisitsState, VisitStatus, VisitRO } from './types';

export const initialState: VisitsState = {};
export const initialBuildingVisitsState: BuildingVisitsState = {
  visits: [],
  visitsMap: {},
  total: 0,
  loading: false,
  error: null,

  createVisitRequest: {
    loading: false,
    error: null,
  },

  openedVisit: null,
  getVisitRequest: {
    loading: false,
    error: null,
  },

  updateVisitRequest: {
    loading: false,
    error: null,
  },

  searchByCredentialRequestLoading: false,
};

const getVisitsStoreData = (
  buildingState: BuildingVisitsState,
  visits: VisitRO[],
): Pick<BuildingVisitsState, 'visits' | 'visitsMap'> =>
  visits.reduce(
    (acc, visit) => {
      const visitState = acc.visitsMap[visit.uuid];

      return {
        visits: [...acc.visits, visit.uuid],
        visitsMap: {
          ...acc.visitsMap,
          [visit.uuid]: {
            loading: visitState?.loading ?? false,
            error: visitState?.error ?? null,
            visit,
          },
        },
      };
    },
    {
      visits: [],
      visitsMap: { ...buildingState.visitsMap },
    } as Pick<BuildingVisitsState, 'visits' | 'visitsMap'>,
  );

const handleFetchVisitsRequest = (state: VisitsState, action: ActionType<typeof fetchVisits.request>): VisitsState => {
  const oldState = state[action.payload.buildingUuid] || initialBuildingVisitsState;
  const offset = action.payload.offset ?? 0;

  return {
    ...state,
    [action.payload.buildingUuid]: {
      ...initialBuildingVisitsState,
      ...state[action.payload.buildingUuid],
      visits: [...oldState.visits.slice(0, offset)],
      loading: true,
      error: null,
    },
  };
};

const handleFetchVisitsSuccess = (state: VisitsState, action: ActionType<typeof fetchVisits.success>): VisitsState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitsState;
  const { visits: newVisits, visitsMap } = getVisitsStoreData(oldState, action.payload.response.data);
  const offset = action.payload.params.offset ?? 0;
  const visits = [...oldState.visits.slice(0, offset), ...newVisits];
  const allVisitsMap = {
    ...oldState.visitsMap,
    ...visitsMap,
  };

  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...oldState,
      loading: false,
      error: null,
      total: action.payload.response.total,
      visits,
      visitsMap: Object.fromEntries(visits.map((visitUuid) => [visitUuid, allVisitsMap[visitUuid]])),
    },
  };
};

const handleFetchVisitsFailure = (state: VisitsState, action: ActionType<typeof fetchVisits.failure>): VisitsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingVisitsState,
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

const handleFetchVisitRequest = (state: VisitsState, action: ActionType<typeof fetchVisit.request>): VisitsState => {
  const oldState = state[action.payload.buildingUuid] || initialBuildingVisitsState;

  return {
    ...state,
    [action.payload.buildingUuid]: {
      ...oldState,
      visitsMap: {
        ...oldState.visitsMap,
        [action.payload.visitUuid]: {
          ...oldState.visitsMap[action.payload.visitUuid],
          loading: true,
        },
      },
      getVisitRequest: {
        loading: true,
        error: null,
      },
    },
  };
};

const handleFetchVisitSuccess = (state: VisitsState, action: ActionType<typeof fetchVisit.success>): VisitsState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitsState;

  return {
    [action.payload.params.buildingUuid]: {
      ...oldState,
      openedVisit: action.payload.response,
      visitsMap: {
        ...oldState.visitsMap,
        [action.payload.response.uuid]: {
          error: null,
          loading: false,
          visit: action.payload.response,
        },
      },
      getVisitRequest: {
        loading: false,
        error: null,
      },
    },
  };
};

const handleFetchVisitFailure = (state: VisitsState, action: ActionType<typeof fetchVisit.failure>): VisitsState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitsState;
  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...oldState,
      visitsMap: {
        ...oldState.visitsMap,
        [action.payload.params.visitUuid]: {
          ...oldState.visitsMap[action.payload.params.visitUuid],
          loading: false,
          error: action.payload.response,
        },
      },
      getVisitRequest: {
        loading: false,
        error: action.payload.response,
      },
    },
  };
};

const handleVisitOperationRequest = (
  state: VisitsState,
  { payload }: ActionType<typeof checkInVisit.request | typeof checkOutVisit.request | typeof cancelVisit.request>,
): VisitsState => {
  const buildingState = state[payload.buildingUuid] ?? initialBuildingVisitsState;

  return {
    ...state,
    [payload.buildingUuid]: {
      ...buildingState,
      visitsMap: {
        ...buildingState.visitsMap,
        [payload.visitUuid]: {
          ...buildingState.visitsMap[payload.visitUuid],
          loading: true,
          error: null,
        },
      },
    },
  };
};

const handleVisitOperationSuccess = (
  state: VisitsState,
  { payload }: ActionType<typeof checkInVisit.success | typeof checkOutVisit.success>,
): VisitsState => {
  const { buildingUuid, visitUuid } = payload.params;
  const buildingState = state[buildingUuid] ?? initialBuildingVisitsState;

  return {
    ...state,
    [buildingUuid]: {
      ...buildingState,
      visitsMap: {
        ...buildingState.visitsMap,
        [visitUuid]: {
          visit: payload.response,
          loading: false,
          error: null,
        },
      },
    },
  };
};

const handleVisitOperationError = (
  state: VisitsState,
  { payload }: ActionType<typeof checkInVisit.failure | typeof checkOutVisit.failure | typeof cancelVisit.failure>,
): VisitsState => {
  const { buildingUuid, visitUuid } = payload.params;
  const buildingState = state[buildingUuid] ?? initialBuildingVisitsState;

  return {
    ...state,
    [buildingUuid]: {
      ...buildingState,
      visitsMap: {
        ...buildingState.visitsMap,
        [visitUuid]: {
          ...buildingState.visitsMap[visitUuid],
          loading: false,
          error: payload.response,
        },
      },
    },
  };
};

const handleCancelVisit = (state: VisitsState, { payload }: ActionType<typeof cancelVisit.success>): VisitsState => {
  const { buildingUuid, visitUuid } = payload.params;
  const buildingState = state[buildingUuid] ?? initialBuildingVisitsState;
  const visit = buildingState.visitsMap[visitUuid]?.visit;

  if (!visit) {
    return state;
  }

  return {
    ...state,
    [buildingUuid]: {
      ...buildingState,
      visitsMap: {
        ...buildingState.visitsMap,
        [visitUuid]: {
          visit: {
            ...visit,
            status: VisitStatus.CANCELED,
          },
          loading: false,
          error: null,
        },
      },
    },
  };
};

const handleCreateVisitsRequest = (
  state: VisitsState,
  { payload }: ActionType<typeof createVisits.request>,
): VisitsState => ({
  ...state,
  [payload.buildingUuid]: {
    ...initialBuildingVisitsState,
    ...state[payload.buildingUuid],
    createVisitRequest: {
      loading: true,
      error: null,
    },
  },
});

const handleCreateVisitsSuccess = (
  state: VisitsState,
  { payload }: ActionType<typeof createVisits.success>,
): VisitsState => {
  const buildingState = {
    ...initialBuildingVisitsState,
    ...state[payload.params.buildingUuid],
  };

  return {
    ...state,
    [payload.params.buildingUuid]: {
      ...buildingState,
      visitsMap: {
        ...buildingState.visitsMap,
        ...getVisitsStoreData(buildingState, payload.response.visits).visitsMap,
      },
      createVisitRequest: {
        loading: false,
        error: null,
      },
    },
  };
};

const handleCreateVisitsFailure = (
  state: VisitsState,
  { payload }: ActionType<typeof createVisits.failure>,
): VisitsState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...initialBuildingVisitsState,
    ...state[payload.params.buildingUuid],
    createVisitRequest: {
      loading: false,
      error: payload.response,
    },
  },
});

const handleUpdateVisitRequest = (
  state: VisitsState,
  { payload }: ActionType<typeof updateVisit.request>,
): VisitsState => ({
  ...state,
  [payload.buildingUuid]: {
    ...initialBuildingVisitsState,
    ...state[payload.buildingUuid],
    updateVisitRequest: {
      loading: true,
      error: null,
    },
  },
});

const handleUpdateVisitSuccess = (
  state: VisitsState,
  { payload }: ActionType<typeof updateVisit.success>,
): VisitsState => {
  const buildingState = {
    ...initialBuildingVisitsState,
    ...state[payload.params.buildingUuid],
  };

  return {
    ...state,
    [payload.params.buildingUuid]: {
      ...buildingState,
      visitsMap: {
        ...buildingState.visitsMap,
        ...getVisitsStoreData(buildingState, [{ ...payload.response }]).visitsMap,
      },
      openedVisit: payload.response,
      updateVisitRequest: {
        loading: false,
        error: null,
      },
    },
  };
};

const handleUpdateVisitFailure = (
  state: VisitsState,
  { payload }: ActionType<typeof updateVisit.failure>,
): VisitsState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...initialBuildingVisitsState,
    ...state[payload.params.buildingUuid],
    updateVisitRequest: {
      loading: false,
      error: payload.response,
    },
  },
});

const handleVisitUpdateEvent = (state: VisitsState, { payload }: ActionType<typeof visitUpdatedEventForCurrentView>): VisitsState => {
  const oldState = state[payload.buildingUuid] || initialBuildingVisitsState;

  return {
    [payload.buildingUuid]: {
      ...oldState,
      visitsMap: {
        ...oldState.visitsMap,
        [payload.visit.uuid]: {
          error: null,
          loading: false,
          visit: payload.visit,
        },
      },
    },
  };
};

const handleSearchByCredentialRequest = (state: VisitsState, { payload }: ActionType<typeof searchVisitByCredential.request>): VisitsState => {
  const oldState = state[payload.buildingUuid] || initialBuildingVisitsState;

  return {
    [payload.buildingUuid]: {
      ...oldState,
      searchByCredentialRequestLoading: true,
    },
    ...state,
  };
};

const handleSearchByCredentialFinish = (
  state: VisitsState,
  { payload: { params } }: ActionType<typeof searchVisitByCredential.success | typeof searchVisitByCredential.failure>,
): VisitsState => {
  const oldState = state[params.buildingUuid] || initialBuildingVisitsState;

  return {
    [params.buildingUuid]: {
      ...oldState,
      searchByCredentialRequestLoading: false,
    },
    ...state,
  };
};

export const visitsReducer = createReducer(initialState)
  .handleAction(fetchVisits.request, handleFetchVisitsRequest)
  .handleAction(fetchVisits.success, handleFetchVisitsSuccess)
  .handleAction(fetchVisits.failure, handleFetchVisitsFailure)

  .handleAction(fetchVisit.request, handleFetchVisitRequest)
  .handleAction(fetchVisit.success, handleFetchVisitSuccess)
  .handleAction(fetchVisit.failure, handleFetchVisitFailure)

  .handleAction(checkInVisit.request, handleVisitOperationRequest)
  .handleAction(checkInVisit.success, handleVisitOperationSuccess)
  .handleAction(checkInVisit.failure, handleVisitOperationError)

  .handleAction(checkOutVisit.request, handleVisitOperationRequest)
  .handleAction(checkOutVisit.success, handleVisitOperationSuccess)
  .handleAction(checkOutVisit.failure, handleVisitOperationError)

  .handleAction(cancelVisit.request, handleVisitOperationRequest)
  .handleAction(cancelVisit.success, handleCancelVisit)
  .handleAction(cancelVisit.failure, handleVisitOperationError)

  .handleAction(createVisits.request, handleCreateVisitsRequest)
  .handleAction(createVisits.success, handleCreateVisitsSuccess)
  .handleAction(createVisits.failure, handleCreateVisitsFailure)

  .handleAction(updateVisit.request, handleUpdateVisitRequest)
  .handleAction(updateVisit.success, handleUpdateVisitSuccess)
  .handleAction(updateVisit.failure, handleUpdateVisitFailure)

  .handleAction(searchVisitByCredential.request, handleSearchByCredentialRequest)
  .handleAction(searchVisitByCredential.success, handleSearchByCredentialFinish)
  .handleAction(searchVisitByCredential.failure, handleSearchByCredentialFinish)

  .handleAction(visitUpdatedEventForCurrentView, handleVisitUpdateEvent);

import { VisitStatus } from 'app/store/visits/types';
import { colors } from '@hqo/react-components-library/dist/hqo-theme-provider';

export const statusColorsMap = new Map<VisitStatus, string>([
  [VisitStatus.INVITED, colors.palette.systemPurple],
  [VisitStatus.CHECKED_IN, colors.palette.systemGreenAlt],
  [VisitStatus.CHECKED_OUT, colors.palette.systemOrange],
  [VisitStatus.CANCELED, colors.palette.systemGreyDarkAlt],
  [VisitStatus.PENDING_WATCHLIST, colors.palette.systemPink],
]);

export const DEFAULT_COLOR = colors.palette.systemGreyDarkAlt;

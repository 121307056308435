import styled from 'styled-components';

export const IconWrapper = styled.button`
  display: inline-flex;
  background: none;
  border: none;
  cursor: pointer;
  margin: auto;
  padding: 0;
  color: ${({ theme }) => theme.colors.palette.systemPink};
`;

import { ActionType, createReducer } from 'typesafe-actions';
import { fetchVisitorVisits } from './actions';
import { VisitorVisitsByBuildingState, VisitorVisitsByVisitorState, VisitorVisitsState } from './types';

export const initialState: VisitorVisitsByBuildingState = {};
export const initialVisitorVisitsState: VisitorVisitsState = {
  visits: [],
  total: 0,
  loading: false,
  error: null,
};

function getOldState(
  buildingUuid: string,
  visitorUuid: string,
  state: VisitorVisitsByBuildingState,
): [VisitorVisitsState, VisitorVisitsByVisitorState] {
  const buildingState = state[buildingUuid] || {};
  const oldState = buildingState?.[visitorUuid] || initialVisitorVisitsState;
  return [oldState, buildingState];
}

const handleFetchVisitsRequest = (
  state: VisitorVisitsByBuildingState,
  { payload: { buildingUuid, visitorUuid, offset } }: ActionType<typeof fetchVisitorVisits.request>,
): VisitorVisitsByBuildingState => {
  const [oldState, buildingState] = getOldState(buildingUuid, visitorUuid, state);

  return {
    ...state,
    [buildingUuid]: {
      ...buildingState,
      [visitorUuid]: {
        ...initialVisitorVisitsState,
        ...oldState,
        visits: [...oldState.visits.slice(0, offset)],
        loading: true,
        error: null,
      },
    },
  };
};

const handleFetchVisitsSuccess = (
  state: VisitorVisitsByBuildingState,
  { payload: { params, response } }: ActionType<typeof fetchVisitorVisits.success>,
): VisitorVisitsByBuildingState => {
  const [oldState, buildingState] = getOldState(params.buildingUuid, params.visitorUuid, state);

  return {
    ...state,
    [params.buildingUuid]: {
      ...buildingState,
      [params.visitorUuid]: {
        loading: false,
        visits: [...oldState.visits.slice(0, params.offset || 0), ...response.data],
        total: response.total,
        error: null,
      },
    },
  };
};

const handleFetchVisitsFailure = (
  state: VisitorVisitsByBuildingState,
  { payload: { error, params } }: ActionType<typeof fetchVisitorVisits.failure>,
): VisitorVisitsByBuildingState => {
  const { buildingUuid, visitorUuid } = params;
  const [oldState, buildingState] = getOldState(buildingUuid, visitorUuid, state);

  return {
    ...state,
    [buildingUuid]: {
      ...buildingState,
      [visitorUuid]: {
        ...oldState,
        loading: false,
        error,
      },
    },
  };
};

export const visitorVisitsReducer = createReducer(initialState)
  .handleAction(fetchVisitorVisits.request, handleFetchVisitsRequest)
  .handleAction(fetchVisitorVisits.success, handleFetchVisitsSuccess)
  .handleAction(fetchVisitorVisits.failure, handleFetchVisitsFailure);

import { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { FormValues } from '../../../visit-form.interfaces';
import { LinkButton } from './add-visitor-button.styles';
import { initialVisitorValues } from 'app/components/visits/visit-form/hooks/creation/use-initial-visitors-value.hook';

export const AddVisitorButton: FC = () => {
  const { formatMessage } = useIntl();
  const { setValues, values } = useFormikContext<FormValues>();
  const addVisitor = useCallback(() => {
    setValues({ ...values, visitors: [...values.visitors, initialVisitorValues], groupUuid: undefined, groupName: '' });
  }, [setValues, values]);

  return (
    <LinkButton
      variant="Plain"
      onClick={addVisitor}
      Icon={<span className="material-icons">add</span>}
      iconDirection="left"
      text={formatMessage({ id: 'visitForm.visitors.addVisitor' })}
      data-testid="add-visitor-button"
    />
  );
};

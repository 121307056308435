import { VISITS_PATH } from 'app/shared/consts';
import { rootPathSelector } from 'app/store/config/selectors';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

export const DefaultVisitsRouteRedirect = () => {
  const rootPath = useSelector(rootPathSelector);

  return <Navigate to={`${rootPath}/${VISITS_PATH}`} replace />;
};

import { RefObject, useCallback, useMemo, useRef } from 'react';
import { useField } from 'formik';
import { VisitDateTime } from 'app/components/visits/visit-form/visit-form.interfaces';
import { MultiDayPickerChange } from './multi-day-date-picker/calendar-view';
import { sortDateAsc } from 'app/utils';
import {
  DefaultVisitDateTimeValuesFactory,
  initialVisitDateTime,
  useDefaultVisitDateTimeValuesFactory,
} from 'app/components/visits/visit-form/hooks/creation/use-initial-date-time-values.hook';
import { getDateString } from 'app/shared/utils/get-date-string';
import { isDefined } from '../../../../../../shared/utils/is-defined';

type DateChangerHookReturn = [VisitDateTime[], Date[], VoidFunction, MultiDayPickerChange, RefObject<boolean>];

const createCurrentDateMap = (existingDates: VisitDateTime[]): Map<number, VisitDateTime> =>
  new Map(
    existingDates
      .map((item): [number, VisitDateTime] | null => (item.startDate ? [item.startDate.getTime(), item] : null))
      .filter(isDefined),
  );

const mergeDates = (
  currentMap: Map<number, VisitDateTime>,
  dates: Date[],
  defaultVisitDateTimeFactory: DefaultVisitDateTimeValuesFactory,
): VisitDateTime[] =>
  sortDateAsc(dates)
    .map((date): [Date, number] => [date, date.getTime()])
    .map(([startDate, timestamp]) => {
      if (currentMap.has(timestamp)) {
        return currentMap.get(timestamp)!;
      }

      return defaultVisitDateTimeFactory(getDateString(startDate));
    });

export const useDateChangeHandler = (fieldName: string): DateChangerHookReturn => {
  const [{ value }, _, { setValue }] = useField<VisitDateTime[]>(fieldName);
  const isInitialValueChanged = useRef(false);
  const defaultVisitDateTimeFactory = useDefaultVisitDateTimeValuesFactory();

  const dates = useMemo(() => value.map(({ startDate }) => startDate).filter(isDefined), [value]);
  const onClear = useCallback(() => setValue([initialVisitDateTime]), [setValue]);
  const onChange = useCallback(
    (date: Date[]) => {
      isInitialValueChanged.current = true;
      setValue(mergeDates(createCurrentDateMap(value), date, defaultVisitDateTimeFactory));
    },
    [value, setValue, defaultVisitDateTimeFactory],
  );

  return [value, dates, onClear, onChange, isInitialValueChanged];
};

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { fetchUserPermissions as fetchUserPermissionsAction } from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootAction } from 'app/store/actions';
import { of } from 'rxjs';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { toast } from 'react-toastify';

export const fetchUserPermissionsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchUserPermissionsAction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => apiClient(state)
      .fetchUserPermissions(payload)
      .pipe(
        map((response) => fetchUserPermissionsAction.success({ params: payload, response })),
        catchError((error: Error) => {
          toast.error(intl.formatMessage({ id: 'notifications.userPermissions.fetch.error' }));

          return of(fetchUserPermissionsAction.failure({ params: payload, response: error }));
        }),
      )),
  );

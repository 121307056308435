import { Epic } from 'redux-observable';
import { RootAction } from 'app/store/actions';
import { RootState } from 'app/store/reducer';
import { RootDependencies } from 'app/store/dependencies';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { fetchVisitsCompanyFilterOptions, fetchVisitsGroupFilterOptions, fetchVisitsHostFilterOptions } from 'app/store/visits-filters/actions';
import { toast } from 'react-toastify';

export const fetchCompanyFilterOptionsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitsCompanyFilterOptions.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .fetchCompanyFilterOptions()
        .pipe(
          map((response) => fetchVisitsCompanyFilterOptions.success({ params: action.payload, response })),
          catchError((error:Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitsFilters.fetch.error' }));

            return of(fetchVisitsCompanyFilterOptions.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const fetchHostFilterOptionsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitsHostFilterOptions.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .fetchHostFilterOptions()
        .pipe(
          map((response) => fetchVisitsHostFilterOptions.success({ params: action.payload, response })),
          catchError((error:Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitsFilters.fetch.error' }));

            return of(fetchVisitsHostFilterOptions.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const fetchGroupFilterOptionsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitsGroupFilterOptions.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .fetchGroupFilterOptions()
        .pipe(
          map((response) => fetchVisitsGroupFilterOptions.success({ params: action.payload, response })),
          catchError((error:Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitsFilters.fetch.error' }));

            return of(fetchVisitsGroupFilterOptions.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

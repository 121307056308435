import { ZIndex } from 'app/shared/consts/z-index';
import styled from 'styled-components';

export const Container = styled.div`
  z-index: ${ZIndex.POPOVER};

  .no-results {
    height: 0;
    padding: 0;
  }
`;

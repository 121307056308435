import styled from 'styled-components';

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  font-weight: 500;
  line-height: 24px;
`;

export const HeaderButtonsGroup = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

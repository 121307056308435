import { Loader } from 'app/components/loader';
import { TabPageLayout } from 'app/components/visitor-management-tabs/tab-page-layout';
import { useDaypassRequestEventsListener } from 'app/shared/hooks/use-daypass-request-events-listener';
import { daypassRequestUpdated } from 'app/store/daypass-requests/actions';
import { FC, lazy, Suspense } from 'react';
import { Outlet } from 'react-router';

const RequestedDayPasses = lazy(() => import('app/components/requested-day-passes/requested-day-passes.container'));

export const RequestedDayPassesPage: FC = () => {
  useDaypassRequestEventsListener(daypassRequestUpdated);

  return (
    <TabPageLayout data-testid="requested-day-passes-page">
      <Suspense fallback={<Loader testId="requested-day-passes-loader" />}>
        <RequestedDayPasses />
      </Suspense>
      <Outlet />
    </TabPageLayout>
  );
};

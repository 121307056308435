import { ActionType, createReducer } from 'typesafe-actions';
import { VisitorGroupsState, BuildingVisitorGroupsState } from './types';
import { fetchVisitorGroup, fetchVisitorGroups } from './actions';

export const initialState: VisitorGroupsState = {};
export const initialVisitorGroupsState: BuildingVisitorGroupsState = {
  visitorGroups: null,
  total: 0,
  offset: 0,
  limit: 10,
  loading: false,
  error: null,
  visitorGroupDetails: {},
};

const handleFetchVisitorGroupsRequest = (
  state: VisitorGroupsState,
  action: ActionType<typeof fetchVisitorGroups.request>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialVisitorGroupsState,
    ...state[action.payload.buildingUuid],
    loading: true,
  },
});

const handleFetchVisitorGroupsSuccess = (
  state: VisitorGroupsState,
  { payload }: ActionType<typeof fetchVisitorGroups.success>,
): VisitorGroupsState => {
  const oldGroups = payload.params.shouldReset ? [] : state[payload.params.buildingUuid].visitorGroups ?? [];
  return ({
    ...state,
    [payload.params.buildingUuid]: {
      ...state[payload.params.buildingUuid],
      loading: false,
      visitorGroups: [...oldGroups, ...payload.response.data],
      total: payload.response.total,
      offset: payload.response.offset,
      limit: payload.response.limit,
      error: null,
    },
  });
};

const handleFetchVisitorGroupsFailure = (
  state: VisitorGroupsState,
  action: ActionType<typeof fetchVisitorGroups.failure>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

const handleFetchVisitorGroupRequest = (
  state: VisitorGroupsState,
  action: ActionType<typeof fetchVisitorGroup.request>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialVisitorGroupsState,
    ...state[action.payload.buildingUuid],
    loading: true,
  },
});

const handleFetchVisitorGroupSuccess = (
  state: VisitorGroupsState,
  { payload }: ActionType<typeof fetchVisitorGroup.success>,
): VisitorGroupsState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    loading: false,
    visitorGroupDetails: {
      ...state[payload.params.buildingUuid].visitorGroupDetails,
      [payload.params.visitorGroupUuid]: payload.response.data,
    },
  },
});

const handleFetchVisitorGroupFailure = (
  state: VisitorGroupsState,
  action: ActionType<typeof fetchVisitorGroup.failure>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

export const visitorGroupsReducer = createReducer(initialState)
  .handleAction(fetchVisitorGroups.request, handleFetchVisitorGroupsRequest)
  .handleAction(fetchVisitorGroups.success, handleFetchVisitorGroupsSuccess)
  .handleAction(fetchVisitorGroups.failure, handleFetchVisitorGroupsFailure)

  .handleAction(fetchVisitorGroup.request, handleFetchVisitorGroupRequest)
  .handleAction(fetchVisitorGroup.success, handleFetchVisitorGroupSuccess)
  .handleAction(fetchVisitorGroup.failure, handleFetchVisitorGroupFailure);

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const Title = styled.div`
  font-weight: 500;
  line-height: 24px;
`;

export const HeaderButtonsGroup = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const VisitorControlsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
`;

export const ButtonWrapper = styled.div`
  margin-top: 8px;
  margin-left: 24px;
`;

import { changeVisitorPassStatus, fetchVisitorPassesInfo, visitorPassUpdated } from 'app/store/visitor-passes/actions';
import {
  PassesState,
  RequestState,
  VisitorPassAction,
  VisitorPassInfo,
  VisitorPassStatus,
  VisitorPassesState,
} from 'app/store/visitor-passes/types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialRequestState: RequestState = {
  loading: false,
  error: null,
};

export const initialPassesState: PassesState = {};
export const initialVisitorPassesState: VisitorPassesState = {
  passesMap: {},
  activateVisitorRequest: initialRequestState,
  fetchVisitorPassesRequest: initialRequestState,
};

const transformPassesArrayToMmap = (passes: VisitorPassInfo[]) =>
  passes.reduce((acc: Record<string, VisitorPassInfo>, pass: VisitorPassInfo) => ({ ...acc, [pass.visitUuid]: pass }), {});

const reassignActivationPassesMapState = (
  state: PassesState,
  buildingUuid: string,
  action: VisitorPassAction,
  activatedPassUuid?: string,
): Record<string, VisitorPassInfo> => {
  const existedPassesMap = state[buildingUuid]?.passesMap ?? {};
  const existingPass = Object.values(existedPassesMap).find((pass) => pass.passUuid === activatedPassUuid);

  if (!existingPass) {
    return existedPassesMap;
  }

  return {
    ...existedPassesMap,
    [existingPass.visitUuid]: {
      ...existingPass,
      active: action === VisitorPassAction.activate || existingPass.active,
      status: action === VisitorPassAction.regenerate ? VisitorPassStatus.PENDING : existingPass.status,
    },
  };
};

const handleFetchVisitorPassesRequest = (
  state: PassesState,
  action: ActionType<typeof fetchVisitorPassesInfo.request>,
): PassesState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialVisitorPassesState,
    ...state[action.payload.buildingUuid],
    fetchVisitorPassesRequest: {
      error: null,
      loading: true,
    },
    activateVisitorRequest: initialRequestState,
  },
});

const handleFetchVisitorPassesSuccess = (
  state: PassesState,
  action: ActionType<typeof fetchVisitorPassesInfo.success>,
): PassesState => {
  const oldState = state[action.payload.params.buildingUuid];

  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...oldState,
      passesMap: {
        ...oldState?.passesMap,
        ...transformPassesArrayToMmap(action.payload.response.data),
      },
      fetchVisitorPassesRequest: initialRequestState,
      activateVisitorRequest: initialRequestState,
    },
  };
};

const handleFetchVisitorPassesFailure = (
  state: PassesState,
  action: ActionType<typeof fetchVisitorPassesInfo.failure>,
): PassesState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialVisitorPassesState,
    ...state[action.payload.params.buildingUuid],
    fetchVisitorPassesRequest: {
      error: action.payload.response,
      loading: false,
    },
    activateVisitorRequest: initialRequestState,
  },
});

const handleChangeVisitorPassStatusRequest = (
  state: PassesState,
  action: ActionType<typeof changeVisitorPassStatus.request>,
): PassesState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialVisitorPassesState,
    ...state[action.payload.buildingUuid],
    fetchVisitorPassesRequest: initialRequestState,
    activateVisitorRequest: {
      error: null,
      loading: true,
    },
  },
});

const handleChangeVisitorPassStatusSuccess = (
  state: PassesState,
  action: ActionType<typeof changeVisitorPassStatus.success>,
): PassesState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    passesMap: reassignActivationPassesMapState(
      state,
      action.payload.params.buildingUuid,
      action.payload.params.action,
      action.payload.params.visitorPassUuid,
    ),
    fetchVisitorPassesRequest: initialRequestState,
    activateVisitorRequest: {
      error: null,
      loading: false,
    },
  },
});

const handleChangeVisitorPassStatusFailure = (
  state: PassesState,
  action: ActionType<typeof changeVisitorPassStatus.failure>,
): PassesState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialVisitorPassesState,
    ...state[action.payload.params.buildingUuid],
    fetchVisitorPassesRequest: initialRequestState,
    activateVisitorRequest: {
      error: action.payload.response,
      loading: false,
    },
  },
});

const handleVisitorPassUpdated = (state: PassesState, action: ActionType<typeof visitorPassUpdated>) => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...state[action.payload.buildingUuid],
    passesMap: {
      ...state[action.payload.buildingUuid]?.passesMap,
      [action.payload.pass.visitUuid]: action.payload.pass,
    },
  },
});

export const visitorPassesReducer = createReducer(initialPassesState)
  .handleAction(fetchVisitorPassesInfo.request, handleFetchVisitorPassesRequest)
  .handleAction(fetchVisitorPassesInfo.success, handleFetchVisitorPassesSuccess)
  .handleAction(fetchVisitorPassesInfo.failure, handleFetchVisitorPassesFailure)

  .handleAction(changeVisitorPassStatus.request, handleChangeVisitorPassStatusRequest)
  .handleAction(changeVisitorPassStatus.success, handleChangeVisitorPassStatusSuccess)
  .handleAction(changeVisitorPassStatus.failure, handleChangeVisitorPassStatusFailure)

  .handleAction(visitorPassUpdated, handleVisitorPassUpdated);

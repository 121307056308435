import React from 'react';
import ReactDOM from 'react-dom';

export type ReactAdapterProviderProps<P = object> = P & {
  component: React.FunctionComponent<P> | React.ComponentClass<P> | keyof React.ReactHTML | string;
  children?: React.ReactNode;
};

export interface ReactAdapterProviderState {
  Component: React.ReactNode;
}

export class ReactAdapterProvider<P = object> extends React.PureComponent<
  ReactAdapterProviderProps<P>,
  ReactAdapterProviderState
> {
  private refHold: HTMLDivElement | null = null;

  constructor(props: ReactAdapterProviderProps<P>) {
    super(props);

    this.refHold = null;
  }

  componentDidMount() {
    this.renderChildren();
  }

  componentDidUpdate() {
    this.renderChildren(true);
  }

  renderChildren = (hydrate?: boolean) => {
    (async () => {
      const { component, children, ...rest } = this.props;
      // Need to use old method for compatibility with React 16
      // eslint-disable-next-line react/no-deprecated
      const renderMethod = hydrate ? ReactDOM.hydrate : ReactDOM.render;
      renderMethod(React.createElement(component as string, rest, children), this.refHold);
    })();
  };

  saveRef = (ref: HTMLDivElement) => {
    this.refHold = ref;
  };

  render() {
    return <div className="application-root-ref" ref={this.saveRef} />;
  }
}

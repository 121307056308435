import {
  fetchAccessGroups,
  fetchAccessGroupsMappingRules,
  updateAccessGroupsMappingRules,
} from 'app/store/access-groups/actions';
import { AccessGroupsBuildingState, AccessGroupsState, RequestState } from './types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialRequestState: RequestState = {
  loading: false,
  error: null,
};

const initialBuildingAccessGroupsState: AccessGroupsBuildingState = {
  accessGroups: [],
  accessGroupMappingRules: [],
  fetchAccessGroupsRequest: initialRequestState,
  fetchAccessGroupsMappingRulesRequest: initialRequestState,
  updateAccessGroupsMappingRulesRequest: initialRequestState,
};

export const initialState: AccessGroupsState = {};

const handleFetchAccessGroupsRequest = (
  state: AccessGroupsState,
  action: ActionType<typeof fetchAccessGroups.request>,
): AccessGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingAccessGroupsState,
    ...state[action.payload.buildingUuid],
    fetchAccessGroupsRequest: {
      loading: true,
      error: null,
    },
  },
});

const handleFetchAccessGroupsSuccess = (
  state: AccessGroupsState,
  action: ActionType<typeof fetchAccessGroups.success>,
): AccessGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingAccessGroupsState,
    ...state[action.payload.params.buildingUuid],
    accessGroups: action.payload.response.data,
    fetchAccessGroupsRequest: {
      loading: false,
      error: null,
    },
  },
});

const handleFetchAccessGroupsFailure = (
  state: AccessGroupsState,
  action: ActionType<typeof fetchAccessGroups.failure>,
): AccessGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingAccessGroupsState,
    ...state[action.payload.params.buildingUuid],
    fetchAccessGroupsRequest: {
      loading: false,
      error: action.payload.response,
    },
  },
});

const handleFetchAccessGroupsMappingRulesRequest = (
  state: AccessGroupsState,
  action: ActionType<typeof fetchAccessGroupsMappingRules.request>,
): AccessGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingAccessGroupsState,
    ...state[action.payload.buildingUuid],
    fetchAccessGroupsMappingRulesRequest: {
      loading: true,
      error: null,
    },
  },
});

const handleFetchAccessGroupsMappingRulesSuccess = (
  state: AccessGroupsState,
  action: ActionType<typeof fetchAccessGroupsMappingRules.success>,
): AccessGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingAccessGroupsState,
    ...state[action.payload.params.buildingUuid],
    accessGroupMappingRules: action.payload.response.data,
    fetchAccessGroupsMappingRulesRequest: {
      loading: false,
      error: null,
    },
  },
});

const handleFetchAccessGroupsMappingRulesFailure = (
  state: AccessGroupsState,
  action: ActionType<typeof fetchAccessGroupsMappingRules.failure>,
): AccessGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingAccessGroupsState,
    ...state[action.payload.params.buildingUuid],
    fetchAccessGroupsMappingRulesRequest: {
      loading: false,
      error: action.payload.response,
    },
  },
});

const handleUpdateAccessGroupsMappingRulesRequest = (
  state: AccessGroupsState,
  action: ActionType<typeof updateAccessGroupsMappingRules.request>,
): AccessGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingAccessGroupsState,
    ...state[action.payload.buildingUuid],
    updateAccessGroupsMappingRulesRequest: {
      loading: true,
      error: null,
    },
  },
});

const handleUpdateAccessGroupsMappingRulesSuccess = (
  state: AccessGroupsState,
  action: ActionType<typeof updateAccessGroupsMappingRules.success>,
): AccessGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingAccessGroupsState,
    ...state[action.payload.params.buildingUuid],
    accessGroupMappingRules: action.payload.response.data,
    updateAccessGroupsMappingRulesRequest: {
      loading: false,
      error: null,
    },
  },
});

const handleUpdateAccessGroupsMappingRulesFailure = (
  state: AccessGroupsState,
  action: ActionType<typeof updateAccessGroupsMappingRules.failure>,
): AccessGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingAccessGroupsState,
    ...state[action.payload.params.buildingUuid],
    updateAccessGroupsMappingRulesRequest: {
      loading: false,
      error: action.payload.response,
    },
  },
});

export const accessGroupsReducer = createReducer(initialState)
  .handleAction(fetchAccessGroups.request, handleFetchAccessGroupsRequest)
  .handleAction(fetchAccessGroups.success, handleFetchAccessGroupsSuccess)
  .handleAction(fetchAccessGroups.failure, handleFetchAccessGroupsFailure)

  .handleAction(fetchAccessGroupsMappingRules.request, handleFetchAccessGroupsMappingRulesRequest)
  .handleAction(fetchAccessGroupsMappingRules.success, handleFetchAccessGroupsMappingRulesSuccess)
  .handleAction(fetchAccessGroupsMappingRules.failure, handleFetchAccessGroupsMappingRulesFailure)

  .handleAction(updateAccessGroupsMappingRules.request, handleUpdateAccessGroupsMappingRulesRequest)
  .handleAction(updateAccessGroupsMappingRules.success, handleUpdateAccessGroupsMappingRulesSuccess)
  .handleAction(updateAccessGroupsMappingRules.failure, handleUpdateAccessGroupsMappingRulesFailure);

import { VISIT_UUID_PARAMETER } from 'app/shared/consts';
import { useParams } from 'react-router-dom';

export const useVisitUuidParam = (): string => {
  const params = useParams();

  if (!params[VISIT_UUID_PARAMETER]) {
    throw new Error(`Missing required parameter ${VISIT_UUID_PARAMETER}`);
  }

  return params[VISIT_UUID_PARAMETER];
};

import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import {
  fetchDaypassUsers as fetchDaypassUsersAction,
  fetchAllDaypassUsers as fetchAllDaypassUsersAction,
  fetchDaypassUserCredential,
  setCompanyDaypassUsers,
  createDaypassRequest,
  daypassUserRequestUpdated,
  daypassUserUpdated,
} from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootAction } from 'app/store/actions';
import { of, tap } from 'rxjs';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { toast } from 'react-toastify';
import { daypassUsersSelector } from './selectors';
import { DaypassStatus } from './types';
import { MessageIds } from 'i18n';
import { showDaypassUpdatedNotification } from 'app/shared/utils/daypass-notifications';

export const fetchDaypassUsersEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchDaypassUsersAction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .fetchDaypassUsers(payload)
        .pipe(
          map((response) => fetchDaypassUsersAction.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.daypassUsers.fetch.error' }));

            return of(fetchDaypassUsersAction.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

export const fetchAllDaypassUsersEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchAllDaypassUsersAction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .fetchDaypassUsers({ ...payload, includeAll: true })
        .pipe(
          map((response) => fetchAllDaypassUsersAction.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.daypassUsers.fetch.error' }));

            return of(fetchAllDaypassUsersAction.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

export const fetchDaypassUserCredentialEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchDaypassUserCredential.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .getDaypassUserCredential(payload)
        .pipe(
          map((response) => fetchDaypassUserCredential.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.fetchDaypassUserCredential.fetch.error' }));

            return of(fetchDaypassUserCredential.failure({ params: payload, error }));
          }),
        ),
    ),
  );

export const setCompanyDaypassUsersEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(setCompanyDaypassUsers.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .setCompanyDaypassUsers(payload)
        .pipe(
          map(() => {
            payload.onSuccess?.();

            return setCompanyDaypassUsers.success({ params: payload });
          }),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.setCompanyDaypassUsers.fetch.error' }));

            return of(setCompanyDaypassUsers.failure({ params: payload, error }));
          }),
        ),
    ),
  );

export const createDaypassRequestEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(createDaypassRequest.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .createDaypassRequest(payload)
        .pipe(
          map(({ response }) => createDaypassRequest.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.daypassRequests.createRequest.error' }));

            return of(createDaypassRequest.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

const notificationsMap: Partial<
  Record<DaypassStatus, MessageIds | Partial<Record<DaypassStatus | string, MessageIds>>>
> = {
  [DaypassStatus.AWAITING_APPROVAL]: 'notifications.daypassRequests.statusChanged.requested',
  [DaypassStatus.PASS_AVAILABLE]: 'notifications.daypassRequests.statusChanged.passReady',
  [DaypassStatus.DENIED]: 'notifications.daypassRequests.statusChanged.denied',
  [DaypassStatus.APPROVED]: {
    [DaypassStatus.AWAITING_APPROVAL]: 'notifications.daypassRequests.statusChanged.approved',
  },
  [DaypassStatus.AWAITING_PASS]: {
    [DaypassStatus.AWAITING_APPROVAL]: 'notifications.daypassRequests.statusChanged.approved',
  },
};

function getMessageKey(oldStatus: DaypassStatus | undefined, newStatus: DaypassStatus): MessageIds | null {
  const notificationKeyOrMap = notificationsMap[newStatus];

  if (typeof notificationKeyOrMap === 'string') {
    return notificationKeyOrMap;
  }

  return notificationKeyOrMap?.[oldStatus ?? ''] ?? null;
}

export const daypassUserRequestUpdatedEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { intl },
) =>
  action$.pipe(
    filter(isActionOf(daypassUserRequestUpdated)),
    withLatestFrom(state$),
    tap(([{ payload }, state]) => {
      const { person, request } = payload;
      const existingDaypassUsers = daypassUsersSelector(state);
      const exisingUser = existingDaypassUsers?.find((user) => user.uuid === person.uuid);

      showDaypassUpdatedNotification(exisingUser?.daypassStatus, request.status, person, intl, getMessageKey);
    }),
    map(([{ payload }]) => daypassUserUpdated(payload)),
  );

import { createSelector } from 'reselect';
import { RootState } from 'app/store/reducer';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { BuildingUserPermissionsState, Permission, UserPermissions } from './types';
import {
  hasDaypassFeatureEnabledSelector,
  showAccessGroupsSettingsSelector,
  showDaypassTabSelector,
  showVisRegSettingsPageSelector,
  showWatchlistFeatureSelector,
  showVisitorRegistrationGroupsTabSelector,
} from 'app/store/config/selectors';
import { hasDaypassApprovalEnabledSelector } from '../daypass-modes/selectors';
import { hasVisitorPassesEnabledSelector, hasWatchlistEnabledSelector, hasVisitorGroupsEnabledSelector } from '../building-settings/selectors';

const userPermissionsByBuildingState = (state: RootState) => state.userPermissions;

export const userPermissionsState = createSelector(
  [buildingUuidSelector, userPermissionsByBuildingState],
  (buildingUuid, userPermissionsByBuilding) => userPermissionsByBuilding?.[buildingUuid] ?? null,
);
export const userPermissionsSelector = createSelector(
  userPermissionsState,
  (state: BuildingUserPermissionsState): UserPermissions | null => state?.userPermissions ?? null,
);
export const userPermissionsLoadingState = createSelector(
  userPermissionsState,
  (state: BuildingUserPermissionsState): boolean => state?.loading ?? false,
);
export const userPermissionsErrorState = createSelector(
  userPermissionsState,
  (state: BuildingUserPermissionsState): Error | null => state?.error ?? null,
);

export const hasPermissionSelector = createSelector(
  [userPermissionsSelector, (_, permission: Permission) => permission],
  (userPermissions, permission) => userPermissions?.[permission] ?? false,
);

export const makeHasPermissionSelector = (permission: Permission) =>
  createSelector([userPermissionsSelector], (userPermissions) => userPermissions?.[permission] ?? false);

export const hasRequestDaypassPermissionsSelector = createSelector(
  [hasDaypassFeatureEnabledSelector, makeHasPermissionSelector(Permission.REQUEST_DAYPASS)],
  (hasDayPassEnabled, hasPermission) => hasDayPassEnabled && hasPermission,
);

export const hasManageDaypassFeaturePermissionsSelector = createSelector(
  [showDaypassTabSelector, makeHasPermissionSelector(Permission.CONFIGURE_VISITOR_REGISTRATION_DAY_PASS)],
  (hasDayPassEnabled, hasPermission) => hasDayPassEnabled && hasPermission,
);

export const hasManageVisitorGroupsPermissionsSelector = makeHasPermissionSelector(Permission.CONFIGURE_VISITOR_REGISTRATION_GROUPS);

export const hasManageWatchlistFeaturePermissionsSelector = createSelector(
  [showWatchlistFeatureSelector, makeHasPermissionSelector(Permission.CONFIGURE_VISITOR_REGISTRATION_WATCHLIST)],
  (hasWatchlistEnabled, hasPermission) => hasWatchlistEnabled && hasPermission,
);

export const hasAnyManageFeaturePermissionsSelector = createSelector(
  [hasManageDaypassFeaturePermissionsSelector, hasManageWatchlistFeaturePermissionsSelector, hasManageVisitorGroupsPermissionsSelector],
  (...permissions) => permissions.some(Boolean),
);

export const hasWatchlistPermissionsSelector = createSelector(
  [showWatchlistFeatureSelector, makeHasPermissionSelector(Permission.READ_WATCHLIST)],
  (showWatchlistFeature, hasPermission) => showWatchlistFeature && hasPermission,
);

export const hasWatchlistMatchesPermissionsSelector = createSelector(
  [showWatchlistFeatureSelector, makeHasPermissionSelector(Permission.READ_WATCHLIST_MATCHES)],
  (showWatchlistFeature, hasPermission) => showWatchlistFeature && hasPermission,
);

export const canSeeWatchlistTabSelector = createSelector(
  [showWatchlistFeatureSelector, hasWatchlistEnabledSelector, hasWatchlistPermissionsSelector, hasWatchlistMatchesPermissionsSelector],
  (watchlistLdFlag, hasFeatureEnabled, ...permissions) => watchlistLdFlag && hasFeatureEnabled && permissions.some(Boolean),
);

export const hasViewGroupsPermissionsSelector = createSelector(
  [showVisitorRegistrationGroupsTabSelector, hasVisitorGroupsEnabledSelector, makeHasPermissionSelector(Permission.VISITOR_MANAGEMENT_VIEW_GROUPS)],
  (showGroupsTab, hasVisitorGroupsEnabled, hasPermission) => showGroupsTab && hasVisitorGroupsEnabled && hasPermission,
);

export const hasManageDaypassPermissionsSelector = createSelector(
  [
    hasDaypassFeatureEnabledSelector,
    makeHasPermissionSelector(Permission.MANAGE_COMPANY_DAYPASS_REQUESTS),
    hasDaypassApprovalEnabledSelector,
  ],
  (hasDayPassEnabled, hasPermission, hasDaypassApprovalEnabled) =>
    hasDayPassEnabled && hasPermission && hasDaypassApprovalEnabled,
);

export const hasDaypassRequestsPermissionsSelector = createSelector(
  [
    hasDaypassFeatureEnabledSelector,
    makeHasPermissionSelector(Permission.REQUEST_DAYPASS),
    makeHasPermissionSelector(Permission.MANAGE_COMPANY_DAYPASS_REQUESTS),
  ],
  (hasDaypassEnabled, ...permissionChecks) => hasDaypassEnabled && permissionChecks.some((canAccess) => canAccess),
);

export const hasManageDaypassUsersPermissionsSelector = createSelector(
  [hasDaypassFeatureEnabledSelector, makeHasPermissionSelector(Permission.MANAGE_COMPANY_DAYPASS_USERS)],
  (hasDaypassFeatureEnabled, hasPermission) => hasDaypassFeatureEnabled && hasPermission,
);

export const hasManageGeneralSettingsPermissionSelector = makeHasPermissionSelector(
  Permission.CUSTOMIZE_GENERAL_SETTINGS,
);

export const hasManageVisitorEmailPermissionSelector = makeHasPermissionSelector(
  Permission.CUSTOMIZE_VISITOR_EMAIL_SETTINGS,
);

export const hasCancelVisitPermissionSelector = makeHasPermissionSelector(Permission.CANCEL_VISITS);

export const hasCheckInVisitPermissionSelector = makeHasPermissionSelector(Permission.CHECK_IN_VISIT);

export const hasCheckOutVisitPermissionSelector = makeHasPermissionSelector(Permission.CHECK_OUT_VISIT);

export const hasExportAuditReportPermissionSelector = makeHasPermissionSelector(
  Permission.VISITOR_MANAGEMENT_EXPORT_AUDIT_REPORT,
);

export const hasCreateWatchlistPermissionSelector = makeHasPermissionSelector(
  Permission.CREATE_WATCHLIST,
);

export const hasManageAccessGroupsSettingsPermissionSelector = createSelector(
  [
    showAccessGroupsSettingsSelector,
    hasVisitorPassesEnabledSelector,
    makeHasPermissionSelector(Permission.CUSTOMIZE_ACCESS_GROUPS_SETTINGS),
  ],
  (showAccessGroupsSettings, hasVisitorPassesEnabled, hasManageAccessGroupsSettingsPermission) =>
    showAccessGroupsSettings && hasVisitorPassesEnabled && hasManageAccessGroupsSettingsPermission,
);

export const hasSomeManageSettingsPermissions = createSelector(
  [
    showVisRegSettingsPageSelector,
    hasManageAccessGroupsSettingsPermissionSelector,
    hasManageDaypassFeaturePermissionsSelector,
    hasManageVisitorEmailPermissionSelector,
    hasManageDaypassUsersPermissionsSelector,
    hasManageGeneralSettingsPermissionSelector,
    hasManageWatchlistFeaturePermissionsSelector,
  ],
  (showVisRegSettingsPage, ...manageSettingsPermissionChecks: boolean[]) =>
    showVisRegSettingsPage && manageSettingsPermissionChecks.some((hasPermissions) => hasPermissions),
);

export const hasManageCreateVipVisitPermissionSelector = makeHasPermissionSelector(
  Permission.VISITOR_MANAGEMENT_CREATE_VIP_VISIT,
);

import { BACK_ROUTE_QUERY_PARAM } from 'app/components/visits/visit-modal/visit-modal.consts';
import { ModalStyled } from './modal.styles';
import { FC, PropsWithChildren, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useQueryParam } from 'app/shared/hooks/use-query-param';

interface ModalProps {
  basePath: string;
  dataTestId: string;
}

export const Modal: FC<PropsWithChildren<ModalProps>> = ({ basePath, children, dataTestId }) => {
  const navigate = useNavigate();
  const [backRoute] = useQueryParam(BACK_ROUTE_QUERY_PARAM);
  const handleClose = useCallback(() => {
    navigate(backRoute ? decodeURIComponent(backRoute) : basePath, { replace: true });
  }, [navigate, basePath, backRoute]);

  return (
    <ModalStyled opened onClose={handleClose} dataTestId={dataTestId}>
      {children}
    </ModalStyled>
  );
};

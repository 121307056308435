import { createAction, createAsyncAction } from 'typesafe-actions';
import {
  VisitorRegistrationConfigParams,
  ImageUploadSuccess,
  PatchVisitorRegistrationConfigParams,
  UploadImageError,
  UploadImageParams,
  VisitorRegistrationConfigErrorDto,
  VisitorRegistrationConfigSuccessDto,
} from './types';

export const fetchVisitorRegistrationConfig = createAsyncAction(
  'FETCH_CONFIG_REQUEST',
  'FETCH_CONFIG_SUCCESS',
  'FETCH_CONFIG_ERROR',
)<VisitorRegistrationConfigParams, VisitorRegistrationConfigSuccessDto, VisitorRegistrationConfigErrorDto>();

export const createDefaultVisitorRegistrationConfig = createAsyncAction(
  'CREATE_DEFAULT_CONFIG_REQUEST',
  'CREATE_DEFAULT_CONFIG_SUCCESS',
  'CREATE_DEFAULT_CONFIG_ERROR',
)<VisitorRegistrationConfigParams, VisitorRegistrationConfigSuccessDto, VisitorRegistrationConfigErrorDto>();

export const patchVisitorRegistrationConfigForCurrentBuilding = createAction('PATCH_CONFIG_FOR_CURRENT_BUILDING')<
  Pick<PatchVisitorRegistrationConfigParams, 'config'>
>();

export const patchVisitorRegistrationConfig = createAsyncAction(
  'PATCH_CONFIG_REQUEST',
  'PATCH_CONFIG_SUCCESS',
  'PATCH_CONFIG_ERROR',
)<PatchVisitorRegistrationConfigParams, VisitorRegistrationConfigSuccessDto, VisitorRegistrationConfigErrorDto>();

export const uploadEmailBrandingImage = createAsyncAction(
  'UPLOAD_IMAGE_REQUEST',
  'UPLOAD_IMAGE_SUCCESS',
  'UPLOAD_IMAGE_ERROR',
)<UploadImageParams, ImageUploadSuccess, UploadImageError>();

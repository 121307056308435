import { Panel } from 'app/components/panel';
import styled from 'styled-components';

export const VisitsPageHeadingWrapper = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 16px;
`;

const PanelWrapper = styled(Panel)`
  height: auto;
  display: flex;
  align-items: center;
`;

export const DateFilterPanelWrapper = styled(PanelWrapper)`
  flex: 2;
  min-width: 480px;
`;

export const MetricsPanelWrapper = styled(PanelWrapper)`
  flex: 5;
  min-width: 340px;
`;

export const hexToRgba = (colorHex: string, opacity: number): string => {
  if (opacity === 1) {
    return colorHex;
  }

  let hex = colorHex.split('#')[1];
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const hexToDecimal = (color: string) => {
    const matches = color.match(/..?/g);
    return matches ? matches.map((match: string) => parseInt(match, 16)) : [];
  };

  return `rgba(${hexToDecimal(hex).join(', ')}, ${opacity})`;
};

import { useLocationInfo } from 'app/shared/hooks/use-location-info';
import { VisitType } from 'app/store/location-info/types';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { MessageIds } from '../../../../../../i18n';

type GetVisitTypeLabel = (name: string) => string;

const mapVisitType = ({ id, name }: VisitType, getVisitTypeLabel: GetVisitTypeLabel) => ({
  value: id,
  label: getVisitTypeLabel(name),
});
const getTranslationId = (name: string): MessageIds => `visitTypes.${name}` as MessageIds;

export const useVisitTypes = () => {
  const { formatMessage } = useIntl();
  const getVisitTypeLabel = useCallback(
    (name: string) => formatMessage({ id: getTranslationId(name) }),
    [formatMessage],
  );
  const locationInfo = useLocationInfo();

  return locationInfo?.visitTypes?.map((visitType) => mapVisitType(visitType, getVisitTypeLabel)) ?? [];
};

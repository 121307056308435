import { CalendarAltDay } from '@hqo/react-components-library/dist/icons';
import { CalendarFieldStyled } from './styles';
import { useFieldTranslations } from 'app/shared/hooks/use-field-translations';

export interface DateFieldProps {
  onClick?: VoidFunction;
  isError: boolean;
  fieldName: string;
  value?: string;
}

export const DateField = ({ onClick, isError, fieldName, value }: DateFieldProps) => {
  const { label, placeholder } = useFieldTranslations(fieldName);
  return (
    <CalendarFieldStyled
      className="date-picker-select"
      onClick={onClick}
      variant={isError ? 'error' : 'normal'}
      required
      label={label}
      placeholder={placeholder}
      Icon={CalendarAltDay}
      value={value}
      data-testid="visit-date-select"
    />
  );
};

import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { FormValues } from '../../../../visit-form.interfaces';

export const useDeleteVisitor = (index: number) => {
  const { setValues, values, setTouched, touched, setFieldValue } = useFormikContext<FormValues>();
  const deleteVisitorValues = useCallback(() => {
    const newVisitors = [...values.visitors];
    newVisitors.splice(index, 1);
    setValues({ ...values, visitors: newVisitors });
  }, [values, setValues, index]);

  const resetGroupValues = useCallback(() => {
    setFieldValue('groupUuid', undefined);
    setFieldValue('groupName', '');
  }, [setFieldValue]);

  const deleteVisitorTouched = useCallback(() => {
    if (!touched.visitors) {
      return;
    }

    const updatedTouched = [...touched.visitors];
    updatedTouched.splice(index, 1);
    setTouched({ ...touched, visitors: updatedTouched });
  }, [touched, setTouched, index]);

  const handleVisitorDelete = useCallback(() => {
    deleteVisitorValues();
    deleteVisitorTouched();
    resetGroupValues();
  }, [deleteVisitorValues, deleteVisitorTouched, resetGroupValues]);

  return handleVisitorDelete;
};

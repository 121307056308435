import {
  FetchVisitorGroupFailureDto,
  FetchVisitorGroupPayloadDto,
  FetchVisitorGroupsFailureDto,
  FetchVisitorGroupsPayloadDto,
  FetchVisitorGroupsSuccessDto,
  FetchVisitorGroupSuccessDto,
} from 'app/store/visitor-groups/types';
import { createAsyncAction } from 'typesafe-actions';

export const fetchVisitorGroups = createAsyncAction(
  'VISITOR_GROUPS/FETCH_REQUEST',
  'VISITOR_GROUPS/FETCH_SUCCESS',
  'VISITOR_GROUPS/FETCH_FAILURE',
)<FetchVisitorGroupsPayloadDto, FetchVisitorGroupsSuccessDto, FetchVisitorGroupsFailureDto>();

export const fetchVisitorGroup = createAsyncAction(
  'VISITOR_GROUP/FETCH_REQUEST',
  'VISITOR_GROUP/FETCH_SUCCESS',
  'VISITOR_GROUP/FETCH_FAILURE',
)<FetchVisitorGroupPayloadDto, FetchVisitorGroupSuccessDto, FetchVisitorGroupFailureDto>();

import { colors } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { VisitorRegistrationApp } from 'app';
import { Building } from 'app/store/building/types';
import { FeatureFlags } from 'app/store/config/types';
import { useFetch } from 'example/use-fetch.hook';
import { useOpenParams } from 'example/use-open-params.hook';
import { FC, useCallback, useMemo } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

export interface OpenAppParams {
  authToken: string;
  apiUrl: string;
  locale: string;
  publicUrl?: string;
  buildingUuid: string;
}

export interface User {
  uuid: string;
  company_uuid: string;
  first_name: string;
  last_name: string;
  email: string;
}

const Wrapper = styled.div`
  padding: 0 32px;
`;

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100vh;
    background-color: ${colors.palette.systemGreyLight};
  }
`;

const featureFlags: Record<FeatureFlags, boolean> = {
  enableVisitsRegistration: true,
  showVisRegSettingsPage: true,
  showVisitorsTypeAhead: true,
  showAccessGroupsSettings: true,
  showVisitorPasses: true,
  showVisitorsTab: true,
  showVisitorRegistrationDaypassTab: true,
  showAdminNav51: true,
  showWatchlistFeature: true,
  showVisitorRegistrationGroupsTab: true,
};

export const App: FC = () => {
  const { apiUrl, authToken, publicUrl, buildingUuid, locale } = useOpenParams();
  const getAuthToken = useCallback(() => Promise.resolve(authToken), [authToken]);
  const buildingResponse = useFetch<Building>(`${apiUrl}/api/buildings/${buildingUuid}`, authToken);
  const userResponse = useFetch<User>(`${apiUrl}/api/auth/current`, authToken);

  const currentUser = useMemo(() => {
    if (!userResponse) {
      return null;
    }

    return {
      ...userResponse,
      company: { uuid: userResponse.company_uuid },
    };
  }, [userResponse]);

  if (!authToken || !apiUrl || !locale || !buildingUuid) {
    return <div>Missing required parameters</div>;
  }

  if (!buildingResponse || !currentUser) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <GlobalStyle />
      <Wrapper className="application-root-ref">
        <VisitorRegistrationApp
          apiUrl={apiUrl}
          publicUrl={publicUrl}
          getAuthToken={getAuthToken}
          user={currentUser}
          locale={locale}
          building={buildingResponse}
          featureFlags={featureFlags}
        />
      </Wrapper>
    </>
  );
};

import styled from 'styled-components';

export const LinkButton = styled.a`
  padding: 0;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.palette.systemBlue};
`;

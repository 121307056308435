import { useField } from 'formik';
import { FieldWrapper } from 'app/components/fields/field-wrapper';
import { FormFeatureFlags, VisitDateTime } from 'app/components/visits/visit-form/visit-form.interfaces';
import { DateField } from './date-field';
import { useDateDisplayValue, usePopoverToggle } from './hooks';
import { DatePopover } from './date-popover';
import { CalendarView, MultiDayPickerChange } from './calendar-view';
import { useCallback } from 'react';

export interface MultiDayDatePickerProps extends Pick<FormFeatureFlags, 'canSelectMultipleDates'> {
  value: Date[];
  onChange: MultiDayPickerChange;
  onClear: VoidFunction;
  fieldName: string;
  shouldReplaceDate?: boolean;
}

const getStartDateError = (error: unknown): string | null => {
  if (Array.isArray(error)) {
    return error?.at(0)?.startDate?.toString() ?? '';
  }
  return null;
};

export const MultiDayDatePicker = ({
  fieldName,
  value,
  onChange,
  onClear,
  canSelectMultipleDates,
  shouldReplaceDate,
}: MultiDayDatePickerProps) => {
  const [_, { error, touched }] = useField<VisitDateTime[]>(fieldName);
  const startDateError = getStartDateError(error);
  const [open, toggleOpen] = usePopoverToggle();
  const hasError = touched && !!startDateError;
  const onChangeHandler = useCallback(
    (dates: Date[]) => {
      onChange(dates);
      toggleOpen();
    },
    [toggleOpen, onChange],
  );
  const onClearHandler = useCallback(() => {
    onClear();
    toggleOpen();
  }, [toggleOpen, onClear]);
  return (
    <FieldWrapper touched={touched} error={startDateError}>
      <DateField onClick={toggleOpen} fieldName={fieldName} isError={hasError} value={useDateDisplayValue(value)} />
      <DatePopover open={open} onClose={toggleOpen}>
        <CalendarView
          value={value}
          onClear={onClearHandler}
          onChange={onChangeHandler}
          canSelectMultipleDates={canSelectMultipleDates}
          shouldReplaceDate={shouldReplaceDate}
        />
      </DatePopover>
    </FieldWrapper>
  );
};

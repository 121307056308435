import { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import set from 'date-fns/set';
import startOfDay from 'date-fns/startOfDay';
import type { CalendarDateInfo } from '@hqo/react-components-library/dist/molecules/calendar-view/calendar-view.interface';

type DateClickHandler = (index: number, data: CalendarDateInfo) => void;

function mergeDates(data: CalendarDateInfo, dates: Date[], setDates: Dispatch<SetStateAction<Date[]>>) {
  const date = startOfDay(set(new Date(), data));
  const result = [...dates];
  const index = result.map((d) => d.toISOString()).indexOf(date.toISOString());
  if (index !== -1) {
    result.splice(index, 1);
  } else {
    result.push(date);
  }
  setDates(result);
}

export const useHandleOnDateClick = (
  initialDates: Date[],
  canSelectMultipleDates: boolean = true,
  shouldReplaceDate: boolean = true,
): [Date[], DateClickHandler] => {
  const [dates, setDates] = useState<Date[]>(initialDates);
  useEffect(() => setDates(initialDates), [initialDates]);
  const shouldReplaceDateRef = useRef(shouldReplaceDate);

  const handleDateClick = useCallback(
    (__: number, data: CalendarDateInfo) => {
      if (shouldReplaceDateRef.current) {
        shouldReplaceDateRef.current = false;
        setDates([startOfDay(set(new Date(), data))]);

        return;
      }

      if (canSelectMultipleDates) {
        mergeDates(data, dates, setDates);
      } else {
        setDates([startOfDay(set(new Date(), data))]);
      }
    },
    [dates, setDates, canSelectMultipleDates],
  );

  return [dates, handleDateClick];
};

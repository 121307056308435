import {
  FetchVisitorsForBuildingParams,
  FetchVisitorsForBuildingSuccessDto,
  FetchVisitorsForBuildingErrorDto,
} from 'app/store/visitors/types';
import { createAsyncAction } from 'typesafe-actions';

export const fetchVisitors = createAsyncAction(
  'VISITORS/FETCH_REQUEST',
  'VISITORS/FETCH_SUCCESS',
  'VISITORS/FETCH_FAILURE',
)<FetchVisitorsForBuildingParams, FetchVisitorsForBuildingSuccessDto, FetchVisitorsForBuildingErrorDto>();

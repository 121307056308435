import { Wrapper, NameItem } from './search-visitor-dropdown-item.styles';
import { FC } from 'react';

interface SearchVisitorItemProps {
  firstName?: string;
  lastName?: string;
  email?: string;
}

export const SearchVisitorItem: FC<SearchVisitorItemProps> = ({ firstName, lastName, email }) => (
  <Wrapper>
    {firstName && <NameItem>{firstName}</NameItem>}
    {lastName && <NameItem>{lastName}</NameItem>}
    {email && <div>{email}</div>}
  </Wrapper>
);

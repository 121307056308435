export const VISITS_PATH = 'visits';

export enum VisitsByDate {
  PREVIOUS = 'previous',
  UPCOMING = 'upcoming',
}

export const ROOT_PATH = '/';

export const CREATE_VISIT_PATH = 'new';

export const EDIT_VISIT_PATH = 'edit';

export const SETTINGS_PATH = 'settings';

export enum SettingsType {
  FEATURE = 'feature',
  EMAIL = 'email',
  GENERAL = 'general',
  ACCESS_GROUPS = 'access-groups',
  DAY_PASS = 'day-passes',
}

export const VISITORS_PATH = 'visitors';

export const VISIT_UUID_PARAMETER = 'visitUuid';

export const PASS_PATH = 'pass';

export const CREDENTIAL_SCANNER_PATH = 'scanner';

export const DETAILS_PATH = 'details';

export enum DetailsRoutes {
  HOSTS = 'hosts',
  VISITORS = 'visitors',
}

export const ENTITY_UUID_PARAMETER = 'entityUuid';

export const HOST_DETAILS_ROUTE = `${DetailsRoutes.HOSTS}/:${ENTITY_UUID_PARAMETER}`;

export const VISITOR_DETAILS_ROUTE = `${DetailsRoutes.VISITORS}/:${ENTITY_UUID_PARAMETER}`;

export const DAY_PASSES_ROUTE = 'day-passes';

export const WATCHLIST_ROUTE = 'watchlist';

export const COMPANY_UUID_PARAMETER = 'companyUuid';

export const PERSON_UUID_PARAMETER = 'personUuid';

export const PRINT_DAY_PASS_ROUTE = `companies/:${COMPANY_UUID_PARAMETER}/persons/:${PERSON_UUID_PARAMETER}/pass`;

export const REQUESTED_DAY_PASSES_ROUTE = 'requested-day-passes';

export const MANAGE_DAY_PASSES_ROUTE = 'manage';

export const MANAGE_WATCHLIST_ROUTE = 'manage';

export const ADD_WATCHLIST_PERSON_ROUTE = 'add';

export const GROUPS_ROUTE = 'groups';

export const GROUP_UUID_PARAMETER = 'uuid';

export const GROUPS_DETAILS_ROUTE = `details/:${GROUP_UUID_PARAMETER}`;

import { ActionType, createReducer } from 'typesafe-actions';
import { fetchLocationInfo } from './actions';
import { BuildingLocationInfoState, LocationInfoState } from './types';

export const initialState: LocationInfoState = {};
export const initialLocationInfoState: BuildingLocationInfoState = {
  locationInfo: null,
  loading: false,
  error: null,
};

const handleFetchLocationInfoRequest = (
  state: LocationInfoState,
  action: ActionType<typeof fetchLocationInfo.request>,
): LocationInfoState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialLocationInfoState,
    ...state[action.payload.buildingUuid],
    error: null,
    loading: true,
  },
});

const handleFetchLocationInfoSuccess = (
  state: LocationInfoState,
  action: ActionType<typeof fetchLocationInfo.success>,
): LocationInfoState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    locationInfo: action.payload.response,
    loading: false,
    error: null,
  },
});

const handleFetchLocationInfoFailure = (
  state: LocationInfoState,
  action: ActionType<typeof fetchLocationInfo.failure>,
): LocationInfoState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

export const locationInfoReducer = createReducer(initialState)
  .handleAction(fetchLocationInfo.request, handleFetchLocationInfoRequest)
  .handleAction(fetchLocationInfo.success, handleFetchLocationInfoSuccess)
  .handleAction(fetchLocationInfo.failure, handleFetchLocationInfoFailure);

import {
  DateFilterPanelWrapper,
  MetricsPanelWrapper,
  VisitsPageHeadingWrapper,
} from 'app/components/visits/visits-page-heading/visits-page-heading.styles';
import { VisitsDateFilter } from 'app/components/visits/visits-date-filter';
import { VisitsMetrics } from 'app/components/visits/visits-metrics';
import { FC } from 'react';

export const VisitsPageHeading: FC = () => (
  <VisitsPageHeadingWrapper>
    <DateFilterPanelWrapper>
      <VisitsDateFilter />
    </DateFilterPanelWrapper>
    <MetricsPanelWrapper>
      <VisitsMetrics />
    </MetricsPanelWrapper>
  </VisitsPageHeadingWrapper>
);

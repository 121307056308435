import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { VisibilityEnum, Visitor } from 'app/components/visits/visit-form/visit-form.interfaces';
import { VISITORS_INITIAL_VALUES_QUERY_STRING_KEY } from 'app/components/visits/visit-form/hooks/creation/get-visitors-initial-value-query-string.util';

export const initialVisitorValues = {
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  visibility: VisibilityEnum.PUBLIC,
};

export const useInitialVisitorsValue = () => {
  const { search } = useLocation();

  return useMemo(() => {
    if (!search) {
      return [initialVisitorValues];
    }

    const searchParams = qs.parse(search, { ignoreQueryPrefix: true });

    const visitors = searchParams[VISITORS_INITIAL_VALUES_QUERY_STRING_KEY] as Visitor[];

    if (!visitors?.length) {
      return [initialVisitorValues];
    }

    return visitors;
  }, [search]);
};

import { FC } from 'react';
import { IconWrapper } from './delete-visitor.styles';
import { useDeleteVisitor } from './delete-visitor.hooks';

interface DeleteProps {
  index: number;
}

export const DeleteVisitor: FC<DeleteProps> = ({ index }) => {
  const handleVisitorDelete = useDeleteVisitor(index);

  return (
    <IconWrapper onClick={handleVisitorDelete} data-testid="delete-visitor">
      <span className="material-icons">delete</span>
    </IconWrapper>
  );
};

import { ActionType, createReducer } from 'typesafe-actions';
import { VisitorState, VisitorStateByUuid } from './types';
import { fetchVisitor } from './actions';

export const initialState: VisitorStateByUuid = {};
export const initialVisitorState: VisitorState = {
  visitor: null,
  loading: false,
  error: null,
};

const handleFetchVisitorRequest = (
  state: VisitorStateByUuid,
  action: ActionType<typeof fetchVisitor.request>,
): VisitorStateByUuid => ({
  ...state,
  [action.payload.uuid]: {
    ...initialVisitorState,
    loading: true,
  },
});

const handleFetchVisitorSuccess = (
  state: VisitorStateByUuid,
  { payload }: ActionType<typeof fetchVisitor.success>,
): VisitorStateByUuid => ({
  ...state,
  [payload.params.uuid]: {
    loading: false,
    visitor: payload.response,
    error: null,
  },
});

const handleFetchVisitorFailure = (
  state: VisitorStateByUuid,
  action: ActionType<typeof fetchVisitor.failure>,
): VisitorStateByUuid => ({
  ...state,
  [action.payload.params.uuid]: {
    ...state[action.payload.params.uuid],
    loading: false,
    error: action.payload.error,
  },
});

export const visitorReducer = createReducer(initialState)
  .handleAction(fetchVisitor.request, handleFetchVisitorRequest)
  .handleAction(fetchVisitor.success, handleFetchVisitorSuccess)
  .handleAction(fetchVisitor.failure, handleFetchVisitorFailure);

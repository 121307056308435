import { DaypassStatus, DaypassUser } from 'app/store/daypass-users/types';
import { MessageIds } from 'i18n';
import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';

const showNotification = (notificationId: MessageIds, status: DaypassStatus, person: DaypassUser, intl: IntlShape) => {
  const name = [person.firstName, person.lastName].filter(Boolean).join(' ');
  const message = intl.formatMessage({ id: notificationId }, { name });

  if (status === DaypassStatus.DENIED) {
    toast.error(message);
  } else {
    toast.success(message);
  }
};

export const showDaypassUpdatedNotification = (
  oldStatus: DaypassStatus | undefined,
  newStatus: DaypassStatus,
  person: DaypassUser,
  intl: IntlShape,
  messageFactory: (oldStatus: DaypassStatus | undefined, newStatus: DaypassStatus) => MessageIds | null,
) => {
  if (oldStatus === newStatus && newStatus !== DaypassStatus.AWAITING_APPROVAL) {
    return;
  }

  const notificationId = messageFactory(oldStatus, newStatus);

  if (notificationId) {
    showNotification(notificationId, newStatus, person, intl);
  }
};

import {
  ContactInformationDisplayFlagEnum,
  ContactInformationRequirementFlagEnum,
  FeatureEnablementFlagEnum,
  UiMetadata,
  VisitorRegistrationFlagProperties,
  VisitorRegistrationUiMetadata,
} from './types';
import { uiMetadataSelector, uiMetadataLoadingState, uiMetadataErrorState } from './selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';

import { fetchUiMetadata } from './actions';
import { buildingUuidSelector } from 'app/store/building/selectors';

export const useFetchUiMetadata = (): boolean => {
  const buildingUuid = useSelector(buildingUuidSelector);
  const loading = useSelector(uiMetadataLoadingState);
  const uiMetadata = useSelector(uiMetadataSelector);
  const error = useSelector(uiMetadataErrorState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!uiMetadata && buildingUuid && !loading && !error) {
      dispatch(fetchUiMetadata.request({ buildingUuid }));
    }
  }, [dispatch, buildingUuid, uiMetadata, loading]);

  return loading;
};

const mapToEnablementFlag = (display: boolean, required: boolean): FeatureEnablementFlagEnum => {
  if (display && required) {
    return FeatureEnablementFlagEnum.ENABLED_REQUIRED;
  }

  if (display) {
    return FeatureEnablementFlagEnum.ENABLED_OPTIONAL;
  }

  return FeatureEnablementFlagEnum.DISABLED;
};

const getVisitorEmail = (
  contactInformationDisplay?: ContactInformationDisplayFlagEnum,
  contactInformationRequirement?: ContactInformationRequirementFlagEnum,
): FeatureEnablementFlagEnum => {
  if (contactInformationDisplay && contactInformationRequirement) {
    const required = [
      ContactInformationRequirementFlagEnum.EMAIL_AND_PHONE,
      ContactInformationRequirementFlagEnum.EMAIL_OR_PHONE,
      ContactInformationRequirementFlagEnum.EMAIL,
    ].includes(contactInformationRequirement);

    const display = [ContactInformationDisplayFlagEnum.EMAIL, ContactInformationDisplayFlagEnum.EMAIL_PHONE].includes(
      contactInformationDisplay,
    );

    return mapToEnablementFlag(display, required);
  }

  return FeatureEnablementFlagEnum.ENABLED_REQUIRED;
};

function getVisitorPhone(
  contactInformationDisplay?: ContactInformationDisplayFlagEnum,
  contactInformationRequirement?: ContactInformationRequirementFlagEnum,
  visitorPhone?: FeatureEnablementFlagEnum,
): undefined | FeatureEnablementFlagEnum {
  if (contactInformationDisplay && contactInformationRequirement) {
    const required = [
      ContactInformationRequirementFlagEnum.EMAIL_AND_PHONE,
      ContactInformationRequirementFlagEnum.EMAIL_OR_PHONE,
      ContactInformationRequirementFlagEnum.PHONE,
    ].includes(contactInformationRequirement);

    const display = [ContactInformationDisplayFlagEnum.PHONE, ContactInformationDisplayFlagEnum.EMAIL_PHONE].includes(
      contactInformationDisplay,
    );

    return mapToEnablementFlag(display, required);
  }

  return visitorPhone;
}

export const checkIfEmailOrPhoneEnabled = (metadata: UiMetadata | null): VisitorRegistrationFlagProperties => {
  const contactInformationDisplay = metadata?.ui_metadata?.contact_information_display;
  const contactInformationRequirement = metadata?.ui_metadata?.contact_information_requirement;

  if (contactInformationDisplay && contactInformationRequirement) {
    const required = ContactInformationRequirementFlagEnum.EMAIL_OR_PHONE === contactInformationRequirement;

    const display = ContactInformationDisplayFlagEnum.EMAIL_PHONE === contactInformationDisplay;
    if (required && display) {
      return {
        visitor_email: FeatureEnablementFlagEnum.DISABLED,
        visitor_phone: FeatureEnablementFlagEnum.DISABLED,
        email_or_phone_case: true,
      };
    }
  }

  return {
    visitor_email: getVisitorEmail(contactInformationDisplay, contactInformationRequirement),
    visitor_phone: getVisitorPhone(
      contactInformationDisplay,
      contactInformationRequirement,
      metadata?.ui_metadata?.visitor_phone,
    ),
    email_or_phone_case: false,
  };
};

export const useUiMetadata = (): UiMetadata | null => {
  const metadata = useSelector(uiMetadataSelector);

  return useMemo(() => {
    if (!metadata) {
      return null;
    }

    return {
      ...metadata,
      ui_metadata: {
        ...metadata.ui_metadata,
        ...checkIfEmailOrPhoneEnabled(metadata),
      },
    };
  }, [metadata]);
};

export const useFieldMetadata = <K extends keyof VisitorRegistrationUiMetadata>(
  key?: K,
): VisitorRegistrationUiMetadata[K] | null => {
  const metadata = useUiMetadata();

  if (!key) {
    return null;
  }

  return metadata?.ui_metadata[key] ?? null;
};

import styled from 'styled-components';
import { Panel } from 'app/components/panel';

export const StyledPanel = styled(Panel)`
  display: flex;
  justify-content: space-around;
  width: auto;
  height: auto;
  gap: 24px;
  padding: 36px 24px 16px;
  margin-bottom: 16px;
  position: relative;
`;

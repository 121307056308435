import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormValues } from '../../visit-form.interfaces';
import { useLocationInfo } from 'app/shared/hooks/use-location-info';
import { userSelector } from 'app/store/user/selectors';
import { useInitialVisitorsValue } from 'app/components/visits/visit-form/hooks/creation/use-initial-visitors-value.hook';
import { useInitialVisitDateTimeValues } from 'app/components/visits/visit-form/hooks/creation/use-initial-date-time-values.hook';
import { useFieldMetadata } from 'app/store/ui-metadata/hooks';
import { useUserPermissionCheck } from 'app/store/user-permissions/hooks';
import { Permission } from 'app/store/user-permissions/types';

export const useInitialValues = (): FormValues => {
  const locationInfo = useLocationInfo();
  const currentUser = useSelector(userSelector);
  const host = useMemo(
    () => `${currentUser.first_name} ${currentUser.last_name}`,
    [currentUser.first_name, currentUser.last_name],
  );
  const hasAccessToChangeHost = useUserPermissionCheck(Permission.CHANGE_VISITOR_HOST);
  const altHostsEnabled = useFieldMetadata('alt_hosts_enabled');
  const visitors = useInitialVisitorsValue();
  const initialVisitDateTime = useInitialVisitDateTimeValues();

  return {
    visitDateTimes: [initialVisitDateTime],
    hostUuid: hasAccessToChangeHost && altHostsEnabled ? currentUser.uuid : undefined,
    host,
    visitors,
    visitTypeId: locationInfo?.visitTypes?.at(0)?.id ?? 0,
    endDate: null,
    saveAsGroup: false,
  };
};

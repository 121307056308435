import { Loader } from 'app/components/loader';
import { lazy, Suspense } from 'react';

const CredentialScanner = lazy(() => import('app/components/credential-scanner/credential-scanner-container'));

export const CredentialScannerPage = () => (
  <Suspense fallback={<Loader testId="credential-scanner-loader" />}>
    <CredentialScanner />
  </Suspense>
);

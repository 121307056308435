import { useIntl } from 'react-intl';
import { MessageIds } from '../../../../i18n';

interface FieldTranslations {
  label: string;
  placeholder: string;
}
export const useFieldTranslations = (fieldName: string): FieldTranslations => {
  const intl = useIntl();

  return {
    label: intl.formatMessage({ id: `fields.${fieldName}.label` as MessageIds }),
    placeholder: intl.formatMessage({ id: `fields.${fieldName}.placeholder` as MessageIds }),
  };
};

import { buildingUuidSelector } from 'app/store/building/selectors';
import { fetchVisit } from 'app/store/visits/actions';
import { openedVisitLoadingSelector, openedVisitSelector } from 'app/store/visits/selectors';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useVisitUuidParam } from './use-visit-uuid-param.hook';

export const useLoadVisit = (): boolean => {
  const dispatch = useDispatch();
  const buildingUuid = useSelector(buildingUuidSelector);
  const visitUuid = useVisitUuidParam();
  const visit = useSelector(openedVisitSelector(visitUuid));
  const isVisitLoading = useSelector(openedVisitLoadingSelector);

  useEffect(() => {
    if (!visit && !isVisitLoading) {
      dispatch(fetchVisit.request({ visitUuid, buildingUuid }));
    }
  }, [dispatch, visitUuid, buildingUuid]);

  return !!visit;
};

import { useEffect, useRef, useState } from 'react';

export const useFetch = <T>(url: string, authToken: string): T | null => {
  const [data, setData] = useState<T | null>(null);
  const isLoading = useRef(false);

  useEffect(() => {
    if (!isLoading.current) {
      isLoading.current = true;

      fetch(url, {
        headers: {
          Authorization: authToken,
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          return response;
        })
        .then((response) => response.json())
        .then((json) => {
          setData(json);
        })
        .finally(() => {
          isLoading.current = false;
        });
    }
  }, [url, authToken]);

  return data;
};

import { DaypassUserRequest } from '../daypass-requests/types';
import {
  CreateDaypassRequestsFailureDto,
  CreateDaypassRequestsPayloadDto,
  CreateDaypassRequestsSuccessDto,
  FetchDaypassUserCredentialFailureDto,
  FetchDaypassUserCredentialPayload,
  FetchDaypassUserCredentialSuccessDto,
} from './daypass-user-credential.types';
import {
  FetchDaypassUsersFailureDto,
  FetchDaypassUsersPayloadDto,
  FetchDaypassUsersSuccessDto,
  SetCompanyDaypassUsersFailureDto,
  SetCompanyDaypassUsersRequestDto,
  SetCompanyDaypassUsersSuccessDto,
} from './types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const fetchDaypassUsers = createAsyncAction(
  'DAYPASS_USERS/FETCH_REQUEST',
  'DAYPASS_USERS/FETCH_SUCCESS',
  'DAYPASS_USERS/FETCH_FAILURE',
)<FetchDaypassUsersPayloadDto, FetchDaypassUsersSuccessDto, FetchDaypassUsersFailureDto>();

export const fetchAllDaypassUsers = createAsyncAction(
  'ALL_DAYPASS_USERS/FETCH_REQUEST',
  'ALL_DAYPASS_USERS/FETCH_SUCCESS',
  'ALL_DAYPASS_USERS/FETCH_FAILURE',
)<FetchDaypassUsersPayloadDto, FetchDaypassUsersSuccessDto, FetchDaypassUsersFailureDto>();

export const fetchDaypassUserCredential = createAsyncAction(
  'DAYPASS_USERS/FETCH_CREDENTIAL_REQUEST',
  'DAYPASS_USERS/FETCH_CREDENTIAL_SUCCESS',
  'DAYPASS_USERS/FETCH_CREDENTIAL_FAILURE',
)<FetchDaypassUserCredentialPayload, FetchDaypassUserCredentialSuccessDto, FetchDaypassUserCredentialFailureDto>();

export const setCompanyDaypassUsers = createAsyncAction(
  'DAYPASS_USERS/SET_COMPANY_DAYPASS_USERS_REQUEST',
  'DAYPASS_USERS/SET_COMPANY_DAYPASS_USERS_SUCCESS',
  'DAYPASS_USERS/SET_COMPANY_DAYPASS_USERS_FAILURE',
)<SetCompanyDaypassUsersRequestDto, SetCompanyDaypassUsersSuccessDto, SetCompanyDaypassUsersFailureDto>();

export const createDaypassRequest = createAsyncAction(
  'DAYPASS_USERS/CREATE_REQUEST',
  'DAYPASS_USERS/CREATE_SUCCESS',
  'DAYPASS_USERS/CREATE_FAILURE',
)<CreateDaypassRequestsPayloadDto, CreateDaypassRequestsSuccessDto, CreateDaypassRequestsFailureDto>();

export const daypassUserRequestUpdated = createAction('DAYPASS_USERS/REQUEST_UPDATED')<DaypassUserRequest>();

export const daypassUserUpdated = createAction('DAYPASS_USERS/USER_UPDATED')<DaypassUserRequest>();

import { FC } from 'react';
import { useSelector } from 'react-redux';
import { hasWatchlistMatchesPermissionsSelector } from 'app/store/user-permissions/selectors';
import { WatchlistUpdatesListener } from './watchlist-updates-listener';

export const WatchlistUpdates: FC = (): JSX.Element | null => {
  const hasWatchlistMatchesPermissions: boolean = useSelector(hasWatchlistMatchesPermissionsSelector);

  if (!hasWatchlistMatchesPermissions) {
    return null;
  }

  return <WatchlistUpdatesListener />;
};

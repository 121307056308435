import { FC, useCallback } from 'react';
import { useField } from 'formik';
import { FormField } from '@hqo/react-components-library/dist/atoms/form-field/form-field';
import { FormFieldType } from '@hqo/react-components-library/dist/atoms/form-field/components/inputs';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const Field = styled(FormField)`
  margin-bottom: 24px;
`;

export const Note: FC = () => {
  const [{ value }, , { setValue }] = useField<string>('description');
  const intl = useIntl();

  const onChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, [setValue]);

  return (
    <Field
      fieldType={FormFieldType.INPUT}
      inputType="text"
      value={value}
      onChange={onChange}
      placeholder={intl.formatMessage({ id: 'visitForm.visitDetails.notePlaceholder' })}
      label={intl.formatMessage({ id: 'visitForm.visitDetails.noteTitle' })}
      maxCharLength={100}
      maxLength={100}
      data-testid="note"
    />
  );
};

import { useMemo } from 'react';
import { DropdownOption } from '@hqo/react-components-library/dist/molecules/search-input-v2/components/dropdown-item';
import { SearchVisitorItem } from './search-visitor-dropdown-item';
import { Visitor } from 'app/store/visitor/types';

export const useSearchVisitorDropdownOptions = (visitorOptions: Visitor[]): DropdownOption[] =>
  useMemo(
    () =>
      visitorOptions.map((visitor) => ({
        value: visitor.uuid,
        label: visitor.uuid,
        element: (
          <SearchVisitorItem firstName={visitor.first_name} lastName={visitor.last_name} email={visitor.email} />
        ),
        props: { className: 'search-visitor-dropdown-item' },
      })),
    [visitorOptions],
  );

import styled from 'styled-components';
import { Panel } from 'app/components/panel';

export const StyledPanel = styled(Panel)`
  display: flex;
  width: auto;
  flex-direction: column;
`;

export const Title = styled.div`
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 24px;
`;

import styled from 'styled-components';
import { HqoPageHeader } from '@hqo/react-components-library/dist/organisms/hqo-page-header';

export const PageHeader = styled(HqoPageHeader)`
  margin: 32px 0;

  & .header-cta-menu {
    width: 100px;
  }

  & .version-2:has(> .header-tabs) {
    overflow: auto;
  }
`;

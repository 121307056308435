import { useMemo } from 'react';
import { useIntl } from 'react-intl';

const visitorSearchEmptyTranslations = 'visitForm.visitors.visitorSearchEmpty';

export const useEmptyContentLabels = () => {
  const { formatMessage } = useIntl();

  const emptyContentLabels = useMemo(() => ({
    title: formatMessage({ id: `${visitorSearchEmptyTranslations}.title` }),
    subtitle: formatMessage({ id: `${visitorSearchEmptyTranslations}.subtitle` }),
  }), [formatMessage]);

  return emptyContentLabels;
};

import { FC } from 'react';
import { VisitDetails } from './visit-details';
import { Visitors } from './visitors';
import { Container } from './visit-creation-form-content.styles';
import { FormFeatureFlags } from 'app/components/visits/visit-form/visit-form.interfaces';

interface VisitFormContentProps extends FormFeatureFlags {
  shouldReplaceInitialDate?: boolean;
  canChangeVisitVisibility?: boolean;
}

export const VisitFormContent: FC<VisitFormContentProps> = ({
  canAddVisitor,
  canEditVisitTypes,
  canEditVisitorDetails,
  canSelectMultipleDates,
  canEditHost,
  shouldReplaceInitialDate,
  canChangeVisitVisibility,
  canSaveAsGroup,
}) => (
  <Container>
    <VisitDetails
      canEditVisitTypes={canEditVisitTypes}
      canSelectMultipleDates={canSelectMultipleDates}
      canEditHost={canEditHost}
      shouldReplaceInitialDate={shouldReplaceInitialDate}
    />
    <Visitors
      canSaveAsGroup={canSaveAsGroup}
      canAddVisitor={canAddVisitor}
      canEditVisitorDetails={canEditVisitorDetails}
      canChangeVisitVisibility={canChangeVisitVisibility}
    />
  </Container>
);

import { fetchUiMetadata } from 'app/store/ui-metadata/actions';
import { BuildingUiMetadataState, UiMetadataState } from 'app/store/ui-metadata/types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: UiMetadataState = {};
export const initialBuildingUiMetadataState: BuildingUiMetadataState = {
  uiMetadata: null,
  error: null,
  loading: false,
};

const getUpdatedBuildingUiMetadataState = (
  state: UiMetadataState,
  buildingUuid: string,
  updates: Partial<BuildingUiMetadataState>,
): UiMetadataState => ({
  ...state,
  [buildingUuid]: {
    ...initialBuildingUiMetadataState,
    ...state[buildingUuid],
    ...updates,
  },
});

const handleFetchUiMetadataRequest = (state: UiMetadataState, action: ActionType<typeof fetchUiMetadata.request>): UiMetadataState =>
  getUpdatedBuildingUiMetadataState(state, action.payload.buildingUuid, { loading: true, error: null });

const handleFetchUiMetadataSuccess = (state: UiMetadataState, action: ActionType<typeof fetchUiMetadata.success>): UiMetadataState =>
  getUpdatedBuildingUiMetadataState(state, action.payload.params.buildingUuid, { loading: false, uiMetadata: action.payload.response, error: null });

const handleFetchUiMetadataFailure = (state: UiMetadataState, action: ActionType<typeof fetchUiMetadata.failure>): UiMetadataState =>
  getUpdatedBuildingUiMetadataState(state, action.payload.params.buildingUuid, { loading: false, error: action.payload.response });

export const uiMetadataReducer = createReducer(initialState)
  .handleAction(fetchUiMetadata.request, handleFetchUiMetadataRequest)
  .handleAction(fetchUiMetadata.success, handleFetchUiMetadataSuccess)
  .handleAction(fetchUiMetadata.failure, handleFetchUiMetadataFailure);

import { Loader } from 'app/components/loader';
import { FC, lazy, Suspense } from 'react';

const AccessGroupsSettings = lazy(
  () => import('app/components/settings/access-groups-settings/access-groups-settings.container'),
);

export const AccessGroupsSettingsPage: FC = () => (
  <Suspense fallback={<Loader testId="visitor-management-access-groups-settings-loader" />}>
    <div data-testid="visitor-management-access-groups-settings-container">
      <AccessGroupsSettings />
    </div>
  </Suspense>
);

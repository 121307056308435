import { useField } from 'formik';
import { FC, useCallback } from 'react';
import { RadioButton } from '@hqo/react-components-library/dist/molecules/radio-button';
import { Container } from './radio-buttons.styles';

export interface RadioButton {
  value: number;
  label: string;
}

interface RadioButtonsProps {
  fieldName: string;
  disabled?: boolean;
  buttons: RadioButton[];
}

export const RadioButtons: FC<RadioButtonsProps> = ({ fieldName, buttons, disabled }) => {
  const [{ value }, , { setValue }] = useField<number>(fieldName);
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => { setValue(parseInt(event.target.value, 10)); },
    [setValue],
  );

  return (
    <Container>
      {buttons.map(({ value: buttonValue, label }) => (
        <RadioButton
          key={buttonValue}
          selectedValue={value.toString()}
          value={buttonValue.toString()}
          label={label}
          onChange={handleChange}
          disabled={disabled}
        />
      ))}
    </Container>
  );
};

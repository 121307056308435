import {
  userPermissionsSelector,
  userPermissionsLoadingState,
  userPermissionsErrorState,
  makeHasPermissionSelector,
} from './selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';

import { fetchUserPermissions } from './actions';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { Permission } from './types';

export const useFetchUserPermissions = (): boolean => {
  const buildingUuid = useSelector(buildingUuidSelector);
  const loading = useSelector(userPermissionsLoadingState);
  const userPermissions = useSelector(userPermissionsSelector);
  const error = useSelector(userPermissionsErrorState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!userPermissions && buildingUuid && !loading && !error) {
      dispatch(fetchUserPermissions.request({ buildingUuid, permissions: Object.values(Permission) }));
    }
  }, [dispatch, buildingUuid, userPermissions, loading]);

  return loading || !userPermissions || !!error;
};

export const useUserPermissionCheck = (permission: Permission): boolean =>
  useSelector(makeHasPermissionSelector(permission));

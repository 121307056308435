import { fetchHosts } from 'app/store/hosts/actions';
import { hostsLoadingSelector, hostsSelector } from 'app/store/hosts/selectors';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { userSelector } from 'app/store/user/selectors';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

export const useSearchHost = () => {
  const dispatch = useDispatch();
  const buildingUuid = useSelector(buildingUuidSelector);
  const companyUuid = useSelector(userSelector)?.company?.uuid;
  const hostOptions = useSelector(hostsSelector) || [];
  const isLoading = useSelector(hostsLoadingSelector);

  const searchHost = useCallback(
    (searchTerm: string) => {
      dispatch(fetchHosts.request({ query: searchTerm, buildingUuid, companyUuid }));
    },
    [dispatch, buildingUuid],
  );

  return { hostOptions, searchHost, isLoading };
};

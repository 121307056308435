import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from 'use-debounce';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { visitorGroupsSelector, visitorGroupsTotalSelector } from 'app/store/visitor-groups/selectors';
import { fetchVisitorGroups } from 'app/store/visitor-groups/actions';

interface UseFetchVisitorGroupsRo {
  query: string;
  setQuery: (query: string) => void;
  hasMore: boolean;
  handleLoadMore: VoidFunction;
  debouncedQuery: string;
}

const DEFAULT_LIMIT = 10;
const DEBOUNCE_TIME = 300;

export const useFetchVisitorGroups = (): UseFetchVisitorGroupsRo => {
  const dispatch = useDispatch();
  const buildingUuid = useSelector(buildingUuidSelector);
  const visitorGroups = useSelector(visitorGroupsSelector);
  const visitorGroupsTotal = useSelector(visitorGroupsTotalSelector);
  const [query, setQuery] = useState('');

  const [debouncedQuery] = useDebounce(query, DEBOUNCE_TIME);

  useEffect(() => {
    dispatch(fetchVisitorGroups.request({ buildingUuid, query: debouncedQuery, limit: DEFAULT_LIMIT, includeVisitors: true, shouldReset: true }));
  }, [dispatch, buildingUuid, debouncedQuery]);

  const handleLoadMore = useCallback(() => {
    const request = { buildingUuid, query: debouncedQuery, limit: DEFAULT_LIMIT, offset: visitorGroups.length, includeVisitors: true };
    dispatch(fetchVisitorGroups.request(request));
  }, [visitorGroups.length, debouncedQuery, dispatch, buildingUuid]);

  return { query, setQuery, hasMore: visitorGroups.length < visitorGroupsTotal, handleLoadMore, debouncedQuery };
};

import { useMemo } from 'react';
import { object, Schema, date, string, TestContext } from 'yup';
import { endOfDay, isBefore, startOfDay, subDays } from 'date-fns';
import { DeepPartial } from 'app/shared/types';
import { AuditExportType } from 'app/store/visits/types';
import { ExportAuditReportForm } from '../audit-export-modal.types';

const today = new Date();
const thirtyDaysBack = subDays(today, 30);

export const initialValues: ExportAuditReportForm = {
  type: AuditExportType.CSV,
  startDate: thirtyDaysBack,
  endDate: endOfDay(today),
};

const startDateBeforeEndDate = (value: Date | null | undefined, { parent }: TestContext): boolean => {
  const { endDate } = parent;
  if (!(value && endDate)) {
    return true;
  }

  return isBefore(startOfDay(value), endOfDay(endDate));
};

const endDateAfterStartDate = (value: Date | null | undefined, { parent }: TestContext): boolean => {
  const { startDate } = parent;
  if (!(value && startDate)) {
    return true;
  }

  return isBefore(startOfDay(startDate), endOfDay(value));
};

const getValidationSchema = (): Schema<DeepPartial<ExportAuditReportForm>> => object().shape({
  type: string().oneOf([AuditExportType.CSV, AuditExportType.PDF]).required(),
  startDate: date().required('errors.startDateRequired').test('start-date-before-end-date', 'errors.startDateBeforeEndDate', startDateBeforeEndDate),
  endDate: date().required('errors.endDateRequired').test('end-date-after-start-date', 'errors.endDateAfterStartDate', endDateAfterStartDate),
});

export const useExportFormValidationSchema = () => useMemo(() => getValidationSchema(), []);

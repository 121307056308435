import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { firstValueFrom } from 'rxjs';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';
import { apiClient as apiClientFactory, ApiClient } from 'app/store/api-client';
import { FormValues } from 'app/components/visits/visit-form/visit-form.interfaces';

const DEBOUNCE_TIME = 300;

const testUniqueGroupName = async (groupName: string, apiClient: ApiClient): Promise<boolean> => {
  try {
    const { data, total } = await firstValueFrom(apiClient.getVisitorGroups({ query: groupName, limit: 1 }));
    return total === 0 || (data[0]?.name?.toLowerCase() !== groupName);
  } catch (e) {
    return false;
  }
};

export const useUniqueGroupNameValidation = () => {
  const { formatMessage } = useIntl();
  const { values, setFieldError } = useFormikContext<FormValues>();
  const apiClient: ApiClient = useSelector(apiClientFactory);

  const [debouncedGroupName] = useDebounce(values.groupName, DEBOUNCE_TIME);

  useEffect(() => {
    const trimmedGroupName = (debouncedGroupName ?? '').trim().toLowerCase();
    if (values.saveAsGroup && trimmedGroupName && !values.groupUuid) {
      testUniqueGroupName(trimmedGroupName, apiClient).then((isUnique) => {
        if (!isUnique) {
          setFieldError('groupName', formatMessage({ id: 'errors.groupNameAlreadyExists' }));
        }
      });
    }
  }, [apiClient, debouncedGroupName, values.saveAsGroup, values.groupUuid, setFieldError]);
};

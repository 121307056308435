import { ActionType, createReducer } from 'typesafe-actions';
import { HostsState, HostsByBuildingState } from './types';
import { clearHosts, fetchHosts } from 'app/store/hosts/actions';

export const initialState: HostsByBuildingState = {};
export const initialBuildingState: HostsState = {
  hosts: [],
  loading: false,
  error: null,
};

const handleFetchHostsRequest = (
  state: HostsByBuildingState,
  action: ActionType<typeof fetchHosts.request>,
): HostsByBuildingState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingState,
    ...state[action.payload.buildingUuid],
    loading: true,
    error: null,
  },
});

const handleFetchHostsSuccess = (
  state: HostsByBuildingState,
  action: ActionType<typeof fetchHosts.success>,
): HostsByBuildingState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    hosts: action.payload.response.data,
    loading: false,
    error: null,
  },
});

const handleFetchHostsFailure = (
  state: HostsByBuildingState,
  action: ActionType<typeof fetchHosts.failure>,
): HostsByBuildingState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingState,
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

const handleClearHosts = (
  state: HostsByBuildingState,
  action: ActionType<typeof clearHosts>,
): HostsByBuildingState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingState,
    ...state[action.payload.buildingUuid],
    hosts: [],
  },
});

export const hostsReducer = createReducer(initialState)
  .handleAction(fetchHosts.request, handleFetchHostsRequest)
  .handleAction(fetchHosts.success, handleFetchHostsSuccess)
  .handleAction(fetchHosts.failure, handleFetchHostsFailure)
  .handleAction(clearHosts, handleClearHosts);

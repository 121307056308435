import { FC, lazy, Suspense } from 'react';
import { Outlet } from 'react-router';
import { TabPageLayout } from 'app/components/visitor-management-tabs/tab-page-layout';
import { Loader } from 'app/components/loader';

const VisitorsTable = lazy(
  () => import('app/components/visits/visitors/visitors-table/visitors-table.container'),
);

export const VisitorsPage: FC = () => (
  <TabPageLayout data-testid="visitors-page">
    <Suspense fallback={<Loader testId="visitors-page-loader" />}>
      <VisitorsTable />
    </Suspense>
    <Outlet />
  </TabPageLayout>
);

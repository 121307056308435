import { SearchSelect, SearchSelectMenuType, SearchSelectTriggerButtonType } from '@hqo/react-components-library/dist/molecules/search-select';
import { useIntl } from 'react-intl';
import { SearchSelectContainer } from './select-visitor-group.styles';
import { useFetchVisitorGroups } from './hooks/use-fetch-visitor-groups.hook';
import { useSelectGroup } from './hooks/use-select-group.hook';

export const SelectVisitorGroup = () => {
  const { formatMessage } = useIntl();

  const { query, setQuery, hasMore, handleLoadMore } = useFetchVisitorGroups();
  const { options, selectedGroupUuid, onSelectGroup } = useSelectGroup(setQuery);

  return (
    <SearchSelectContainer>
      <SearchSelect
        buttonText={formatMessage({ id: 'buttons.inviteGroup' })}
        buttonVariant="Outline"
        dropdownOptions={options}
        dropdownValue={selectedGroupUuid}
        emptyState={formatMessage({ id: 'inviteGroup.emptyState' })}
        hasMore={hasMore}
        infiniteScrollCallback={handleLoadMore}
        menuType={SearchSelectMenuType.SELECT}
        onSingleSelectChange={onSelectGroup}
        searchPlaceholder={formatMessage({ id: 'inviteGroup.searchPlaceholder' })}
        searchText={query}
        setSearchText={setQuery}
        triggerButtonType={SearchSelectTriggerButtonType.BUTTON}
      />
    </SearchSelectContainer>
  );
};

import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { Epic } from 'redux-observable';
import { catchError, EMPTY, filter, map, mergeMap, Observable, of, switchMap, withLatestFrom } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { toast } from 'react-toastify';
import {
  bulkCreateWatchlistRecords,
  createWatchlistRecord,
  deleteWatchlistRecord,
  getWatchlistMatches,
  getWatchlistMatchesCompanies,
  getWatchlistMatchesHosts,
  getWatchlistRecords,
  incrementRequestWatchlistRecordsCounter,
  resolveWatchlistMatch,
} from './actions';
import { rootPathSelector } from 'app/store/config/selectors';
import { WATCHLIST_ROUTE } from 'app/shared/consts';
import { WatchlistMatch, WatchlistRecordStatus } from 'app/store/watchlist/types';
import { AjaxResponse } from 'rxjs/ajax';

export const createWatchlistRecordEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(createWatchlistRecord.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .createWatchlistRecord(payload)
        .pipe(
          map(({ response }) => createWatchlistRecord.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.watchlist.create.error' }));

            return of(createWatchlistRecord.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

export const resolveWatchlistMatchEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(resolveWatchlistMatch.request)),
    withLatestFrom(state$),
    switchMap(([{ payload: params }, state]) => {
      let apiCall: Observable<AjaxResponse<WatchlistMatch>>;

      if (params.status === WatchlistRecordStatus.DENIED) {
        apiCall = apiClient(state).denyWatchlistMatch(params.watchlistMatchUuid, params.notes ?? '');
      } else {
        const alwaysApprove = params.status === WatchlistRecordStatus.ALWAYS_APPROVE;
        apiCall = apiClient(state).approveWatchlistMatch(params.watchlistMatchUuid, alwaysApprove, params.notes ?? '');
      }

      return apiCall.pipe(
        map(({ response }) =>
          resolveWatchlistMatch.success({ params, response }),
        ),
        catchError((error: Error) =>
          of(resolveWatchlistMatch.failure({ params, error })),
        ),
      );
    }),
  );

export const createWatchlistRecordSuccessEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { navigate },
) =>
  action$.pipe(
    filter(isActionOf(createWatchlistRecord.success)),
    withLatestFrom(state$),
    mergeMap(([_action, state]) => {
      const rootPath = rootPathSelector(state);

      navigate(`${rootPath}/${WATCHLIST_ROUTE}`);

      return EMPTY;
    }),
  );

export const getWatchlistRecordsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(getWatchlistRecords.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .getWatchlistRecords(payload)
        .pipe(
          map((response) => getWatchlistRecords.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.watchlist.fetch.error' }));

            return of(getWatchlistRecords.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

export const bulkUploadWatchlistRecordsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(bulkCreateWatchlistRecords)),
    withLatestFrom(state$),
    mergeMap(([{ payload }, state]) =>
      apiClient(state)
        .bulkUploadWatchlistRecords(payload.file)
        .pipe(
          mergeMap(() => {
            toast.success(intl.formatMessage({ id: 'notifications.bulkUploadWatchlist.fetch.success' }));
            return of(incrementRequestWatchlistRecordsCounter({ buildingUuid: payload.buildingUuid }));
          }),
          catchError(() => {
            toast.error(intl.formatMessage({ id: 'notifications.bulkUploadWatchlist.fetch.error' }));
            return EMPTY;
          }),
        ),
    ),
  );

export const getWatchlistMatchesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(getWatchlistMatches.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .getWatchlistMatches(payload)
        .pipe(
          map((response) => getWatchlistMatches.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.watchlistMatches.fetch.error' }));

            return of(getWatchlistMatches.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

export const deleteWatchlistRecordEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(deleteWatchlistRecord.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .deleteWatchlistRecord(payload)
        .pipe(
          map(() => deleteWatchlistRecord.success({ params: payload })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.deleteWatchlistPerson.fetch.error' }));

            return of(deleteWatchlistRecord.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

export const deleteWatchlistRecordSuccessEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { navigate },
) =>
  action$.pipe(
    filter(isActionOf(deleteWatchlistRecord.success)),
    withLatestFrom(state$),
    mergeMap(([_action, state]) => {
      const rootPath = rootPathSelector(state);

      navigate(`${rootPath}/${WATCHLIST_ROUTE}`);

      return EMPTY;
    }),
  );

export const getWatchlistMatchesCompaniesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(getWatchlistMatchesCompanies.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .getWatchlistMatchesCompanies()
        .pipe(
          map((response) => getWatchlistMatchesCompanies.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.watchlistMatchesCompanies.fetch.error' }));

            return of(getWatchlistMatchesCompanies.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

export const getWatchlistMatchesHostsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(getWatchlistMatchesHosts.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .getWatchlistMatchesHosts()
        .pipe(
          map((response) => getWatchlistMatchesHosts.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.watchlistMatchesHosts.fetch.error' }));

            return of(getWatchlistMatchesHosts.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

import { PropsWithChildren } from 'react';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { ModalProps } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { StyledModal, CommonModalButtonContainer, CommonModalTitle } from './common-modal.styles';

export interface CommonModalProps extends ModalProps {
  title: string;
  cancelButtonText?: string;
  disabled?: boolean;
  cta?: React.ReactNode;
  width?: number;
  onClose: VoidFunction;
}

export const CommonModal = ({ children, disabled, onClose, title, cancelButtonText, cta, ...rest }: PropsWithChildren<CommonModalProps>) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <StyledModal withExitButton={false} {...rest}>
    <CommonModalTitle>{title}</CommonModalTitle>
    <div>{children}</div>
    <CommonModalButtonContainer>
      {!!cancelButtonText && <Button variant="Plain" disabled={disabled} text={cancelButtonText} onClick={onClose} />}
      {cta}
    </CommonModalButtonContainer>
  </StyledModal>
);

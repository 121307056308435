import { fetchVisitsMetrics } from 'app/store/visits-metrics/actions';
import { BuildingVisitsMetricsState, VisitsMetricsState } from 'app/store/visits-metrics/types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: VisitsMetricsState = {};
export const initialBuildingState: BuildingVisitsMetricsState = {
  visitsMetrics: null,
  loading: false,
  error: null,
};

const handleFetchVisitsMetricsRequest = (
  state: VisitsMetricsState,
  action: ActionType<typeof fetchVisitsMetrics.request>,
): VisitsMetricsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingState,
    ...state[action.payload.buildingUuid],
    error: null,
    loading: true,
  },
});

const handleFetchVisitsMetricsSuccess = (
  state: VisitsMetricsState,
  action: ActionType<typeof fetchVisitsMetrics.success>,
): VisitsMetricsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    visitsMetrics: action.payload.response.data,
    loading: false,
    error: null,
  },
});

const handleFetchVisitsMetricsFailure = (
  state: VisitsMetricsState,
  action: ActionType<typeof fetchVisitsMetrics.failure>,
): VisitsMetricsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

export const visitsMetricsReducer = createReducer(initialState)
  .handleAction(fetchVisitsMetrics.request, handleFetchVisitsMetricsRequest)
  .handleAction(fetchVisitsMetrics.success, handleFetchVisitsMetricsSuccess)
  .handleAction(fetchVisitsMetrics.failure, handleFetchVisitsMetricsFailure);

import { CheckboxInput } from 'app/components/fields/checkbox-input';
import { FormInput } from 'app/components/fields/form-input';
import { Container } from './group-visit.styles';
import { useGroupVisit } from './use-group-visit.hook';
import { useUniqueGroupNameValidation } from './use-unique-group-name-validation.hook';

export const GroupVisit = () => {
  const { isDisabled, saveAsGroup, isGroupNameDisabled } = useGroupVisit();
  useUniqueGroupNameValidation();

  return (
    <Container>
      <CheckboxInput
        disabled={isDisabled}
        data-testid="save-as-group-checkbox"
        fieldName="saveAsGroup"
        translationKey="saveAsGroupCheckbox"
      />
      {saveAsGroup && (
        <FormInput
          disabled={isDisabled || isGroupNameDisabled}
          autoFocus
          fieldName="groupName"
          translationKey="groupName"
        />
      )}
    </Container>
  );
};

import {
  FetchHostDetailsErrorDto,
  FetchHostDetailsParams,
  FetchHostDetailsSuccessDto,
} from 'app/store/host-details/types';
import { createAsyncAction } from 'typesafe-actions';

export const fetchHostDetails = createAsyncAction(
  'HOST_DETAILS/FETCH_REQUEST',
  'HOST_DETAILS/FETCH_SUCCESS',
  'HOST_DETAILS/FETCH_FAILURE',
)<FetchHostDetailsParams, FetchHostDetailsSuccessDto, FetchHostDetailsErrorDto>();

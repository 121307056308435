import { FC, lazy, Suspense } from 'react';
import { ENTITY_UUID_PARAMETER } from 'app/shared/consts';
import { useParams } from 'react-router-dom';
import { Loader } from 'app/components/loader';

const VisitsTable = lazy(() => import('app/components/details/host-details/table/visits-table.container'));

export const RegisteredVisitorsPage: FC = () => {
  const params = useParams();
  const uuid = params[ENTITY_UUID_PARAMETER] as string;

  return (
    <Suspense fallback={<Loader testId="registered-visitors-loader" />}>
      <VisitsTable hostUuid={uuid} />
    </Suspense>
  );
};

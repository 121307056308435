import { SettingsHeader } from 'app/components/settings/settings-header/settings-header';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

export const SettingsPage: FC = () => (
  <>
    <SettingsHeader />
    <Outlet />
  </>
);

import { HqOTableEnums } from '@hqo/react-components-library/dist/organisms/hqo-table';
import { VisibilityEnum } from 'app/components/visits/visit-form/visit-form.interfaces';
import { Visitor } from 'app/store/visitor/types';

export enum VisitStatus {
  INVITED = 'INVITED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  CANCELED = 'CANCELED',
  PENDING_WATCHLIST = 'PENDING_WATCHLIST',
}

export interface VisitGroupData {
  uuid: string;
  name: string;
}

export interface VisitRO {
  uuid: string;
  visitor_name: string;
  arrival_time: string;
  departure_time?: string;
  host_name: string;
  host_uuid: string;
  host_company: string;
  description?: string;
  floor?: string;
  suite?: string;
  email?: string;
  phone?: string;
  checked_in_at?: string;
  checked_out_at?: string;
  checked_in_by_user_id?: number;
  checked_out_by_user_id?: number;
  status: VisitStatus;
  visit_type_id?: number;
  qr_code_link?: string | null;
  visitor: Visitor;
  visibility: VisibilityEnum;
  group?: VisitGroupData;
}

export interface VisitDOWithLoadingInfo extends VisitRO {
  loading?: boolean;
}

export interface VisitState {
  visit: VisitRO | null;
  loading: boolean;
  error: Error | null;
}

export enum AuditExportType {
  CSV = 'CSV',
  PDF = 'PDF',
}

export interface RequestState {
  loading: boolean;
  error: Error | null;
}

export interface FiltersQueryDto {
  status?: VisitStatus;
  hostUuid?: string;
  companyUuid?: string;
  visitorUuid?: string;
  groupUuid?: string;
}

export interface SearchQueyDto {
  search?: string;
}

export interface BuildingVisitsState {
  visits: string[];
  visitsMap: Record<string, VisitState>;
  total: number;
  loading: boolean;
  error: Error | null;

  createVisitRequest: RequestState;

  openedVisit: VisitRO | null;
  getVisitRequest: RequestState;

  updateVisitRequest: RequestState;
  searchByCredentialRequestLoading: boolean;
}

export type VisitsState = Record<string, BuildingVisitsState>;

export type Order = HqOTableEnums.SortDirection;

export type SortOrder = Order | null;

export interface OrderQueryDto {
  order?: SortOrder;
}

export interface FetchVisitsPaginationParams extends OrderQueryDto, FiltersQueryDto, SearchQueyDto {
  offset: number;
  limit: number;
  arrivalTimeAfter?: string;
  before?: string;
}

export interface FetchVisitsForBuildingParams extends FetchVisitsPaginationParams {
  buildingUuid: string;
}

export interface FetchVisitsForBuildingSuccessDto {
  params: FetchVisitsForBuildingParams;
  response: FetchVisitsResponse;
}

export interface FetchVisitsForBuildingErrorDto {
  params: FetchVisitsForBuildingParams;
  response: Error;
}

export interface FetchVisitsResponse {
  data: VisitRO[];
  total: number;
}

export enum IncludeVisitInfo {
  PASS = 'pass',
}

export interface FetchVisitForBuildingParams {
  buildingUuid: string;
  visitUuid: string;
  include?: IncludeVisitInfo[];
}

export interface FetchVisitForBuildingSuccessDto {
  params: FetchVisitForBuildingParams;
  response: FetchVisitResponse;
}

export interface FetchVisitForBuildingErrorDto {
  params: FetchVisitForBuildingParams;
  response: Error;
}

export interface FetchVisitResponse extends VisitRO {}

export interface CurrentBuildingVisitOperationParams {
  visitUuid: string;
  arrivalTimeAfter?: string;
  before?: string;
}

export interface VisitOperationParams extends CurrentBuildingVisitOperationParams {
  buildingUuid: string;
}

export interface VisitOperationSuccessDto {
  params: VisitOperationParams;
  response: VisitRO;
}

export interface VisitOperationErrorDto {
  params: VisitOperationParams;
  response: Error;
}

export interface CancelVisitSuccessDto {
  params: VisitOperationParams;
}

export interface NotifyHostSuccessDto {
  params: VisitOperationParams;
}

export interface CreateVisitorDto {
  id?: string;
  email?: string;
  phone?: string;
  first_name?: string;
  last_name?: string;
  visibility?: VisibilityEnum;
}

export interface CreateVisitorGroupDto {
  name: string;
}

export interface ExistingVisitorGroupDto {
  uuid: string;
}

export type VisitorGroupDto = CreateVisitorGroupDto | ExistingVisitorGroupDto;

export interface BulkCreateVisitDto {
  host_uuid?: string;
  visit_type_id?: number;
  floor?: string;
  suite?: string;
  arrival_time?: Date;
  departure_time?: Date;
  description?: string | null;
  company_name?: string;
  visitors: CreateVisitorDto[];
}

export type BulkCreateVisitsDto = BulkCreateVisitDto[];

export interface BulkCreateVisitsBody {
  visits: BulkCreateVisitsDto;
  is_group_visit?: boolean;
  group?: VisitorGroupDto;
}

export interface CreateVisitsResponse {
  visits: VisitRO[];
}

export interface CreateVisitsOperationParams {
  buildingUuid: string;
  visits: BulkCreateVisitsDto;
}

export interface CreateVisitsOperationSuccessDto {
  params: CreateVisitsOperationParams;
  response: CreateVisitsResponse;
}

export interface CreateVisitsOperationErrorDto {
  params: CreateVisitsOperationParams;
  response: Error;
}

export interface UpdateVisitDto {
  arrival_time?: Date;
  departure_time?: Date;
  description?: string;
}

export interface UpdateVisitDtoWithUuid {
  visitUuid: string;
  visit: UpdateVisitDto;
}

export interface UpdateVisitResponse extends VisitRO {}

export interface UpdateVisitOperationParams extends UpdateVisitDtoWithUuid {
  buildingUuid: string;
}

export interface UpdateVisitOperationSuccessDto {
  params: UpdateVisitOperationParams;
  response: UpdateVisitResponse;
}

export interface UpdateVisitOperationErrorDto {
  params: UpdateVisitOperationParams;
  response: Error;
}

export enum VisitUpdateOperationType {
  CREATED = 'CREATED',
  UPDATED = 'UPDATED',
  CHECKED_IN = 'CHECKED_IN',
  CHECKED_OUT = 'CHECKED_OUT',
  CANCELED = 'CANCELED',
}

export interface VisitUpdatedEvent {
  visit: VisitRO;
  action: VisitUpdateOperationType;
}

export interface VisitUpdatedForView {
  visit: VisitRO;
  action: VisitUpdateOperationType;
  buildingUuid: string;
}

export interface SearchVisitByCredentialParams {
  buildingUuid: string;
  credential: string;
}

export interface VisitResponseRO {
  data: VisitRO;
}

export interface SearchVisitByCredentialSuccessDto {
  params: SearchVisitByCredentialParams;
  response: VisitRO;
}

export interface SearchVisitByCredentialErrorDto {
  params: SearchVisitByCredentialParams;
  response: Error;
}

import { useDispatch, useSelector } from 'react-redux';
import { hasDaypassRequestsPermissionsSelector } from '../user-permissions/selectors';
import { buildingUuidSelector } from '../building/selectors';
import { useEffect } from 'react';
import { daypassCompaniesModeStateSelector } from './selectors';
import { fetchDaypassCompaniesMode } from './actions';

export const useDaypassModesLoadingEffect = (): boolean => {
  const hasDaypassPermissions = useSelector(hasDaypassRequestsPermissionsSelector);
  const buildingUuid = useSelector(buildingUuidSelector);
  const daypassModesState = useSelector(daypassCompaniesModeStateSelector);
  const hasDaypassModesState = !!daypassModesState;
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasDaypassPermissions && buildingUuid && !hasDaypassModesState) {
      dispatch(fetchDaypassCompaniesMode.request({ buildingUuid }));
    }
  }, [hasDaypassModesState, buildingUuid, hasDaypassPermissions]);

  return !hasDaypassPermissions || !!daypassModesState?.daypassModes || !!daypassModesState?.error;
};

import { buildingUuidSelector } from 'app/store/building/selectors';
import { createDefaultVisitorRegistrationConfig } from 'app/store/settings/actions';
import { createDefaultConfigLoadingSelector } from 'app/store/settings/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useCreateDefaultConfig = (): [VoidFunction, boolean] => {
  const buildingUuid = useSelector(buildingUuidSelector);
  const isLoading = useSelector(createDefaultConfigLoadingSelector);
  const dispatch = useDispatch();

  const createDefaultConfig = useCallback(() => {
    dispatch(createDefaultVisitorRegistrationConfig.request({ buildingUuid }));
  }, [buildingUuid, dispatch]);

  return [createDefaultConfig, isLoading];
};

import { getDateString } from 'app/shared/utils/get-date-string';
import { useCallback, useMemo } from 'react';
import addDays from 'date-fns/addDays';
import { getDateFromIsoDateString } from 'app/shared/utils/get-date-from-iso-date-string';
import { IsoDateString } from 'app/shared/types/iso-date-string';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';

interface DateRange {
  from: Date;
  to: Date;
}

export const useDateFilter = (
  fromValue: IsoDateString,
  toValue: IsoDateString,
  handleChange: (from?: IsoDateString, to?: IsoDateString) => void,
) => {
  const { value: isSingleSelect, toggle: toggleRangeSelect } = useBooleanState(fromValue === toValue);
  const dateFromValue = useMemo(() => getDateFromIsoDateString(fromValue), [fromValue]);
  const dateToValue = useMemo(() => getDateFromIsoDateString(toValue), [toValue]);

  const handleSave = useCallback(
    (dateOrDateRange?: DateRange | Date | string) => {
      if (typeof dateOrDateRange === 'string' || dateOrDateRange instanceof Date) {
        handleChange(getDateString(dateOrDateRange), getDateString(dateOrDateRange));

        return;
      }

      if (!!dateOrDateRange && typeof dateOrDateRange === 'object') {
        handleChange(getDateString(dateOrDateRange.from), getDateString(dateOrDateRange.to));
      }
    },
    [handleChange],
  );
  const handleClear = useCallback(() => {
    if (isSingleSelect) {
      handleSave(new Date());
    } else {
      handleSave({
        from: new Date(),
        to: new Date(),
      });
    }
  }, [isSingleSelect, handleSave]);

  const handleNextClick = useCallback(() => {
    const nextDate = addDays(dateFromValue, 1);

    handleSave(nextDate);
  }, [dateFromValue, handleSave]);

  const handlePreviousClick = useCallback(() => {
    const previousDate = addDays(dateFromValue, -1);

    handleSave(previousDate);
  }, [dateFromValue, handleSave]);

  const value = useMemo(() => {
    if (isSingleSelect) {
      return dateFromValue;
    }

    return {
      from: dateFromValue,
      to: dateToValue,
    };
  }, [dateFromValue, dateToValue, isSingleSelect]);

  return { value, handleSave, handleClear, handleNextClick, handlePreviousClick, isSingleSelect, toggleRangeSelect };
};

import { TimeSelect } from 'app/components/fields/time-select';
import { VisitDateTime } from 'app/components/visits/visit-form/visit-form.interfaces';
import { TimesContainer } from './date-and-time.styles';
import { useIs12hFormat, useVisitTimeOptions } from './date-and-time.hooks';
import { TimeSelectorHeader } from './time-selector-header';

export interface TimeSelectorContainerProps {
  visitDateTime: VisitDateTime;
  showHeader: boolean;
  index: number;
}

export const TimeSelectorContainer = ({
  visitDateTime: { startTime, startDate },
  showHeader,
  index,
}: TimeSelectorContainerProps) => {
  const { maxEndTime, maxStartTime, minEndTime, minStartTime } = useVisitTimeOptions(startDate, startTime);
  const is12h = useIs12hFormat();

  return (
    <>
      {showHeader && <TimeSelectorHeader index={index} date={startDate} />}
      <TimesContainer>
        <TimeSelect
          fieldTranslationKey="startTime"
          fieldName={`visitDateTimes.${index}.startTime`}
          uiMetadataFlag="visit_start_time"
          min={minStartTime}
          max={maxStartTime}
          is12h={is12h}
        />
        <TimeSelect
          fieldTranslationKey="endTime"
          fieldName={`visitDateTimes.${index}.endTime`}
          uiMetadataFlag="visit_end_time"
          min={minEndTime}
          max={maxEndTime}
          is12h={is12h}
        />
      </TimesContainer>
    </>
  );
};

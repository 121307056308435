import { Paper } from 'app/components/panel/panel.styles';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';
import { PaperProps } from '@hqo/react-components-library/dist/atoms/paper';

interface Props extends PaperProps, HTMLAttributes<HTMLDivElement> {}

export const Panel: FC<PropsWithChildren<Props>> = ({ borderRadius = 'md', children, depth = 'none', ...divProps }) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Paper border="adminGreyMedium" depth={depth} borderRadius={borderRadius} {...divProps}>
    {children}
  </Paper>
);

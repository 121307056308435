import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthTokenSelector } from '../../../store/config/selectors';

export const useAuthToken = (): [token: string | null, reload: VoidFunction] => {
  const isTokenFetching = useRef(false);
  const [token, setToken] = useState<string | null>(null);
  const getAuthToken = useSelector(getAuthTokenSelector);
  const reloadToken = useCallback(() => {
    isTokenFetching.current = true;

    getAuthToken()
      .then(setToken)
      .finally(() => {
        isTokenFetching.current = false;
      });
  }, [setToken, getAuthToken, isTokenFetching]);

  useEffect(() => {
    if (!token && !isTokenFetching.current) {
      reloadToken();
    }
  }, [token, reloadToken]);

  return [token, reloadToken];
};

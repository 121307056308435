import { useIntl } from 'react-intl';
import { LinkButton } from './download-visitor-template-button.styles';
import { useSelector } from 'react-redux';
import { publicUrlSelector } from 'app/store/config/selectors';

export const DownloadTemplateButton = () => {
  const publicUrl = useSelector(publicUrlSelector);
  const { formatMessage } = useIntl();

  return (
    <LinkButton
      href={`${publicUrl}/assets/visitors-template.csv`}
      download="visitors-template.csv"
      data-testid="download-visitor-template-button"
    >
      {formatMessage({ id: 'visitForm.visitors.downloadTemplate' })}
    </LinkButton>
  );
};

import { Modal as HqOModal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { ZIndex } from 'app/shared/consts/z-index';
import styled from 'styled-components';

export const ModalStyled = styled(HqOModal)`
  z-index: ${ZIndex.MODAL};

  .modal-paper {
    padding: 0%;
    width: 90%;
    height: 90%;
    background-color: ${({ theme }) => theme.colors.palette.systemGreyLight};

    display: flex;
    flex-direction: column;
  }
`;

import { SearchInputDropdownMode } from '@hqo/react-components-library/dist/molecules/search-input-v2';
import { Visitor } from 'app/store/visitor/types';

export const useDropdownMode = (visitorOptions: Visitor[], isLoading: boolean) => {
  if (isLoading) {
    return SearchInputDropdownMode.Spinner;
  }

  if (visitorOptions.length === 0) {
    return SearchInputDropdownMode.EmptyContent;
  }

  return SearchInputDropdownMode.Content;
};

import { combineEpics } from 'redux-observable';

import * as visitsEpics from './visits/epics';
import * as visitorPassesEpics from './visitor-passes/epics';
import * as visitsMetricsEpics from './visits-metrics/epics';
import * as visitsFiltersEpics from './visits-filters/epics';
import * as settingsEpics from './settings/epics';
import * as locationInfoEpics from './location-info/epics';
import * as accessGroupsEpics from './access-groups/epics';
import * as tenantCompaniesEpics from './tenant-companies/epics';
import * as hostsEpics from './hosts/epics';
import * as visitorsEpics from './visitors/epics';
import * as uiMetadataEpics from './ui-metadata/epics';
import * as userPermissionsEpics from './user-permissions/epics';
import * as hostDetailsEpics from './host-details/epics';
import * as visitorEpics from './visitor/epics';
import * as visitorVisitsEpics from './visitor-visits/epics';
import * as hostVisitorsEpics from './host-visits/epics';
import * as daypassUsersEpics from './daypass-users/epics';
import * as daypassRequestsEpics from './daypass-requests/epics';
import * as daypassCompaniesEpics from './daypass-companies/epics';
import * as daypassCompaniesModeEpics from './daypass-modes/epics';
import * as buildingSettingsEpics from './building-settings/epics';
import * as watchlistEpics from './watchlist/epics';
import * as visitorGroupsEpics from './visitor-groups/epics';

export const rootEpic = combineEpics(
  ...Object.values(visitsEpics),
  ...Object.values(visitorPassesEpics),
  ...Object.values(visitsMetricsEpics),
  ...Object.values(settingsEpics),
  ...Object.values(locationInfoEpics),
  ...Object.values(visitsFiltersEpics),
  ...Object.values(accessGroupsEpics),
  ...Object.values(tenantCompaniesEpics),
  ...Object.values(hostsEpics),
  ...Object.values(visitorsEpics),
  ...Object.values(visitorVisitsEpics),
  ...Object.values(uiMetadataEpics),
  ...Object.values(userPermissionsEpics),
  ...Object.values(hostDetailsEpics),
  ...Object.values(visitorEpics),
  ...Object.values(hostVisitorsEpics),
  ...Object.values(daypassUsersEpics),
  ...Object.values(daypassRequestsEpics),
  ...Object.values(daypassCompaniesEpics),
  ...Object.values(daypassCompaniesModeEpics),
  ...Object.values(buildingSettingsEpics),
  ...Object.values(watchlistEpics),
  ...Object.values(visitorGroupsEpics),
);

import { useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { FormValues } from 'app/components/visits/visit-form/visit-form.interfaces';

interface UseGroupVisitHookResponse {
  isDisabled: boolean;
  isGroupNameDisabled: boolean;
  saveAsGroup: boolean;
}

export const useGroupVisit = (): UseGroupVisitHookResponse => {
  const { values, setValues } = useFormikContext<FormValues>();

  useEffect(() => {
    if (values.saveAsGroup && values.visitors.length < 2) {
      setValues({
        ...values,
        saveAsGroup: false,
        groupName: undefined,
        groupUuid: undefined,
      });
    }
  },
  [values.visitors.length, values.saveAsGroup]);

  return useMemo(() => ({
    isDisabled: values.visitors.length < 2,
    isGroupNameDisabled: !!values.groupUuid,
    saveAsGroup: values.saveAsGroup,
  }), [values.visitors.length, values.saveAsGroup]);
};

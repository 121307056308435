import { FormValues, VisitDateTime } from 'app/components/visits/visit-form/visit-form.interfaces';
import { createVisitsForCurrentBuilding } from 'app/store/visits/actions';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc';

import { BulkCreateVisitDto, BulkCreateVisitsBody, CreateVisitorDto } from 'app/store/visits/types';
import { timezoneSelector } from 'app/store/building/selectors';
import { createVisitsLoadingSelector } from 'app/store/visits/selectors';
import { parseTime } from 'app/shared/utils/date';
import { Time } from 'app/shared/types/time';
import { Visitor } from '../../visit-form.interfaces';

const startDayTime = '00:00';
const endDayTime = '23:59';

const getDateTime = (date: Date, time: Time, timezone: string): Date => {
  const [hour, minute] = parseTime(time);

  return zonedTimeToUtc(new Date(date).setHours(hour, minute), timezone);
};

export const getDates = (
  formValues: VisitDateTime,
  endDate: Date | null,
  timezone: string,
): Pick<BulkCreateVisitDto, 'arrival_time' | 'departure_time'> => {
  const { startDate, startTime, endTime } = formValues;
  const arrivalDate = startDate || new Date();
  const departureDate = endDate || arrivalDate;

  return {
    arrival_time: getDateTime(arrivalDate, startTime ?? startDayTime, timezone),
    departure_time: getDateTime(departureDate, endTime ?? endDayTime, timezone),
  };
};

const mapVisitorValuesToDto = (visitor: Visitor): CreateVisitorDto => ({
  id: visitor.uuid,
  email: visitor.email,
  phone: visitor.phone || undefined,
  first_name: visitor.firstName,
  last_name: visitor.lastName,
});

const mapGroupValuesToDto = (values: FormValues): BulkCreateVisitsBody['group'] => {
  if (values.saveAsGroup) {
    return { name: values.groupName ?? '', uuid: values.groupUuid };
  }

  return undefined;
};

const mapFormValuesToDto = (values: FormValues, timezone: string): BulkCreateVisitsBody => ({
  visits: values.visitDateTimes.flatMap((dates: VisitDateTime) =>
    values.visitors.map((visitor) => ({
      ...getDates(dates, values.endDate, timezone),
      host_uuid: values.hostUuid,
      visit_type_id: values.visitTypeId,
      floor: values.floor,
      suite: values.suite,
      visitors: [mapVisitorValuesToDto(visitor)],
      description: values.description,
      visibility: visitor.visibility,
    })),
  ),
  is_group_visit: values.saveAsGroup,
  group: mapGroupValuesToDto(values),
});

export const useFormSubmitHandler = () => {
  const dispatch = useDispatch();
  const timezone = useSelector(timezoneSelector);
  const loading = useSelector(createVisitsLoadingSelector);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      dispatch(createVisitsForCurrentBuilding(mapFormValuesToDto(values, timezone)));
    },
    [timezone, dispatch],
  );

  return { loading, handleSubmit };
};

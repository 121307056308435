import { useDispatch, useSelector } from 'react-redux';
import { apiUrlSelector } from '../../store/config/selectors';
import { useCallback, useMemo } from 'react';
import { stringify } from 'qs';
import { useServerSentEvents } from '../../shared/hooks/use-server-sent-events';
import { visitorPassUpdated } from '../../store/visitor-passes/actions';
import { buildingUuidSelector } from '../../store/building/selectors';

const VISITOR_PASS_EVENTS_URL = '/access-bridge/v1/visitor-passes/events';

export const useVisitorPassUpdatesListener = (visitUuids: string[]) => {
  const buildingUuid = useSelector(buildingUuidSelector);
  const apiUrl = useSelector(apiUrlSelector);
  const dispatch = useDispatch();
  const eventSourceUrl = useMemo(() => {
    const queryString = stringify({ visitUuid: visitUuids }, { arrayFormat: 'brackets', addQueryPrefix: true });

    return `${apiUrl}${VISITOR_PASS_EVENTS_URL}${queryString}`;
  }, [apiUrl, visitUuids]);
  const eventHandler = useCallback(
    (data: string) => {
      try {
        if (data) {
          const pass = JSON.parse(data);

          dispatch(visitorPassUpdated({ buildingUuid, pass }));
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Failed to parse event data', error);
      }
    },
    [buildingUuid, dispatch],
  );

  useServerSentEvents(eventSourceUrl, eventHandler);
};

import { FC } from 'react';
import { VisitorPassUpdatesListener } from './visitor-pass-updates-listener';
import { useUserPermissionCheck } from '../../store/user-permissions/hooks';
import { Permission } from '../../store/user-permissions/types';
import { useSelector } from 'react-redux';
import { visitsMapUuidsSelector } from '../../store/visits/selectors';

export const VisitorPassUpdates: FC = () => {
  const hasPermissions = useUserPermissionCheck(Permission.ACTIVATE_VISITOR_PASS);
  const visitUuids = useSelector(visitsMapUuidsSelector);

  if (!visitUuids?.length || !hasPermissions) {
    return null;
  }

  return <VisitorPassUpdatesListener visitUuids={visitUuids} />;
};

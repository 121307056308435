import { FC, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { GROUP_UUID_PARAMETER } from 'app/shared/consts';
import { Loader } from 'app/components/loader';

const VisitorGroupDetails = lazy(() => import('app/components/visitor-group-details/visitor-group-details.container'));

export const VisitorGroupDetailsPage: FC = () => {
  const groupUuid = useParams()?.[GROUP_UUID_PARAMETER] ?? '';

  return (
    <div data-testid="visitor-group-details-page">
      <Suspense fallback={<Loader testId="visitor-group-details-loader" />}>
        <VisitorGroupDetails uuid={groupUuid} />
      </Suspense>
    </div>
  );
};

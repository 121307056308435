import {
  FetchVisitsMetricsForBuildingErrorDto,
  FetchVisitsMetricsForBuildingParams,
  FetchVisitsMetricsForBuildingSuccessDto,
} from 'app/store/visits-metrics/types';
import { createAsyncAction } from 'typesafe-actions';

export const fetchVisitsMetrics = createAsyncAction(
  'FETCH_VISITS_METRICS/REQUEST',
  'FETCH_VISITS_METRICS/SUCCESS',
  'FETCH_VISITS_METRICS/FAILURE',
)<
  FetchVisitsMetricsForBuildingParams,
  FetchVisitsMetricsForBuildingSuccessDto,
  FetchVisitsMetricsForBuildingErrorDto
>();

import { useFieldMetadata } from 'app/store/ui-metadata/hooks';
import { FeatureEnablementFlagEnum, VisitorRegistrationFlagProperties } from 'app/store/ui-metadata/types';

export type UIFieldRequirements = { hidden: boolean; required: boolean };

export function useUIMetadataFieldRequirements(
  fieldName?: keyof VisitorRegistrationFlagProperties,
): UIFieldRequirements {
  const fieldFlag = useFieldMetadata(fieldName);

  return {
    hidden: fieldFlag === FeatureEnablementFlagEnum.DISABLED,
    required: fieldFlag === FeatureEnablementFlagEnum.ENABLED_REQUIRED && !!fieldFlag,
  };
}

import { createSelector } from 'reselect';
import { RootState } from 'app/store/reducer';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { DaypassUser } from './types';
import { BuildingDaypassUsersState, DaypassUserCredentialState, DaypassUsersUpdateState } from './state.types';

const daypassUsersByBuildingState = (state: RootState) => state.daypassUsers;

export const daypassUsersState = createSelector(
  [buildingUuidSelector, daypassUsersByBuildingState],
  (buildingUuid, daypassUsersByBuilding) => daypassUsersByBuilding?.[buildingUuid] ?? null,
);

export const daypassUsersSelector = createSelector(
  daypassUsersState,
  (state: BuildingDaypassUsersState): DaypassUser[] | null => state?.daypassUsers ?? null,
);
export const daypassUsersTotalSelector = createSelector(
  daypassUsersState,
  (state: BuildingDaypassUsersState): number => state?.total ?? 0,
);
export const daypassUsersLoadingState = createSelector(
  daypassUsersState,
  (state: BuildingDaypassUsersState): boolean => state?.loading ?? false,
);
export const daypassUsersErrorState = createSelector(
  daypassUsersState,
  (state: BuildingDaypassUsersState): Error | null => state?.error ?? null,
);

export const daypassAllUsersSelector = createSelector(
  daypassUsersState,
  (state: BuildingDaypassUsersState): DaypassUser[] | null => state?.allDaypassUsers ?? null,
);
export const daypassAllUsersTotalSelector = createSelector(
  daypassUsersState,
  (state: BuildingDaypassUsersState): number => state?.totalAllDaypassUsers ?? 0,
);
export const daypassAllUsersLoadingState = createSelector(
  daypassUsersState,
  (state: BuildingDaypassUsersState): boolean => state?.getAllDaypassUsers.loading ?? false,
);
export const daypassAllUsersErrorState = createSelector(
  daypassUsersState,
  (state: BuildingDaypassUsersState): Error | null => state?.getAllDaypassUsers.error ?? null,
);

export const daypassUserCredentialStateSelector = createSelector(
  [daypassUsersState, (_state: RootState, personUuid: string) => personUuid],
  (state: BuildingDaypassUsersState, personUuid: string): DaypassUserCredentialState | null =>
    state?.daypassUserCredentials?.[personUuid] ?? null,
);

export const daypassUsersUpdateStateSelector = createSelector(
  [daypassUsersState],
  (state: BuildingDaypassUsersState): DaypassUsersUpdateState => state?.daypassUsersUpdateState ?? {},
);

export const daypassRequestsUserLoadingSelector = createSelector(
  daypassUsersState,
  (state: BuildingDaypassUsersState): Record<string, boolean> => state?.daypassRequestsUserLoading ?? {},
);

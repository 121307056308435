import { toast } from 'react-toastify';
import { Epic } from 'redux-observable';
import { catchError, filter, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { fetchHostVisits } from 'app/store/host-visits/actions';

export const fetchHostVisitsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchHostVisits.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      apiClient(state)
        .fetchVisits(action.payload)
        .pipe(
          map((response) => fetchHostVisits.success({ params: action.payload, response })),
          catchError((error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visits.fetch.error' }));

            return of(fetchHostVisits.failure({ params: action.payload, error }));
          }),
        ),
    ),
  );

import { useMemo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { hasExportAuditReportPermissionSelector } from 'app/store/user-permissions/selectors';
import { OPEN_EXPORT_MODAL_EVENT } from 'app/components/audit-export/audit-export-modal.consts';
import { SecondaryCtaProps } from './use-secondary-cta.type';

export const useExportCta = (): SecondaryCtaProps | undefined => {
  const { formatMessage } = useIntl();
  const hasExportAuditPermission = useSelector(hasExportAuditReportPermissionSelector);

  const onSecondaryButtonClick = useCallback(() => {
    window.dispatchEvent(new CustomEvent(OPEN_EXPORT_MODAL_EVENT));
  }, []);

  return useMemo(() => {
    if (hasExportAuditPermission) {
      return ({
        secondaryButtonText: formatMessage({ id: 'buttons.exportAuditReport' }),
        onSecondaryButtonClick,
        secondaryButtonProps: {
          variant: 'Outline',
        },
      });
    }
    return undefined;
  }, [hasExportAuditPermission, formatMessage, onSecondaryButtonClick]);
};

import { PropsWithChildren } from 'react';
import { StyledPaper, StyledPopover } from './styles';
import { PopoverProps } from '@hqo/react-components-library/src/molecules/popover/popover-props.interface';

export interface DatePopoverProps extends PropsWithChildren, Pick<PopoverProps, 'open' | 'onClose'> {}

export const DatePopover = ({ open, onClose, children }: DatePopoverProps) => (
  <StyledPopover
    wrapperClassName="popover-wrapper"
    ignoreClickOnClasses={['date-picker-select']}
    open={open}
    onClose={onClose}
    buttonComponent={null}
    position="bottom"
    alignment="center"
    wrapperDataTestId="calendar"
  >
    <StyledPaper>{children}</StyledPaper>
  </StyledPopover>
);

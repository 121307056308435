import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormInput } from 'app/components/fields/form-input';
import { StyledPanel } from './visitor-details.styles';
import { DeleteVisitor } from './delete-visitor/delete-visitor';
import { InternationalPhoneInput } from 'app/components/fields/international-phone-input';
import { SearchVisitor } from './search-visitor/search-visitor';
import { useSelectedVisitorData } from './use-selected-visitor-data.hook';
import { showVisitorsTypeAheadSelector } from 'app/store/config/selectors';
import { useFieldMetadata } from 'app/store/ui-metadata/hooks';
import { useUIMetadataFieldRequirements } from 'app/shared/hooks/use-ui-metadata-field-requirements/use-ui-metadata-field-requirements.hook';
import { hasManageCreateVipVisitPermissionSelector } from 'app/store/user-permissions/selectors';
import { MoreOptions } from './more-options/more-options';

interface VisitorDetailsProps {
  index: number;
  isDeleteAvailable: boolean;
  canEditVisitorDetails: boolean;
  canChangeVisitVisibility?: boolean;
}

export const VisitorDetails: FC<VisitorDetailsProps> = ({
  index,
  isDeleteAvailable,
  canEditVisitorDetails,
  canChangeVisitVisibility,
}) => {
  const fieldNamePrefix = useMemo(() => `visitors[${index}]`, [index]);
  const selectedVisitorIsUser = useSelectedVisitorData(index);
  const isInputsDisabled = selectedVisitorIsUser || !canEditVisitorDetails;
  const showVisitorsTypeAheadLD = useSelector(showVisitorsTypeAheadSelector);
  const showMoreItems = useSelector(hasManageCreateVipVisitPermissionSelector);
  const visitorTypeaheadEnabled = useFieldMetadata('visitors_typeahead_supported');
  const showVisitorsTypeAhead = showVisitorsTypeAheadLD && visitorTypeaheadEnabled;
  const { hidden: phoneHidden, required: phoneRequired } = useUIMetadataFieldRequirements('visitor_phone');
  const { hidden: emailHidden, required: emailRequired } = useUIMetadataFieldRequirements('visitor_email');

  return (
    <StyledPanel data-testid="visitor-details-component">
      {showMoreItems && (
        <MoreOptions fieldNamePrefix={fieldNamePrefix} canChangeVisitVisibility={canChangeVisitVisibility} />
      )}
      {!emailHidden && (showVisitorsTypeAhead && !isInputsDisabled ? (
        <SearchVisitor visitorIndex={index} required={emailRequired} />
      ) : (
        <FormInput
          fieldName={`${fieldNamePrefix}.email`}
          translationKey="visitor.email"
          required={emailRequired}
          disabled={isInputsDisabled}
        />
      ))}
      <FormInput
        fieldName={`${fieldNamePrefix}.firstName`}
        translationKey="visitor.firstName"
        disabled={isInputsDisabled}
        required
      />
      <FormInput
        fieldName={`${fieldNamePrefix}.lastName`}
        translationKey="visitor.lastName"
        disabled={isInputsDisabled}
        required
      />
      {!phoneHidden && (
        <InternationalPhoneInput
          fieldName={`${fieldNamePrefix}.phone`}
          translationKey="visitor.phone"
          disabled={isInputsDisabled}
          required={phoneRequired}
        />
      )}
      {isDeleteAvailable && <DeleteVisitor index={index} />}
    </StyledPanel>
  );
};

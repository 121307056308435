import { useDispatch, useSelector } from 'react-redux';

import { useEffect } from 'react';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { VisitorRegistrationConfig } from 'app/store/settings/types';
import { initialSettingsState } from 'app/store/settings/reducer';
import { fetchVisitorRegistrationConfig } from 'app/store/settings/actions';
import { settingsStateSelector } from 'app/store/settings/selectors';

export const useSettings = (): [VisitorRegistrationConfig | null, boolean, Error | null] => {
  const dispatch = useDispatch();
  const buildingUuid = useSelector(buildingUuidSelector);
  const { error, loading, config } = useSelector(settingsStateSelector) || initialSettingsState;

  useEffect(() => {
    if (config === null && !loading && error === null && buildingUuid) {
      dispatch(fetchVisitorRegistrationConfig.request({ buildingUuid }));
    }
  }, [dispatch, config, loading, error, buildingUuid]);

  return [config, loading, error];
};

import { useMemo } from 'react';
import { getDateFnsLocale } from 'app/utils';
import eachDayOfInterval from 'date-fns/eachDayOfInterval';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import format from 'date-fns/format';
import getMonth from 'date-fns/getMonth';
import isPast from 'date-fns/isPast';
import isToday from 'date-fns/isToday';
import startOfMonth from 'date-fns/startOfMonth';
import startOfWeek from 'date-fns/startOfWeek';
import { CalendarDateInfo } from '@hqo/react-components-library/dist/molecules/calendar-view/calendar-view.interface';

const TITLE_DATE_FORMAT = 'MMMM yyyy';
const DATE_FORMAT = 'yyyy-MM-dd';

export const getDatesForCurrentMonth = (locale: Locale, currentMonth: Date): Date[] => {
  const options = { locale };
  const startOfCurrentMonth = startOfMonth(currentMonth);
  const endOfCurrentMonth = endOfMonth(currentMonth);

  const startOfFirstWeek = startOfWeek(startOfCurrentMonth, options);
  const endOfFirstWeek = endOfWeek(endOfCurrentMonth, options);

  return eachDayOfInterval({ start: startOfFirstWeek, end: endOfFirstWeek });
};

export const getStatusForTheDate = (
  date: Date,
  currentMonthId: number,
  selectedDates: Set<string>,
): CalendarDateInfo['status'] => {
  if (getMonth(date) !== currentMonthId) {
    return 'adjacentMonth';
  }
  if (selectedDates.has(format(date, DATE_FORMAT))) {
    return 'active';
  }
  if (isPast(endOfDay(date)) || selectedDates.size >= 30) {
    return 'unavailable';
  }
  return isToday(date) ? 'current' : 'available';
};

const getCalendarData = (locale: Locale, currentMonth: Date, selectedDates: Set<string>): CalendarDateInfo[] => {
  const currentMonthId = getMonth(currentMonth);
  return getDatesForCurrentMonth(locale, currentMonth).map((date) => ({
    date: date.getDate(),
    month: date.getMonth(),
    year: date.getFullYear(),
    status: getStatusForTheDate(date, currentMonthId, selectedDates),
  }));
};

export const useCalendarProps = (dates: Date[], currentMonth: Date, locale: string) => {
  const dateFnsLocale = useMemo(() => getDateFnsLocale(locale), [locale]);
  const selectedDatesSet = useMemo(() => new Set(dates.map((date) => format(date, DATE_FORMAT))), [dates]);
  return useMemo(
    () => ({
      data: getCalendarData(dateFnsLocale, currentMonth, selectedDatesSet),
      title: format(currentMonth, TITLE_DATE_FORMAT, { locale: dateFnsLocale }),
    }),
    [locale, currentMonth, selectedDatesSet, dateFnsLocale],
  );
};

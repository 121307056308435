import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { fetchBuildingSettings } from './actions';
import { buildingSettingsSelector } from './selectors';
import { BuildingSettingsDto } from './types';

export const useFetchBuildingSettings = () => {
  const buildingUuid = useSelector(buildingUuidSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchBuildingSettings.request({ buildingUuid }));
  }, [buildingUuid, dispatch]);
};

export const useBuildingSettings = (): [
  settings: BuildingSettingsDto | null,
  loading: boolean,
  error: Error | null,
  isConfigured: boolean,
] => {
  const buildingUuid = useSelector(buildingUuidSelector);
  const dispatch = useDispatch();
  const { error, loading, settings, isConfigured } = useSelector(buildingSettingsSelector);

  useEffect(() => {
    if (settings === null && !loading && error === null && buildingUuid) {
      dispatch(fetchBuildingSettings.request({ buildingUuid }));
    }
  }, [dispatch, settings, loading, error, buildingUuid]);

  return [settings, loading, error, isConfigured];
};

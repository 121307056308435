import { FC, useCallback } from 'react';
import { useField } from 'formik';
import { useFieldTranslations } from 'app/shared/hooks/use-field-translations';
import { FieldWrapper } from 'app/components/fields/field-wrapper';
import { RadioButton } from '@hqo/react-components-library/dist/molecules/radio-button/radio-button';
import { FieldLabel } from '@hqo/react-components-library/dist/atoms/field-label/field-label';
import { OptionsContainer } from 'app/components/fields/radio-form/radio-form.style';

interface RadioOption{
  value: string;
  label: string;
}

interface RadioFormProps {
  fieldName: string;
  translationKey: string;
  options: RadioOption[];
}

export const RadioForm: FC<RadioFormProps> = ({ fieldName, translationKey, options }) => {
  const [field, { error, touched }, { setValue }] = useField(fieldName);
  const { label } = useFieldTranslations(translationKey);

  const handleChange = useCallback((value: string) => () => setValue(value), [setValue]);

  return (
    <FieldWrapper touched={touched} error={error}>
      <FieldLabel label={label} />
      <OptionsContainer>
        {options.map(({ value: optionValue, label: optionLabel }) => (
          <RadioButton
            id={optionValue}
            key={optionValue}
            selectedValue={field.value.toString()}
            value={optionValue}
            label={optionLabel}
            onChange={handleChange(optionValue)}
          />
        ))}
      </OptionsContainer>
    </FieldWrapper>
  );
};

import { FC } from 'react';
import { useVisitsMetrics } from 'app/components/visits/visits-metrics/use-visits-metrics.hook';
import { MetricsPanel } from 'app/components/visits/visits-metrics/visits-metrics.styles';
import { VisitsMetricsProps } from 'app/components/visits/visits-metrics/visits-metrics.interfaces';

export const VisitsMetricsView: FC<VisitsMetricsProps> = ({
  cancelledVisitsCount,
  checkedInVisitsCount,
  expectedVisitsCount,
  totalVisitsCount,
}) => {
  const { total, subItems } = useVisitsMetrics({
    cancelledVisitsCount,
    checkedInVisitsCount,
    expectedVisitsCount,
    totalVisitsCount,
  });

  return <MetricsPanel total={total} subItems={subItems} data-testid="visits-metrics" />;
};

import styled from 'styled-components';
import { Pill } from '@hqo/react-components-library/dist/atoms/pill';
import { HqoPageHeader } from '@hqo/react-components-library/dist/organisms/hqo-page-header';

export const StyledPill = styled(Pill)`
  font-size: 12px;
  line-height: 22px;

  &,
  &:hover {
    background-color: ${({ theme }) => theme.colors.admin.green};
  }
`;

export const PageHeader = styled(HqoPageHeader)`
  margin: 32px 0;
`;

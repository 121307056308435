import { VisitUpdatedEvent } from 'app/store/visits/types';
import { IntlShape } from 'react-intl';
import { toast } from 'react-toastify';

export const showVisitUpdatedNotification = (
  { visit, action }: VisitUpdatedEvent,
  intl: IntlShape,
) => {
  if (!visit || !action) {
    return;
  }

  toast.info(intl.formatMessage({ id: `notifications.visit.updated.${action}` }, { name: visit.visitor_name }));
};

import { MetricsPanelItemProps } from '@hqo/react-components-library/dist/molecules/metrics-panel-item';
import { VisitsMetricsProps } from 'app/components/visits/visits-metrics/visits-metrics.interfaces';
import { statusColorsMap } from 'app/shared/consts/visit-status-colors';
import { VisitStatus } from 'app/store/visits/types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

interface Visitsmetrics {
  total: MetricsPanelItemProps;
  subItems: MetricsPanelItemProps[];
}

export const useVisitsMetrics = ({
  cancelledVisitsCount,
  checkedInVisitsCount,
  expectedVisitsCount,
  totalVisitsCount,
}: VisitsMetricsProps): Visitsmetrics => {
  const { formatMessage } = useIntl();

  return useMemo(
    () => ({
      total: {
        title: formatMessage({ id: 'metrics.totalVisits' }),
        value: totalVisitsCount || 0,
      },
      subItems: [
        {
          title: formatMessage({ id: 'metrics.expectedVisits' }),
          value: expectedVisitsCount || 0,
          color: statusColorsMap.get(VisitStatus.INVITED),
        },
        {
          title: formatMessage({ id: 'metrics.checkedInVisits' }),
          value: checkedInVisitsCount || 0,
          color: statusColorsMap.get(VisitStatus.CHECKED_IN),
        },
        {
          title: formatMessage({ id: 'metrics.cancelledVisits' }),
          value: cancelledVisitsCount || 0,
          color: statusColorsMap.get(VisitStatus.CANCELED),
        },
      ],
    }),
    [cancelledVisitsCount, checkedInVisitsCount, expectedVisitsCount, totalVisitsCount],
  );
};

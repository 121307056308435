import styled from 'styled-components';

export const DateHeader = styled.div`
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 8px;
`;

export const TimesContainer = styled.div`
  display: flex;
  gap: 24px;
`;

export const Icon = styled.span`
  color: ${({ theme }) => theme.colors.palette.systemPink};
  border-radius: 50%;
  vertical-align: middle;
  cursor: pointer;
  width: 24px;
  height: 24px;

  &:hover {
    color: ${({ theme }) => theme.colors.palette.systemPinkHover};
  }

  &:active {
    color: ${({ theme }) => theme.colors.palette.systemPinkPressed};
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

import { useIntl } from 'react-intl';
import { Title } from './date-and-time.styles';
import { MultiDayDatePicker } from './multi-day-date-picker';
import { TimeSelectorContainer } from './time-selector';
import { useDateChangeHandler } from './use-date-change-handler.hook';
import { VISIT_DATE_TIMES_FIELD_NAME } from './consts';
import { FC } from 'react';
import { FormFeatureFlags } from 'app/components/visits/visit-form/visit-form.interfaces';

interface DateAndTimeProps extends Pick<FormFeatureFlags, 'canSelectMultipleDates'> {
  shouldReplaceInitialDate?: boolean;
}

export const DateAndTime: FC<DateAndTimeProps> = ({ canSelectMultipleDates, shouldReplaceInitialDate }) => {
  const { formatMessage } = useIntl();
  const [visitDateTimes, dates, onClear, onChange, isChangedRef] = useDateChangeHandler(VISIT_DATE_TIMES_FIELD_NAME);

  return (
    <>
      <Title>{formatMessage({ id: 'visitForm.visitDetails.dateAndTime.title' })}</Title>
      <MultiDayDatePicker
        value={dates}
        onClear={onClear}
        onChange={onChange}
        fieldName={VISIT_DATE_TIMES_FIELD_NAME}
        canSelectMultipleDates={canSelectMultipleDates}
        shouldReplaceDate={!isChangedRef.current && shouldReplaceInitialDate}
      />
      {visitDateTimes?.map((visitDate, idx) => (
        <TimeSelectorContainer
          index={idx}
          showHeader={visitDateTimes?.length > 1}
          key={visitDate.startDate?.toString()}
          visitDateTime={visitDate}
        />
      ))}
    </>
  );
};

import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { Epic } from 'redux-observable';
import { EMPTY, catchError, filter, map, of, switchMap, exhaustMap, withLatestFrom } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { TypeOptions, toast } from 'react-toastify';
import { changeVisitorPassStatus, fetchVisitorPassesInfo, visitorPassUpdated } from 'app/store/visitor-passes/actions';
import { cancelVisit, checkInVisit, checkOutVisit, fetchVisits } from 'app/store/visits/actions';
import { visitSelector, visitUuidsSelector } from 'app/store/visits/selectors';
import { Permission } from 'app/store/user-permissions/types';
import { hasPermissionSelector } from 'app/store/user-permissions/selectors';
import { VisitorPassActivationStatus, VisitorPassInfo, VisitorPassStatus } from './types';
import { MessageIds } from '../../../i18n';

export const fetchPassesOnVisitSuccessEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf(fetchVisits.success)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const couldViewPasses = hasPermissionSelector(state, Permission.ACTIVATE_VISITOR_PASS);
      const { response, params } = action.payload;
      const visitUuids = response.data.map((visit) => visit.uuid);

      if (!visitUuids.length || !couldViewPasses) {
        return EMPTY;
      }

      return of(fetchVisitorPassesInfo.request({ buildingUuid: params.buildingUuid, visitUuids }));
    }),
  );

export const fetchPassesOnVisitVisitStatusChangeEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
) =>
  action$.pipe(
    filter(isActionOf([checkInVisit.success, checkOutVisit.success, cancelVisit.success])),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const couldViewPasses = hasPermissionSelector(state, Permission.ACTIVATE_VISITOR_PASS);
      const visitUuids = visitUuidsSelector(state);

      if (!visitUuids.length || !couldViewPasses) {
        return EMPTY;
      }

      return of(fetchVisitorPassesInfo.request({ buildingUuid: action.payload.params.buildingUuid, visitUuids }));
    }),
  );

export const fetchVisitorPassesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitorPassesInfo.request)),
    withLatestFrom(state$),
    exhaustMap(([action, state]) =>
      apiClient(state)
        .fetchVisitorPassesInfo(action.payload)
        .pipe(
          map((response) => fetchVisitorPassesInfo.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorPasses.fetchVisitorPasses.error' }));

            return of(fetchVisitorPassesInfo.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const changeVisitorPassStatusEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(changeVisitorPassStatus.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .activateVisitorPassByUuid(action.payload)
        .pipe(
          map(() => changeVisitorPassStatus.success({ params: action.payload })),
          catchError((error: Error) => {
            toast.error(
              intl.formatMessage({
                id: `notifications.visitorPasses.changeVisitorPassStatus.${action.payload.action}.error`,
              }),
            );

            return of(changeVisitorPassStatus.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

const messageKeyByStatus: Partial<
  Record<VisitorPassStatus, Record<VisitorPassActivationStatus, MessageIds> | MessageIds>
> = {
  [VisitorPassStatus.GENERATED]: {
    [VisitorPassActivationStatus.ACTIVE]: 'notifications.visitorPasses.status.activated',
    [VisitorPassActivationStatus.INACTIVE]: 'notifications.visitorPasses.status.generated',
  },
  [VisitorPassStatus.REVOKED]: 'notifications.visitorPasses.status.revoked',
  [VisitorPassStatus.ERROR]: 'notifications.visitorPasses.status.error',
};
const toastTypeByStatus: Partial<Record<VisitorPassStatus, Exclude<TypeOptions, 'default'>>> = {
  [VisitorPassStatus.GENERATED]: 'success',
  [VisitorPassStatus.REVOKED]: 'error',
  [VisitorPassStatus.ERROR]: 'error',
};

function getMessageKeyByPass(pass: Pick<VisitorPassInfo, 'status' | 'active'>): MessageIds | null {
  const messageKey = messageKeyByStatus[pass.status];

  if (!messageKey) {
    return null;
  }

  return typeof messageKey === 'string'
    ? messageKey
    : messageKey[pass.active ? VisitorPassActivationStatus.ACTIVE : VisitorPassActivationStatus.INACTIVE];
}

export const visitorPassUpdatedEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { intl },
) =>
  action$.pipe(
    filter(isActionOf(visitorPassUpdated)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const { pass } = action.payload;
      const visit = visitSelector(state, pass.visitUuid);
      const messageKey = getMessageKeyByPass(pass);
      const toastType = toastTypeByStatus[pass.status];

      if (visit && messageKey && toastType) {
        toast[toastType]?.(intl.formatMessage({ id: messageKey }, { name: visit.visitor_name }));
      }

      return EMPTY;
    }),
  );

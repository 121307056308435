import {
  FetchAccessGroupsErrorDto,
  FetchAccessGroupsParams,
  FetchAccessGroupsSuccessDto,
  FetchAccessGroupsMappingRulesParams,
  FetchAccessGroupsMappingRulesSuccessDto,
  FetchAccessGroupsMappingRulesErrorDto,
  UpdateAccessGroupsMappingRulesParams,
  UpdateAccessGroupsMappingRulesSuccessDto,
  UpdateAccessGroupsMappingRulesErrorDto,
} from 'app/store/access-groups/types';
import { createAsyncAction } from 'typesafe-actions';

export const fetchAccessGroups = createAsyncAction(
  'ACCESS_GROUPS/FETCH_REQUEST',
  'ACCESS_GROUPS/FETCH_SUCCESS',
  'ACCESS_GROUPS/FETCH_FAILURE',
)<FetchAccessGroupsParams, FetchAccessGroupsSuccessDto, FetchAccessGroupsErrorDto>();

export const fetchAccessGroupsMappingRules = createAsyncAction(
  'ACCESS_GROUPS/FETCH_MAPPING_RULES_REQUEST',
  'ACCESS_GROUPS/FETCH_MAPPING_RULES_SUCCESS',
  'ACCESS_GROUPS/FETCH_MAPPING_RULES_FAILURE',
)<
  FetchAccessGroupsMappingRulesParams,
  FetchAccessGroupsMappingRulesSuccessDto,
  FetchAccessGroupsMappingRulesErrorDto
>();

export const updateAccessGroupsMappingRules = createAsyncAction(
  'ACCESS_GROUPS/UPDATE_MAPPING_RULES_REQUEST',
  'ACCESS_GROUPS/UPDATE_MAPPING_RULES_SUCCESS',
  'ACCESS_GROUPS/UPDATE_MAPPING_RULES_FAILURE',
)<
  UpdateAccessGroupsMappingRulesParams,
  UpdateAccessGroupsMappingRulesSuccessDto,
  UpdateAccessGroupsMappingRulesErrorDto
>();

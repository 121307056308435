import { fetchHostDetails } from 'app/store/host-details/actions';
import { HostDetailsByUuidState } from 'app/store/host-details/types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: HostDetailsByUuidState = {};

const handleFetchHostDetailsRequest = (
  state: HostDetailsByUuidState,
  action: ActionType<typeof fetchHostDetails.request>,
): HostDetailsByUuidState => {
  const hostDetails = state[action.payload.uuid];

  return {
    ...state,
    [action.payload.uuid]: {
      data: hostDetails?.data ?? null,
      error: hostDetails?.error ?? null,
      loading: true,
    },
  };
};

const handleFetchHostDetailsSuccess = (
  state: HostDetailsByUuidState,
  action: ActionType<typeof fetchHostDetails.success>,
): HostDetailsByUuidState => ({
  ...state,
  [action.payload.params.uuid]: {
    data: action.payload.response,
    error: null,
    loading: false,
  },
});

const handleFetchHostDetailsFailure = (
  state: HostDetailsByUuidState,
  action: ActionType<typeof fetchHostDetails.failure>,
): HostDetailsByUuidState => ({
  ...state,
  [action.payload.params.uuid]: {
    data: null,
    error: action.payload.response,
    loading: false,
  },
});

export const hostDetailsReducer = createReducer(initialState)
  .handleAction(fetchHostDetails.request, handleFetchHostDetailsRequest)
  .handleAction(fetchHostDetails.success, handleFetchHostDetailsSuccess)
  .handleAction(fetchHostDetails.failure, handleFetchHostDetailsFailure);

import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { FormValues } from 'app/components/visits/visit-form/visit-form.interfaces';
import { VisitorType } from 'app/store/visitor/types';

export const useSelectedVisitorData = (visitorIndex: number) => {
  const {
    values: { visitors },
  } = useFormikContext<FormValues>();
  return useMemo(
    () => visitors[visitorIndex].type === VisitorType.USER || !!visitors[visitorIndex].uuid,
    [visitors[visitorIndex].type, visitors[visitorIndex].uuid],
  );
};

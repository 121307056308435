import { VISITS_PATH } from 'app/shared/consts';
import { RootAction } from 'app/store/actions';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { rootPathSelector } from 'app/store/config/selectors';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import {
  createDefaultVisitorRegistrationConfig,
  fetchVisitorRegistrationConfig,
  patchVisitorRegistrationConfig,
  patchVisitorRegistrationConfigForCurrentBuilding,
  uploadEmailBrandingImage,
} from 'app/store/settings/actions';
import { toast } from 'react-toastify';
import { Epic } from 'redux-observable';
import { catchError, concat, EMPTY, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { isActionOf } from 'typesafe-actions';

export const fetchVisitorRegistrationConfigEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitorRegistrationConfig.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .fetchVisitorRegistrationConfig()
        .pipe(
          map((response) => fetchVisitorRegistrationConfig.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.config.fetch.error' }));

            return of(fetchVisitorRegistrationConfig.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const createDefaultVisitorRegistrationConfigEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(createDefaultVisitorRegistrationConfig.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .createDefaultVisitorRegistrationApp(action.payload)
        .pipe(
          map(({ response }) => createDefaultVisitorRegistrationConfig.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.config.createDefault.error' }));

            return of(createDefaultVisitorRegistrationConfig.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const patchVisitorRegistrationConfigEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(patchVisitorRegistrationConfigForCurrentBuilding)),
    withLatestFrom(state$),
    switchMap(([action, state]) => {
      const buildingUuid = buildingUuidSelector(state);
      const params = { ...action.payload, buildingUuid };

      return concat(
        of(patchVisitorRegistrationConfig.request(params)),
        apiClient(state)
          .patchVisitorRegistrationConfig(action.payload)
          .pipe(
            map(({ response }) => patchVisitorRegistrationConfig.success({ params: { buildingUuid }, response })),
            catchError((error: Error) => {
              toast.error(intl.formatMessage({ id: 'notifications.config.patch.error' }));

              return of(patchVisitorRegistrationConfig.failure({ params, response: error }));
            }),
          ),
      );
    }),
  );

export const patchVisitorRegistrationConfigSuccessEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { navigate },
) =>
  action$.pipe(
    filter(isActionOf(patchVisitorRegistrationConfig.success)),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const rootPath = rootPathSelector(state);

      navigate(`${rootPath}/${VISITS_PATH}`);

      return EMPTY;
    }),
  );

export const uploadImageEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(uploadEmailBrandingImage.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const buildingUuid = buildingUuidSelector(state);
      const params = { buildingUuid };
      return apiClient(state)
        .uploadImage(action.payload.image)
        .pipe(
          map(({ response }) => {
            toast.success(intl.formatMessage({ id: 'settings.notificationSettings.image.upload.success' }));
            return uploadEmailBrandingImage.success({ params, response });
          }),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'settings.notificationSettings.image.upload.error' }));
            return of(uploadEmailBrandingImage.failure({ params, response: error }));
          }),
        );
    }),
  );

import { FC, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { Navigate, useParams } from 'react-router';
import { COMPANY_UUID_PARAMETER, DAY_PASSES_ROUTE, PERSON_UUID_PARAMETER } from '../../shared/consts';
import { useSelector } from 'react-redux';
import { rootPathSelector } from 'app/store/config/selectors';
import { Loader } from 'app/components/loader';

interface DayPassPageRouteParams extends Record<string, string> {
  [COMPANY_UUID_PARAMETER]: string;
  [PERSON_UUID_PARAMETER]: string;
}

const DayPass = lazy(() => import('../../components/day-pass/day-pass-container'));

export const DayPassPage: FC = () => {
  const params = useParams<DayPassPageRouteParams>();
  const { search } = useLocation();
  const rootPath = useSelector(rootPathSelector);
  const showOpenPrintDialog = !!new URLSearchParams(search).get('print');
  const companyUuid = params?.[COMPANY_UUID_PARAMETER];
  const personUuid = params?.[PERSON_UUID_PARAMETER];

  if (!companyUuid || !personUuid) {
    return <Navigate to={`${rootPath}/${DAY_PASSES_ROUTE}`} replace />;
  }

  return (
    <Suspense fallback={<Loader testId="day-pass-loader" />}>
      <DayPass companyUuid={companyUuid} personUuid={personUuid} showOpenPrintDialog={showOpenPrintDialog} />
    </Suspense>
  );
};

/* eslint-disable operator-linebreak */
import { compose } from 'redux';

const reduxDevToolsExtensionCompose =
  process.env.NODE_ENV === 'development' && window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const devToolExtension =
  typeof reduxDevToolsExtensionCompose === 'boolean'
    ? reduxDevToolsExtensionCompose
    : reduxDevToolsExtensionCompose?.({ name: 'visitor-registration-admin', trace: true, traceLimit: 25 });

export const composeEnhancers = devToolExtension || compose;

export enum ZIndex {
  TABLE = 1, // default z-index for the table in the react-components-library
  POPOVER,
  SELECT,
  NAVBAR = 1000, // default z-index for the navbar in the host application
  MODAL,
  HEADER = 1002, // default z-index for the header in the host application
  OVERLAY,
  NOTIFICATION,
}

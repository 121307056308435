import { ChangeEvent, forwardRef } from 'react';
import { InvisibleInput } from './file-upload-input.styles';

interface UploadVisitorsInputProps {
  onUpload: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const FileUploadInput = forwardRef<HTMLInputElement, UploadVisitorsInputProps>(({ onUpload }, ref) => (
  <InvisibleInput
    type="file"
    data-testid="upload-visitors-file-input"
    id="upload-visitors-file-input"
    accept=".csv"
    multiple={false}
    onChange={onUpload}
    ref={ref}
  />
));

FileUploadInput.displayName = 'UploadVisitorsInput';

import { FC, lazy, Suspense } from 'react';
import { Outlet } from 'react-router';
import { Loader } from 'app/components/loader';
import { TabPageLayout } from 'app/components/visitor-management-tabs/tab-page-layout';
import { VisitsPageHeading } from 'app/components/visits/visits-page-heading';
import { AuditExportModal } from 'app/components/audit-export';

const VisitsTable = lazy(() => import('app/components/visits/visits-table/visits-table.container'));

export const VisitsPage: FC = () => (
  <TabPageLayout data-testid="visits-page">
    <VisitsPageHeading />
    <Suspense fallback={<Loader testId="visits-loader" />}>
      <VisitsTable />
    </Suspense>
    <Outlet />
    <AuditExportModal />
  </TabPageLayout>
);

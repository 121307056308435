import { buildingUuidSelector } from 'app/store/building/selectors';
import { RootState } from 'app/store/reducer';
import { createSelector } from 'reselect';

const settingsByBuildingState = (state: RootState) => state.settings;

const settingsState = createSelector(
  [buildingUuidSelector, settingsByBuildingState],
  (buildingUuid, visitsMetricsByBuilding) => visitsMetricsByBuilding?.[buildingUuid] ?? null,
);

export const settingsStateSelector = createSelector([settingsState], (settingsData) => settingsData ?? null);

export const createDefaultConfigLoadingSelector = createSelector(
  [settingsStateSelector],
  (settingsData) => settingsData?.createDefaultConfigLoading ?? false,
);

import { fetchUserPermissions } from './actions';
import { BuildingUserPermissionsState, UserPermissionsState } from './types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: UserPermissionsState = {};
export const initialBuildingUserPermissionsState: BuildingUserPermissionsState = {
  userPermissions: null,
  error: null,
  loading: false,
};

const getUpdatedBuildingUserPermissionsState = (
  state: UserPermissionsState,
  buildingUuid: string,
  updates: Partial<BuildingUserPermissionsState>,
): UserPermissionsState => ({
  ...state,
  [buildingUuid]: {
    ...initialBuildingUserPermissionsState,
    ...state[buildingUuid],
    ...updates,
  },
});

const handleFetchUserPermissionsRequest = (state: UserPermissionsState, action: ActionType<typeof fetchUserPermissions.request>): UserPermissionsState =>
  getUpdatedBuildingUserPermissionsState(state, action.payload.buildingUuid, { loading: true, error: null });

const handleFetchUserPermissionsSuccess = (state: UserPermissionsState, action: ActionType<typeof fetchUserPermissions.success>): UserPermissionsState =>
  getUpdatedBuildingUserPermissionsState(state, action.payload.params.buildingUuid, { loading: false, userPermissions: action.payload.response, error: null });

const handleFetchUserPermissionsFailure = (state: UserPermissionsState, action: ActionType<typeof fetchUserPermissions.failure>): UserPermissionsState =>
  getUpdatedBuildingUserPermissionsState(state, action.payload.params.buildingUuid, { loading: false, error: action.payload.response });

export const userPermissionsReducer = createReducer(initialState)
  .handleAction(fetchUserPermissions.request, handleFetchUserPermissionsRequest)
  .handleAction(fetchUserPermissions.success, handleFetchUserPermissionsSuccess)
  .handleAction(fetchUserPermissions.failure, handleFetchUserPermissionsFailure);

import './index.css';

import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';
import { App } from 'example/app';
import { StrictMode } from 'react';

const rootElement = document.getElementById('root');

if (!rootElement) {
  throw new Error('Root element not found, could not instantiate app');
}

const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { Loader } from 'app/components/loader';
import { ENTITY_UUID_PARAMETER } from 'app/shared/consts';
import { FC, lazy, Suspense } from 'react';
import { Outlet, useParams } from 'react-router-dom';

const HostDetails = lazy(() => import('app/components/details/host-details/host-details'));

export const HostDetailsPage: FC = () => {
  const params = useParams();
  const uuid = params[ENTITY_UUID_PARAMETER] as string;

  return (
    <div data-testid="host-details-page">
      <Suspense fallback={<Loader testId="host-details-loader" />}>
        <HostDetails uuid={uuid} />
      </Suspense>
      <Outlet />
    </div>
  );
};

import { Loader } from 'app/components/loader';
import { useVisitUuidParam } from 'app/components/visits/hooks/use-visit-uuid-param.hook';
import { FC, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';

const VisitPass = lazy(() => import('app/components/visit-pass/visit-pass-container'));

export const VisitPassPage: FC = () => {
  const visitUuid = useVisitUuidParam();
  const { search } = useLocation();
  const showOpenPrintDialog = !!new URLSearchParams(search).get('print');

  return (
    <Suspense fallback={<Loader testId="visitor-pass-loader" />}>
      <VisitPass visitUuid={visitUuid} showOpenPrintDialog={showOpenPrintDialog} />
    </Suspense>
  );
};

import { useFetchUserPermissions } from 'app/store/user-permissions/hooks';
import { useBuildingSettings } from 'app/store/building-settings/hooks';
import { useDaypassModesLoadingEffect } from 'app/store/daypass-modes/use-daypass-modes.hook';

/**
 * Custom hook that checks if the user's permissions and building settings are loaded.
 * @returns A boolean value indicating whether the user's permissions and building settings are loaded.
 */
export const useRouterBootstrap = (): boolean => {
  const permissionsLoading = useFetchUserPermissions();
  const [buildingSettings, loading] = useBuildingSettings();
  const daypassModesLoaded = useDaypassModesLoadingEffect();

  return !permissionsLoading && !loading && !!buildingSettings && daypassModesLoaded;
};

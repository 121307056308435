import { Loader } from 'app/components/loader';
import { TabPageLayout } from 'app/components/visitor-management-tabs/tab-page-layout';
import { useDaypassRequestEventsListener } from 'app/shared/hooks/use-daypass-request-events-listener';
import { daypassUserRequestUpdated } from 'app/store/daypass-users/actions';
import { FC, lazy, Suspense } from 'react';
import { Outlet } from 'react-router';

const DayPasses = lazy(() => import('app/components/day-passes/day-passes.container'));

export const DayPassesPage: FC = () => {
  useDaypassRequestEventsListener(daypassUserRequestUpdated);

  return (
    <TabPageLayout data-testid="day-passes-page">
      <Suspense fallback={<Loader testId="day-passes-loader" />}>
        <DayPasses />
      </Suspense>
      <Outlet />
    </TabPageLayout>
  );
};

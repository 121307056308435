import styled from 'styled-components';
import { DateSelection as HqODateSelection } from '@hqo/react-components-library/dist/molecules/date-selection';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { ZIndex } from 'app/shared/consts/z-index';

export const DateSelection = styled(HqODateSelection)`
  flex-grow: 1;

  & .popover-body {
    z-index: ${ZIndex.POPOVER};
  }

  & .popover-date-picker,
  & .popover-date-picker > div:not(.popover-body) {
    width: 100%;
  }
`;

export const DateFilterWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  align-items: center;
`;

export const ArrowButton = styled(Button)`
  border-radius: 50%;
  overflow: hidden;
`;

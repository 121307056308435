import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { VISITS_PATH } from 'app/shared/consts';
import { rootPathSelector } from 'app/store/config/selectors';
import { StyledPill, PageHeader } from './header.styles';
import { useSettingsTabs } from 'app/components/settings/settings-tabs';

export const Header = () => {
  const { formatMessage } = useIntl();
  const rootPath = useSelector(rootPathSelector);
  const navigate = useNavigate();
  const tabProps = useSettingsTabs();

  const onBackButtonClick = useCallback(() => {
    navigate(`${rootPath}/${VISITS_PATH}`);
  }, [navigate, rootPath]);

  const onCtaClick = useCallback(() => {
    window.dispatchEvent(new CustomEvent('save-settings'));
  }, []);

  return (
    <PageHeader
      title={formatMessage({ id: 'settings.title' })}
      data-testid="visitor-registration-settings-header"
      pill={<StyledPill>{formatMessage({ id: 'settings.active' })}</StyledPill>}
      ctaButtonText={formatMessage({ id: 'common.form.save' })}
      onCtaButtonClick={onCtaClick}
      backButtonText={formatMessage({ id: 'title' })}
      onBackButtonClick={onBackButtonClick}
      tabSize="auto"
      activeTab={tabProps.activeTab}
      tabs={tabProps.tabs}
      onTabChange={tabProps.onTabChange}
    />
  );
};

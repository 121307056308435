import { ErrorMessage, FieldWrapperStyled } from './field-wrapper.styles';

import { FormattedMessage } from 'react-intl';
import React, { FC } from 'react';
import { MessageIds } from '../../../../i18n';

interface FieldWrapperProps {
  error?: string | null;
  touched?: boolean;
  children: React.ReactNode;
  className?: string;
  flexGrow?: string|number;
}
export const FieldWrapper: FC<FieldWrapperProps> = ({ error, touched, children, className, flexGrow }) => (
  <FieldWrapperStyled flexGrow={flexGrow} className={className}>
    {children}
    {touched && error && (
      <ErrorMessage>
        <FormattedMessage id={error as MessageIds} defaultMessage={error} />
      </ErrorMessage>
    )}
  </FieldWrapperStyled>
);

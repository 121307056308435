import { FetchHostsErrorDto, FetchHostsParams, FetchHostsSuccessDto, ClearHostsParams } from 'app/store/hosts/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const fetchHosts = createAsyncAction('HOSTS/FETCH_REQUEST', 'HOSTS/FETCH_SUCCESS', 'HOSTS/FETCH_FAILURE')<
  FetchHostsParams,
  FetchHostsSuccessDto,
  FetchHostsErrorDto
>();

export const clearHosts = createAction('HOSTS/CLEAR')<ClearHostsParams>();

import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import {
  daypassRequestUpdatedForCurrentCompany,
  fetchDaypassRequests as fetchDaypassRequestsAction,
  setDaypassRequestApproval,
  daypassRequestUpdated,
} from './actions';
import { isActionOf } from 'typesafe-actions';
import { RootAction } from 'app/store/actions';
import { of } from 'rxjs';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { toast } from 'react-toastify';
import { userSelector } from '../user/selectors';
import { daypassRequestsSelector } from './selectors';
import { DaypassStatus } from '../daypass-users/types';
import { MessageIds } from 'i18n';
import { showDaypassUpdatedNotification } from 'app/shared/utils/daypass-notifications';

export const fetchDaypassRequestsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchDaypassRequestsAction.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .fetchDaypassRequests(payload)
        .pipe(
          map((response) => fetchDaypassRequestsAction.success({ params: payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.daypassRequests.fetch.error' }));

            return of(fetchDaypassRequestsAction.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

export const setDaypassRequestApprovalEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(setDaypassRequestApproval.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) =>
      apiClient(state)
        .setDaypassRequestApproval(payload)
        .pipe(
          map(() => {
            const user = userSelector(state);

            return setDaypassRequestApproval.success({
              params: payload,
              approver: {
                uuid: user.uuid,
                firstName: user.first_name,
                lastName: user.last_name,
              },
            });
          }),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.daypassRequests.updateStatus.error' }));

            return of(setDaypassRequestApproval.failure({ params: payload, response: error }));
          }),
        ),
    ),
  );

function getMessageKey(oldStatus: DaypassStatus | undefined, newStatus: DaypassStatus): MessageIds | null {
  if (newStatus === DaypassStatus.AWAITING_APPROVAL) {
    return 'notifications.daypassRequests.statusChanged.requested';
  }

  if (oldStatus !== DaypassStatus.AWAITING_APPROVAL) {
    return null;
  }

  return newStatus === DaypassStatus.DENIED
    ? 'notifications.daypassRequests.statusChanged.denied'
    : 'notifications.daypassRequests.statusChanged.approved';
}

export const daypassRequestUpdatedEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { intl },
) =>
  action$.pipe(
    filter(isActionOf(daypassRequestUpdated)),
    withLatestFrom(state$),
    filter(([{ payload }, state]) => {
      const user = userSelector(state);

      return payload.request.companyUuid === user?.company?.uuid;
    }),
    tap(([{ payload }, state]) => {
      const { person } = payload;
      const exisingRequests = daypassRequestsSelector(state);
      const exisingRequest = exisingRequests?.find((request) => request.request.uuid === payload.request.uuid);

      showDaypassUpdatedNotification(
        exisingRequest?.request?.status,
        payload.request.status,
        person,
        intl,
        getMessageKey,
      );
    }),
    map(([{ payload }]) => daypassRequestUpdatedForCurrentCompany(payload)),
  );

import {
  FetchVisitorPassesParams,
  ChangeVisitorPassStatusParams,
  FetchVisitorPassesErrorDto,
  FetchVisitorPassesSuccessDto,
  ChangeVisitorPassesStatusErrorDto,
  ChangeVisitorPassStatusSuccessDto,
  VisitorPassInfo,
} from 'app/store/visitor-passes/types';
import { createAction, createAsyncAction } from 'typesafe-actions';

export const fetchVisitorPassesInfo = createAsyncAction(
  'VISITOR_PASSES_INFO/FETCH_REQUEST',
  'VISITOR_PASSES_INFO/FETCH_SUCCESS',
  'VISITOR_PASSES_INFO/FETCH_FAILURE',
)<FetchVisitorPassesParams, FetchVisitorPassesSuccessDto, FetchVisitorPassesErrorDto>();

export const changeVisitorPassStatus = createAsyncAction(
  'VISITOR_PASSES/CHANGE_STATUS_REQUEST',
  'VISITOR_PASSES/CHANGE_STATUS_SUCCESS',
  'VISITOR_PASSES/CHANGE_STATUS_FAILURE',
)<ChangeVisitorPassStatusParams, ChangeVisitorPassStatusSuccessDto, ChangeVisitorPassesStatusErrorDto>();

export const visitorPassUpdated = createAction('VISITOR_PASSES/VISITOR_PASS_UPDATED')<{
  buildingUuid: string;
  pass: VisitorPassInfo;
}>();

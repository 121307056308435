import { buildingUuidSelector } from 'app/store/building/selectors';
import { RootState } from 'app/store/reducer';
import { initialBuildingWatchlistState } from 'app/store/watchlist/reducer';
import { createSelector } from 'reselect';

const watchlistByBuildingState = (state: RootState) => state.watchlist;

const watchlistState = createSelector(
  [buildingUuidSelector, watchlistByBuildingState],
  (buildingUuid, watchlistByBuilding) => watchlistByBuilding?.[buildingUuid] ?? initialBuildingWatchlistState,
);

export const createWatchlistRecordIsLoadingSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.createWatchlistRecord?.loading,
);

export const pendingWatchlistModalPeopleSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.pendingWatchlistModalPeople,
);

export const resolveWatchlistMatchModalSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.resolveWatchlistMatchForm ?? null,
);

export const resolveWatchlistMatchLoadingSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.resolveWatchlistMatch?.loading ?? false,
);

export const pendingWatchlistMatchesCountSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.pendingWatchlistMatchesCount,
);

const watchlistRecordsSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.watchlistRecords,
);

export const watchlistRecordsDataSelector = createSelector(
  [watchlistRecordsSelector], (watchlistRecords) => watchlistRecords?.data,
);

export const watchlistRecordsLoadingSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData.getWatchlistRecords?.loading,
);

export const watchlistRecordsRequestCounterSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData.getWatchlistRecords?.count,
);

export const watchlistRecordsTotalSelector = createSelector(
  [watchlistRecordsSelector], (watchlistRecords) => watchlistRecords?.total,
);

const watchlistMatchesSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.watchlistMatches,
);

export const watchlistMatchesDataSelector = createSelector(
  [watchlistMatchesSelector], (watchlistMatches) => watchlistMatches?.data,
);

export const watchlistMatchesLoadingSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData.getWatchlistMatches?.loading,
);

export const watchlistMatchesTotalSelector = createSelector(
  [watchlistMatchesSelector], (watchlistMatches) => watchlistMatches?.total,
);

export const watchlistLiveMatchesSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.liveMatches,
);

export const watchlistMatchesFiltersSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.watchlistMatchesFilters,
);

export const watchlistDeletePersonLoadingSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.deleteWatchlistRecord?.loading,
);

export const watchlistMatchesCompaniesSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.watchlistMatchesCompanies,
);

export const watchlistMatchesHostsSelector = createSelector(
  [watchlistState], (watchlistData) => watchlistData?.watchlistMatchesHosts,
);

import { toast } from 'react-toastify';
import { Epic } from 'redux-observable';
import { EMPTY, catchError, concat, filter, map, of, switchMap, withLatestFrom, mergeMap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { fetchBuildingSettings, patchBuildingSettings } from './actions';
import { rootPathSelector } from 'app/store/config/selectors';
import { VISITS_PATH } from 'app/shared/consts';
import { AjaxError } from 'rxjs/ajax';
import { createDefaultVisitorRegistrationConfig } from '../settings/actions';

const HTTP_CONFLICT_STATUS = 409;
const isHttpConflictError = (error: Error) => error instanceof AjaxError && error.status === HTTP_CONFLICT_STATUS;

export const fetchBuildingSettingsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchBuildingSettings.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .getBuildingSettings(action.payload.buildingUuid)
        .pipe(
          map((response) => fetchBuildingSettings.success({ params: action.payload, response })),
          catchError((error: Error) => {
            const isConfigError = isHttpConflictError(error);

            if (!isConfigError) {
              toast.error(intl.formatMessage({ id: 'notifications.buildingSettings.fetch.error' }));
            }

            return of(fetchBuildingSettings.failure({ params: action.payload, response: error, isConfigError }));
          }),
        ),
    ),
  );

export const patchBuildingSettingsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(patchBuildingSettings.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      concat(
        apiClient(state)
          .setBuildingSettings(action.payload)
          .pipe(
            map(({ response }) =>
              patchBuildingSettings.success({ params: { buildingUuid: action.payload.buildingUuid }, response }),
            ),
            catchError((error: Error) => {
              const isConfigError = isHttpConflictError(error);

              if (!isConfigError) {
                toast.error(intl.formatMessage({ id: 'notifications.buildingSettings.patch.error' }));
              }

              return of(
                patchBuildingSettings.failure({
                  params: { buildingUuid: action.payload.buildingUuid },
                  response: error,
                  isConfigError,
                }),
              );
            }),
          ),
      ),
    ),
  );

export const patchBuildingSettingsSuccessEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { navigate },
) =>
  action$.pipe(
    filter(isActionOf(patchBuildingSettings.success)),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const rootPath = rootPathSelector(state);

      navigate(`${rootPath}/${VISITS_PATH}`);

      return EMPTY;
    }),
  );

export const reloadBuildingSettingsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (action$) =>
  action$.pipe(
    filter(isActionOf(createDefaultVisitorRegistrationConfig.success)),
    map((action) => fetchBuildingSettings.request({ buildingUuid: action.payload.params.buildingUuid })),
  );

import { useFetchVisitors } from './use-fetch-visitors.hook';
import { useCallback, useEffect, useRef } from 'react';
import { Subject, debounceTime } from 'rxjs';

const timeToDebounce = 1000;

export const useDebounceFetchVisitors = () => {
  const { visitors, setVisitors, isLoading, setIsLoading, fetchVisitors } = useFetchVisitors();
  const { current: subject } = useRef(new Subject<string>());

  useEffect(() => {
    const subscription = subject.pipe(debounceTime(timeToDebounce)).subscribe(fetchVisitors);

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const fetchVisitorsDebounced = useCallback(
    (query: string) => {
      setIsLoading(true);
      subject.next(query);
    },
    [setIsLoading, subject],
  );

  return { visitors, setVisitors, isLoading, fetchVisitors: fetchVisitorsDebounced };
};

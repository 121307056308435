import { Visitor } from 'app/components/visits/visit-form/visit-form.interfaces';
import qs from 'qs';

export const VISITORS_INITIAL_VALUES_QUERY_STRING_KEY = 'visitors';

export function getVisitorsInitialValueQueryString(visitors: Visitor[]): string {
  return qs.stringify(
    { [VISITORS_INITIAL_VALUES_QUERY_STRING_KEY]: visitors },
    { addQueryPrefix: true, arrayFormat: 'brackets' },
  );
}

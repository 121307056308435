import { ActionType, createReducer } from 'typesafe-actions';
import { fetchBuildingSettings, patchBuildingSettings } from './actions';
import { BuildingSettingsByBuilfingState, BuildingSettingsState } from './types';

export const initialState: BuildingSettingsByBuilfingState = {};
export const initialSettingsState: BuildingSettingsState = {
  settings: null,
  loading: false,
  error: null,
  isConfigured: true,
};

const handleBuildingSettingsRequest = (
  state: BuildingSettingsByBuilfingState,
  action: ActionType<typeof fetchBuildingSettings.request | typeof patchBuildingSettings.request>,
): BuildingSettingsByBuilfingState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialSettingsState,
    ...state[action.payload.buildingUuid],
    error: null,
    loading: true,
  },
});

const handleBuildingSettingsSuccess = (
  state: BuildingSettingsByBuilfingState,
  action: ActionType<typeof fetchBuildingSettings.success | typeof patchBuildingSettings.success>,
): BuildingSettingsByBuilfingState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    settings: action.payload.response.data,
    loading: false,
    error: null,
    isConfigured: true,
  },
});

const handleBuildingSettingsFailure = (
  state: BuildingSettingsByBuilfingState,
  action: ActionType<typeof fetchBuildingSettings.failure | typeof patchBuildingSettings.failure>,
): BuildingSettingsByBuilfingState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialSettingsState,
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
    isConfigured: !action.payload.isConfigError,
  },
});

export const buildingSettingsReducer = createReducer(initialState)
  .handleAction(fetchBuildingSettings.request, handleBuildingSettingsRequest)
  .handleAction(fetchBuildingSettings.success, handleBuildingSettingsSuccess)
  .handleAction(fetchBuildingSettings.failure, handleBuildingSettingsFailure)

  .handleAction(patchBuildingSettings.request, handleBuildingSettingsRequest)
  .handleAction(patchBuildingSettings.success, handleBuildingSettingsSuccess)
  .handleAction(patchBuildingSettings.failure, handleBuildingSettingsFailure);

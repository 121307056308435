import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { apiUrlSelector } from 'app/store/config/selectors';
import { useServerSentEvents } from 'app/shared/hooks/use-server-sent-events';
import { generatePath } from 'react-router-dom';
import { WatchlistUpdateEvent, WatchlistUpdateEventTypes } from 'app/store/watchlist/types';
import { removeWatchlistMatch, setPendingWatchlistMatchesCount, setWatchlistMatch } from 'app/store/watchlist/actions';

const WATCHLIST_UPDATES_EVENTS_URL = '/visitor-registration/v1/locations/:buildingUuid/watchlist/events';

export const useWatchlistUpdatesListener = () => {
  const buildingUuid: string = useSelector(buildingUuidSelector);
  const apiUrl: string = useSelector(apiUrlSelector);
  const dispatch = useDispatch();

  const eventSourceUrl = useMemo(
    () => `${apiUrl}${generatePath(WATCHLIST_UPDATES_EVENTS_URL, { buildingUuid })}`,
    [apiUrl, buildingUuid],
  );

  const eventHandler = useCallback(
    (data: string) => {
      try {
        const event: WatchlistUpdateEvent = JSON.parse(data);

        if (!event) {
          return;
        }

        Object.keys(event).forEach((objectKey: string) => {
          if (objectKey === WatchlistUpdateEventTypes.PENDING_WATCHLIST_MATCHES_COUNT) {
            dispatch(setPendingWatchlistMatchesCount({ buildingUuid, count: event[WatchlistUpdateEventTypes.PENDING_WATCHLIST_MATCHES_COUNT] }));
          }

          if (objectKey === WatchlistUpdateEventTypes.MATCH) {
            const match = event[objectKey];
            dispatch(setWatchlistMatch(match));

            setTimeout(() => {
              dispatch(removeWatchlistMatch({ buildingUuid: match.building.uuid, matchUuid: match.uuid }));
            }, 5000);
          }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Failed to handle watchlist update event', error);
      }
    },
    [buildingUuid, dispatch],
  );

  useServerSentEvents(eventSourceUrl, eventHandler);
};

import { ConfigState } from 'app/store/config/types';
import { reInitializeState } from 'app/store/initial-state/actions';
import { createReducer } from 'typesafe-actions';

export const initialState: ConfigState = {
  apiUrl: '',
  publicUrl: '',
  cdnUrl: '',
  getAuthToken: () => Promise.resolve(''),
  locale: '',
  rootPath: '',
  enableVisitsRegistration: false,
};

const handleReInitializeState = (_state: ConfigState, { payload }: ReturnType<typeof reInitializeState>): ConfigState =>
  payload.config;

export const configReducer = createReducer(initialState).handleAction(reInitializeState, handleReInitializeState);

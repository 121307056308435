import { useCallback } from 'react';
import { DropdownOption } from '@hqo/react-components-library/dist/molecules/search-input-v2/components/dropdown-item';
import { Visitor } from 'app/store/visitor/types';
import { useFormikContext } from 'formik';
import { FormValues, Visitor as VisitorFormValues } from 'app/components/visits/visit-form/visit-form.interfaces';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';

const emptyVisitorData: VisitorFormValues = {
  uuid: undefined,
  type: undefined,
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
};

const mapVisitorToFormValues = (visitor: Visitor): VisitorFormValues => ({
  firstName: visitor.first_name || emptyVisitorData.firstName,
  lastName: visitor.last_name || emptyVisitorData.lastName,
  email: visitor.email || emptyVisitorData.email,
  phone: visitor.phone || emptyVisitorData.phone,
  uuid: visitor.uuid || emptyVisitorData.uuid,
  type: visitor.type || emptyVisitorData.type,
});

const resetVisitorFormData = (visitorIndex: number, visitors: VisitorFormValues[]) => {
  const updatedVisitors = visitors.slice();
  updatedVisitors[visitorIndex] = emptyVisitorData;

  return updatedVisitors;
};

const setVisitorFormData = (visitorIndex: number, visitors: VisitorFormValues[], selectedVisitor: Visitor) => {
  const updatedVisitors = visitors.slice();
  updatedVisitors[visitorIndex] = { ...updatedVisitors[visitorIndex], ...mapVisitorToFormValues(selectedVisitor) };

  return updatedVisitors;
};

export const useSearchDropdown = (visitorIndex: number, visitorOptions: Visitor[], clearSearch: VoidFunction) => {
  const { value: isDropdownVisible, setTrue: openDropdown, setFalse: closeDropdown } = useBooleanState(false);
  const { setValues } = useFormikContext<FormValues>();

  const handleClearButtonClick = useCallback(() => {
    setValues((previousValues) => ({
      ...previousValues,
      visitors: resetVisitorFormData(visitorIndex, previousValues.visitors),
    }));
    clearSearch();
  }, [setValues, visitorIndex]);

  const handleSelectOption = useCallback(
    ({ value }: DropdownOption) => {
      const selectedVisitor = visitorOptions.find((visitor) => visitor.uuid === value);

      if (selectedVisitor) {
        setValues((previousValues) => ({
          ...previousValues,
          visitors: setVisitorFormData(visitorIndex, previousValues.visitors, selectedVisitor),
        }));
        closeDropdown();
      }
    },
    [setValues, closeDropdown, visitorIndex, visitorOptions],
  );

  return {
    isDropdownVisible,
    handleClearButtonClick,
    handleSelectOption,
    handleClickOutsideDropdown: closeDropdown,
    handleClick: openDropdown,
  };
};

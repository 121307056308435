import { SortOrder } from 'app/store/visits/types';

export enum WatchlistRecordStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  ALWAYS_APPROVE = 'ALWAYS_APPROVE',
  DENIED = 'DENIED',
}

export type WatchlistResolutionStatus =
  WatchlistRecordStatus.APPROVED |
  WatchlistRecordStatus.DENIED |
  WatchlistRecordStatus.ALWAYS_APPROVE;

export interface CreateWatchlistRecordParams {
  buildingUuid: string;
  companyUuid: string;
  firstName: string;
  lastName: string;
  alias?: string | null;
  email?: string | null;
  phone?: string | null;
  reason?: string | null;
}

export interface WatchlistRecordBuilding {
  uuid: string;
  name: string;
}

export interface WatchlistRecordCompany {
  uuid: string;
  name: string;
}

export interface WatchlistRecordUser {
  uuid: string;
  firstName: string;
  lastName: string;
}

export interface WatchlistRecord {
  uuid: string;
  companyUuid: string;
  company: WatchlistRecordCompany;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  alias: string;
  reason: string;
  photoUrl: string;
  createdBy: string;
  createdByUser: WatchlistRecordUser;
  updatedBy: string;
  createdAt: Date;
  updatedAt: Date;
  totalMatchesCount: number;
  pendingMatchesCount: number;
}

export interface WatchlistRecordRO {
  data: WatchlistRecord;
}

export interface CreateWatchlistRecordSuccessDto {
  params: CreateWatchlistRecordParams;
  response: WatchlistRecordRO;
}

export interface CreateWatchlistRecordErrorDto {
  params: CreateWatchlistRecordParams;
  response: Error;
}

export interface GetWatchlistRecordsParams {
  buildingUuid: string;
  offset?: number;
  limit?: number;
  query?: string;
}

export interface GetWatchlistRecordResponse {
  data: WatchlistRecord[] | null;
  count: number;
  total: number;
  offset: number;
}

export interface GetWatchlistRecordsSuccessDto {
  params: GetWatchlistRecordsParams;
  response: GetWatchlistRecordResponse;
}

export interface GetWatchlistRecordsErrorDto {
  params: GetWatchlistRecordsParams;
  response: Error;
}

export interface GetWatchlistMatchesParams {
  buildingUuid: string | null;
  companyUuid?: string | null;
  hostUuid?: string | null;
  limit?: number;
  offset?: number;
  order: SortOrder;
  orderBy: string;
  query?: string | null;
  status?: WatchlistRecordStatus | null;
}

export interface WatchlistItem {
  uuid: string;
  companyUuid: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  alias: string;
  reason: string;
  photoUrl: string | null;
}

export interface WatchlistVisit {
  uuid: string;
  arrivalTime: Date,
}

export interface WatchlistVisitorProfile {
  uuid: string;
  name: string;
  email: string;
  phone: string | null;
}

export interface WatchlistMatch {
  uuid: string;
  hostCompany: WatchlistRecordCompany;
  building: WatchlistRecordBuilding;
  statusChangedBy: WatchlistRecordUser | null;
  host: WatchlistRecordUser;
  watchlistItem: WatchlistItem;
  visit: WatchlistVisit;
  visitorProfile: WatchlistVisitorProfile;
  status: WatchlistRecordStatus;
  matchCriteria: string;
  note: string | null;
  updatedAt: Date;
  createdAt: Date;
}

export interface GetWatchlistMatchesResponse {
  data: WatchlistMatch[] | null;
  count: number;
  total: number;
  offset: number;
}

export interface GetWatchlistMatchesSuccessDto {
  params: GetWatchlistMatchesParams;
  response: GetWatchlistMatchesResponse;
}

export interface GetWatchlistMatchesErrorDto {
  params: GetWatchlistMatchesParams;
  response: Error;
}

export interface RequestState {
  loading: boolean;
  error: Error | null;
}

export interface RequestStateWithCounter extends RequestState {
  count: number;
}

export type WatchlistRecordState = {
  record: WatchlistRecord | null;
  loading: boolean;
  error: Error | null;
}

export interface PendingWatchlistModalRecord {
  uuid: string;
  visitor_name: string;
}

export interface PeopleForPendingWatchlistModal {
  buildingUuid: string;
  pendingWatchlistModalPeople: PendingWatchlistModalRecord[];
}

export enum WatchlistUpdateEventTypes {
  PENDING_WATCHLIST_MATCHES_COUNT = 'pendingWatchlistMatchesCount',
  MATCH = 'match',
}

export type WatchlistUpdateEvent = {
  [WatchlistUpdateEventTypes.PENDING_WATCHLIST_MATCHES_COUNT]: number;
  [WatchlistUpdateEventTypes.MATCH]: WatchlistMatch;
}

export interface PendingWatchlistMatchesCount {
  buildingUuid: string;
  count: number;
}

export interface BulkUploadWatchlistRecords {
  buildingUuid: string;
  file: File;
}

export interface RemoveWatchlistMatchParams {
  buildingUuid: string;
  matchUuid: string;
}

export interface DeleteWatchlistRecordParams {
  buildingUuid: string;
  watchlistUuid: string;
}

export interface DeleteWatchlistRecordSuccessDto {
  params: DeleteWatchlistRecordParams;
}

export interface DeleteWatchlistRecordErrorDto {
  params: DeleteWatchlistRecordParams;
  response: Error;
}

export interface GetWatchlistMatchesCompaniesParams {
  buildingUuid: string;
}

export interface IncrementRequestWatchlistRecordsCounterParams {
  buildingUuid: string;
}

export interface GetWatchlistMatchesCompaniesRO {
  uuid: string;
  name: string;
}

export interface GetWatchlistMatchesCompaniesSuccessDto {
  params: GetWatchlistMatchesCompaniesParams;
  response: GetWatchlistMatchesCompaniesRO[];
}

export interface GetWatchlistMatchesCompaniesErrorDto {
  params: GetWatchlistMatchesCompaniesParams;
  response: Error;
}

export interface GetWatchlistMatchesHostsParams {
  buildingUuid: string;
}

export interface GetWatchlistMatchesHostsRO {
  uuid: string;
  name: string;
}

export interface GetWatchlistMatchesHostsSuccessDto {
  params: GetWatchlistMatchesHostsParams;
  response: GetWatchlistMatchesHostsRO[];
}

export interface GetWatchlistMatchesHostsErrorDto {
  params: GetWatchlistMatchesHostsParams;
  response: Error;
}

export interface ResolveWatchlistMatchForm {
  watchlistMatchUuid: string;
  status: WatchlistResolutionStatus;
  notes?: string;
}

export interface ResolveWatchlistMatchModal {
  resolveWatchlistMatchForm: ResolveWatchlistMatchForm | null;
  buildingUuid: string;
}

export interface ResolveWatchlistMatchRequest extends ResolveWatchlistMatchForm {
  buildingUuid: string;
}

export interface ResolveWatchlistMatchSuccess {
  params: ResolveWatchlistMatchRequest;
  response: WatchlistMatch;
}

export interface ResolveWatchlistMatchError {
  params: ResolveWatchlistMatchRequest;
  error: Error;
}

export type BuildingWatchlistState = {
  createWatchlistRecord: RequestState;
  watchlistRecord: Record<string, WatchlistRecordState>;
  pendingWatchlistModalPeople: PendingWatchlistModalRecord[];
  pendingWatchlistMatchesCount: number;
  getWatchlistRecords: RequestStateWithCounter;
  watchlistRecords: GetWatchlistRecordResponse;
  getWatchlistMatches: RequestState;
  watchlistMatches: GetWatchlistMatchesResponse;
  watchlistMatchesFilters: GetWatchlistMatchesParams;
  liveMatches: Record<string, WatchlistMatch>;
  deleteWatchlistRecord: RequestState;
  getWatchlistMatchesCompanies: RequestState;
  watchlistMatchesCompanies: GetWatchlistMatchesCompaniesRO[];
  getWatchlistMatchesHosts: RequestState;
  watchlistMatchesHosts: GetWatchlistMatchesHostsRO[];
  resolveWatchlistMatchForm: ResolveWatchlistMatchForm | null;
  resolveWatchlistMatch: RequestState;
};

export type WatchlistState = Record<string, BuildingWatchlistState>;

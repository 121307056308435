import { makeHasPermissionSelector } from 'app/store/user-permissions/selectors';
import { Permission } from 'app/store/user-permissions/types';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  SplashScreenButton,
  SplashScreenContainer,
  SplashScreenMessage,
  SplashScreenPanel,
  SplashScreenPanelTitle,
} from './splash-screen.styles';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCreateDefaultConfig } from './use-create-default-config.hook';

export const SplashScreen: FC = () => {
  const canEnableApplication = useSelector(makeHasPermissionSelector(Permission.CREATE_DEFAULT_APP));
  const splashScreenMessageId = canEnableApplication
    ? 'splashScreen.addApplication'
    : 'splashScreen.requestApplication';
  const { formatMessage } = useIntl();
  const [handleCreate, createDefaultConfigLoading] = useCreateDefaultConfig();

  return (
    <SplashScreenContainer>
      <SplashScreenPanel data-testid="splash-screen-panel">
        <SplashScreenPanelTitle>
          <FormattedMessage id="title" />
        </SplashScreenPanelTitle>
        <SplashScreenMessage>
          <FormattedMessage
            id={`${splashScreenMessageId}.message`}
            values={{
              br: <br />,
            }}
          />
        </SplashScreenMessage>
        {canEnableApplication && (
          <SplashScreenButton
            variant="Primary"
            type="button"
            text={formatMessage({ id: `${splashScreenMessageId}.button` })}
            onClick={handleCreate}
            loading={createDefaultConfigLoading}
            data-testid="create-application-button"
          />
        )}
      </SplashScreenPanel>
    </SplashScreenContainer>
  );
};

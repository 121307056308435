import { Button } from '@hqo/react-components-library/dist/atoms/button';
import styled from 'styled-components';

export const LinkButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.colors.palette.systemBlue};

  .button-content + span {
    margin-left: 0;
  }
`;

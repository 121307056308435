import { useCallback, useEffect, useRef } from 'react';
import { Subject, takeUntil } from 'rxjs';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { AjaxResponse } from 'rxjs/ajax';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';
import { ExportAuditReportForm } from 'app/components/audit-export/audit-export-modal.types';
import { useApiClient } from 'app/store/api-client/use-api-client.hook';
import { timezoneSelector } from 'app/store/building/selectors';
import { AuditExportType } from 'app/store/visits/types';
import { convertToEndOfDayInTargetTimeZone, convertToStartOfDayInTargetTimeZone } from 'app/utils';

const handleDownload = (content: ArrayBuffer, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([content]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function generateFileName(fileType: AuditExportType, startDate: Date, endDate: Date): string {
  const formatDate = (date: Date): string => format(date, 'yyyyMMdd');

  return `visits_${formatDate(startDate)}_${formatDate(endDate)}.${fileType.toLowerCase()}`;
}

export const useSubmitAuditExportRequest = (onSuccessCallback?: VoidFunction) => {
  const { formatMessage } = useIntl();
  const apiClient = useApiClient();
  const timezone = useSelector(timezoneSelector);
  const destroy$ = useRef(new Subject<void>()).current;

  const handleResponse = useCallback((filename: string) => ({ response }: AjaxResponse<ArrayBuffer>) => {
    handleDownload(response, filename);
    onSuccessCallback?.();
  }, [onSuccessCallback]);

  const handleSubmit = useCallback(
    (values: ExportAuditReportForm) => {
      const startDate = convertToStartOfDayInTargetTimeZone(values.startDate, timezone);
      const endDate = convertToEndOfDayInTargetTimeZone(values.endDate, timezone);
      apiClient
        .getVisitsAudit(values.type, startDate, endDate)
        .pipe(takeUntil(destroy$))
        .subscribe({
          next: handleResponse(generateFileName(values.type, values.startDate, values.endDate)),
          error: () => toast.error(formatMessage({ id: 'notifications.visitsAuditExport.fetch.error' })),
        });
    },
    [apiClient, destroy$, timezone, handleResponse],
  );

  useEffect(() => () => {
    destroy$.next();
    destroy$.complete();
  }, [destroy$]);

  return { handleSubmit };
};

import { useFetchUserPermissions } from 'app/store/user-permissions/hooks';
import { useBuildingSettings } from 'app/store/building-settings/hooks';

export const useAppBootstrap = (): [loading: boolean, isAppConfigured: boolean] => {
  const permissionsLoading = useFetchUserPermissions();
  const [buildingSettings, loading, buildingSettingsError, isAppConfigured] = useBuildingSettings();
  const isBuildingSettingsLoaded = !!buildingSettings || !!buildingSettingsError;
  const isAppBootstrapInfoLoading = permissionsLoading || loading || !isBuildingSettingsLoaded;

  return [isAppBootstrapInfoLoading, isAppConfigured];
};

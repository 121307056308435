import { FC } from 'react';
import { useVisitorPassUpdatesListener } from './use-visitor-pass-updates-listener.hook';

interface VisitorPassUpdatesListenerProps {
  visitUuids: string[];
}

export const VisitorPassUpdatesListener: FC<VisitorPassUpdatesListenerProps> = ({ visitUuids }) => {
  useVisitorPassUpdatesListener(visitUuids);

  return null;
};

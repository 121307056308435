import { FC } from 'react';
import {
  Row,
  VisitPendingBottomText,
  VisitPendingButtonContainer,
  VisitPendingContents,
  VisitPendingIcon,
  VisitPendingIconContainer,
  VisitPendingModal as Modal,
  VisitPendingText,
  VisitPendingTitle,
  VisitPendingUserList,
  VisitPendingUser,
} from './visit-pending-modal.styles';
import { useIntl } from 'react-intl';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import type { PendingWatchlistModalRecord } from 'app/store/watchlist/types';
import { useVisitPendingModal } from './hooks/use-visit-pending-modal.hook';
import type { UseVisitPendingModalResponse } from './hooks/use-visit-pending-modal.hook';

export const VisitPendingModal: FC = () => {
  const { formatMessage } = useIntl();
  const { closeModal, isOpen, pendingPeople }: UseVisitPendingModalResponse = useVisitPendingModal();

  return (
    <Modal opened={isOpen} withExitButton={false} dataTestId="visit-pending-modal">
      <VisitPendingTitle>{formatMessage({ id: 'modals.visitPending.title' })}</VisitPendingTitle>
      <VisitPendingContents>
        <Row>
          <VisitPendingIconContainer>
            <VisitPendingIcon className="material-icons-outlined">priority_high</VisitPendingIcon>
          </VisitPendingIconContainer>
          <VisitPendingText>{formatMessage({ id: 'modals.visitPending.topText' })}</VisitPendingText>
        </Row>
        <VisitPendingUserList>
          {pendingPeople?.map((person: PendingWatchlistModalRecord) => <VisitPendingUser key={person.uuid}>{person.visitor_name}</VisitPendingUser>)}
        </VisitPendingUserList>
        <VisitPendingBottomText>{formatMessage({ id: 'modals.visitPending.bottomText' })}</VisitPendingBottomText>
      </VisitPendingContents>
      <VisitPendingButtonContainer>
        <Button variant="Primary" text={formatMessage({ id: 'buttons.done' })} onClick={closeModal} />
      </VisitPendingButtonContainer>
    </Modal>
  );
};

import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const NameItem = styled.div`
  font-weight: 500;
`;

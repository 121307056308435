import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { Epic } from 'redux-observable';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { toast } from 'react-toastify';
import { fetchTenantCompanies } from 'app/store/tenant-companies/actions';
import { CompanyType } from 'app/store/tenant-companies/types';

export const fetchTenantCompaniesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchTenantCompanies.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .fetchBuildingCompanies({ ...action.payload, type: CompanyType.TENANT })
        .pipe(
          map((response) => fetchTenantCompanies.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.tenantCompanies.fetch.error' }));

            return of(fetchTenantCompanies.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

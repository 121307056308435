import { FC } from 'react';
import { useSearchHost } from './hooks/use-search-host.hook';
import { useSearchField } from './hooks/use-search-field.hook';
import { useSearchDropdown } from './hooks/use-search-dropdown.hook';
import { useSearchHostDropdownOptions } from './search-host-dropdown-item/use-search-host-dropdown-options.hook';
import { Container } from './search-host.styles';
import { FieldWrapper } from 'app/components/fields/field-wrapper';
import { useField } from 'formik';
import { HqOSelect } from '@hqo/react-components-library/dist/organisms/hqo-select';

export const SearchHost: FC = () => {
  const { hostOptions, searchHost, isLoading } = useSearchHost();
  const { searchText, handleSearch, label, placeholder, touched, error } = useSearchField(searchHost);
  const [{ value: hostUuid }] = useField<string>('hostUuid');
  const [{ value: hostName }] = useField<string>('host');

  const showError = touched && !!error;
  const { handleSelectOption } = useSearchDropdown(hostOptions);
  const dropdownOptions = useSearchHostDropdownOptions(hostOptions, hostUuid, hostName);

  return (
    <Container>
      <FieldWrapper touched={touched} error={error}>
        <HqOSelect
          required
          onSelect={handleSelectOption}
          value={hostUuid}
          searchText={searchText}
          setSearchText={handleSearch}
          isLoading={isLoading}
          options={dropdownOptions}
          label={label}
          placeholder={placeholder}
          dataCy="host"
          variant={showError ? 'error' : 'master'}
          isErrored={showError}
        />
      </FieldWrapper>
    </Container>
  );
};

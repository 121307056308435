import { IsoDateString } from 'app/shared/types/iso-date-string';
import format from 'date-fns/format';

const ISO_DATE_FORMAT = 'yyyy-MM-dd';

export const getDateString = (dateOrDateString: string | Date): IsoDateString => {
  const date = new Date(dateOrDateString);

  return format(date, ISO_DATE_FORMAT) as IsoDateString;
};

import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { SearchSelectOption, SearchSelectOptionValue } from '@hqo/react-components-library/dist/molecules/search-select';
import { FormValues, VisibilityEnum } from 'app/components/visits/visit-form/visit-form.interfaces';
import { visitorGroupsSelector } from 'app/store/visitor-groups/selectors';
import { Visitor } from 'app/store/visitor-groups/types';

interface UseSelectGroupRo {
  options: SearchSelectOption<string>[];
  onSelectGroup: (groupUuid: SearchSelectOptionValue) => void;
  selectedGroupUuid: SearchSelectOptionValue | undefined;
}

const visitorMapper = ({ uuid, firstName, lastName, email, phone }: Visitor) => ({
  uuid,
  firstName,
  lastName,
  email: email ?? '',
  phone: phone ?? '',
  visibility: VisibilityEnum.PUBLIC,
});

export const useSelectGroup = (callback: (query: string) => void): UseSelectGroupRo => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const visitorGroups = useSelector(visitorGroupsSelector);

  const options = useMemo(() => visitorGroups.map(({ name: label, uuid: value }) => ({ label, value })), [visitorGroups]);

  const onSelectGroup = useCallback((groupUuid: SearchSelectOptionValue) => {
    const group = visitorGroups.find(({ uuid }) => uuid === groupUuid);
    if (group && group.visitors?.length) {
      setFieldValue('groupUuid', group.uuid);
      setFieldValue('saveAsGroup', true);
      setFieldValue('visitors', group.visitors.map(visitorMapper));
      setFieldValue('groupName', group.name, true);
    }
    callback('');
  }, [callback, visitorGroups, setFieldValue, values]);

  return ({
    options,
    selectedGroupUuid: values.groupUuid,
    onSelectGroup,
  });
};

import { FC } from 'react';
import { SearchInputV2 } from '@hqo/react-components-library/dist/molecules/search-input-v2';
import { useSearchVisitor } from './hooks/use-search-visitor.hook';
import { useEmptyContentLabels } from './hooks/use-empty-content-labels.hook';
import { useSearchField } from './hooks/use-search-field.hook';
import { useDropdownMode } from './hooks/use-dropdown-mode.hook';
import { useSearchDropdown } from './hooks/use-search-dropdown.hook';
import { useSearchVisitorDropdownOptions } from './search-visitor-dropdown-item/use-search-visitor-dropdown-options.hook';
import { Container } from './search-visitor.styles';
import { FieldWrapper } from 'app/components/fields/field-wrapper';
import { useLoadDataOnOpen } from 'app/components/visits/visit-form/visit-form-content/visitors/visitor-details/search-visitor/hooks/use-load-data-on-open.hook';
import { useField } from 'formik';

interface SearchVisitorProps {
  visitorIndex: number;
  required?: boolean;
}

export const SearchVisitor: FC<SearchVisitorProps> = ({ visitorIndex, required }) => {
  const { visitorOptions, searchVisitor, isLoading, clearSearch } = useSearchVisitor();
  const { fieldName, field, label, placeholder, touched, error, handleChange } = useSearchField(visitorIndex, searchVisitor);
  const [{ value: dropdownValue }] = useField<string>(`visitors[${visitorIndex}].uuid`);

  const showError = touched && !!error;
  const {
    isDropdownVisible,
    handleClearButtonClick,
    handleClickOutsideDropdown,
    handleSelectOption,
    handleClick,
  } = useSearchDropdown(visitorIndex, visitorOptions, clearSearch);
  const dropdownMode = useDropdownMode(visitorOptions, isLoading);
  const emptyContentLabels = useEmptyContentLabels();
  const dropdownOptions = useSearchVisitorDropdownOptions(visitorOptions);
  const handleLoadDataOnOpen = useLoadDataOnOpen(visitorOptions.length, searchVisitor, field.value, handleClick);

  return (
    <Container>
      <FieldWrapper touched={touched} error={error}>
        <SearchInputV2
          required={required}
          defaultDropdownValueIndex={0}
          onSelectOption={handleSelectOption}
          isDropdownVisible={field.value && isDropdownVisible}
          value={field.value}
          dropdownValue={dropdownValue}
          options={dropdownOptions}
          onClearButtonClick={handleClearButtonClick}
          clickOutsideDropdown={handleClickOutsideDropdown}
          onChange={handleChange}
          onBlur={field.onBlur}
          onClick={handleLoadDataOnOpen}
          dropdownMode={dropdownMode}
          label={label}
          placeholder={placeholder}
          name={fieldName}
          dataCy="visitor.email"
          variant={showError ? 'error' : 'master'}
          isErrored={showError}
          emptyContentLabels={emptyContentLabels}
        />
      </FieldWrapper>
    </Container>
  );
};

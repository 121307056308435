import { fetchVisitors } from 'app/store/visitors/actions';
import { VisitorsState, BuildingVisitorsState } from 'app/store/visitors/types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: VisitorsState = {};
export const initialBuildingVisitorsState: BuildingVisitorsState = {
  visitors: [],
  total: 0,
  loading: false,
  error: null,
};

const handleFetchVisitorsRequest = (
  state: VisitorsState,
  action: ActionType<typeof fetchVisitors.request>,
): VisitorsState => {
  const offset = action.payload.offset || 0;
  const oldState = state[action.payload.buildingUuid] || initialBuildingVisitorsState;

  return {
    ...state,
    [action.payload.buildingUuid]: {
      ...initialBuildingVisitorsState,
      ...state[action.payload.buildingUuid],
      visitors: [...oldState.visitors.slice(0, offset)],
      loading: true,
      error: null,
    },
  };
};

const handleFetchVisitorsSuccess = (
  state: VisitorsState,
  action: ActionType<typeof fetchVisitors.success>,
): VisitorsState => {
  const offset = action.payload.params.offset || 0;
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitorsState;

  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      loading: false,
      visitors: [...oldState.visitors.slice(0, offset), ...action.payload.response.data],
      total: action.payload.response.total,
      error: null,
    },
  };
};

const handleFetchVisitorsFailure = (
  state: VisitorsState,
  action: ActionType<typeof fetchVisitors.failure>,
): VisitorsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.error,
  },
});

export const visitorsReducer = createReducer(initialState)
  .handleAction(fetchVisitors.request, handleFetchVisitorsRequest)
  .handleAction(fetchVisitors.success, handleFetchVisitorsSuccess)
  .handleAction(fetchVisitors.failure, handleFetchVisitorsFailure);

import { FC, Suspense, lazy } from 'react';
import { Loader } from 'app/components/loader';
import { useSettings } from 'app/components/settings/general-settings/hooks/use-settings.hook';

const GeneralSettingsForm = lazy(
  () => import('app/components/settings/general-settings/general-settings-form/general-settings-form'),
);

export const GeneralSettingsPage: FC = () => {
  const [settings, loading, error] = useSettings();

  if (loading || !settings) {
    return <Loader testId="visitor-management-general-settings-loader" />;
  }

  if (error) {
    return null;
  }

  return (
    <div data-testid="visitor-management-general-settings-container">
      <Suspense fallback={<Loader testId="visitor-management-general-settings-loader" />}>
        <GeneralSettingsForm settings={settings} />
      </Suspense>
    </div>
  );
};

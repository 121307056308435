import { useEffect, useRef } from 'react';
import { useAuthToken } from '../use-auth-token';
import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { FatalError } from './fatal.error';
import { RetriableError } from './retriable.error';

export const useServerSentEvents = (eventSourceUrl: string, eventHandler: (data: string) => void) => {
  const [token, reloadToken] = useAuthToken();
  const abortController = useRef<AbortController>(new AbortController());

  useEffect(() => {
    if (token) {
      abortController.current = new AbortController();
      fetchEventSource(eventSourceUrl, {
        headers: {
          Authorization: token,
        },
        async onopen(response) {
          if (response.ok && response.headers.get('content-type') === EventStreamContentType) {
            return;
          }

          if (response.status === 401) {
            throw new RetriableError(response.status);
          }

          if (response.status >= 400 && response.status < 500 && response.status !== 429) {
            throw new FatalError();
          } else {
            throw new RetriableError(response.status);
          }
        },
        onmessage({ event, data }) {
          if (event === 'FatalError') {
            throw new FatalError(data);
          }

          eventHandler(data);
        },
        onclose() {
          throw new RetriableError();
        },
        onerror(error) {
          if (error instanceof FatalError) {
            throw error;
          }

          if (error instanceof RetriableError && error.status === 401) {
            reloadToken();
          }
        },
        signal: abortController.current.signal,
      });
    }

    return () => {
      abortController.current.abort();
    };
  }, [token, reloadToken, eventSourceUrl, eventHandler]);
};

import {
  createDaypassRequest,
  daypassUserUpdated,
  fetchAllDaypassUsers,
  fetchDaypassUserCredential,
  fetchDaypassUsers,
  setCompanyDaypassUsers,
} from './actions';
import { ActionType, createReducer } from 'typesafe-actions';
import { BuildingDaypassUsersState, DaypassUsersState, DaypassUsersUpdateState } from './state.types';
import { Selector, SetCompanyDaypassUsersDto } from './types';

export const initialState: DaypassUsersState = {};
export const initialBuildingDaypassUsersState: BuildingDaypassUsersState = {
  daypassUsers: [],
  total: 0,
  error: null,
  loading: false,
  daypassUserCredentials: {},
  allDaypassUsers: [],
  daypassUsersUpdateState: {},
  daypassRequestsUserLoading: {},
  totalAllDaypassUsers: 0,
  getAllDaypassUsers: {
    loading: false,
    error: null,
  },
};

const getUpdatedBuildingDaypassUsersState = (
  state: DaypassUsersState,
  buildingUuid: string,
  updates: Partial<BuildingDaypassUsersState>,
): DaypassUsersState => ({
  ...state,
  [buildingUuid]: {
    ...initialBuildingDaypassUsersState,
    ...state[buildingUuid],
    ...updates,
  },
});

const handleFetchDaypassUsersRequest = (
  state: DaypassUsersState,
  action: ActionType<typeof fetchDaypassUsers.request>,
): DaypassUsersState => {
  const offset = action.payload.offset || 0;
  const oldState = state[action.payload.buildingUuid] || initialBuildingDaypassUsersState;

  return getUpdatedBuildingDaypassUsersState(state, action.payload.buildingUuid, {
    daypassUsers: oldState.daypassUsers.slice(0, offset),
    loading: true,
    error: null,
  });
};

const handleFetchDaypassUsersSuccess = (
  state: DaypassUsersState,
  action: ActionType<typeof fetchDaypassUsers.success>,
): DaypassUsersState => {
  const offset = action.payload.params.offset || 0;
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingDaypassUsersState;

  return getUpdatedBuildingDaypassUsersState(state, action.payload.params.buildingUuid, {
    daypassUsers: [...oldState.daypassUsers.slice(0, offset), ...action.payload.response.data.users],
    total: action.payload.response.total,
    loading: false,
    error: null,
  });
};

const handleFetchDaypassUsersFailure = (
  state: DaypassUsersState,
  action: ActionType<typeof fetchDaypassUsers.failure>,
): DaypassUsersState =>
  getUpdatedBuildingDaypassUsersState(state, action.payload.params.buildingUuid, {
    loading: false,
    error: action.payload.response,
  });

const handleFetchAllDaypassUsersRequest = (
  state: DaypassUsersState,
  action: ActionType<typeof fetchAllDaypassUsers.request>,
): DaypassUsersState => {
  const offset = action.payload.offset || 0;
  const oldState = state[action.payload.buildingUuid] || initialBuildingDaypassUsersState;

  return getUpdatedBuildingDaypassUsersState(state, action.payload.buildingUuid, {
    allDaypassUsers: oldState.allDaypassUsers.slice(0, offset),
    getAllDaypassUsers: {
      loading: true,
      error: null,
    },
  });
};

const handleFetchAllDaypassUsersSuccess = (
  state: DaypassUsersState,
  action: ActionType<typeof fetchAllDaypassUsers.success>,
): DaypassUsersState => {
  const offset = action.payload.params.offset || 0;
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingDaypassUsersState;

  return getUpdatedBuildingDaypassUsersState(state, action.payload.params.buildingUuid, {
    allDaypassUsers: [...oldState.allDaypassUsers.slice(0, offset), ...action.payload.response.data.users],
    totalAllDaypassUsers: action.payload.response.total,
    getAllDaypassUsers: {
      loading: false,
      error: null,
    },
  });
};

const handleFetchAllDaypassUsersFailure = (
  state: DaypassUsersState,
  action: ActionType<typeof fetchAllDaypassUsers.failure>,
): DaypassUsersState =>
  getUpdatedBuildingDaypassUsersState(state, action.payload.params.buildingUuid, {
    getAllDaypassUsers: {
      loading: false,
      error: action.payload.response,
    },
  });

const handleFetchDeypassUserCredentialRequest = (
  state: DaypassUsersState,
  action: ActionType<typeof fetchDaypassUserCredential.request>,
): DaypassUsersState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...state[action.payload.buildingUuid],
    daypassUserCredentials: {
      ...state[action.payload.buildingUuid]?.daypassUserCredentials,
      [action.payload.personUuid]: {
        loading: true,
        error: null,
        data: null,
      },
    },
  },
});

const handleFetchDeypassUserCredentialSuccess = (
  state: DaypassUsersState,
  action: ActionType<typeof fetchDaypassUserCredential.success>,
): DaypassUsersState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    daypassUserCredentials: {
      ...state[action.payload.params.buildingUuid]?.daypassUserCredentials,
      [action.payload.params.personUuid]: {
        loading: false,
        error: null,
        data: action.payload.response.data,
      },
    },
  },
});

const handleFetchDeypassUserCredentialFailure = (
  state: DaypassUsersState,
  action: ActionType<typeof fetchDaypassUserCredential.failure>,
): DaypassUsersState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    daypassUserCredentials: {
      ...state[action.payload.params.buildingUuid]?.daypassUserCredentials,
      [action.payload.params.personUuid]: {
        loading: false,
        error: action.payload.error,
        data: null,
      },
    },
  },
});

function getDaypassUsersUpdateLoadingStateFromParams(
  params: SetCompanyDaypassUsersDto,
  loading: boolean,
): DaypassUsersUpdateState {
  if (params.selector === Selector.ALL) {
    return {
      [params.selector]: {
        loading,
        enabled: params.enabled,
      },
    };
  }

  return Object.fromEntries(params.users.map((user) => [user.uuid, { loading, enabled: user.enabled }]));
}

const handleSetCompanyDaypassUsersRequest = (
  state: DaypassUsersState,
  action: ActionType<typeof setCompanyDaypassUsers.request>,
): DaypassUsersState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...state[action.payload.buildingUuid],
    daypassUsersUpdateState: {
      ...state[action.payload.buildingUuid]?.daypassUsersUpdateState,
      ...getDaypassUsersUpdateLoadingStateFromParams(action.payload, true),
    },
  },
});

const handleSetCompanyDaypassUsersRequestSuccess = (
  state: DaypassUsersState,
  action: ActionType<typeof setCompanyDaypassUsers.success>,
): DaypassUsersState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    allDaypassUsers: state[action.payload.params.buildingUuid]?.allDaypassUsers?.map((daypassUser) => {
      if (action.payload.params.selector === Selector.ALL) {
        return {
          ...daypassUser,
          enabled: action.payload.params.enabled,
        };
      }

      const user = action.payload.params.users.find(({ uuid }) => uuid === daypassUser.userUuid);

      if (user) {
        return {
          ...daypassUser,
          enabled: user.enabled,
        };
      }

      return daypassUser;
    }),
    daypassUsersUpdateState: {
      ...state[action.payload.params.buildingUuid]?.daypassUsersUpdateState,
      ...getDaypassUsersUpdateLoadingStateFromParams(action.payload.params, false),
    },
  },
});

const handleSetCompanyDaypassUsersRequestFailure = (
  state: DaypassUsersState,
  action: ActionType<typeof setCompanyDaypassUsers.failure>,
): DaypassUsersState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    daypassUsersUpdateState: {
      ...state[action.payload.params.buildingUuid]?.daypassUsersUpdateState,
      ...getDaypassUsersUpdateLoadingStateFromParams(action.payload.params, false),
    },
  },
});

const handleCreateDaypassRequest = (
  state: DaypassUsersState,
  action: ActionType<typeof createDaypassRequest.request>,
): DaypassUsersState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...state[action.payload.buildingUuid],
    daypassRequestsUserLoading: {
      ...state[action.payload.buildingUuid]?.daypassRequestsUserLoading,
      [action.payload.personUuid]: true,
    },
  },
});

const handleCreateDaypassRequestSuccess = (
  state: DaypassUsersState,
  action: ActionType<typeof createDaypassRequest.success>,
): DaypassUsersState => {
  const buildingState = state[action.payload.params.buildingUuid] || initialBuildingDaypassUsersState;

  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...buildingState,
      daypassUsers: buildingState.daypassUsers.map((daypassUser) => {
        if (daypassUser.uuid === action.payload.params.personUuid) {
          return {
            ...daypassUser,
            daypassStatus: action.payload.response.data.status,
          };
        }

        return daypassUser;
      }),
      daypassRequestsUserLoading: {
        ...buildingState.daypassRequestsUserLoading,
        [action.payload.params.personUuid]: false,
      },
    },
  };
};

const handleCreateDaypassRequestFailure = (
  state: DaypassUsersState,
  action: ActionType<typeof createDaypassRequest.failure>,
): DaypassUsersState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    daypassRequestsUserLoading: {
      ...state[action.payload.params.buildingUuid]?.daypassRequestsUserLoading,
      [action.payload.params.personUuid]: false,
    },
  },
});

const handleDaypassUserUpdated = (
  state: DaypassUsersState,
  action: ActionType<typeof daypassUserUpdated>,
): DaypassUsersState => {
  const { buildingUuid } = action.payload.request;
  const buildingState = state[buildingUuid] || initialBuildingDaypassUsersState;

  return {
    ...state,
    [buildingUuid]: {
      ...buildingState,
      daypassUsers: buildingState.daypassUsers.map((daypassUser) => {
        if (daypassUser.uuid === action.payload.person.uuid) {
          return {
            ...daypassUser,
            daypassStatus: action.payload.request.status,
          };
        }

        return daypassUser;
      }),
    },
  };
};

export const daypassUsersReducer = createReducer(initialState)
  .handleAction(fetchDaypassUsers.request, handleFetchDaypassUsersRequest)
  .handleAction(fetchDaypassUsers.success, handleFetchDaypassUsersSuccess)
  .handleAction(fetchDaypassUsers.failure, handleFetchDaypassUsersFailure)

  .handleAction(fetchAllDaypassUsers.request, handleFetchAllDaypassUsersRequest)
  .handleAction(fetchAllDaypassUsers.success, handleFetchAllDaypassUsersSuccess)
  .handleAction(fetchAllDaypassUsers.failure, handleFetchAllDaypassUsersFailure)

  .handleAction(fetchDaypassUserCredential.request, handleFetchDeypassUserCredentialRequest)
  .handleAction(fetchDaypassUserCredential.success, handleFetchDeypassUserCredentialSuccess)
  .handleAction(fetchDaypassUserCredential.failure, handleFetchDeypassUserCredentialFailure)

  .handleAction(setCompanyDaypassUsers.request, handleSetCompanyDaypassUsersRequest)
  .handleAction(setCompanyDaypassUsers.success, handleSetCompanyDaypassUsersRequestSuccess)
  .handleAction(setCompanyDaypassUsers.failure, handleSetCompanyDaypassUsersRequestFailure)

  .handleAction(createDaypassRequest.request, handleCreateDaypassRequest)
  .handleAction(createDaypassRequest.success, handleCreateDaypassRequestSuccess)
  .handleAction(createDaypassRequest.failure, handleCreateDaypassRequestFailure)

  .handleAction(daypassUserUpdated, handleDaypassUserUpdated);

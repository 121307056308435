export interface VisitorPassInfo {
  status: VisitorPassStatus;
  active: boolean;
  passUuid: string;
  visitUuid: string;
  visitParticipantUuid: string;
}

export interface FetchVisitorPassesParams {
  buildingUuid: string;
  visitUuids: string[];
}

export interface FetchVisitorPassesResponse {
  buildingUuid: string;
  data: VisitorPassInfo[];
}

export interface FetchVisitorPassesSuccessDto {
  params: FetchVisitorPassesParams;
  response: FetchVisitorPassesResponse;
}

export interface FetchVisitorPassesErrorDto {
  params: FetchVisitorPassesParams;
  response: Error;
}

export interface RequestState {
  loading: boolean;
  error: Error | null;
}

export interface VisitorPassesState {
  passesMap?: Record<string, VisitorPassInfo>;
  fetchVisitorPassesRequest?: RequestState;
  activateVisitorRequest?: RequestState;
}

export type PassesState = Record<string, VisitorPassesState>;

export enum VisitorPassAction {
  activate = 'ACTIVATE',
  regenerate = 'REGENERATE',
}

export interface ChangeVisitorPassStatusParams {
  buildingUuid: string;
  visitorPassUuid: string;
  action: VisitorPassAction;
}

export interface ChangeVisitorPassStatusSuccessDto {
  params: ChangeVisitorPassStatusParams;
}

export interface ChangeVisitorPassesStatusErrorDto {
  params: ChangeVisitorPassStatusParams;
  response: Error;
}

export enum VisitorPassStatus {
  PENDING = 'PENDING',
  GENERATING = 'GENERATING',
  GENERATED = 'GENERATED',
  ERROR = 'ERROR',
  NO_PASS = 'NO_PASS',
  REVOKED = 'REVOKED',
}

export enum VisitorPassActivationStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

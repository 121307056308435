import { FC, lazy, Suspense } from 'react';
import { Outlet } from 'react-router';
import { Loader } from 'app/components/loader';
import { TabPageLayout } from 'app/components/visitor-management-tabs/tab-page-layout';

const WatchlistContainer = lazy(() => import('app/components/watchlist/watchlist.container'));

export const WatchlistPage: FC = () => (
  <TabPageLayout data-testid="watchlist-page">
    <Suspense fallback={<Loader testId="watchlist-page-loader" />}>
      <WatchlistContainer />
    </Suspense>
    <Outlet />
  </TabPageLayout>
);

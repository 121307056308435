import { useSelector } from 'react-redux';
import { localeSelector } from '../../../store/config/selectors';
import { useMemo } from 'react';

export const useCountry = () => {
  const locale = useSelector(localeSelector) ?? navigator.language;
  const [, country] = useMemo(() => locale?.split(/_|-/) ?? [], [locale]);

  return country;
};

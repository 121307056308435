import { format, Locale, isFuture, startOfDay, endOfDay } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import * as Locales from 'date-fns/locale';

export const DATE_FORMAT_UI = 'MMMM d, yyyy';
export const DATE_FORMAT_WITH_WEEK_DAY = 'eeee, MMMM d, yyyy';
export const DATE_TIME_FORMAT = 'Pp';

/**
 * Represents the keys of the available locales in the Locales object.
 */
type LocaleKeys = keyof typeof Locales;

/**
 * Retrieves a locale object from the `Locales` dictionary based on the provided locale key or string.
 * If no locale is found, it falls back to the 'enUS' locale.
 *
 * @param {LocaleKeys|string} [locale='en'] the locale key or string to retrieve the locale for.
 * @returns {Locale} the locale object for the specified key or string.
 */
export const getDateFnsLocale = (locale: LocaleKeys | string = 'en'): Locale => {
  const isLocaleKey = (str: string): str is LocaleKeys => str in Locales;

  if (isLocaleKey(locale)) {
    return Locales[locale];
  }

  const normalizedLocale = locale.replace(/[_-]/, '');
  if (isLocaleKey(normalizedLocale)) {
    return Locales[normalizedLocale];
  }

  const localeCode = locale.substring(0, 2);
  if (isLocaleKey(localeCode)) {
    return Locales[localeCode];
  }

  return Locales.enUS;
};

export const formatDateForUI = (date: Date, locale: string, pattern: string = DATE_FORMAT_UI): string =>
  format(date, pattern, { locale: getDateFnsLocale(locale) });

export const sortDateAsc = (dates: Date[]) => dates.sort((a, b) => a.getTime() - b.getTime());

export const futureDateMatcher = (date: Date): boolean => {
  const checkedDate = typeof date === 'string' ? new Date(date) : date;
  return isFuture(checkedDate);
};

export const convertToStartOfDayInTargetTimeZone = (date: Date | string | number, timeZone: string) => {
  const starOfDay = startOfDay(new Date(date));
  return zonedTimeToUtc(starOfDay, timeZone);
};

export const convertToEndOfDayInTargetTimeZone = (date: Date | string | number, timeZone: string) => {
  const starOfDay = endOfDay(new Date(date));
  return zonedTimeToUtc(starOfDay, timeZone);
};

import { fetchVisitors } from 'app/store/visitors/actions';
import { toast } from 'react-toastify';
import { Epic } from 'redux-observable';
import { catchError, filter, map, mergeMap, of, withLatestFrom } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { isDefined } from '../../shared/utils/is-defined';

export const fetchVisitorsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitors.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      apiClient(state)
        .fetchVisitors({
          buildingUuids: [action.payload.buildingUuid],
          companyUuids: [action.payload.companyUuid].filter(isDefined),
          hostUserUuids: [action.payload.hostUserUuid].filter(isDefined),
          query: action.payload.query,
          limit: action.payload.limit,
          offset: action.payload.offset,
        })
        .pipe(
          map((response) => fetchVisitors.success({ params: action.payload, response })),
          catchError((error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitors.fetch.error' }));

            return of(fetchVisitors.failure({ params: action.payload, error }));
          }),
        ),
    ),
  );

import { createAsyncAction } from 'typesafe-actions';
import {
  FetchDaypassCompaniesModePayloadDto,
  FetchDaypassCompaniesModeSuccessDto,
  FetchDaypassCompaniesModeFailureDto,
  SaveDaypassModePayloadDto,
  SaveDaypassModeSuccessDto,
  SaveDaypassModeFailureDto,
} from './types';

export const fetchDaypassCompaniesMode = createAsyncAction(
  'DAYPASS_COMPANIES_MODE/FETCH_REQUEST',
  'DAYPASS_COMPANIES_MODE/FETCH_SUCCESS',
  'DAYPASS_COMPANIES_MODE/FETCH_FAILURE',
)<FetchDaypassCompaniesModePayloadDto, FetchDaypassCompaniesModeSuccessDto, FetchDaypassCompaniesModeFailureDto>();

export const saveDaypassMode = createAsyncAction(
  'DAYPASS_COMPANIES_MODE/SAVE_REQUEST',
  'DAYPASS_COMPANIES_MODE/SAVE_SUCCESS',
  'DAYPASS_COMPANIES_MODE/SAVE_FAILURE',
)<SaveDaypassModePayloadDto, SaveDaypassModeSuccessDto, SaveDaypassModeFailureDto>();

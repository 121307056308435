import { useField } from 'formik';
import { FC, useCallback } from 'react';
import { Time } from 'app/shared/types/time';
import { FieldWrapper } from '../field-wrapper';

import { TimePickerInput } from './time-select-base.styles';

interface VisitTimeSelectBaseProps {
  label: string;
  placeholder: string;
  fieldName: string;
  required?: boolean;
  is12h: boolean;
  min?: Time;
  max?: Time;
}

const TIME_PICKER_STEP = 15;

export const TimeSelectBase: FC<VisitTimeSelectBaseProps> = ({
  label,
  placeholder,
  fieldName,
  required,
  is12h,
  min,
  max,
}) => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<Time>(fieldName);

  const handleChange = useCallback(
    (newValue: string) => {
      const isTouched = true;
      const shouldValidateOnTouch = false;

      setTouched(isTouched, shouldValidateOnTouch);
      setValue(newValue as Time);
    },
    [setValue, setTouched],
  );

  return (
    <FieldWrapper touched={touched} error={error}>
      <TimePickerInput
        is12h={is12h}
        value={value}
        onChange={handleChange}
        minuteStep={TIME_PICKER_STEP}
        label={label}
        required={required}
        placeholder={placeholder}
        variant={touched && error ? 'error' : 'normal'}
        min={min}
        max={max}
        dataTestId={`${fieldName}-picker`}
      />
    </FieldWrapper>
  );
};

import { useVisitUuidParam } from 'app/components/visits/hooks/use-visit-uuid-param.hook';
import { FormValues } from 'app/components/visits/visit-form/visit-form.interfaces';
import { timezoneSelector } from 'app/store/building/selectors';
import { updateVisitForCurrentBuilding } from 'app/store/visits/actions';
import { updateVisitLoadingSelector } from 'app/store/visits/selectors';
import { UpdateVisitDtoWithUuid } from 'app/store/visits/types';
import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDates } from '../creation/use-form-submit-handler.hook';

const mapFormValuesToDto = (
  visitUuid: string,
  values: Pick<FormValues, 'endDate' | 'visitDateTimes' | 'description'>,
  timezone: string,
): UpdateVisitDtoWithUuid => ({
  visitUuid,
  visit: {
    ...getDates(values.visitDateTimes[0], values.endDate, timezone),
    description: values.description ?? undefined,
  },
});

export const useFormSubmitHandler = () => {
  const dispatch = useDispatch();
  const timezone = useSelector(timezoneSelector);
  const loading = useSelector(updateVisitLoadingSelector);
  const visitUuid = useVisitUuidParam();

  const handleSubmit = useCallback(
    (values: Pick<FormValues, 'endDate' | 'visitDateTimes' | 'description'>) => {
      dispatch(updateVisitForCurrentBuilding(mapFormValuesToDto(visitUuid, values, timezone)));
    },
    [timezone, dispatch],
  );

  return { loading, handleSubmit };
};

import { createSelector } from 'reselect';
import { RootState } from 'app/store/reducer';
import { buildingUuidSelector } from 'app/store/building/selectors';

const visitrGroupsByBuildingState = (state: RootState) => state.visitorGroups;

export const visitorGroupsState = createSelector(
  [buildingUuidSelector, visitrGroupsByBuildingState],
  (buildingUuid, visitorGroupsByBuilding) => visitorGroupsByBuilding?.[buildingUuid] ?? null,
);

export const visitorGroupsSelector = createSelector(
  visitorGroupsState,
  (state) => state?.visitorGroups ?? [],
);

export const visitorGroupsLoadingSelector = createSelector(
  visitorGroupsState,
  (state) => state?.loading ?? false,
);

export const visitorGroupsErrorSelector = createSelector(
  visitorGroupsState,
  (state) => state?.error ?? null,
);

export const visitorGroupsTotalSelector = createSelector(
  visitorGroupsState,
  (state) => state?.total ?? 0,
);

export const visitorGroupSelector = createSelector(
  visitorGroupsState,
  (state) => state?.visitorGroupDetails ?? null,
);

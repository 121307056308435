import { fetchDaypassCompanies } from './actions';
import { BuildingDaypassCompaniesState, DaypassCompaniesState } from './types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: DaypassCompaniesState = {};
export const initialBuildingDaypassCompaniesState: BuildingDaypassCompaniesState = {
  daypassCompanies: null,
  error: null,
  loading: false,
};

const handleFetchDaypassCompaniesRequest = (
  state: DaypassCompaniesState,
  action: ActionType<typeof fetchDaypassCompanies.request>,
): DaypassCompaniesState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingDaypassCompaniesState,
    ...state[action.payload.buildingUuid],
    loading: true,
    error: null,
  },
});

const handleFetchDaypassCompaniesSuccess = (
  state: DaypassCompaniesState,
  action: ActionType<typeof fetchDaypassCompanies.success>,
): DaypassCompaniesState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    daypassCompanies: action.payload.response.data.companies,
    loading: false,
    error: null,
  },
});

const handleFetchDaypassCompaniesFailure = (
  state: DaypassCompaniesState,
  action: ActionType<typeof fetchDaypassCompanies.failure>,
): DaypassCompaniesState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...initialBuildingDaypassCompaniesState,
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

export const daypassCompaniesReducer = createReducer(initialState)
  .handleAction(fetchDaypassCompanies.request, handleFetchDaypassCompaniesRequest)
  .handleAction(fetchDaypassCompanies.success, handleFetchDaypassCompaniesSuccess)
  .handleAction(fetchDaypassCompanies.failure, handleFetchDaypassCompaniesFailure);

import { hasDayPassEnabledSelector } from 'app/store/building-settings/selectors';
import { RootState } from 'app/store/reducer';
import { createSelector } from 'reselect';

export const configSelector = (state: RootState) => state.config;

export const localeSelector = createSelector(configSelector, (config) => config.locale);
export const rootPathSelector = createSelector(configSelector, (config) => config.rootPath);
export const publicUrlSelector = createSelector(configSelector, (config) => config.publicUrl);
export const getAuthTokenSelector = createSelector(configSelector, (config) => config.getAuthToken);
export const apiUrlSelector = createSelector(configSelector, (config) => config.apiUrl);
export const enableVisitsRegistrationSelector = createSelector(
  configSelector,
  (config) => config.enableVisitsRegistration ?? false,
);
export const showVisRegSettingsPageSelector = createSelector(
  configSelector,
  (config) => config.showVisRegSettingsPage || true,
);
export const showVisitorsTypeAheadSelector = createSelector(
  configSelector,
  (config) => config.showVisitorsTypeAhead ?? false,
);

export const showAccessGroupsSettingsSelector = createSelector(
  configSelector,
  (config) => config.showAccessGroupsSettings ?? false,
);

export const showVisitorsTabSelector = createSelector(configSelector, (config) => config.showVisitorsTab ?? false);

export const showAdminNav51Selector = createSelector(configSelector, (config) => config.showAdminNav51 ?? false);

export const showDaypassTabSelector = createSelector(
  configSelector,
  (config) => config.showVisitorRegistrationDaypassTab ?? false,
);

export const showWatchlistFeatureSelector = createSelector(
  configSelector,
  (config) => config.showWatchlistFeature ?? false,
);

export const showVisitorRegistrationGroupsTabSelector = createSelector(
  configSelector,
  (config) => config.showVisitorRegistrationGroupsTab ?? false,
);

export const hasDaypassFeatureEnabledSelector = createSelector(
  [showDaypassTabSelector, hasDayPassEnabledSelector],
  (showDaypassTab, hasDaypassFeatureEnabled) => showDaypassTab && hasDaypassFeatureEnabled,
);

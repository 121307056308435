import { FC } from 'react';
import { useAppBootstrap } from './use-app-bootstrap.hook';
import { Loader } from 'app/components/loader';
import { AppRouter } from '../router';
import { SplashScreen } from './splash-screen';

export const AppRoot: FC = () => {
  const [isLoading, isAppConfigured] = useAppBootstrap();

  if (isLoading) {
    return <Loader testId="permissions-loader" />;
  }

  return isAppConfigured ? <AppRouter /> : <SplashScreen />;
};

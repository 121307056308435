import { fetchHostVisits } from 'app/store/host-visits/actions';
import { HostVisitsByBuildingState, HostVisitsState } from 'app/store/host-visits/types';
import { ActionType, createReducer } from 'typesafe-actions';

export const initialState: HostVisitsByBuildingState = {};
export const initialHostVisitsState: HostVisitsState = {
  visits: [],
  total: 0,
  loading: false,
  error: null,
};

const handleFetchVisitsRequest = (
  state: HostVisitsByBuildingState,
  action: ActionType<typeof fetchHostVisits.request>,
): HostVisitsByBuildingState => {
  const offset = action.payload.offset || 0;
  const buildingState = state[action.payload.buildingUuid] || {};
  const oldState = buildingState?.[action.payload.hostUuid] || initialHostVisitsState;

  return {
    ...state,
    [action.payload.buildingUuid]: {
      ...buildingState,
      [action.payload.hostUuid]: {
        ...initialHostVisitsState,
        ...oldState,
        visits: [...oldState.visits.slice(0, offset)],
        loading: true,
        error: null,
      },
    },
  };
};

const handleFetchVisitsSuccess = (
  state: HostVisitsByBuildingState,
  action: ActionType<typeof fetchHostVisits.success>,
): HostVisitsByBuildingState => {
  const offset = action.payload.params.offset || 0;
  const buildingState = state[action.payload.params.buildingUuid] || {};
  const oldState = buildingState?.[action.payload.params.hostUuid] || initialHostVisitsState;

  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...buildingState,
      [action.payload.params.hostUuid]: {
        loading: false,
        visits: [...oldState.visits.slice(0, offset), ...action.payload.response.data],
        total: action.payload.response.total,
        error: null,
      },
    },
  };
};

const handleFetchVisitsFailure = (
  state: HostVisitsByBuildingState,
  action: ActionType<typeof fetchHostVisits.failure>,
): HostVisitsByBuildingState => {
  const buildingState = state[action.payload.params.buildingUuid] || {};
  const oldState = buildingState?.[action.payload.params.hostUuid] || initialHostVisitsState;

  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...buildingState,
      [action.payload.params.hostUuid]: {
        ...oldState,
        loading: false,
        error: action.payload.error,
      },
    },
  };
};

export const hostVisitsReducer = createReducer(initialState)
  .handleAction(fetchHostVisits.request, handleFetchVisitsRequest)
  .handleAction(fetchHostVisits.success, handleFetchVisitsSuccess)
  .handleAction(fetchHostVisits.failure, handleFetchVisitsFailure);

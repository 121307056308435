import { reInitializeState } from 'app/store/initial-state/actions';
import { UserState } from 'app/store/user/types';
import { createReducer } from 'typesafe-actions';

export const initialState: UserState = {
  uuid: '',
  company: {
    uuid: '',
  },
  first_name: '',
  last_name: '',
  email: '',
};

const handleReInitializeState = (_state: UserState, { payload }: ReturnType<typeof reInitializeState>): UserState =>
  payload.user;

export const userReducer = createReducer<UserState>(initialState).handleAction(
  reInitializeState,
  handleReInitializeState,
);

import { BuildingVisitsFiltersState, FilterOptionResponseDto, VisitsFilterState } from 'app/store/visits-filters/types';
import { ActionType, createReducer } from 'typesafe-actions';
import { fetchVisitsCompanyFilterOptions, fetchVisitsGroupFilterOptions, fetchVisitsHostFilterOptions } from 'app/store/visits-filters/actions';

export const initialState : VisitsFilterState = {};
export const initialBuildingVisitsFilterState: BuildingVisitsFiltersState = {
  hosts: [],
  companies: [],
  groups: {
    data: [],
    total: 0,
    offset: 0,
    limit: 0,
  },
};

function mapFilterOptions(options: FilterOptionResponseDto[]) {
  return options.map(({ name, uuid }) => ({
    label: name,
    value: uuid,
  }));
}

const handleFiltersRequest = (state: VisitsFilterState, action: ActionType<typeof fetchVisitsCompanyFilterOptions.request | typeof fetchVisitsHostFilterOptions.request | typeof fetchVisitsGroupFilterOptions.request>) : VisitsFilterState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialBuildingVisitsFilterState,
  },
});

const handleCompanyFiltersSuccess = (state: VisitsFilterState, action: ActionType<typeof fetchVisitsCompanyFilterOptions.success>) : VisitsFilterState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitsFilterState;
  const options = action.payload.response;
  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...oldState,
      companies: mapFilterOptions(options),
    },
  };
};

const handleCompanyFiltersFailure = (state: VisitsFilterState, action: ActionType<typeof fetchVisitsCompanyFilterOptions.failure>) : VisitsFilterState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitsFilterState;
  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...oldState,
      companies: [],
    },
  };
};

const handleHostsFiltersSuccess = (state: VisitsFilterState, action: ActionType<typeof fetchVisitsHostFilterOptions.success>) : VisitsFilterState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitsFilterState;
  const options = action.payload.response;
  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...oldState,
      hosts: mapFilterOptions(options),
    },
  };
};

const handleHostsFiltersFailure = (state: VisitsFilterState, action: ActionType<typeof fetchVisitsHostFilterOptions.failure>) : VisitsFilterState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitsFilterState;
  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...oldState,
      hosts: [],
    },
  };
};

const handleGroupsFiltersSuccess = (state: VisitsFilterState, action: ActionType<typeof fetchVisitsGroupFilterOptions.success>) : VisitsFilterState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitsFilterState;
  const options = action.payload.response.data;
  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...oldState,
      groups: {
        data: mapFilterOptions(options),
        total: action.payload.response.total,
        offset: action.payload.response.offset,
        limit: action.payload.response.limit,
      },
    },
  };
};

const handleGroupsFiltersFailure = (state: VisitsFilterState, action: ActionType<typeof fetchVisitsGroupFilterOptions.failure>) : VisitsFilterState => {
  const oldState = state[action.payload.params.buildingUuid] || initialBuildingVisitsFilterState;
  return {
    ...state,
    [action.payload.params.buildingUuid]: {
      ...oldState,
      groups: {
        data: [],
        total: 0,
        offset: 0,
        limit: 0,
      },
    },
  };
};

export const visitsFiltersReducer = createReducer(initialState)
  .handleAction(fetchVisitsCompanyFilterOptions.request, handleFiltersRequest)
  .handleAction(fetchVisitsCompanyFilterOptions.success, handleCompanyFiltersSuccess)
  .handleAction(fetchVisitsCompanyFilterOptions.failure, handleCompanyFiltersFailure)
  .handleAction(fetchVisitsHostFilterOptions.request, handleFiltersRequest)
  .handleAction(fetchVisitsHostFilterOptions.success, handleHostsFiltersSuccess)
  .handleAction(fetchVisitsHostFilterOptions.failure, handleHostsFiltersFailure)
  .handleAction(fetchVisitsGroupFilterOptions.request, handleFiltersRequest)
  .handleAction(fetchVisitsGroupFilterOptions.success, handleGroupsFiltersSuccess)
  .handleAction(fetchVisitsGroupFilterOptions.failure, handleGroupsFiltersFailure);

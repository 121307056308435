import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useQueryParam } from 'app/shared/hooks/use-query-param';
import { filtersCompanyOptionsSelector, filtersHostOptionsSelector } from 'app/store/visits-filters/selectors';
import { VisitFilterParams } from 'app/shared/consts/visit-filter-params';
import { FilterOption } from 'app/shared/types/filter-option';

export interface HostFiltersQueryDto {
  hostUuid?: string;
  companyUuid?: string;
}

export interface HostFilters {
  filters: HostFiltersQueryDto;
  setHostUuid: (value?: string) => void;
  setCompanyUuid: (value?: string) => void;
}

const getValueOrUndefined = (options: FilterOption[], value: string) => {
  const hasValue = options.some(({ value: uuid }) => uuid === value);
  return hasValue ? value : undefined;
};

export const useHostFilters = (): HostFilters => {
  const [hostUuid, setHostUuid] = useQueryParam(VisitFilterParams.HOST_UUID);
  const [companyUuid, setCompanyUuid] = useQueryParam(VisitFilterParams.COMPANY_UUID);
  const companyOptions = useSelector(filtersCompanyOptionsSelector);
  const hostOptions = useSelector(filtersHostOptionsSelector);

  const filters: HostFiltersQueryDto = useMemo(
    () => ({
      hostUuid: getValueOrUndefined(hostOptions, hostUuid),
      companyUuid: getValueOrUndefined(companyOptions, companyUuid),
    }),
    [hostUuid, companyUuid, hostOptions, companyOptions],
  );

  return useMemo(
    () => ({
      filters,
      setHostUuid,
      setCompanyUuid,
    }),
    [filters, setHostUuid, setCompanyUuid],
  );
};

import { useFieldTranslations } from 'app/shared/hooks/use-field-translations';
import { useField } from 'formik';
import { useCallback, useState } from 'react';

export const useSearchField = (searchHost: (searchTerm: string) => void) => {
  const [_, { error, touched }] = useField('host');
  const { label, placeholder } = useFieldTranslations('host');

  const [searchText, setSearchText] = useState<string>('');

  const handleSearch = useCallback(
    (value: string) => {
      searchHost(value);
      setSearchText(value);
    },
    [searchHost, setSearchText],
  );

  return { searchText, handleSearch, label, placeholder, touched, error };
};

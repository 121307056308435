import { useDispatch, useSelector } from 'react-redux';
import { pendingWatchlistModalPeopleSelector } from 'app/store/watchlist/selectors';
import type { PendingWatchlistModalRecord } from 'app/store/watchlist/types';
import { useCallback } from 'react';
import { setPeopleForPendingWatchlistModal } from 'app/store/watchlist/actions';
import { buildingUuidSelector } from 'app/store/building/selectors';

export interface UseVisitPendingModalResponse {
  closeModal: VoidFunction;
  isOpen: boolean;
  pendingPeople: PendingWatchlistModalRecord[];
}

export const useVisitPendingModal = (): UseVisitPendingModalResponse => {
  const dispatch = useDispatch();

  const pendingPeople: PendingWatchlistModalRecord[] = useSelector(pendingWatchlistModalPeopleSelector);
  const isOpen: boolean = !!pendingPeople?.length;
  const buildingUuid: string = useSelector(buildingUuidSelector);

  const closeModal = useCallback(() => {
    dispatch(setPeopleForPendingWatchlistModal({ buildingUuid, pendingWatchlistModalPeople: [] }));
  }, [buildingUuid, dispatch]);

  return {
    closeModal,
    isOpen,
    pendingPeople,
  };
};

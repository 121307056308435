import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-grow: 1;

  .search-input-container {
    width: 100%;
  }

  .container {
    width: auto;
  }
`;

import { useQueryParam } from 'app/shared/hooks/use-query-param';
import { useMemo } from 'react';
import { FiltersQueryDto, VisitStatus } from 'app/store/visits/types';
import { useHostFilters } from 'app/shared/hooks/use-host-filters';
import { useVisitorGroupFilters } from 'app/shared/hooks/use-visitor-group-filters';
import { useFilterResetOnContextSwitch } from 'app/shared/hooks/use-host-filters/use-filter-reset-on-context-switch.hook';
import { VisitFilterParams } from 'app/shared/consts/visit-filter-params';

const visitStatusLookupMap: Record<string, VisitStatus | undefined> = Object.entries(VisitStatus).reduce(
  (lookup, [k, v]) => ({ ...lookup, [k]: v }),
  {},
);

export interface VisitFilters {
  filters: FiltersQueryDto;
  setStatus: (value: string) => void;
  setHostUuid: (value: string) => void;
  setCompanyUuid: (value: string) => void;
  setGroupUuid: (value: string) => void;
}

const filterParams = Object.values(VisitFilterParams);

export const useVisitsFilters = (): VisitFilters => {
  const [status, setStatus] = useQueryParam(VisitFilterParams.STATUS);
  const hostFilters = useHostFilters();
  const visitorGroupFilters = useVisitorGroupFilters();

  const filters: FiltersQueryDto = useMemo(
    () => ({
      status: visitStatusLookupMap[status],
      ...hostFilters.filters,
      ...visitorGroupFilters.filters,
    }),
    [status, hostFilters.filters, visitorGroupFilters.filters],
  );

  useFilterResetOnContextSwitch(filterParams);

  return useMemo(
    () => ({
      filters,
      setStatus,
      setHostUuid: hostFilters.setHostUuid,
      setCompanyUuid: hostFilters.setCompanyUuid,
      setGroupUuid: visitorGroupFilters.setGroupUuid,
    }),
    [filters, setStatus, hostFilters.setHostUuid, hostFilters.setCompanyUuid, visitorGroupFilters.setGroupUuid],
  );
};

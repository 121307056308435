import { useStore } from 'app/store';
import { InitialStateOptions } from 'app/store/initial-state/types';
import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

export const ReduxProvider: FC<PropsWithChildren<InitialStateOptions>> = ({
  children,
  apiUrl,
  getAuthToken,
  publicUrl,
  locale,
  user,
  building,
  rootPath,
  featureFlags,
}) => {
  const store = useStore({
    apiUrl,
    publicUrl,
    getAuthToken,
    locale,
    user,
    building,
    rootPath,
    featureFlags,
  });

  return <Provider store={store}>{children}</Provider>;
};

import { locationInfoSelector } from 'app/store/location-info/selectors';
import { useSelector } from 'react-redux';

export const useLocationInfo = () => {
  const data = useSelector(locationInfoSelector);

  return {
    floors: data?.floors ?? null,
    visitTypes: data?.visit_types ?? null,
  };
};

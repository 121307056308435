import { BuildingState } from 'app/store/building/types';
import { reInitializeState } from 'app/store/initial-state/actions';
import { createReducer } from 'typesafe-actions';

export const initialState: BuildingState = {
  uuid: '',
  timezone: 'UTC',
  name: '',
};

const handleReInitializeState = (
  _state: BuildingState,
  { payload }: ReturnType<typeof reInitializeState>,
): BuildingState => payload.building;

export const buildingReducer = createReducer<BuildingState>(initialState).handleAction(
  reInitializeState,
  handleReInitializeState,
);

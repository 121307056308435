import { useClient } from 'app/shared/hooks/use-client';
import { Visitor } from 'app/store/visitor/types';
import { userSelector } from 'app/store/user/selectors';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { catchError, map, of } from 'rxjs';

export type FetchVisitors = (query: string) => void;

export const useFetchVisitors = () => {
  const { formatMessage } = useIntl();
  const companyUuid = useSelector(userSelector)?.company?.uuid;
  const client = useClient();
  const [visitors, setVisitors] = useState<Visitor[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchVisitors = useCallback(
    (query: string) => {
      setIsLoading(true);

      client
        .fetchVisitors({ query, companyUuids: [companyUuid] })
        .pipe(
          map((response) => {
            setVisitors(response.data);
            setIsLoading(false);
          }),
          catchError((error: Error) => {
            toast.error(formatMessage({ id: 'notifications.visitors.fetch.error' }));
            setIsLoading(false);
            return of(error.message);
          }),
        )
        .subscribe();
    },
    [setIsLoading, setVisitors, client, companyUuid],
  );

  return { visitors, setVisitors, isLoading, setIsLoading, fetchVisitors };
};

import { FC, lazy, Suspense } from 'react';
import { useParams } from 'react-router-dom';
import { ENTITY_UUID_PARAMETER } from 'app/shared/consts';
import { VisitsTableDateFilter } from 'app/components/details/visitor/table/visits-table.consts';
import { Loader } from 'app/components/loader';

interface VisitorVisitsPageProps {
  dateFilterKey: VisitsTableDateFilter;
}

const VisitsTable = lazy(() => import('app/components/details/visitor/table/visits-table.container'));

export const VisitorVisitsPage: FC<VisitorVisitsPageProps> = ({ dateFilterKey }) => {
  const params = useParams();
  const visitorUuid = params[ENTITY_UUID_PARAMETER] as string;

  return (
    <Suspense fallback={<Loader testId="visitor-visits-loader" />}>
      <VisitsTable dateFilterKey={dateFilterKey} visitorUuid={visitorUuid} />
    </Suspense>
  );
};

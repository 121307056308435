import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { Epic } from 'redux-observable';
import { EMPTY, catchError, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { toast } from 'react-toastify';
import {
  fetchAccessGroups,
  fetchAccessGroupsMappingRules,
  updateAccessGroupsMappingRules,
} from 'app/store/access-groups/actions';
import { rootPathSelector } from 'app/store/config/selectors';
import { VISITS_PATH } from 'app/shared/consts';

export const fetchAccessGroupsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchAccessGroups.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .fetchAccessGroups(action.payload)
        .pipe(
          map((response) => fetchAccessGroups.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.accessGroups.fetch.error' }));

            return of(fetchAccessGroups.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const fetchAccessGroupsMappingRulesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchAccessGroupsMappingRules.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .fetchAccessGroupsMappingRules(action.payload)
        .pipe(
          map((response) => fetchAccessGroupsMappingRules.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.accessGroups.mappingRules.fetch.error' }));

            return of(fetchAccessGroupsMappingRules.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const updateAccessGroupsMappingRulesEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(updateAccessGroupsMappingRules.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .updateAccessGroupsMappingRules(action.payload)
        .pipe(
          map(({ response }) => {
            toast.success(intl.formatMessage({ id: 'notifications.accessGroups.mappingRules.update.success' }));

            return updateAccessGroupsMappingRules.success({ params: action.payload, response });
          }),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.accessGroups.mappingRules.update.error' }));

            return of(updateAccessGroupsMappingRules.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const updateAccessGroupsMappingRulesSuccessEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { navigate },
) =>
  action$.pipe(
    filter(isActionOf(updateAccessGroupsMappingRules.success)),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const rootPath = rootPathSelector(state);

      navigate(`${rootPath}/${VISITS_PATH}`);

      return EMPTY;
    }),
  );

import { useUserPermissionCheck } from 'app/store/user-permissions/hooks';
import { Permission } from 'app/store/user-permissions/types';
import { userSelector } from 'app/store/user/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

interface VisitScopeFilters<H, T, V> {
  hostUuid: H;
  companyUuid: T;
  groupUuid?: V;
}

export const useVisitScopeFilters = <H, T, V>(
  filters: VisitScopeFilters<H, T, V>,
): VisitScopeFilters<H | string, T | string, V | string> => {
  const user = useSelector(userSelector);
  const canSeeAllVisits = useUserPermissionCheck(Permission.VIEW_BUILDING_VISITORS);
  const canSeeAllTenantVisits = useUserPermissionCheck(Permission.VIEW_TENANT_VISITORS);

  const currentHostUuid = user.uuid;
  const currentCompanyUuid = user.company?.uuid ?? null;

  return useMemo(
    () => ({
      hostUuid: canSeeAllVisits || canSeeAllTenantVisits ? filters.hostUuid : currentHostUuid,
      companyUuid: canSeeAllVisits ? filters.companyUuid : currentCompanyUuid,
      groupUuid: filters.groupUuid,
    }),
    [
      canSeeAllTenantVisits,
      canSeeAllVisits,
      currentHostUuid,
      currentCompanyUuid,
      filters.hostUuid,
      filters.companyUuid,
      filters.groupUuid,
    ],
  );
};

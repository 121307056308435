import { Formik } from 'formik';
import { FC } from 'react';
import { useValidationSchema } from './visit-form/hooks';
import { FormValues, VisitFormProps } from 'app/components/visits/visit-form/visit-form.interfaces';
import { useInitialValues, useFormSubmitHandler } from 'app/components/visits/visit-form/hooks/editing';
import { VisitModalView } from 'app/components/visits/visit-modal/visit-modal';

export const VisitUpdateForm: FC<VisitFormProps> = ({ onClose }) => {
  const validationSchema = useValidationSchema();
  const initialValues = useInitialValues();
  const { handleSubmit } = useFormSubmitHandler();

  return (
    <Formik<FormValues> initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
      <VisitModalView
        onClose={onClose}
        translationPrefix="modals.editVisit"
        canAddVisitor={false}
        canSaveAsGroup={false}
        canEditVisitTypes={false}
        canEditVisitorDetails={false}
        canSelectMultipleDates={false}
        canEditHost={false}
        shouldReplaceInitialDate={false}
        canChangeVisitVisibility={false}
      />
    </Formik>
  );
};

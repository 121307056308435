import { useVisitsDateFilter } from 'app/components/visits/visits-date-filter/use-visits-date-filter.hook';
import { VisitsMetricsView } from 'app/components/visits/visits-metrics/visits-metrics';
import { buildingUuidSelector, timezoneSelector } from 'app/store/building/selectors';
import { fetchVisitsMetrics } from 'app/store/visits-metrics/actions';
import { visitsMetricsSelector } from 'app/store/visits-metrics/selectors';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useVisitsFilters } from 'app/components/visits/shared/visits-filters.hook';
import { useVisitsSearch } from 'app/components/visits/shared/visits-search.hook';
import { useVisitScopeFilters } from 'app/components/visits/visits-table/hooks/use-visit-scope-filters.hook';
import { getStartOfDateISOString } from 'app/shared/utils/get-start-of-date-iso-string';
import { getEndOfDateISOString } from 'app/shared/utils/get-end-of-date-iso-string';

export const VisitsMetrics: FC = () => {
  const dispatch = useDispatch();
  const { filters } = useVisitsFilters();
  const { companyUuid, hostUuid } = useVisitScopeFilters({
    hostUuid: filters.hostUuid,
    companyUuid: filters.companyUuid,
  });
  const [after, before] = useVisitsDateFilter();
  const timeZone = useSelector(timezoneSelector);
  const [search] = useVisitsSearch();
  const visitsMetrics = useSelector(visitsMetricsSelector);
  const buildingUuid = useSelector(buildingUuidSelector);
  const { status } = filters;

  useEffect(() => {
    dispatch(
      fetchVisitsMetrics.request({
        arrivalTimeAfter: getStartOfDateISOString(after, timeZone),
        before: getEndOfDateISOString(before, timeZone),
        buildingUuid,
        status,
        hostUuid,
        companyUuid,
        search,
      }),
    );
  }, [buildingUuid, after, before, status, hostUuid, companyUuid, search, timeZone]);

  return (
    <VisitsMetricsView
      cancelledVisitsCount={visitsMetrics?.canceled}
      checkedInVisitsCount={visitsMetrics?.checked_in}
      expectedVisitsCount={visitsMetrics?.expected}
      totalVisitsCount={visitsMetrics?.total}
    />
  );
};

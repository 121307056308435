import { buildingUuidSelector } from 'app/store/building/selectors';
import { RootState } from 'app/store/reducer';
import { createSelector } from 'reselect';

export const locationInfoByBuildingState = (state: RootState) => state.locationInfo;

export const locationInfoState = createSelector(
  [buildingUuidSelector, locationInfoByBuildingState],
  (buildingUuid, locationInfoByBuilding) => locationInfoByBuilding?.[buildingUuid] ?? null,
);

export const locationInfoSelector = createSelector(
  [locationInfoState],
  (buildingLocationInfoState) => buildingLocationInfoState?.locationInfo ?? null,
);

import { buildingUuidSelector } from 'app/store/building/selectors';
import { RootState } from 'app/store/reducer';
import { createSelector } from 'reselect';

const visitsFiltersByBuildingState = (state: RootState) => state.visitsFilters;

const visitsFiltersState = createSelector(
  [buildingUuidSelector, visitsFiltersByBuildingState],
  (buildingUuid, visitsByBuilding) => visitsByBuilding?.[buildingUuid] ?? null,
);

export const filtersCompanyOptionsSelector = createSelector(
  [visitsFiltersState],
  (visitsFilters) => visitsFilters?.companies ?? [],
);

export const filtersHostOptionsSelector = createSelector(
  [visitsFiltersState],
  (visitsFilters) => visitsFilters?.hosts ?? [],
);

export const filtersGroupOptionsSelector = createSelector(
  [visitsFiltersState],
  (visitsFilters) => visitsFilters?.groups?.data ?? [],
);

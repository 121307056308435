import { OpenAppParams } from 'example/app';
import qs from 'qs';

const LOCAL_STORAGE_KEY = 'openAppParams';

export const useOpenParams = (): OpenAppParams => {
  const localStorageRawParams = localStorage.getItem(LOCAL_STORAGE_KEY);
  const localStorageParams = localStorageRawParams ? JSON.parse(localStorageRawParams) : null;
  const routeParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  const params = {
    ...localStorageParams,
    ...routeParams,
  };

  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(params));

  return params;
};

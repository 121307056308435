import { MutableRefObject, useCallback, useRef } from 'react';

export const useFileInputControl = ():[VoidFunction, MutableRefObject<HTMLInputElement | null>] => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const clickOnInput = useCallback(() => {
    inputRef?.current?.click();
  }, [inputRef?.current]);

  return [clickOnInput, inputRef];
};

import { Loader } from 'app/components/loader';
import { Modal } from 'app/components/modal';
import { FC, lazy, Suspense } from 'react';
import { useResolvedPath } from 'react-router';

const AddWatchlistPersonForm = lazy(() => import('app/components/add-watchlist-person/add-watchlist-person-form'));

export const AddWatchlistPersonPage: FC = () => {
  const resolvedPath = useResolvedPath('..');

  return (
    <Modal basePath={resolvedPath.pathname} dataTestId="add-watchlist-person-page">
      <Suspense fallback={<Loader testId="add-watchlist-person-loader" />}>
        <AddWatchlistPersonForm />
      </Suspense>
    </Modal>
  );
};

import { PaginatedCompaniesRO } from 'app/store/api-client/api-client.types';

export enum CompanyType {
  CORPORATE = 'CORPORATE',
  LANDLORD = 'LANDLORD',
  TENANT = 'TENANT',
  VENDOR = 'VENDOR',
  DEVELOPER = 'DEVELOPER',
}

export interface Company {
  uuid: string;
  name: string;
  description?: string;
  url?: string;
  logo_url?: string;
  twitter_handle?: string;
  facebook_handle?: string;
  phone_number?: string;
  address: string;
  company_types: CompanyType[];
  created_at: string;
  updated_at: string;
}

export interface BuildingTenantCompaniesState {
  tenantCompanies: Company[] | null;
  loading: boolean;
  error: Error | null;
}

export type TenantCompaniesState = Record<string, BuildingTenantCompaniesState>;

export interface FetchCompaniesParams {
  buildingUuid: string;
}

export interface FetchCompaniesSuccessDto {
  params: FetchCompaniesParams;
  response: PaginatedCompaniesRO;
}

export interface FetchCompaniesErrorDto {
  params: FetchCompaniesParams;
  response: Error;
}

import { BuildingVisitsSettingsState, VisitsSettingsState } from 'app/store/settings/types';
import { ActionType, createReducer } from 'typesafe-actions';
import {
  createDefaultVisitorRegistrationConfig,
  fetchVisitorRegistrationConfig,
  patchVisitorRegistrationConfig,
  uploadEmailBrandingImage,
} from './actions';

export const initialState: VisitsSettingsState = {};
export const initialSettingsState: BuildingVisitsSettingsState = {
  config: null,
  loading: false,
  error: null,
  createDefaultConfigLoading: false,
};

const handleVisitorRegistrationConfigRequest = (
  state: VisitsSettingsState,
  action:
    | ActionType<typeof fetchVisitorRegistrationConfig.request | typeof patchVisitorRegistrationConfig.request>
    | ActionType<typeof uploadEmailBrandingImage.request>,
): VisitsSettingsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialSettingsState,
    ...state[action.payload.buildingUuid],
    error: null,
    loading: true,
  },
});

const handleVisitorRegistrationConfigSuccess = (
  state: VisitsSettingsState,
  action: ActionType<typeof fetchVisitorRegistrationConfig.success | typeof patchVisitorRegistrationConfig.success>,
): VisitsSettingsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    config: action.payload.response.data,
    loading: false,
    error: null,
  },
});

const handleVisitorRegistrationConfigFailure = (
  state: VisitsSettingsState,
  action: ActionType<
    | typeof fetchVisitorRegistrationConfig.failure
    | typeof patchVisitorRegistrationConfig.failure
    | typeof uploadEmailBrandingImage.failure
  >,
): VisitsSettingsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

const handleCreateDefaultVisitorRegistrationConfigRequest = (
  state: VisitsSettingsState,
  action: ActionType<typeof createDefaultVisitorRegistrationConfig.request>,
): VisitsSettingsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialSettingsState,
    ...state[action.payload.buildingUuid],
    createDefaultConfigLoading: true,
  },
});

const handleCreateDefaultVisitorRegistrationConfigSuccess = (
  state: VisitsSettingsState,
  action: ActionType<typeof createDefaultVisitorRegistrationConfig.success>,
): VisitsSettingsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    config: action.payload.response.data,
    createDefaultConfigLoading: false,
  },
});

const handleCreateDefaultVisitorRegistrationConfigFailure = (
  state: VisitsSettingsState,
  action: ActionType<typeof createDefaultVisitorRegistrationConfig.failure>,
): VisitsSettingsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    createDefaultConfigLoading: false,
  },
});

const handleEmailBrandingImageUploadSuccess = (
  state: VisitsSettingsState,
  action: ActionType<typeof uploadEmailBrandingImage.success>,
): VisitsSettingsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    config: {
      ...state[action.payload.params.buildingUuid]?.config,
      branding_image_path: action.payload.response.path,
    },
    loading: false,
    error: null,
  },
});

export const settingsReducer = createReducer(initialState)
  .handleAction(fetchVisitorRegistrationConfig.request, handleVisitorRegistrationConfigRequest)
  .handleAction(fetchVisitorRegistrationConfig.success, handleVisitorRegistrationConfigSuccess)
  .handleAction(fetchVisitorRegistrationConfig.failure, handleVisitorRegistrationConfigFailure)

  .handleAction(patchVisitorRegistrationConfig.request, handleVisitorRegistrationConfigRequest)
  .handleAction(patchVisitorRegistrationConfig.success, handleVisitorRegistrationConfigSuccess)
  .handleAction(patchVisitorRegistrationConfig.failure, handleVisitorRegistrationConfigFailure)

  .handleAction(createDefaultVisitorRegistrationConfig.request, handleCreateDefaultVisitorRegistrationConfigRequest)
  .handleAction(createDefaultVisitorRegistrationConfig.success, handleCreateDefaultVisitorRegistrationConfigSuccess)
  .handleAction(createDefaultVisitorRegistrationConfig.failure, handleCreateDefaultVisitorRegistrationConfigFailure)

  .handleAction(uploadEmailBrandingImage.success, handleEmailBrandingImageUploadSuccess)
  .handleAction(uploadEmailBrandingImage.failure, handleVisitorRegistrationConfigFailure);

import { ToastWrapper } from 'app/components/toast-provider/toast-provider.styles';
import { ZIndex } from 'app/shared/consts/z-index';
import { FC } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export const ToastProvider: FC = () => (
  <ToastWrapper>
    <ToastContainer
      newestOnTop
      position={toast.POSITION.TOP_RIGHT}
      style={{
        zIndex: ZIndex.NOTIFICATION,
        top: '100px',
      }}
      theme="light"
      autoClose={5000}
      hideProgressBar={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      className="toast-container"
    />
  </ToastWrapper>
);

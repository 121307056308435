import { VisitDateTime } from 'app/components/visits/visit-form/visit-form.interfaces';
import { useQueryParam } from 'app/shared/hooks/use-query-param';
import { Time } from 'app/shared/types/time';
import { timezoneSelector } from 'app/store/building/selectors';
import addDays from 'date-fns/addDays';
import addMinutes from 'date-fns/addMinutes';
import clamp from 'date-fns/clamp';
import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import isSameDay from 'date-fns/isSameDay';
import set from 'date-fns/set';
import startOfDay from 'date-fns/startOfDay';
import { toDate, utcToZonedTime } from 'date-fns-tz';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const initialVisitDateTime: VisitDateTime = {
  startTime: null,
  endTime: null,
  startDate: null,
};

export const DEFAULT_START_DATE_QUERY_PARAM = 'defaultVisitDate';

const DEFAULT_TIME_MINUTES_INTERVAL = 15;
const DEFAULT_END_TIME_DELTA_MINUTES = 60;
const END_OF_BUSINESS_HOURS = 18;
const TIME_FORMAT = 'HH:mm';
const START_OF_BUSINESS_HOURS_TIME: Time = '8:00';
const END_OF_BUSINESS_HOURS_TIME: Time = '18:00';

function toPreviousInterval(date: Date, intervalMinutes: number): Date {
  const roundedMinutes = Math.floor(date.getMinutes() / intervalMinutes) * intervalMinutes;

  return set(date, { minutes: roundedMinutes, seconds: 0, milliseconds: 0 });
}

function getLastIntervalInADay(startTime: Date): Date {
  return set(startTime, {
    hours: 23,
    minutes: 45,
    seconds: 0,
    milliseconds: 0,
  });
}

function getStartTime(currentBuildingTime: Date): Date {
  const startTimeCandidate = toPreviousInterval(currentBuildingTime, DEFAULT_TIME_MINUTES_INTERVAL);
  const lastIntervalInADay = getLastIntervalInADay(startTimeCandidate);

  if (isBefore(startTimeCandidate, lastIntervalInADay)) {
    return startTimeCandidate;
  }

  return startOfDay(addDays(startTimeCandidate, 1));
}

function getEndTime(startTime: Date): Date {
  const endOfBusinessHours = set(startTime, {
    hours: END_OF_BUSINESS_HOURS,
    minutes: 0,
    seconds: 0,
    milliseconds: 0,
  });
  const endForOneHourVisit = addMinutes(startTime, DEFAULT_END_TIME_DELTA_MINUTES);
  const lastIntervalInADay = getLastIntervalInADay(startTime);

  return clamp(endOfBusinessHours, {
    start: endForOneHourVisit,
    end: lastIntervalInADay,
  });
}

export type DefaultVisitDateTimeValuesFactory = (startDate?: string) => VisitDateTime;

export const useDefaultVisitDateTimeValuesFactory = (): DefaultVisitDateTimeValuesFactory => {
  const timeZone = useSelector(timezoneSelector);

  return useCallback(
    (startDate?: string) => {
      const currentBuildingTime = utcToZonedTime(new Date(), timeZone);
      const defaultStartDate = startDate ? startOfDay(toDate(startDate)) : currentBuildingTime;
      const startDateCandidate = isBefore(defaultStartDate, currentBuildingTime)
        ? currentBuildingTime
        : defaultStartDate;

      if (!isSameDay(startDateCandidate, currentBuildingTime)) {
        return {
          startTime: START_OF_BUSINESS_HOURS_TIME,
          endTime: END_OF_BUSINESS_HOURS_TIME,
          startDate: startOfDay(startDateCandidate),
        };
      }

      const startTime = getStartTime(startDateCandidate);
      const endTime = getEndTime(startTime);

      return {
        startTime: format(startTime, TIME_FORMAT) as Time,
        endTime: format(endTime, TIME_FORMAT) as Time,
        startDate: startOfDay(startTime),
      };
    },
    [timeZone],
  );
};

export const useInitialVisitDateTimeValues = (): VisitDateTime => {
  const [defaultVisitDate] = useQueryParam(DEFAULT_START_DATE_QUERY_PARAM);
  const visitDateTimeFactory = useDefaultVisitDateTimeValuesFactory();

  return useMemo(() => visitDateTimeFactory(defaultVisitDate), [visitDateTimeFactory, defaultVisitDate]);
};

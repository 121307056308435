import { useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useIntl } from 'react-intl';
import { BACK_ROUTE_QUERY_PARAM } from 'app/components/visits/visit-modal/visit-modal.consts';
import { MANAGE_DAY_PASSES_ROUTE, MANAGE_WATCHLIST_ROUTE } from 'app/shared/consts';
import { SecondaryCtaType } from './secondary-cta.consts';
import { useExportCta } from './use-export-cta.hook';
import { SecondaryCtaProps } from './use-secondary-cta.type';

const getSecondaryCtaProps = (label: string, onClick: VoidFunction) : SecondaryCtaProps => ({
  secondaryButtonText: label,
  onSecondaryButtonClick: onClick,
  secondaryButtonProps: {
    variant: 'Outline',
  },
});

export const useSecondaryCta = (secondaryCtaType?: SecondaryCtaType): SecondaryCtaProps | undefined => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { pathname, search } = useLocation();
  const exportCta = useExportCta();

  const handleNavigateTo = useCallback((postfix: string) => {
    const backRoute = encodeURIComponent(`${pathname}${search}`);
    return () => navigate(`${pathname}/${postfix}?${BACK_ROUTE_QUERY_PARAM}=${backRoute}`);
  }, [navigate, pathname, search]);

  return useMemo(() => {
    switch (secondaryCtaType) {
    case SecondaryCtaType.MANAGE_DAY_PASS_USERS:
      return getSecondaryCtaProps(formatMessage({ id: 'buttons.manageDayPasses' }), handleNavigateTo(MANAGE_DAY_PASSES_ROUTE));
    case SecondaryCtaType.MANAGE_WATCHLIST_USERS:
      return getSecondaryCtaProps(formatMessage({ id: 'buttons.manageWatchlist' }), handleNavigateTo(MANAGE_WATCHLIST_ROUTE));
    case SecondaryCtaType.EXPORT_AUDIT_REPORT:
      return exportCta;
    default:
      return undefined;
    }
  }, [secondaryCtaType, handleNavigateTo, formatMessage]);
};

import { SETTINGS_PATH, SettingsType } from 'app/shared/consts';
import { rootPathSelector, showAdminNav51Selector } from 'app/store/config/selectors';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { TabConfig } from 'app/components/visitor-management-tabs/common-tabs/types';
import { useCommonTabs } from 'app/components/visitor-management-tabs/common-tabs';
import {
  hasManageAccessGroupsSettingsPermissionSelector,
  hasManageDaypassFeaturePermissionsSelector,
  hasManageDaypassUsersPermissionsSelector,
  hasManageGeneralSettingsPermissionSelector,
  hasManageVisitorEmailPermissionSelector,
} from 'app/store/user-permissions/selectors';

const getGeneralSettingsTab = (label: string): TabConfig => ({
  label,
  route: `${SETTINGS_PATH}/${SettingsType.GENERAL}`,
  dataCy: 'general-settings-tab',
});

const getEmailSettingsTab = (label: string): TabConfig => ({
  label,
  route: `${SETTINGS_PATH}/${SettingsType.EMAIL}`,
  dataCy: 'email-template-settings-tab',
});

const getAccessGroupsSettingsTab = (label: string): TabConfig => ({
  label,
  route: `${SETTINGS_PATH}/${SettingsType.ACCESS_GROUPS}`,
  dataCy: 'access-groups-settings-tab',
});

const getFeaturesSettingsTab = (label: string): TabConfig => ({
  label,
  route: `${SETTINGS_PATH}/${SettingsType.FEATURE}`,
  dataCy: 'feature-settings-tab',
});

const getDayPassSettingsTab = (label: string): TabConfig => ({
  label,
  route: `${SETTINGS_PATH}/${SettingsType.DAY_PASS}`,
  dataCy: 'day-pass-settings-tab',
});

export const useSettingsTabs = () => {
  const rootPath = useSelector(rootPathSelector);
  const { formatMessage } = useIntl();
  const showAdminNav51 = useSelector(showAdminNav51Selector);
  const showAccessGroupsSettings = useSelector(hasManageAccessGroupsSettingsPermissionSelector);
  const showFeatureTab = useSelector(hasManageDaypassFeaturePermissionsSelector);
  const showVisitorEmailSettings = useSelector(hasManageVisitorEmailPermissionSelector);
  const showDayPass = useSelector(hasManageDaypassUsersPermissionsSelector);
  const showGeneralSettings = useSelector(hasManageGeneralSettingsPermissionSelector);

  const tabConfigs = useMemo(() => {
    const tabs: TabConfig[] = [];
    const translationPrefix = `settings${showAdminNav51 ? '.adminNav51' : ''}` as const;

    if (showGeneralSettings) {
      tabs.push(getGeneralSettingsTab(formatMessage({ id: `${translationPrefix}.generalSettings` })));
    }

    if (showVisitorEmailSettings) {
      tabs.push(getEmailSettingsTab(formatMessage({ id: `${translationPrefix}.emailTemplateSettings` })));
    }

    if (showAccessGroupsSettings) {
      tabs.push(getAccessGroupsSettingsTab(formatMessage({ id: `${translationPrefix}.accessGroupSettings` })));
    }

    if (showFeatureTab) {
      tabs.push(getFeaturesSettingsTab(formatMessage({ id: 'settings.featuresSettingsTab' })));
    }

    if (showDayPass) {
      tabs.push(getDayPassSettingsTab(formatMessage({ id: 'settings.dayPassSettingsTab' })));
    }

    return tabs;
  }, [formatMessage, showAccessGroupsSettings, showVisitorEmailSettings, showAdminNav51]);

  return useCommonTabs(`${rootPath}`, tabConfigs);
};

import { ajax, AjaxResponse } from 'rxjs/ajax';

import { from, map, Observable, switchMap } from 'rxjs';
import {
  BulkCreateVisitsBody,
  CreateVisitsResponse,
  FetchVisitForBuildingParams,
  FetchVisitResponse,
  FetchVisitsPaginationParams,
  FetchVisitsResponse,
  UpdateVisitOperationParams,
  VisitResponseRO,
  VisitRO,
  SearchVisitByCredentialParams,
  AuditExportType,
} from 'app/store/visits/types';

import qs from 'qs';
import { FetchVisitsMetricsParams, FetchVisitsMetricsResponse } from 'app/store/visits-metrics/types';
import {
  ImageUploadResponse,
  PatchVisitorRegistrationConfigParams,
  VisitorRegistrationConfigParams,
  VisitorRegistrationConfigResponse,
} from 'app/store/settings/types';
import { LocationInfo } from 'app/store/location-info/types';
import { FilterOptionResponseDto, PaginatedFilterOptionResponseDto } from 'app/store/visits-filters/types';
import { FetchCompaniesParams, PaginatedCompaniesRO } from 'app/store/api-client/api-client.types';
import {
  FetchAccessGroupsMappingRulesParams,
  FetchAccessGroupsMappingRulesResponse,
  FetchAccessGroupsParams,
  FetchAccessGroupsResponse,
  UpdateAccessGroupsMappingRulesParams,
  UpdateAccessGroupsMappingRulesResponse,
} from 'app/store/access-groups/types';
import {
  ChangeVisitorPassStatusParams,
  FetchVisitorPassesParams,
  FetchVisitorPassesResponse,
} from 'app/store/visitor-passes/types';
import { FetchHostsParams, PaginatedHostsRO } from 'app/store/hosts/types';
import { FetchVisitorsParams, FetchVisitorsResponse } from 'app/store/visitors/types';
import { VERTICAL_NAME } from 'app/shared/consts';
import { FetchUiMetadataPayloadDto, UiMetadata } from 'app/store/ui-metadata/types';
import { FetchUserPermissionsPayloadDto, UserPermissions } from 'app/store/user-permissions/types';
import { FetchHostDetailsParams, HostDetailsResponse } from 'app/store/host-details/types';
import { FetchVisitorParams, Visitor } from 'app/store/visitor/types';
import {
  DaypassUsersRO,
  FetchDaypassUsersPayload,
  SetCompanyDaypassUsersRequestDto,
} from 'app/store/daypass-users/types';
import {
  DaypassRequestsRO,
  FetchDaypassRequestsPayloadDto,
  SetDaypassRequestApprovalPayloadDto,
} from 'app/store/daypass-requests/types';
import {
  CreateDaypassRequestsPayloadDto,
  DaypassUserCredentialRO,
  DaypassUserRequestResponseDto,
  FetchDaypassUserCredentialPayload,
} from '../daypass-users/daypass-user-credential.types';
import { DaypassCompaniesRO, FetchDaypassCompaniesPayloadDto } from '../daypass-companies/types';
import { BuildingSettingsResponseDto, PatchBuildingSettingsParams } from 'app/store/building-settings/types';
import { DaypassCompaniesModeRO, SaveDaypassModePayloadDto } from '../daypass-modes/types';
import {
  CreateWatchlistRecordParams,
  WatchlistMatch,
  GetWatchlistMatchesCompaniesRO,
  GetWatchlistMatchesHostsRO,
  GetWatchlistMatchesParams,
  GetWatchlistMatchesResponse,
  GetWatchlistRecordResponse,
  GetWatchlistRecordsParams,
  WatchlistRecordRO,
} from '../watchlist/types';
import { FetchVisitorGroupPayloadDto, GetVisitorGroupsQueryDto, PaginatedVisitorGroupRoDto, VisitorGroupRO } from 'app/store/visitor-groups/types';

const CONTENT_TYPE_HEADER = 'Content-Type';

const stringifyQuery = (params: unknown): string =>
  qs.stringify(params, { skipNulls: true, arrayFormat: 'brackets', addQueryPrefix: true });

export class ApiClient {
  constructor(
    private baseUrl: string,
    private getAuthToken: () => Promise<string>,
    private buildingUuid?: string | null,
  ) {}

  protected getDefaultHeaders(): Observable<Record<string, string>> {
    return from(this.getAuthToken()).pipe(
      map((authHeader) => ({
        [CONTENT_TYPE_HEADER]: 'application/json',
        Authorization: authHeader,
        ...(!!this.buildingUuid && { 'HqO-Building-Uuid': this.buildingUuid }),
      })),
    );
  }

  fetchVisits({
    arrivalTimeAfter,
    limit,
    offset,
    search,
    order,
    before,
    status,
    hostUuid,
    visitorUuid,
    companyUuid,
    groupUuid,
  }: FetchVisitsPaginationParams): Observable<FetchVisitsResponse> {
    const queryParams = stringifyQuery({
      limit,
      offset,
      arrival_time_after: arrivalTimeAfter,
      q: search,
      order,
      before,
      status,
      host_uuid: hostUuid,
      company_uuid: companyUuid,
      visitor_profile_uuid: visitorUuid,
      group_uuids: groupUuid,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<FetchVisitsResponse>(
          `${this.baseUrl}/api/visits/v2/buildings/${this.buildingUuid}/visits${queryParams}`,
          headers,
        ),
      ),
    );
  }

  getVisitorGroups(params: GetVisitorGroupsQueryDto): Observable<PaginatedVisitorGroupRoDto> {
    const queryParams = stringifyQuery(params);

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<PaginatedVisitorGroupRoDto>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/visitors/groups${queryParams}`,
          headers,
        ),
      ),
    );
  }

  getVisitorGroup({ visitorGroupUuid }: FetchVisitorGroupPayloadDto): Observable<VisitorGroupRO> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<VisitorGroupRO>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/visitors/group/${visitorGroupUuid}`,
          headers,
        ),
      ),
    );
  }

  fetchCompanyFilterOptions(): Observable<FilterOptionResponseDto[]> {
    return this.getDefaultHeaders().pipe(
      switchMap((header) =>
        ajax.getJSON<FilterOptionResponseDto[]>(
          `${this.baseUrl}/api/visits/v2/buildings/${this.buildingUuid}/visits/filter/companies`,
          header,
        ),
      ),
    );
  }

  fetchHostFilterOptions(): Observable<FilterOptionResponseDto[]> {
    return this.getDefaultHeaders().pipe(
      switchMap((header) =>
        ajax.getJSON<FilterOptionResponseDto[]>(
          `${this.baseUrl}/api/visits/v2/buildings/${this.buildingUuid}/visits/filter/hosts`,
          header,
        ),
      ),
    );
  }

  fetchGroupFilterOptions(): Observable<PaginatedFilterOptionResponseDto> {
    return this.getDefaultHeaders().pipe(
      switchMap((header) =>
        ajax.getJSON<PaginatedFilterOptionResponseDto>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/visits/filters/groups`,
          header,
        ),
      ),
    );
  }

  checkInVisit(visitUuid: string): Observable<AjaxResponse<VisitRO>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<VisitRO>(`${this.baseUrl}/api/visits/v2/visits/${visitUuid}/check-in`, null, headers),
      ),
    );
  }

  checkOutVisit(visitUuid: string): Observable<AjaxResponse<VisitRO>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<VisitRO>(`${this.baseUrl}/api/visits/v2/visits/${visitUuid}/check-out`, null, headers),
      ),
    );
  }

  cancelVisit(visitUuid: string): Observable<AjaxResponse<void>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) => ajax.delete<void>(`${this.baseUrl}/api/visits/v2/visits/${visitUuid}`, headers)),
    );
  }

  fetchVisitsMetrics({
    arrivalTimeAfter,
    before,
    companyUuid,
    hostUuid,
    status,
    search,
  }: FetchVisitsMetricsParams): Observable<FetchVisitsMetricsResponse> {
    const queryParams = stringifyQuery({
      arrival_time_after: arrivalTimeAfter,
      before,
      company_uuid: companyUuid,
      host_uuid: hostUuid,
      q: search,
      status,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<FetchVisitsMetricsResponse>(
          `${this.baseUrl}/api/visits/v2/buildings/${this.buildingUuid}/visits/stats${queryParams}`,
          headers,
        ),
      ),
    );
  }

  fetchVisit({ visitUuid, include }: FetchVisitForBuildingParams): Observable<FetchVisitResponse> {
    const queryParams = stringifyQuery({
      include,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<FetchVisitResponse>(
          `${this.baseUrl}/api/visits/v2/buildings/${this.buildingUuid}/visits/${visitUuid}${queryParams}`,
          headers,
        ),
      ),
    );
  }

  createVisits(body: BulkCreateVisitsBody): Observable<AjaxResponse<CreateVisitsResponse>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<CreateVisitsResponse>(
          `${this.baseUrl}/api/visits/v2/buildings/${this.buildingUuid}/visits/bulk`,
          body,
          headers,
        ),
      ),
    );
  }

  createWatchlistRecord(body: CreateWatchlistRecordParams): Observable<AjaxResponse<WatchlistRecordRO>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<WatchlistRecordRO>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/watchlist`,
          body,
          headers,
        ),
      ),
    );
  }

  bulkUploadWatchlistRecords(file: File): Observable<AjaxResponse<void>> {
    return this.getDefaultHeaders().pipe(
      switchMap(({ [CONTENT_TYPE_HEADER]: _, ...headers }) => {
        const formData = new FormData();
        formData.append('file', file);

        return ajax.post<void>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/watchlist/bulk`,
          formData,
          headers,
        );
      }),
    );
  }

  getVisitsAudit(exportType: AuditExportType, after: Date, before: Date): Observable<AjaxResponse<ArrayBuffer>> {
    const queryParams = stringifyQuery({
      after,
      before,
    });
    const url = exportType === AuditExportType.CSV
      ? `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/audit/visits/csv${queryParams}`
      : `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/audit/visits/pdf${queryParams}`;
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax<ArrayBuffer>({
          url,
          method: 'GET',
          responseType: 'blob',
          headers: {
            ...headers,
            responseType: 'blob',
          },
        }),
      ),
    );
  }

  getWatchlistRecords({
    offset,
    limit,
    query,
  }: Partial<GetWatchlistRecordsParams>): Observable<GetWatchlistRecordResponse> {
    const queryParams = stringifyQuery({
      offset,
      limit,
      query,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<GetWatchlistRecordResponse>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/watchlist${queryParams}`,
          headers,
        ),
      ),
    );
  }

  deleteWatchlistRecord({ watchlistUuid }: { watchlistUuid: string }): Observable<AjaxResponse<void>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.delete<void>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/watchlist/${watchlistUuid}`,
          headers,
        ),
      ),
    );
  }

  getWatchlistMatches({
    companyUuid,
    hostUuid,
    limit,
    offset,
    order,
    orderBy,
    query,
    status,
  }: Partial<GetWatchlistMatchesParams>): Observable<GetWatchlistMatchesResponse> {
    const queryParams = stringifyQuery({
      companyUuid: companyUuid || null,
      hostUuid: hostUuid || null,
      limit,
      offset,
      order,
      orderBy,
      query,
      status,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<GetWatchlistMatchesResponse>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/watchlist/matches${queryParams}`,
          headers,
        ),
      ),
    );
  }

  getWatchlistMatchesCompanies(): Observable<GetWatchlistMatchesCompaniesRO[]> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<GetWatchlistMatchesCompaniesRO[]>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/watchlist/matches/filter/companies`,
          headers,
        ),
      ),
    );
  }

  getWatchlistMatchesHosts(): Observable<GetWatchlistMatchesHostsRO[]> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<GetWatchlistMatchesHostsRO[]>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/watchlist/matches/filter/hosts`,
          headers,
        ),
      ),
    );
  }

  denyWatchlistMatch(watchlistMatchUuid: string, note: string): Observable<AjaxResponse<WatchlistMatch>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<WatchlistMatch>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/watchlist/matches/${watchlistMatchUuid}/deny`,
          { note },
          headers,
        ),
      ),
    );
  }

  approveWatchlistMatch(
    watchlistMatchUuid: string,
    alwaysApprove: boolean,
    note: string,
  ): Observable<AjaxResponse<WatchlistMatch>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<WatchlistMatch>(
          `${this.baseUrl}/visitor-registration/v1/locations/${this.buildingUuid}/watchlist/matches/${watchlistMatchUuid}/approve`,
          { note, alwaysApprove },
          headers,
        ),
      ),
    );
  }

  notifyHost(visitUuid: string): Observable<AjaxResponse<void>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<void>(`${this.baseUrl}/api/visits/v2/${visitUuid}/notify-host`, undefined, headers),
      ),
    );
  }

  createDefaultVisitorRegistrationApp({
    buildingUuid,
  }: VisitorRegistrationConfigParams): Observable<AjaxResponse<VisitorRegistrationConfigResponse>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<VisitorRegistrationConfigResponse>(
          `${this.baseUrl}/api/visitor-registration/v1/locations/${buildingUuid}/config/default`,
          null,
          headers,
        ),
      ),
    );
  }

  fetchVisitorRegistrationConfig(): Observable<VisitorRegistrationConfigResponse> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) => ajax.getJSON<VisitorRegistrationConfigResponse>(this.configRoute, headers)),
    );
  }

  patchVisitorRegistrationConfig({
    config,
  }: Pick<PatchVisitorRegistrationConfigParams, 'config'>): Observable<
    AjaxResponse<VisitorRegistrationConfigResponse>
  > {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) => ajax.patch<VisitorRegistrationConfigResponse>(this.configRoute, config, headers)),
    );
  }

  fetchLocationInfo(): Observable<LocationInfo> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<LocationInfo>(
          `${this.baseUrl}/api/visitor-registration/v2/locations/${this.buildingUuid}`,
          headers,
        ),
      ),
    );
  }

  uploadImage(imageForm: FormData): Observable<AjaxResponse<ImageUploadResponse>> {
    return this.getDefaultHeaders().pipe(
      switchMap(({ [CONTENT_TYPE_HEADER]: _, ...headers }) =>
        ajax.post<ImageUploadResponse>(`${this.baseUrl}/api/images/upload`, imageForm, headers),
      ),
    );
  }

  fetchVisitors({
    query,
    companyUuids,
    buildingUuids,
    hostUserUuids,
    limit,
    offset,
  }: FetchVisitorsParams): Observable<FetchVisitorsResponse> {
    const queryParams = stringifyQuery({
      query,
      company_uuids: companyUuids,
      building_uuids: buildingUuids,
      host_user_uuids: hostUserUuids,
      limit,
      offset,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<FetchVisitorsResponse>(`${this.baseUrl}/api/visits/v2/visitors${queryParams}`, headers),
      ),
    );
  }

  fetchAccessGroups({ buildingUuid }: FetchAccessGroupsParams): Observable<FetchAccessGroupsResponse> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<FetchAccessGroupsResponse>(
          `${this.baseUrl}/access-bridge/v1/buildings/${buildingUuid}/access-groups`,
          headers,
        ),
      ),
    );
  }

  fetchVisitorPassesInfo({ visitUuids }: FetchVisitorPassesParams) {
    const queryParams = stringifyQuery({
      visitUuids,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<FetchVisitorPassesResponse>(
          `${this.baseUrl}/access-bridge/v1/visitor-passes${queryParams}`,
          headers,
        ),
      ),
    );
  }

  activateVisitorPassByUuid({ action: actionStatus, visitorPassUuid }: ChangeVisitorPassStatusParams) {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.put<FetchVisitorPassesResponse>(
          `${this.baseUrl}/access-bridge/v1/visitor-passes/${visitorPassUuid}/status`,
          { action: actionStatus },
          headers,
        ),
      ),
    );
  }

  fetchAccessGroupsMappingRules({
    buildingUuid,
  }: FetchAccessGroupsMappingRulesParams): Observable<FetchAccessGroupsMappingRulesResponse> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<FetchAccessGroupsMappingRulesResponse>(
          `${this.baseUrl}/access-bridge/v1/buildings/${buildingUuid}/access-groups/mapping-rules/visitor`,
          headers,
        ),
      ),
    );
  }

  updateAccessGroupsMappingRules({
    buildingUuid,
    payload,
  }: UpdateAccessGroupsMappingRulesParams): Observable<AjaxResponse<UpdateAccessGroupsMappingRulesResponse>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.put<UpdateAccessGroupsMappingRulesResponse>(
          `${this.baseUrl}/access-bridge/v1/buildings/${buildingUuid}/access-groups/mapping-rules/visitor`,
          payload,
          headers,
        ),
      ),
    );
  }

  fetchBuildingCompanies({
    buildingUuid,
    type,
    offset,
    limit,
  }: FetchCompaniesParams): Observable<PaginatedCompaniesRO> {
    const queryParams = stringifyQuery({
      type,
      offset,
      limit,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<PaginatedCompaniesRO>(
          `${this.baseUrl}/api/admin/v1/buildings/${buildingUuid}/companies${queryParams}`,
          headers,
        ),
      ),
    );
  }

  updateVisit({ visitUuid, visit: body }: UpdateVisitOperationParams): Observable<AjaxResponse<VisitRO>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.patch<VisitRO>(
          `${this.baseUrl}/api/visits/v2/buildings/${this.buildingUuid}/visits/${visitUuid}`,
          body,
          headers,
        ),
      ),
    );
  }

  fetchHosts({ buildingUuid, query, companyUuid }: FetchHostsParams): Observable<PaginatedHostsRO> {
    const queryParams = stringifyQuery({
      query,
      buildingUuids: [buildingUuid],
      companyUuid,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<PaginatedHostsRO>(`${this.baseUrl}/api/visits/v2/hosts${queryParams}`, headers),
      ),
    );
  }

  fetchHostDetails({ uuid }: FetchHostDetailsParams): Observable<HostDetailsResponse> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<HostDetailsResponse>(`${this.baseUrl}/api/admin/v1/users/${uuid}/info`, headers),
      ),
    );
  }

  fetchVisitor({ uuid }: FetchVisitorParams): Observable<Visitor> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<Visitor>(`${this.baseUrl}/api/visits/v2/buildings/${this.buildingUuid}/visitors/${uuid}`, headers),
      ),
    );
  }

  fetchUiMetadata({ buildingUuid }: FetchUiMetadataPayloadDto): Observable<UiMetadata> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<UiMetadata>(
          `${this.baseUrl}/api/verticals/${VERTICAL_NAME}/BUILDING/${buildingUuid}/ui-metadata`,
          headers,
        ),
      ),
    );
  }

  fetchUserPermissions({ buildingUuid, permissions }: FetchUserPermissionsPayloadDto): Observable<UserPermissions> {
    const queryParams = stringifyQuery({
      buildingUuid,
      permissions,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<UserPermissions>(`${this.baseUrl}/api/user-permissions/v1${queryParams}`, headers),
      ),
    );
  }

  fetchDaypassUsers({
    buildingUuid,
    companyUuid,
    limit,
    offset,
    includeAll,
    text,
  }: FetchDaypassUsersPayload): Observable<DaypassUsersRO> {
    const queryParams = stringifyQuery({
      filter: {
        text,
        ...(!!companyUuid && { company: companyUuid }),
      },
      limit,
      offset,
      includeAll,
    });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<DaypassUsersRO>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/daypass-users${queryParams}`,
          headers,
        ),
      ),
    );
  }

  fetchDaypassRequests({
    buildingUuid,
    companyUuid,
    limit,
    offset,
    status,
    search,
  }: FetchDaypassRequestsPayloadDto): Observable<DaypassRequestsRO> {
    const queryParams = stringifyQuery({ limit, offset, status, text: search });

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<DaypassRequestsRO>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/companies/${companyUuid}/daypass-requests${queryParams}`,
          headers,
        ),
      ),
    );
  }

  fetchDaypassCompanies({ buildingUuid }: FetchDaypassCompaniesPayloadDto): Observable<DaypassCompaniesRO> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<DaypassCompaniesRO>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/daypass-users/companies`,
          headers,
        ),
      ),
    );
  }

  saveDaypassMode({
    approvalEnabled,
    buildingUuid,
    companyUuid,
    daypassMode,
  }: SaveDaypassModePayloadDto): Observable<AjaxResponse<void>> {
    const body = { approvalEnabled, daypassMode };

    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<void>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/companies/${companyUuid}/daypass-mode`,
          body,
          headers,
        ),
      ),
    );
  }

  getBuildingSettings(buildingUuid: string): Observable<BuildingSettingsResponseDto> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<BuildingSettingsResponseDto>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/settings`,
          headers,
        ),
      ),
    );
  }

  setBuildingSettings({
    buildingUuid,
    settings,
  }: PatchBuildingSettingsParams): Observable<AjaxResponse<BuildingSettingsResponseDto>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.patch<BuildingSettingsResponseDto>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/settings`,
          settings,
          headers,
        ),
      ),
    );
  }

  setDaypassRequestApproval({ buildingUuid, requestUuid, status }: SetDaypassRequestApprovalPayloadDto) {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.put<void>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/daypass-requests/${requestUuid}/status`,
          { status },
          headers,
        ),
      ),
    );
  }

  getDaypassUserCredential({
    buildingUuid,
    companyUuid,
    personUuid,
  }: FetchDaypassUserCredentialPayload): Observable<DaypassUserCredentialRO> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<DaypassUserCredentialRO>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/companies/${companyUuid}/daypass-users/${personUuid}/credential`,
          headers,
        ),
      ),
    );
  }

  setCompanyDaypassUsers({
    buildingUuid,
    companyUuid,
    ...data
  }: SetCompanyDaypassUsersRequestDto): Observable<AjaxResponse<void>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<void>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/companies/${companyUuid}/daypass-users`,
          data,
          headers,
        ),
      ),
    );
  }

  createDaypassRequest({
    buildingUuid,
    personUuid,
  }: CreateDaypassRequestsPayloadDto): Observable<AjaxResponse<DaypassUserRequestResponseDto>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<DaypassUserRequestResponseDto>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/daypass-users/${personUuid}/daypass-requests`,
          null,
          headers,
        ),
      ),
    );
  }

  fetchDaypassCompaniesMode({ buildingUuid }: FetchDaypassCompaniesPayloadDto): Observable<DaypassCompaniesModeRO> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.getJSON<DaypassCompaniesModeRO>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/companies/all/daypass-modes`,
          headers,
        ),
      ),
    );
  }

  searchVisitByCredential({
    buildingUuid,
    credential,
  }: SearchVisitByCredentialParams): Observable<AjaxResponse<VisitResponseRO>> {
    return this.getDefaultHeaders().pipe(
      switchMap((headers) =>
        ajax.post<VisitResponseRO>(
          `${this.baseUrl}/visitor-registration/v1/locations/${buildingUuid}/visits/credentials/search`,
          { credential },
          headers,
        ),
      ),
    );
  }

  private get configRoute(): string {
    return `${this.baseUrl}/api/visitor-registration/v1/locations/${this.buildingUuid}/config`;
  }
}

import styled from 'styled-components';

import { TimePickerInput as HqOTimePickerInput } from '@hqo/react-components-library/dist/molecules/time-picker-input';
import { ZIndex } from 'app/shared/consts/z-index';

export const TimePickerInput = styled(HqOTimePickerInput)`
  .popover-body {
    z-index: ${ZIndex.POPOVER};
  }
`;

import { Loader } from 'app/components/loader';
import { VisitCreationForm } from 'app/components/visits/visit-creation-form';
import { FC, lazy, Suspense } from 'react';

const VisitModal = lazy(() => import('app/components/visits/visit-modal/visit-modal.container'));

export const CreateVisitPage: FC = () => (
  <Suspense fallback={<Loader testId="create-visit-form-loader" />}>
    <VisitModal FormComponent={VisitCreationForm} />
  </Suspense>
);

import { createSelector } from 'reselect';
import { RootState } from 'app/store/reducer';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { BuildingUiMetadataState, UiMetadata } from 'app/store/ui-metadata/types';

const uiMetadataByBuildingState = (state: RootState) => state.uiMetadata;

export const uiMetadataState = createSelector(
  [buildingUuidSelector, uiMetadataByBuildingState],
  (buildingUuid, uiMetadataByBuilding) => uiMetadataByBuilding?.[buildingUuid] ?? null,
);
export const uiMetadataSelector = createSelector(
  uiMetadataState,
  (state: BuildingUiMetadataState): UiMetadata | null => state?.uiMetadata ?? null,
);
export const uiMetadataLoadingState = createSelector(
  uiMetadataState,
  (state: BuildingUiMetadataState): boolean => state?.loading ?? false,
);
export const uiMetadataErrorState = createSelector(
  uiMetadataState,
  (state: BuildingUiMetadataState): Error | null => state?.error ?? null,
);

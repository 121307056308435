import { useCallback, useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { localeSelector } from 'app/store/config/selectors';
import { FormValues } from 'app/components/visits/visit-form/visit-form.interfaces';
import { DATE_FORMAT_WITH_WEEK_DAY, formatDateForUI } from 'app/utils';
import { DateHeader, FlexContainer, Icon } from './date-and-time.styles';
import { VISIT_DATE_TIMES_FIELD_NAME } from '../consts';

export interface TimeSelectorHeaderProps {
  date: Date | null;
  index: number;
}

const DELETE_ICON_NAME = 'delete';

export const TimeSelectorHeader = ({ date, index }: TimeSelectorHeaderProps) => {
  const { setFieldValue, values: { visitDateTimes } } = useFormikContext<FormValues>();
  const locale = useSelector(localeSelector);

  const handleOnDelete = useCallback(() => {
    setFieldValue(VISIT_DATE_TIMES_FIELD_NAME, visitDateTimes.filter((_, idx) => idx !== index));
  }, [index, visitDateTimes, setFieldValue]);

  const formattedDate = useMemo(() =>
    (date ? formatDateForUI(date, locale, DATE_FORMAT_WITH_WEEK_DAY) : ''),
  [date, locale]);

  return (
    <FlexContainer>
      <DateHeader>
        {formattedDate}
      </DateHeader>
      <Icon onClick={handleOnDelete} className="material-icons">
        {DELETE_ICON_NAME}
      </Icon>
    </FlexContainer>
  );
};
